<template>
  <div>
    <component :is="getAccessToken != null ? home : login">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getAccessToken: localStorage.getItem("accessToken"),
      home: "home",
      login: "login",
      common: "common",
    };
  },

  methods: {},
  computed: {},

  watch: {
    $route(to) {
      (document.title = to.meta.title), to.meta.array_index + " . Amen";
    },
  },
};
</script>

<style scoped>
body {
  background-color: white !important;
}
</style>
