<template>
  <div>
    <b-card-group deck class="JDI-card-group">
      <b-card no-body class="JDI-card">
        <div class="float-right">
          <b-button
            size="sm"
            class="float-right mt-3 mr-2 btn-load"
            variant="info"
            @click="loadPrevioushistory"
            >Load previous History</b-button
          >
        </div>
        <keep-alive>
          <b-row>
            <b-col lg="6" class="mx-1">
              <h5 class="subTitle">Note</h5>
              <b-row class="my-1">
                <b-col sm="1" style="padding-left: 25px">
                  <label>C/C</label>
                </b-col>
                <b-col sm="10">
                  <b-form-input
                    v-model="title.cc"
                    placeholder="Type C/C content Here.."
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col lg="1" style="padding-left: 25px">
                  <label>HPI</label>
                </b-col>
                <b-col lg="10">
                  <b-form-textarea
                    v-model="title.hpi"
                    placeholder="Type HPI Content Here..."
                    rows="10"
                    max-rows="10"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="11">
                  <b-button
                    variant="info"
                    class="float-right mx-2 my-1 btnPositive"
                    size="sm"
                    :disabled="Titlebtn"
                    @click="addTitle"
                  >
                    Add
                  </b-button>
                </b-col>
              </b-row>
            </b-col>

            <b-col lg="5" class="mx-1">
              <h5 class="subTitle">Physical Examination</h5>
              <b-row>
                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">BP</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.bp"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">Ht</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.ht"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">PR</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.pr"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">Wt</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.wt"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">RR</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.rr"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">BMI</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        class="red"
                        :class="{ green: bmiChangeColor }"
                        :disabled="true"
                        v-model="bmicalculate"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2" class="mx-1">
                      <label size="sm" for="input-small">PSO<sub>2</sub></label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.pso"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">AC</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.ac"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label for="input-small">T<sup>o</sup></label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.to"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="2" class="mx-1">
                      <label size="sm" for="input-small">HC</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        v-model="pe.hc"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6" class="col-6"> </b-col>

                <b-col lg="6" class="col-6">
                  <b-row class="my-1">
                    <b-col sm="3" class="mx-1">
                      <label size="sm" for="input-small">MUAC</label>
                    </b-col>
                    <b-col sm="7">
                      <b-form-input
                        v-model="pe.muac"
                        id="input-small"
                        size="sm"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="textAreaRow">
                <b-form-textarea
                  id="textarea"
                  v-model="pe.descrpition"
                  placeholder="further note for P/E"
                  rows="2"
                  max-rows="4"
                ></b-form-textarea>
              </b-row>

              <b-row>
                <b-col>
                  <b-button
                    variant="info"
                    class="float-right mx-2 my-1 btnPositive"
                    size="sm"
                    @click="addPE"
                    :disabled="Pebtn"
                  >
                    Add
                  </b-button>
                  <b-button
                    variant="danger"
                    class="float-right mx-2 my-1"
                    size="sm"
                    @click="clearPE"
                    v-if="physicalValidate"
                  >
                    Clear
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </keep-alive>
      </b-card>
    </b-card-group>

    <b-card no-body class="mx-1">
      <b-row style="margin: 2px">
        <b-col sm="1">
          <label for="input-none">DX</label>
        </b-col>
        <b-col sm="11">
          <b-form-input
            id="input-none"
            v-model="footer.dx"
            placeholder="Type diagnosis here.."
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            class="float-right mx-2 my-1 btnPositive"
            size="sm"
            :disabled="Dxbtn"
            @click="addFooter"
          >
            Add
          </b-button>
          <b-button
            v-if="footerValidate"
            variant="danger"
            class="float-right mx-2 my-1"
            size="sm"
            @click="clearFooter"
          >
            Clear
          </b-button>
        </b-col>
      </b-row>

      <b-row style="margin: 2px">
        <b-col sm="1">
          <label for="input-none">Treatment</label>
        </b-col>
        <b-col sm="11">
          <b-form-input
            id="input-none"
            v-model="footer.treatment"
            placeholder="Type treatment here.."
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            variant="info"
            class="float-right mx-2 my-1 btnPositive"
            size="sm"
            :disabled="Treatmentbtn"
            @click="addTreatment"
          >
            Add
          </b-button>
          <b-button
            v-if="treatmentValidate"
            variant="danger"
            class="float-right mx-2 my-1"
            size="sm"
            @click="clearTreatment"
          >
            Clear
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      Treatmentbtn: false,
      Dxbtn: false,
      Titlebtn: false,
      Pebtn: false,
      result: "",
      drsNote: null,
      physical: null,
      diagnosis: null,
      treatmentLoad: null,
      title: {
        cc: "",
        hpi: "",
      },
      pe: {
        bp: "",
        pr: "",
        rr: "",
        to: "",
        wt: 1,
        bmi: 1,
        pso: "",
        ac: "",
        hc: "",
        muac: "",
        ht: 1,
        descrpition: "",
      },
      footer: {
        dx: "",
        treatment: "",
      },
    };
  },
  created() {
    this.drsNote = this.getPatientProfile.patient.medicalHistory[0].drsNote;
    this.diagnosis = this.getPatientProfile.patient.medicalHistory[0].dx;
    this.treatmentLoad = this.getPatientProfile.patient.medicalHistory[0].txMgmt;
    this.physical = this.getPatientProfile.patient.medicalHistory[0].physicalExam;
  },
  methods: {
    ...mapActions([
      "addPhysicalExam",
      "fetchPhysicalExams",
      "getPatientProfile",
      "addDrsNote",
      "addDiagnosis",
      "addTreatmentsWithDx",
    ]),
    loadPrevioushistory() {
      if (this.drsNote != null) {
        this.title.cc = this.drsNote[0].c_c;
        this.title.hpi = this.drsNote[0].hpi;
      }
      if (this.physical != null) {
        this.pe.bp = this.physical[0].bp;
        this.pe.pr = this.physical[0].pr;
        this.pe.rr = this.physical[0].rr;
        this.pe.to = this.physical[0].t;
        this.pe.pso = this.physical[0].pso2;
        this.pe.wt = this.physical[0].wt;
        this.pe.ht = this.physical[0].ht;
        this.pe.bmi = this.physical[0].bmi;
        this.pe.hc = this.physical[0].hc;
        this.pe.muac = this.physical[0].muac;
        this.pe.ac = this.physical[0].ac;
        this.pe.descrpition = this.physical[0].p_e_note;
      }

      if (this.diagnosis != null) {
        this.footer.dx = this.diagnosis[0].dx;
      }
      if (this.treatmentLoad != null) {
        this.footer.treatment = this.treatmentLoad[0].txMgmt;
      }
    },
    addTitle() {
      const titleData = {
        c_c: this.title.cc,
        hpi: this.title.hpi,
        patientId: this.getPatientProfile.patient._id,
      };

      this.addDrsNote(titleData);
      this.Titlebtn = true;
      this.$bvToast.toast("Note Added", {
        title: "Note Added Successfully",
        variant: "sucesss",
        solid: true,
      });
    },
    clearTitle() {
      (this.title.cc = ""), (this.title.hpi = "");
    },

    // for p/e
    addPE() {
      const peData = {
        bp: this.pe.bp,
        pr: this.pe.pr,
        rr: this.pe.rr,
        t: this.pe.to,
        wt: this.pe.wt,
        bmi: this.bmicalculate,
        ac: this.pe.ac,
        hc: this.pe.hc,
        muac: this.pe.muac,
        pso2: this.pso,
        ht: this.pe.ht,
        p_e_note: this.pe.descrpition,
        patientId: this.getPatientProfile.patient._id,
      };
      this.addPhysicalExam(peData)
        .then(() => {
          this.Pebtn = true;
          this.$bvToast.toast("Note Added", {
            title: "Physical Exam Added Successfully",
            variant: "sucesss",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Note Added", {
            title: "Failed",
            variant: "sucesss",
            solid: true,
          });
        });
    },
    clearPE() {
      (this.pe.bp = ""),
        (this.pe.pr = ""),
        (this.pe.rr = ""),
        (this.pe.to = ""),
        (this.pe.wt = ""),
        (this.pe.bmi = ""),
        (this.pe.pso = ""),
        (this.pe.ht = "");
    },

    // for the footer one dx and ix
    addFooter() {
      const footerData = {
        dx: this.footer.dx,
        patientId: this.getPatientProfile.patient._id,
      };
      this.addDiagnosis(footerData)
        .then(() => {
          this.Dxbtn = true;
          this.$bvToast.toast("Note Added", {
            title: "Diagnosis Added Successfully.",
            variant: "sucesss",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Note Added", {
            title: "Failed",
            variant: "sucesss",
            solid: true,
          });
        });
    },
    addTreatment() {
      const footerData = {
        dx: this.footer.treatment,
        patientId: this.getPatientProfile.patient._id,
      };
      this.addTreatmentsWithDx(footerData)
        .then(() => {
          this.Treatmentbtn = true;
          this.$bvToast.toast("Treatment Added", {
            title: "Treatment added successfully.",
            variant: "sucesss",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed", {
            title: "Failed",
            variant: "sucesss",
            solid: true,
          });
        });
    },
    clearFooter() {
      (this.footer.dx = ""), (this.footer.ix = "");
    },
  },
  computed: {
    ...mapGetters(["getPhysicalExams", "getPhysicalExam", "getPatientProfile"]),
    titleValidate() {
      return this.title.cc == null || this.title.hpi ? true : false;
    },
    bmiChangeColor() {
      return this.bmicalculate > 18 && this.bmicalculate < 25 ? true : false;
    },

    bmicalculate() {
      let weight = this.pe.wt;
      let height = this.pe.ht;
      return Number(weight / (height * height)).toFixed(2);
    },
    changeColor() {
      return this.bmicalculate > 24 ? true : false;
    },
    physicalValidate() {
      return this.pe.bp == null ||
        this.pe.pr == null ||
        this.pe.to == null ||
        this.pe.rr == null ||
        this.pe.wt == null ||
        this.pe.ht == null ||
        this.pe.bmi == null ||
        this.pe.pso == null ||
        this.pe.descrpition == null
        ? true
        : false;
    },
    footerValidate() {
      return this.footer.dx == null ? true : false;
    },
  },
};
</script>
<style scoped>
.JDI-card-group {
  margin-top: 0px;
  padding-top: 0px;
}
.JDI-card {
  margin-top: 0px;
  padding-top: 0px;
}

.btn-load {
  width: auto;
}

.textAreaRow {
  margin: 2px 13px;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
.subTitle {
  padding-left: 2rem;
  padding-top: 1rem;
}
</style>
