<template>
  <b-container fluid class="container">
    <b-row class="my-1">
      <b-col sm="4">
        <label for="input-small">Current Password:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input v-model="pPassword" size="sm"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col sm="4">
        <label for="input-small">New Password:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input
          v-model="nPassword"
          type="password"
          size="sm"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col sm="4">
        <label for="input-small" class="float-right">Confirm:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input
          v-model="cPassword"
          type="password"
          size="sm"
          :class="{ incorrect: !validateInncorrect }"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      size="sm"
      class="float-right"
      :disabled="!validatePassword"
      @click="changePass"
    >
      Change
    </b-button>

    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      userId: "",
      username: "",
      pPassword: "",
      nPassword: "",
      cPassword: "",
    };
  },
  created() {
    let user_token = VueJwtDecode.decode(localStorage.getItem("accessToken"));
    (this.userId = user_token.userId), (this.username = user_token.username);
  },
  methods: {
    ...mapActions(["changePassword"]),
    changePass() {
      const data = {
        userId: this.userId,
        username: this.username,
        currentPassword: this.pPassword,
        password1: this.nPassword,
        password2: this.cPassword,
      };
      this.changePassword(data);
      this.$bvToast.show("toaster");
      this.clear();
    },
    clear() {
      (this.pPassword = ""), (this.nPassword = ""), (this.cPassword = "");
    },
  },
  computed: {
    ...mapGetters(["getErrors"]),
    validatePassword() {
      return this.nPassword == this.cPassword &&
        this.nPassword != "" &&
        this.cPassword != "" &&
        this.pPassword != ""
        ? true
        : false;
    },
    validateInncorrect() {
      return this.nPassword == this.cPassword ? true : false;
    },
  },
};
</script>

<style scoped>
.incorrect {
  border: 1px solid red;
}
.container {
  width: 500px;
  height: auto;
  margin-top: 100px;
}
</style>
