<template>
  <div>
    <b-card no-body>
      <b-card-header style="padding: 0px">
        <b-form>
          <b-row class="Jdi-row">
            <b-col lg="4" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Medication category:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.categorie"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="8">
              <b-button
                style="width: auto"
                v-if="createMode"
                :disabled="categoryValidate"
                @click="registerCategorie"
                variant="primary"
                class="float-right mt-3"
              >
                Save
              </b-button>

              <b-button
                style="width: auto"
                v-if="editMode"
                :disabled="categoryValidate"
                @click="updateCategoriesData"
                variant="info"
                class="float-right mt-3"
              >
                Update
              </b-button>

              <!-- <b-button
                style="width: auto"
                variant="danger"
                :disabled="categoryValidate"
                class="float-right mt-3 mr-4"
              >
                Clear
              </b-button> -->
            </b-col>
            <!-- <ul v-for="h in haftom" :key="h.id">
            <li>{{h.name}}</li>
            <ul v-for="a in h.address" :key="a.id">
            <li>{{a.name}}</li>
            
            </ul>
            </ul> -->
          </b-row>
        </b-form>
      </b-card-header>

      <b-card-body class="Jdi-body">
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :busy.sync="isBusy"
          :current-page="currentPage"
          :per-page="per_page"
          :small="small"
          :sort-compare-options="{ numeric: true, sensitivity: 'base' }"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getLabTests"
          show-empty
          :fields="fields"
          :empty-text="
            getTestsList
              ? 'There is no medication category yet.'
              : 'Unable to load data.'
          "
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(status)="data">
            <toggle-button
              :value="data.item.status >= 1 ? true : false"
              color="#007bff"
              :labels="true"
              @change="updateStatus(data.item._id)"
            />
          </template>

          <template v-slot:cell(actions)="data">
            <b-link @click="editCategorie(data.item._id)" class="text-primary">
              <b-icon icon="pen" variant="primary"></b-icon> </b-link
            >.
            <b-link
              @click="deleteCategories(data.item._id)"
              class="text-danger"
            >
              <b-icon icon="trash" variant="danger"></b-icon>
            </b-link>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";
//import router from "../../routes";
export default {
  data() {
    return {
      small: true,
      isdrop: true,
      value: false,
      getTestsList: false,
      myToggle: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortBy: "age",
      sortDesc: false,
      isBusy: false,
      curent_page: 1,
      per_page: 20,
      pageOptions: [5, 10, 15, "All"],

      form: {
        categorie: "",
      },
      fields: fields.fieldsCategories,

      haftom: [
        {
          name: "haf",
          address: [{ name: "haftpppppp" }],
        },
        {
          name: "azmeru",
          address: [{ name: "azmeruppp" }],
        },
      ],
    };
  },
  created() {
    this.fetchLabTests().then(() => {
      this.getTestsList = true;
    });
    this.$on("dataInserted", () => {
      this.fetchLabTests().then(() => {
        this.getTestsList = true;
      });
    });
  },
  methods: {
    ...mapActions([
      "addCategory",
      "fetchLabTests",
      "changeCategorieStatus",
      "updateCategorie",
      "deleteCategorie",
    ]),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === false) return "table-danger";
    },
    onRowSelected(items) {
      this.selected = items;
    },
    registerCategorie() {
      //router.push("/create");
      const CategorieData = {
        category: this.form.categorie,
      };
      this.addCategory(CategorieData)
        .then(() => {
          this.$emit("dataInserted");
          this.$bvToast.toast("Registered Sucessfully!!!!!", {
            title: "Registered",
            variant: "sucesss",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to register!!!!!", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    updateStatus(id) {
      this.changeCategorieStatus(id).then(() => {
        this.$emit("dataInserted");
      });
    },
    deleteCategories(id) {
      this.deleteCategory(id)
        .then(() => {
          this.$emit("dataInserted");
          this.$bvToast.toast("Deleted Sucessfully!!!!!", {
            title: "Deleted",
            variant: "sucesss",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to delete!!!!!", {
            title: "delete",
            variant: "danger",
            solid: true,
          });
        });
    },
    editCategorie(index) {
      for (let i = 0; i < this.getLabTests.length; i++) {
        this.form.categorie = this.getLabTests[index].category;
        this.curentEditId = this.getLabTests[i]._id;
        this.createMode = false;
        this.editMode = true;
      }
    },
    updateCategoriesData() {
      const CategoryData = {
        category: this.form.categorie,
        id: this.curentEditId,
      };
      this.updateCategorie(CategoryData)
        .then(() => {
          this.$emit("dataInserted");
          this.$bvToast.toast("Updated Sucessfully!!!!!", {
            title: "Updated",
            variant: "info",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to update!!!!!", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["getLabTests"]),
    categoryValidate() {
      return this.form.categorie == "" ? true : false;
    },
  },
};
</script>

<style scoped>
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
</style>
