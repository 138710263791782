import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
// import router from "../router/indexRouter";

const state = {
  idToken: null,
  userId: null,
  user: null,
  userRole: null,
  admin: null,
  program: null,
  economy: null,
  department: null,
  userName: null,
  status: null,
  errors: null,
  changePassword: [],
};
const mutations = {
  authUser(state, userData) {
    state.idToken = userData.token;
    state.userId = userData.userId;
    state.userRole = userData.userRole;
    state.userName = userData.userName;
    state.status = userData.status;
    state.errors = userData.errors;
  },
  setErrors: (state, errors) => {
    state.errors = errors
    setTimeout(() => {
      state.errors = ''
    }, 6000);
  },
  storeUser(state, user) {
    state.user = user;
  },
  clearAuthData(state) {
    state.idToken = null;
    state.userId = null;
  },
  pushChangePassword: (state, changePassword) =>
    state.changePassword.unshift(changePassword),
};
const actions = {
  login({ commit }, authData) {
    axios
      .post("/login", {
        username: authData.username,
        password: authData.password,
      })
      .then((res) => {
        localStorage.setItem("accessToken", res.data.accessToken);
        let decodeToken = VueJwtDecode.decode(res.data.accessToken);
        localStorage.setItem("Role", decodeToken.role);
        localStorage.setItem("firstName", decodeToken.firstName);
        localStorage.setItem("middleName", decodeToken.middleName);
        localStorage.setItem("lastName", decodeToken.lastName);
        localStorage.setItem("userId", decodeToken.userId);
        // if (decodeToken.role == "Admin") {
        //   router.push("/cashierQueue");
        // } else if (decodeToken.role == "Cashier") {
        //   router.push("/cashierQueue");
        // } else if (decodeToken.role == "Labratory") {
        //   router.push("/labQueue");
        // } else if (decodeToken.role == "Radiology") {
        //   router.push("/radioQueue");
        // } else if (decodeToken.role == "Doctor") {
        //   router.push("/drQueue");
        // }

        location.reload();
        commit("authUser", {
          token: res.data.access_token,
          userId: decodeToken.id,
          userRole: decodeToken.role,
          department: decodeToken.department,
          userName: decodeToken.userName,
          status: res.data.status,
        });
        commit("setErrors", res.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
      });
  },
  async fetchAccessToken({ commit }) {
    commit("authUser", localStorage.getItem("accessToken"));
    if (localStorage.getItem("accessToken") != null) {
      let user_token = VueJwtDecode.decode(localStorage.getItem("accessToken"));
      if (user_token) {
        commit("authUser", {
          token: user_token,
          userId: user_token.id,
          userRole: user_token.role,
          department: user_token.department,
          userName: user_token.userName,
        });
      }
    }
  },
  async resetUserPassword({ commit }, id) {
    await axios
      .patch("/resetPassword/" + `${id}`)
      .then((response) => {
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async changePassword({ commit }, object) {
    await axios
      .patch("/changePassword", object)
      .then((response) => {
        commit("pushChangePassword", response.data);

        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  logout() {
    axios.defaults.headers.common["Authorization"] = null;

    return Promise.resolve();
  },
};
const getters = {
  getCurrentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.idToken !== null;
  },

  getErrors(state) {
    return state.errors;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
