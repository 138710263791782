<template>
  <div>
    <div class="card" style="position: relative; left: 0px; top: 0px">
      <div class="card-header">
        <h3 class="card-title float-left">
          <i class="fa fa-bookmark mr-1"></i>
          Notes
        </h3>

        <!--<div class="card-tools flot-right">
          <ul class="pagination pagination-sm">
            <li class="page-item"><a href="#" class="page-link">«</a></li>
            <li class="page-item"><a href="#" class="page-link">1</a></li>
            <li class="page-item"><a href="#" class="page-link">2</a></li>
            <li class="page-item"><a href="#" class="page-link">3</a></li>
            <li class="page-item"><a href="#" class="page-link">»</a></li>
          </ul>
        </div>-->
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <h6
          class="text-center text-info italic"
          v-if="!todolists.todo.length > 0"
        >
          Sorry you don't have any note's yet,
        </h6>
        <ul class="todo-list" data-widget="todo-list">
          <li
            :class="{ done: item.status == true }"
            v-for="item in todolists.todo"
            :key="item._id"
          >
            <div class="icheck-primary d-inline ml-2">
              <input
                @change="changeStatus(item)"
                type="checkbox"
                name="todo2"
                id="todoCheck2"
                :checked="item.status"
              />
            </div>
            <span class="text">{{ item.content }}</span>
            <small class="badge badge-info mx-2"
              ><i class="far fa-clock"></i> {{ timeCal(item.date) }}</small
            >
            <div class="tools ml-2">
              <i class="fas fa-edit ml-2 text-info" @click="edit(item)"></i>
              <i class="fas fa-trash ml-2" @click="remove(item)"></i>
            </div>
          </li>
        </ul>
      </div>
      <!-- /.card-body -->
      <div class="card-footer clearfix">
        <input
          type="text"
          v-model="name"
          class="my-1"
          placeholder="Enter your note..."
        />

        <b-button
          v-if="createMode"
          size="sm"
          :disabled="name == ''"
          @click="addTodo"
          type="button"
          class="btn btn-info my-1 float-right"
        >
          <i class="fas fa-plus"></i> Add
        </b-button>
        <b-button
          v-if="!createMode"
          size="sm"
          :disabled="name == ''"
          @click="updateTodolist"
          type="button"
          class="btn btn-info mx-2 my-1 float-right"
        >
          <i class="fas fa-edit"></i> Update
        </b-button>
        <b-button
          v-if="!createMode"
          size="sm"
          :disabled="name == ''"
          @click="cancel"
          type="button"
          class="btn btn-default my-1 float-right"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      createMode: true,
      todolists: [],
      editId: "",
    };
  },
  created() {
    this.getTodoList();
  },
  methods: {
    addTodo() {
      axios
        .post("/addTodo", {
          content: this.name,
          user: localStorage.getItem("userId"),
        })
        .then(() => {
          this.getTodoList();
          this.name = "";
          this.$bvToast.toast("Added Sucessfully!!!!!", {
            title: "Note Added successfully",
            variant: "success",
            solid: true,
          });
        });
    },
    getTodoList() {
      axios
        .get("/todolist/" + `${localStorage.getItem("userId")}`)
        .then((res) => {
          this.todolists = res.data;
        });
    },
    cancel() {
      this.name = "";
      this.createMode = true;
    },
    edit(data) {
      this.editId = data._id;
      this.name = data.content;
      this.createMode = false;
    },
    updateTodolist() {
      axios
        .patch("/updateTodo/" + `${this.editId}`, {
          content: this.name,
          user: localStorage.getItem("userId"),
        })
        .then(() => {
          this.getTodoList();
          this.createMode = true;
          this.name = "";
          this.$bvToast.toast("Updated Sucessfully!!!!!", {
            title: "Note Updated successfully",
            variant: "success",
            solid: true,
          });
        });
    },
    remove(data) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((deleteU) => {
          if (deleteU) {
            axios.delete("/deleteTodo/" + `${data._id}`).then(() => {
              this.getTodoList();
              this.name = "";
              this.createMode = true;
            });
            this.$bvToast.toast("Deleted Sucessfully!!!!!", {
              title: "Note Deleted successfully",
              variant: "success",
              solid: true,
            });
          }
        });
    },
    changeStatus(data) {
      if (data.status == true) {
        this.$bvToast.toast("Deleted Sucessfully!!!!!", {
          title: "You didn't finshed yet.",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast("Deleted Sucessfully!!!!!", {
          title: "Congra you achive one goal",
          variant: "success",
          solid: true,
        });
      }
      axios.patch("/updateStatus/" + `${data._id}`).then(() => {
        this.getTodoList();
      });
    },
    timeCal(date) {
      return window.moment(date, "DD-MM-YYYY").toNow(true);
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>
</style>