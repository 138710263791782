<template>
  <div>
    <b-card no-body v-if="displayList" class="my-1">
      <b-card-body class="text-center twa-body my-1">
        <b-row class="mx-1">
          <b-col lg="3" class="col-6">
            <b-form-input
              type="date"
              v-model="requestListDate"
              @change="sendRequest"
            ></b-form-input
          ></b-col>
          <b-col lg="3" class="col-6">
            <b-form-input
              type="text"
              placeholder="Type to serach..."
              v-model="filter"
              @change="sendRequest"
            ></b-form-input>
          </b-col>
          <!-- </b-row>
        <b-row> -->
          <b-col sm="4" class="ml-4 mr-4 border" v-if="getTodayRevenue">
            Total <b>{{ requestListDate }}</b> revenue is
            <b>{{ getTodayRevenue.totalIncome }} Birr</b> from 
            <b>{{ getTodayRevenue.todaysPayments.length }}</b> Patients
          </b-col>
          <!-- {{getTodayRevenue}} -->
        </b-row>
        <!-- {{ getTodayRevenue.length }} -->
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :current-page="curentpage"
          :per-page="per_page"
          :small="small"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          stacked="md"
          :select-mode="selectMode"
          :items="getTodayRevenue.todaysPayments"
          show-empty
          :fields="fields"
          :empty-text="getUsersList ? 'Empty data' : 'Unable to load data.'"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(Ptype)="data">
            <span v-if="data.item.isOutdoor">Outdoor</span>
            <span v-if="!data.item.isOutdoor">Normal</span>
          </template>
          <template v-slot:cell(discount)="data">
            <span v-if="data.item.discount">Discounted</span>
            <span v-if="!data.item.discount">Paid all</span>
          </template>
          <template v-slot:cell(actualPaid)="data">
            <span v-if="data.item.actualPayment">{{
              data.item.actualPayment
            }}</span>
            <span v-if="!data.item.actualPayment">{{ data.item.total }}</span>
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }} {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>

          <template v-slot:cell(payment)="data">
            <span v-for="i in data.item.paymentList" :key="i">
              <p class="mx-0 my-0 tag">{{ i.type }}</p>
              <b-table :fields="fieldsPayment" :items="i.payments">
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>
              </b-table>
            </span>

            <b-row>
              <b-col lg="6"></b-col>
              <b-col lg="6">
                <span class="float-right mr-2">
                  <b>Total price</b> {{ data.item.total }}</span
                ></b-col
              ></b-row
            >
          </template>

          <template v-slot:cell(Price)="data">
            <p v-if="data.item.discount">{{ data.item.actualPayment }}</p>
            <p v-if="!data.item.discount">{{ data.item.total }}</p>
          </template>

          <!-- <template v-slot:table-caption>
            <b-pagination
              class="float-right"
              v-model="curentpage"
              :total-rows="getTodayRevenue.todaysPayments.length"
              :per-page="per_page"
              align="fill"
              size="sm"
            >
            </b-pagination> </template
        > -->
        </b-table>
        <!-- {{ sortedPayment }} -->

        <!-- <div class="fa-3x">
          <i class="fa fa-blind" style="color: blue"></i>
        </div> -->
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";

export default {
  data() {
    return {
      small: true,
      requestListDate: "",
      sortedPayment: [],
      getUsersList: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      filter: "",
      paymentId: "",
      sortBy: "fullName",
      sortDesc: false,
      curentpage: 1,
      per_page: 50,
      pageOptions: [5, 10, 15, 30, 50, "All"],
      fields: fields.fieldsPaymentQueue,
      fieldsPayment: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "name",
          label: "Test Name",
        },
        {
          key: "price",
          label: "Price",
        },
      ],
    };
  },

  created() {
    //this.sorting();
    this.fetchCashierQueues().then(() => {
      this.getUsersList = true;
    });
  },
  sockets: {
    newPaymentRequest: function () {
      this.fetchCashierQueues();
      this.isNewAction(true);
    },
  },
  methods: {
    ...mapActions([
      "fetchCashierQueues",
      "fetchPatientsData",
      "fetchPatientsProfile",
      "paymentForCashier",
      "getPaymentData",
      "isNewAction",
      "requestListByDate",
    ]),
    sendRequest() {
      this.requestListByDate(this.requestListDate);
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === false) return "table-danger";
    },
  },
  computed: {
    ...mapGetters(["getTodayRevenue"]),
    address() {
      return this.form.address.length > 2 ? true : false;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.tag {
  border: 1px solid blue;
  padding: 2px;
  color: rgb(0, 0, 0);
  background-color: rgba(244, 247, 250, 0.185);
  align-items: center;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}

.twa-body {
  margin: 0px;
  padding: 0px;
}

.twa-row {
  margin: 0px;
  padding: 0px;
}

select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
