<template>
  <div class="horizontal-menu">
    <b-nav horizontal>
      <b-dropdown dropdown no-caret variant="primary1" right class="ml-auto avater">
        <template v-slot:button-content>
          <b-icon v-if="!getIsNew" :class="{ red: getIsNew }" icon="bell-fill" variant="primary" title="Notification">
          </b-icon>
          <b-spinner v-if="getIsNew" small variant="danger" type="grow"></b-spinner>
          <b-badge variant="primary" style="font-size: 10px; margin-top: 0px" pill :class="{ red: getIsNew }"
            title="New notification">{{ notification }}</b-badge>
        </template>
        <div style="width: 300px">
          <span v-if="role == 'Doctor'" class="my-0">
            <p v-if="drQueue.length == 0">
              Sorry there is no new notification.
            </p>
            <span v-if="drQueue" class="my-1">
              <b-link class="my-1" @click="sendPatient(me._id)" v-for="me in doctor" :key="me.id">
                <p class="mx-1">
                  you have new patient called
                  <b>{{ me.firstName }} {{ me.middleName }}</b> in queue
                </p>
                <div class="dropdown-divider"></div>
              </b-link>
              <p v-if="!drQueue">Sorry there is no new notification.</p>
            </span>
            <span class="my-0" v-if="laboratory.length != 0">
              <b-link class="my-0" @click="sendLab(me)" v-for="me in laboratory" :key="me.id">
                <p class="mx-1">
                  you have new laboratory result of
                  <b>{{ me.firstName }} {{ me.middleName }}</b>
                </p>
                <div class="dropdown-divider"></div>
              </b-link>
              <p v-if="laboratory.length == 0">
                Sorry there is no new notification.
              </p>
            </span>
            <span class="my-0" v-if="radiology.length != 0">
              <b-link class="my-0" @click="sendRadio(me)" v-for="me in radiology" :key="me.id">
                <p class="mx-1">
                  you have new radiology result of
                  <b>{{ me.firstName }} {{ me.middleName }}</b>
                </p>
                <div class="dropdown-divider"></div>
              </b-link>
            </span>
            <span class="my-0" v-if="cashier.length != 0">
              <b-link class="my-0" @click="sendCashier(me)" v-for="me in cashier" :key="me.id">
                <p class="mx-1">
                  you have new patient
                  <b>{{ me.firstName }} {{ me.middleName }}</b>
                </p>
                <div class="dropdown-divider"></div>
              </b-link>
              <p v-if="cashier.length == 0">
                Sorry there is no new notification.
              </p>
            </span>
          </span>
        </div>
      </b-dropdown>

      <b-dropdown dropdown variant="primary1" no-caret right class="ml-auto avater" style="z-index: 20">
        <template v-slot:button-content>
          <b-avatar variant="secondary" size="1rem" badge badge-variant="success"></b-avatar>
          <!-- <i class="fa fa-tools"></i> -->
        </template>

        <b-dropdown-item class="lists">
          <i class="fa fa-user"></i> {{ firstName }} {{ middleName }}
        </b-dropdown-item>

        <b-dropdown-item class="lists">
          <i class="fa fa-igloo"></i> {{ role }}</b-dropdown-item>
        <b-dropdown-item class="lists">
          <router-link to="/changePassword">
            <i class="fa fa-undo"></i> Change Password</router-link>
        </b-dropdown-item>
        <b-dropdown-item class="lists" @click.prevent="remove"><i class="fa fa-sign-out-alt"></i> Sign Out
        </b-dropdown-item>
      </b-dropdown>

      <div class="sideBarList">
        <b-dropdown dropdown no-caret variant="primary1" right class="ml-auto avater">
          <template v-slot:button-content>
            <!-- <b-icon
            icon="bell-fill"
            class="bageIcon"
            :class="{ green: changeBadgeColor }"
          ></b-icon> -->
            <b-icon icon="list" title="side bar"></b-icon>
          </template>

          <div style="width: 200px">
            <Sidebar :showLogo="false"></Sidebar>
          </div>
        </b-dropdown>
      </div>
    </b-nav>
    <audio src="../assets//sound.wav" controls hidden id="notification"></audio>
  </div>
</template>

<script>
  import Sidebar from "./sidebar";
  import { mapGetters, mapActions } from "vuex";
  export default {
    components: { Sidebar },
    data() {
      return {
        name: "",
        drQueue: false,
        lang: localStorage.getItem("lang"),
        firstName: localStorage.getItem("firstName"),
        middleName: localStorage.getItem("middleName"),
        role: localStorage.getItem("Role"),
        doctor: [],
        cashier: [],
        laboratory: [],
        radiology: [],
        admin: [],
      };
    },
    created() {
      if (this.role == "Doctor") {
        this.fetchDrQueues();
      } else if (this.role == "Radiology") {
        this.fetchRadioQueue();
      } else if (this.role == "Cashier") {
        this.fetchCashierQueues();
      } else if (this.role == "Labratory") {
        this.fetchLabQueue();
      }
    },
    sockets: {
      cashierNotification: function () {
        this.fetchCashierQueues();
        if (this.role == 'Cashier') {
          let cashier = document.getElementById("notification");
          cashier.play()
        }

        //this.doctor.unshift(data.patientId);
      },
      newDrsQueue: function (data) {
        this.doctor.unshift(data.patient);
        this.drQueue = true;
        if (this.role == 'Doctor') {
          let cashier = document.getElementById("notification");
          cashier.play()
        }
      },
      
      labResultIsSaved: function (data) {
        this.laboratory.unshift(data.patient);
        this.drQueue = true;
        if (this.role == 'Doctor') {
          let cashier = document.getElementById("notification");
          cashier.play()
        }

      },
      radiologyResultIsSaved: function (data) {
        this.radiology.unshift(data.patient);
        this.drQueue = true;
        if (this.role == 'Doctor') {
          let cashier = document.getElementById("notification");
          cashier.play()
        }

      },
      labIsPaid: function () {
        if (this.role == 'Labratory') {
          let cashier = document.getElementById("notification");
          cashier.play()
        }
      },
      radioIsPaid: function () {
        if (this.role == 'Radiology') {
          let cashier = document.getElementById("notification");
          cashier.play()
        }
      },
    },
    methods: {
      ...mapActions([
        "fetchDrQueues",
        "logout",
        "fetchLabQueue",
        "fetchRadioQueue",
        "fetchCashierQueues",
        "isNewAction",
        "setPatientName",
        "setPatientId",
        "fetchPatientsProfileNormalPatient",
      ]),
      setLocale(locale) {
        localStorage.setItem("lang", locale);
        location.reload();
      },
      sendPatient(patientId) {
        this.setPatientId(patientId).then(() => {
          this.$router.push("/drQueue");
        });
      },
      sendLab(patient) {
        this.setPatientId(patient._id).then(() => {
          this.fetchPatientsProfileNormalPatient(patient._id);
          this.$router.push("/patientHistory");
        });
      },
      sendRadio(patient) {
        this.setPatientId(patient._id).then(() => {
          this.fetchPatientsProfileNormalPatient(patient._id);
          this.$router.push("/patientHistory");
        });
      },
      remove() {
        this.logout();
        localStorage.removeItem("firstName"),
          localStorage.removeItem("middleName"),
          localStorage.removeItem("lastName"),
          localStorage.removeItem("Role"),
          localStorage.removeItem("accessToken"),
          localStorage.removeItem("patientId");
        this.$router.replace("/");
        location.reload();
      },
    },

    computed: {
      // currentPage() {
      //   return this.$route.path;
      // },
      ...mapGetters([
        "getRadioQueues",
        "getLabQueues",
        "getDrQueues",
        "getCashierQueues",
        "getIsNew",
        "getPatientId",
      ]),
      notification() {
        let count = 0;
        let role = localStorage.getItem("Role");
        if (role == "Cashier") {
          count = this.getCashierQueues.length;
        } else if (role == "Doctor") {
          count = this.getDrQueues.length;
        } else if (role == "Labratory") {
          count = this.getLabQueues.length;
        } else if (role == "radiology") {
          count = this.getRadioQueues.length;
        }
        return count;
      },
    },
    changeBadgeColor() {
      return this.notification > 0 ? true : false;
    },
  };
</script>

<style scoped>
  .dropdown-toggle::after {
    height: 100px;
  }

  .border {
    border: 2px solid red;
    margin-right: 2px;
  }

  .red {
    color: white;
    background-color: red;
  }

  .notify {
    float: right;
  }

  .avater {
    margin-left: 0px;
    padding: 0px;
    width: auto;
  }

  .green {
    color: green;
  }

  .blue {
    color: blue;
  }

  .bageIcon {
    font-size: 20px;
    margin-top: 10px;
    margin-right: 0px;
    padding: 0px;
  }

  .lists {
    border-bottom: 1px solid lightgray;
  }

  .badge {
    font-size: 16px;
  }

  .message {
    padding: 5px;
    margin-top: 3px;
    clear: both;
  }

  .dropdown-menu {
    left: 15px !important;
    top: 15px !important;
    -webkit-appearance: none !important;
    background-color: transparent !important;
  }

  .horizontal-menu {
    margin-top: 10px;
    float: right;
    /* overflow-y: auto; */
  }

  .dropdown-toggle {
    padding: 0 0;
    margin: 0 0;
  }

  .btn-primary {
    color: #fff !important;
  }
</style>