<template>
  <div>
    <b-card no-body v-if="displayList">
      <b-card-header>
        <b-row>
          <!-- <b-col lg="4">
            <b-form-input
              type="date"
              v-model="requestListDate"
              @change="sendRequest"
            ></b-form-input
          ></b-col>-->
          <b-col lg="4">
            <b-form-input
              class="float-right"
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to search..."
            ></b-form-input
          ></b-col>
          <b-col lg="4"> </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :current-page="currentpage"
          :per-page="per_page"
          :filter="filter"
          :small="small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          stacked="md"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getLabQueues"
          show-empty
          :fields="fields"
          :empty-text="
            getUsersList
              ? 'There is no patients queue yet.'
              : 'Unable to load data'
          "
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }} {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>
          <template v-slot:cell(type)="data">
            <span v-if="data.item.isOutdoor == true">Outdoor</span>
            <span v-if="data.item.isOutdoor == false">Normal</span>
          </template>
          <template v-slot:cell(status)="data">
            <span class="tag mx-2">
              <span class="confirmed" v-if="data.item.percentCompleted == 100"
                >Lab {{ data.item.percentCompleted }} %</span
              >
              <span class="danger" v-if="data.item.percentCompleted == 0"
                >Lab {{ data.item.percentCompleted }} %</span
              >
              <span
                class="warning"
                v-if="
                  data.item.percentCompleted > 0 &&
                  data.item.percentCompleted < 100
                "
                >Lab {{ data.item.percentCompleted }} %</span
              >
            </span>
          </template>

          <template v-slot:table-caption>
            <b-pagination
              v-if="!checkQueue"
              class="float-right my-0"
              v-model="currentpage"
              :total-rows="getLabQueues.length"
              :per-page="per_page"
              align="fill"
              size="sm"
              aria-controls="my-table"
            >
            </b-pagination> </template
        ></b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";
export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      filter: "",
      curentEditId: "",
      requestListDate: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      isOutDoor: false,
      patientName: "",
      patientId: "",
      sortBy: "percentCompleted",
      sortDesc: false,
      currentpage: 1,
      per_page: 30,
      pageOptions: [20, 30, 40, "All"],

      fields: fields.fieldsLabQueue,
    };
  },
  sockets: {
    labIsPaid: function () {
      this.fetchLabQueue();
      this.isNewAction(true);
    },
  },
  created() {
    this.fetchLabQueue().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "fetchLabQueue",
      "fetchPatientsData",
      "fetchLabQueueById",
      "fetchPatientsProfile",
      "fetchOutDoorPatient",
      "isNewAction",
      "requestLabByDate",
    ]),
    sendRequest() {
      this.requestLabByDate(this.requestListDate);
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isResultSaved == false) return "table-warning";
      if (item.percentCompleted > 0 && item.percentCompleted < 100)
        return "table-wh";
      if (item.percentCompleted == 100) return "table-success";
    },
    // onRowSelected(items) {
    //   this.selected = items;
    //   this.fetchLabQueueById();
    //   this.$router.push("/labResultForm");
    // },
    onRowSelected(items) {
      this.selected = items;
      this.check();
      if (this.isOutDoor) {
        const data = {
          patientId: this.patientName,
          isOutdoor: true,
          // date: this.requestListDate || window.moment().format("YYYY-MM-DD"),
        };
        this.fetchLabQueueById(data).then(
          () => this.$router.push("/labResultForm"),
          this.isNewAction(false),
          localStorage.setItem("patientId", this.patientName)
        );
        this.fetchOutDoorPatient(this.patientName);
      } else {
        const data = {
          patientId: this.patientName,
          isOutdoor: false,
          //date: this.requestListDate || window.moment().format("YYYY-MM-DD"),
        };
        this.fetchLabQueueById(data).then(
          () => this.$router.push("/labResultForm"),
          this.isNewAction(false),
          localStorage.setItem("patientId", this.patientName)
        );
      }
    },
    check() {
      for (let i = 0; i < this.selected.length; i++) {
        this.patientName = this.selected[i].patientId;
        this.isOutDoor = this.selected[i].isOutdoor;
      }
    },
  },
  computed: {
    ...mapGetters(["getPatients", "getLabQueue", "getLabQueues"]),
    address() {
      return this.form.address.length > 2 ? true : false;
    },
    checkQueue() {
      return this.getLabQueues.length <= 0 ? true : false;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
