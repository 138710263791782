<template>
  <div>
    <div class="mt-3">
      <b-row class="Jdi-row mt-3">
        <b-col lg="9">
          <ul>
            <b-row>
              <b-col sm="4" v-for="item in getLabTests" :key="item.id">
                <hr />
                <b-form-checkbox-group v-model="selected">
                  <b-form-checkbox
                    size="lg"
                    :disabled="isSendLab"
                    @change="clickedAll()"
                    :value="item.tests"
                    switches
                    >{{ item.category }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
                <hr />
                <div v-for="test in item.tests" :key="test.id">
                  <b-form-checkbox-group v-model="selected">
                    <b-form-checkbox
                      size="sm"
                      :testId="test._id"
                      :value="test"
                      :testPrice="test.price"
                      >{{ test.name }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </div>
              </b-col>
            </b-row>
          </ul>
        </b-col>
        <b-col lg="3" v-if="!checkSelection">
          <div class="mt-1 labSelected">
            <ul>
              <p v-if="checkSelection">You Are Not Selected Yet Nigga</p>
              <b-table
                v-if="!checkSelection"
                :items="selected"
                :small="small"
                :fields="fields"
              ></b-table>
              <li v-if="checkRole">{{ total }}</li>
              <b-button variant="info" @click="sendTests">
                Send to Laboratory
              </b-button>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      patientName: "",
      patientId: "",
      testId: "",
      isSendLab: false,
      testPrice: "",
      selected: [],
      small: true,

      fields: ["name", "price"],
    };
  },
  created() {
    this.fetchLabTests();
  },
  clickedAll() {
    this.isSendLab = true;
  },
  methods: {
    ...mapActions([
      "fetchLabTests",
      "addLabQueue",
      "sendLabTestRequestToCashier",
    ]),
    sendTests() {
      const data = {
        firstName: this.getOutDoorPatients.firstName,
        middleName: this.getOutDoorPatients.middleName,
        lastName: this.getOutDoorPatients.lastName,
        patientId: this.getOutDoorPatients._id,
        isOutdoor: true,
        total: this.total,
        tests: this.selected,
      };
      this.addLabQueue(data).then(() => {
        this.$bvToast.toast("Note Added", {
          title: "Labratory Tests Sent To Labratory.",
          variant: "sucesss",
          solid: true,
        });
        this.$emit("dataInserted");
      });
    },
  },

  computed: {
    ...mapGetters(["getLabTests", "getPatientProfile", "getOutDoorPatients"]),

    checkSelection() {
      return this.selected.length <= 0 ? true : false;
    },
    total() {
      return this.selected.reduce(
        (index, item) => index + Number(item.price),
        0
      );
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}
.labSelected {
  border: 1px solid darkgray;
  background-color: lightgray;
}
</style>
