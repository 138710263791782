<template>
  <div>
    <b-card class="my-1">
      <b-row>
        <b-col lg="4" class="my-1">
          <label>Above Age Card Price :</label>
          <!-- <span
            v-if="!checkCardPrice"
            class="text-muted mx-1"
            style="font-size: 12px; font-style: italic; color: red !important"
            >Required</span
          > -->
          <span
            ><b-icon
              v-if="checkCardPrice"
              class="mx-2 icon"
              icon="check-circle"
              font-scale="1.7"
              variant="success"
            ></b-icon
          ></span>

          <b-form-input
            class="my-1"
            id="input-1"
            size="sm"
            v-model="form.cardPrice"
            type="text"
          ></b-form-input>
        </b-col>
        <b-col lg="4" class="my-1">
          <label>Under Age Card Price :</label>
          <!-- <span
            v-if="!checkExpiredAt"
            class="text-muted mx-1"
            style="font-size: 12px; font-style: italic; color: red !important"
            >Required</span
          > -->
          <span
            ><b-icon
              v-if="checkExpiredAt"
              class="mx-2 icon"
              icon="check-circle"
              font-scale="1.7"
              variant="success"
            ></b-icon
          ></span>

          <b-form-input
            class="my-1"
            id="input-1"
            size="sm"
            v-model="form.cardPriceUndareAge"
            type="text"
          ></b-form-input>
        </b-col>
        <b-col lg="4" class="my-1">
          <label>Service time :</label>
          <!-- <span
            v-if="!checkExpiredAt"
            class="text-muted mx-1"
            style="font-size: 12px; font-style: italic; color: red !important"
            >Required</span
          > -->
          <span
            ><b-icon
              v-if="checkExpiredAt"
              class="mx-2 icon"
              icon="check-circle"
              font-scale="1.7"
              variant="success"
            ></b-icon
          ></span>

          <b-form-input
            class="my-1"
            id="input-1"
            size="sm"
            v-model="form.expiredAt"
            type="text"
          ></b-form-input>
        </b-col>

        <b-col>
          <b-button
          size="sm"
            :disabled="
              form.expiredAt == '' ||
              form.cardPrice == '' ||
              form.cardPriceUndareAge == ''
            "
            v-if="createMode"
            class="mt-2 mr-1 float-right btnPositive"
            type="submit"
            @click.prevent="addConfig"
          >
           <i class="fa fa-tools"></i> Set Card Price
          </b-button>
          <!-- <b-button
            :disabled="form.expiredAt == '' || form.cardPrice == ''"
            v-if="!createMode"
            class="my-1 mx-2 float-right btnPositive"
            type="submit"
            @click.prevent="addConfig"
          >
            Update
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      createMode: true,
      form: {
        expiredAt: "",
        cardPrice: "",
        cardPriceUndareAge: "",
      },
    };
  },
  methods: {
    ...mapActions(["addCardSetting", "fetchCardSetting"]),
    addConfig() {
      const data = {
        cardPrice: this.form.cardPrice,
        validFor: this.form.expiredAt,
        cardPriceUnderAge: this.form.cardPriceUndareAge,
      };
      this.addCardSetting(data);
    },
  },
  created() {
    this.fetchCardSetting().then(() => {
      if (this.getCardSetting != null) {
        this.form.cardPrice = this.getCardSetting.cardPrice;
        this.form.expiredAt = this.getCardSetting.validFor;
        this.form.cardPriceUndareAge = this.getCardSetting.cardPriceUnderAge;
      }
    });
  },
  computed: {
    ...mapGetters(["getCardSetting", "getStatusMessage"]),
    checkCardPrice() {
      return this.form.cardPrice.length > 0 ? true : false;
    },
    checkExpiredAt() {
      return this.form.expiredAt.length > 0 ? true : false;
    },
  },
};
</script>

<style scoped>
</style>