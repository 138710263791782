<template>
  <div>
    <b-card no-body v-if="displayList">
      <b-card-header class="twa-header">
        <b-row class="twa-row">
          <b-col lg="2" class="my-1 col-3">
            <b-input-group size="sm">
              <b-form-select
                class="perPageSelect"
                v-model="per_page"
                id="sortBySelect"
                :options="pageOptions"
              >
              </b-form-select>
            </b-input-group>
          </b-col>

          <b-col sm="3" class="my-1 col-9">
            <b-form-group>
              <b-input-group size="sm" class="w-20">
                <b-form-input
                  class="float-right"
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to search..."
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          class="customTable table table-head-fixed"
          fixed
          @filtered="onFiltered"
          noCollapse="true"
          :current-page="currentPage"
          :per-page="per_page"
          :small="small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          responsive="sm"
          :select-mode="selectMode"
          :items="getTodayCreditPaid.list"
          show-empty
          :fields="fields"
          :empty-text="getUsersList ? 'Empty Record' : 'Unable To Load Data'"
        >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'index' ||
                  field.key === 'dateOfCredit' ||
                  field.key === 'total' ||
                  field.key === 'fullName'
                    ? '100px'
                    : '380px',
              }"
            />
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(Details)="data">
            <b-table  :fields="fieldsDetails" :items="data.item.payments">
              <template v-slot:cell(fullName)="data">
                {{ data.item.user.firstName }} {{ data.item.user.middleName }}
                {{ data.item.user.lastName }}
              </template>
            </b-table>
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.patient.firstName }}
            {{ data.item.patient.middleName }}
            {{ data.item.patient.lastName }}
          </template>
        </b-table>

        <b-pagination
          class="float-right my-0"
          v-model="currentPage"
          :total-rows="total"
          :per-page="per_page"
          align="fill"
          size="sm"
          aria-controls="my-table"
        >
        </b-pagination>
      </b-card-body>
    </b-card>

    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="info" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    maxDate.setDate(now.getDate());
    return {
      total: "",
      small: true,
      modalShow: false,
      getUsersList: false,
      filter: null,
      errors: {},
      value_date: true,
      curentEditId: "",
      currentPatient: {},
      max: maxDate,
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      PatientId: [],
      selectMode: "single",
      selected: [],
      patientAddQueue: [],
      sortBy: "registeredDate",
      sortDesc: false,
      showInvalid: true,
      hideCompleted: true,
      currentPage: 1,
      per_page: 50,
      left: "",
      discount: "",
      pageOptions: [5, 10, 15, 50, "All"],
      genderList: ["Female", "Male"],
      fieldsDetails: [
        { key: "date", label: "Pay Date" },
        { key: "left", label: "Unpaid" },
        { key: "paid", label: "Paid" },
        { key: "fullName", label: "Received By" },
      ],
      fields: [
        { key: "index", label: "#", sortable: false },
        {
          key: "fullName",
          label: "Full Name",
          sortable: true,
        },
        { key: "total", label: "Total Price" },
        { key: "dateOfCredit", label: "Credit Date" },
        "Details",
      ],
    };
  },
  created() {
    this.count();
    this.todayCreditPaidList().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "fetchPatients",
      "todayCreditPaidList",
      "discountPay",
      "fetchPatientsProfile",
      "fetchPatientsProfileNormalPatient",
    ]),
    async count() {
      this.total = await this.getTodayCreditPaid.length;
    },
    pay(patient) {
      this.currentPatient = patient;
      this.left = patient.total - patient.actualPayment;
      this.modalShow = true;
    },

    ageCa(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.total = filteredItems.length;
      this.currentPage = 1;
    },

    // onRowSelected(items) {
    //   this.PatientId = items;
    //   this.check();
    //   this.fetchPatientsProfileNormalPatient(this.patientName).then(
    //     () => this.$router.push("/patientHistory"),
    //     localStorage.setItem("patientId", this.patientName)
    //   );
    // },
    check() {
      for (let i = 0; i < this.PatientId.length; i++) {
        this.patientName = this.PatientId[i]._id;
      }
    },
  },
  computed: {
    ...mapGetters(["getTodayCreditPaid", "getErrors"]),

    filteredTasks() {
      return this.hideCompleted
        ? this.getTodayCreditPaid.filter((t) => !t.done)
        : this.tasks;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
li {
  list-style-type: none;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
