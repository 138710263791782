<template>
  <div>
    <Header subTitle="Payment Reconcilation Slip"></Header>
    <hr />
    <PersonalName :personal="personal"></PersonalName>
    <hr />
    <!-- {{getSinglePayment}} -->
    <div class="row mx-2 mt-4">
      <div class="col-sm-1 col-1">
        <div class="border-slip text-center bold"># </div>
      </div>
      <div class="col-sm-8 col-8">
        <div class="border-slip text-center bold">Name</div>
      </div>
      <div class="col-sm-3 col-3">
        <div class="border-slip text-center bold">Price</div>
      </div>
    </div>
    <span v-for="item in getSinglePayment.paymentList" :key="item.id">
      <!-- <Category :type="item.type" /> -->
      <p class="my-2 mx-3 result italic" v-if="item.type == 'labRequest'">
        Labratory Investgations
      </p>
      <p class="my-2 mx-3 result italic" v-if="item.type == 'radiologyRequest'">
        Radiology Investgations
      </p>
      <p class="my-2 mx-3 result italic" v-if="item.type == 'treatmentRequest'">
        Treatments
      </p>
      <slip class="mx-3" :item="item.payments" />
    </span>
    <b-row class="mx-0">
      <div class="col-sm-9 col-9">
    <Footer class="float-left" :dataFooter="dataFooter"></Footer>

      </div>
      <div class="col-sm-3">
        <div class="border-slip pl-2">
         <b style="font-size:30px"> Total :
          <span style="font-size:30px"> {{ getSinglePayment.total }} Birr</span></b>
        </div>
      </div>
    </b-row>

  </div>
</template>

<script>
// import Category from "./category";
import PersonalName from "../../../common/print/personalName";
import Header from "../../../common/print/header";
import Footer from "../../../common/print/footer";
import { mapActions, mapGetters } from "vuex";
import slip from "../component/slip";
export default {
  components: {
    slip,
    PersonalName,
    Header,
    Footer,
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: "Service" },
        { key: "price", label: "Net.Pay" },
        // { key: "price", label: "Net Pay" },
      ],
      personal: {},
      dataFooter: {},
    };
  },
  methods: {
    ...mapActions(["fetchCashierQueues"]),
    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
  },
  created() {
    this.personal = {
      mrn: this.getSinglePayment.cardNo,
      fullName:
        this.getSinglePayment.firstName +
        " " +
        this.getSinglePayment.middleName +
        " " +
        this.getSinglePayment.lastName,
      age: this.calcAge(this.getSinglePayment.birthDate),
      sex: this.getSinglePayment.sex,
      dateCheck: this.getSinglePayment.date,
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
    };
    this.dataFooter = {
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
      printBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("middleName"),
      role: localStorage.getItem("Role"),
    };
    this.fetchCashierQueues().then(() => {
      setTimeout(() => {
        window.print();
      }, 4000);
    });
  },

  computed: {
    ...mapGetters(["getSinglePayment"]),
  },
};
</script>

<style scoped>
*{
  font-size: 25px;
}
.table td {
  padding: 0.3rem 0.1rem !important;
  text-align: left;
  font-size: 16px !important;
}
.table th {
  padding: 0.3rem 0.2rem !important;
  text-align: left;
  font-size: 18px !important;
  background-color: #e3e8ee;
}
.result {
  font-weight: bold;
  font-size: 18px !important;
}
.bold {
  font-size: 20px !important;
  font-weight: bolder;
}
.large-width {
  margin-left: 490px !important;
}
.italic{
  font-style: italic;
}
.mt {
  margin-top: 210px;
}
</style>
