import axios from "axios";

const state = {
  pysicalExam: [],
  pysicalExams: [],
  tratment: [],
  diagnosis: [],
  medicationRecord: [],

  dxs: [],
  dx: [],
  treatmentDx: [],
};

const mutations = {
  setPysicalExam: (state, pysicalExam) => (state.pysicalExam = pysicalExam),

  setMedicationRecord: (state, medicationRecord) =>
    (state.medicationRecord = medicationRecord),

  setPysicalExams: (state, pysicalExams) => (state.pysicalExams = pysicalExams),
  setTreatmentDr: (state, tratment) => (state.tratment = tratment),

  setTreatmentWithDx: (state, treatmentDx) => (state.treatmentDx = treatmentDx),
  setDiagnosis: (state, diagnosis) => (state.diagnosis = diagnosis),
  pushPysicalExam: (state, pysicalExams) =>
    state.pysicalExams.unshift(pysicalExams),

  setHpi: (state, hpi) => (state.hpi = hpi),
  setHpis: (state, hpis) => (state.hpis = hpis),
  pushHpi: (state, hpis) => state.hpis.unshift(hpis),

  setDx: (state, dx) => (state.dx = dx),
  setDxs: (state, dxs) => (state.dxs = dxs),
  pushDx: (state, dxs) => state.dxs.unshift(dxs),
};

const getters = {
  getPysicalExams: (state) => {
    return state.pysicalExams;
  },
  getPysicalExam: (state) => {
    return state.pysicalExam;
  },
  getHpi: (state) => {
    return state.hpi;
  },
  getHpis: (state) => {
    return state.hpis;
  },
  getdx: (state) => {
    return state.dx;
  },
  getdxs: (state) => {
    return state.dxs;
  },
};

const actions = {
  async fetchPatientsPE({ commit }, id) {
    await axios
      .get("/medicalHistory" + `/${id}`)
      .then((response) => {
        commit("setPysicalExam", response.data.patient);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async addPhysicalExam({ commit }, object) {
    await axios
      .post("/physicalExam", object)
      .then((response) => {
        commit("setPysicalExam", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async addTreatmentDr({ commit }, object) {
    await axios
      .post("/tx", object)
      .then((response) => {
        commit("setTreatmentDr", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async UpdateTreatmentRequest({ commit }, object) {
    await axios
      .patch("/treatmentRequest/" + `${object.requestId}`, object)
      .then((response) => {
        //commit("setTreatmentDr", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async deleteTreatmentRequest({ commit }, id) {
    await axios
      .delete("/treatmentRequest/" + `${id}`)
      .then((response) => {
        //commit("setTreatmentDr", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async addMedicationRecord({ commit }, object) {
    await axios
      .post("/medicationRecord", object)
      .then((response) => {
        commit("setMedicationRecord", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async addDiagnosis({ commit }, object) {
    await axios
      .post("/dx", object)
      .then((response) => {
        commit("setDiagnosis", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
      });
  },
  async addTreatmentsWithDx({ commit }, object) {
    await axios
      .post("/txManagement", object)
      .then((response) => {
        commit("setTreatmentWithDx", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
      });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
