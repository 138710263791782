import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import accountStore from "./accountStore";

import labratoryTest from "./labratoryTestsStore";
import addNote from "./addNote";
import patientStore from "./patientStore";
import category from "./category";
import radiologyTests from "./radiologyTests";
import login from "./login";
import report from "./report";
import common from "./common";
import patientAppointment from "./patientAppointment";
import treatment from "./treatment";
import outDoorPatient from "./outDoorService";
import medication from "./medication";
export default new Vuex.Store({
  modules: [
    accountStore,
    medication,
    labratoryTest,
    treatment,
    report,
    patientStore,
    addNote,
    category,
    radiologyTests,
    login,
    patientAppointment,
    common,
    outDoorPatient,
  ],
});
