<template>
  <div class="Jdi-menu-sidebar">
    <Brand v-if="showLogo" />
    <b-nav vertical style="margin-bottom: 30px">
      <div v-if="isAdmin">
        <b-nav-item
          v-for="item in admin"
          class="nav-item-class"
          :key="item.title"
          :to="{ name: item.link }"
          active-class="active"
        >
          <b-icon class="mr-2" :icon="item.icon"></b-icon>
          {{ item.title }}
        </b-nav-item>
      </div>
      <div v-if="isLab">
        <b-nav-item
          v-for="item in Labratory"
          class="nav-item-class"
          :key="item.title"
          :to="{ name: item.link }"
          active-class="active"
        >
          <b-icon class="mr-2" :icon="item.icon"></b-icon>
          {{ item.title }}
        </b-nav-item>
      </div>
      <div v-if="isCashier">
        <b-nav-item
          v-for="item in Cashier"
          class="nav-item-class"
          :key="item.title"
          :to="{ name: item.link }"
          active-class="active"
        >
          <b-icon class="mr-2" :icon="item.icon"></b-icon>
          {{ item.title }}
        </b-nav-item>
      </div>
      <div v-if="isDoctor">
        <b-nav-item
          v-for="item in Doctor"
          class="nav-item-class"
          :key="item.title"
          :to="{ name: item.link }"
          active-class="active"
        >
          <b-icon class="mr-2" :icon="item.icon"></b-icon>
          {{ item.title }}
        </b-nav-item>
      </div>
      <div v-if="isRadio">
        <b-nav-item
          v-for="item in Radiology"
          class="nav-item-class"
          :key="item.title"
          :to="{ name: item.link }"
          active-class="active"
        >
          <b-icon class="mr-2" :icon="item.icon"></b-icon>
          {{ item.title }}
        </b-nav-item>
      </div>
    </b-nav>
    <div class="vertical-bottom-end">
      <JustCare v-if="showLogo"></JustCare>
    </div>
  </div>
</template>

<script>
import JustCare from "../view/common/justCare";
import Brand from "./brand";
export default {
  components: {
    JustCare,
    Brand,
  },
  props: ["showLogo"],
  data() {
    return {
      ismobileNav_open: false,
      adminShow: false,
      labratory: false,
      doctor: false,
      cashier: false,
      radiology: false,
      rolelocal: "",

      admin: [
        {
          title: "Dashboard",
          link: "dashboardAdmin",
          icon: "grid-fill",
        },
        {
          title: "Account",
          link: "account",
          icon: "people-fill",
        },
        {
          title: "Message",
          link: "reportMessage",
          icon: "envelope-open-fill",
        },

        {
          title: "Laboratory",
          link: "labratoryCategory",
          icon: "plus",
        },
        {
          title: "Lab-List",
          link: "createLabTest",
          icon: "list",
        },

        {
          title: "Radiology",
          link: "radioCategory",
          icon: "plus",
        },
        {
          title: "Rad-List",
          link: "radioCreate",
          icon: "list",
        },

        {
          title: "Treatment",
          link: "treatmentCategory",
          icon: "plus",
        },

        {
          title: "Tx-List",
          link: "createTreatment",
          icon: "list",
        },
        {
          title: "Manage Card",
          link: "cardSetting",
          icon: "list",
        },

        // {
        //   title: "Medication",
        //   link: "createMedication",
        //   icon: "list",
        // },
      ],
      Labratory: [
        {
          title: "Dashboard",
          link: "labDashboard",
          icon: "grid-fill",
        },
        {
          title: "Queue",
          link: "labQueue",
          icon: "list-ol",
        },
      ],
      Cashier: [
        {
          title: "Dashboard",
          link: "cashierDashboard",
          icon: "grid-fill",
        },
        {
          title: "Credit Lists",
          link: "discountLists",
          icon: "kanban-fill",
        },
        {
          title: "Patients",
          link: "patientRegister",
          icon: "list",
        },
        {
          title: "Cashier Queue",
          link: "cashierQueue",
          icon: "list-ol",
        },
        {
          title: "Treatments",
          link: "treatmentTable",
          icon: "bullseye",
        },
        {
          title: "Out Door",
          link: "outDoorService",
          icon: "archive",
        },
        {
          title: "Appoitnments",
          link: "listAppointment",
          icon: "clock-fill",
        },
      ],
      Radiology: [
        {
          title: "Dashboard",
          link: "radioDashboard",
          icon: "grid-fill",
        },
        {
          title: "Queue",
          link: "radioQueue",
          icon: "list-ol",
        },
      ],
      Doctor: [
        {
          title: "Dashboard",
          link: "drDashboard",
          icon: "grid-fill",
        },
        {
          title: "Queue",
          link: "drQueue",
          icon: "list-ol",
        },
        {
          title: "Patients",
          link: "allPatients",
          icon: "list",
        },
        // {
        //   title: "Refer",
        //   link: "refer",
        //   icon: "forward-fill",
        // },
        // {
        //   title: "Certficate",
        //   link: "certificate",
        //   icon: "layout-sidebar",
        // },
        // {
        //   title: "Sick Leave",
        //   link: "sickLeave",
        //   icon: "square-half",
        // },
      ],
    };
  },
  created() {
    this.roleLocal = localStorage.getItem("Role");
  },
  computed: {
    isLab() {
      return this.roleLocal == "Labratory" ? true : false;
    },
    isRadio() {
      return this.roleLocal == "Radiology" ? true : false;
    },
    isDoctor() {
      return this.roleLocal == "Doctor" ? true : false;
    },
    isCashier() {
      return this.roleLocal == "Cashier" ? true : false;
    },
    isAdmin() {
      return this.roleLocal == "Admin" ? true : false;
    },
  },
};
</script>

<style scoped>
.sideNav {
  padding-top: 50px;
  border-top: 2px solid green;
  height: 100%;
  width: 180px;
  float: center;
  left: 0;
  padding-left: 0px;
  margin-left: 0px;
  position: fixed;
  z-index: 8;
  padding-bottom: 30px;
}
li {
  display: block;
  text-align: center;
  padding-bottom: 3px;
  border-bottom: 2px solid white;
  text-align: left;
  margin: 5px;
  font-size: 14px;
  color: white;
}
li:hover {
  border: 2px solid gray;
  background-color: white;
  color: black;
}
.profile {
  float: center;
  align-items: center;
}

.active {
  background-color: #007bff !important;
  font-weight: bold !important;
  color: white !important;
}
</style>
