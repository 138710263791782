var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"A4",staticStyle:{"mrgin":"0px"},attrs:{"no-body":"","id":"print"}},[_c('b-card-header',{staticClass:"Jdi-header",attrs:{"small":_vm.small}},[_c('b-row',{staticClass:"Jdi-row"},[_c('b-col',{attrs:{"lg":"11"}}),_c('b-col',{attrs:{"lg":"1"}},[_c('b-link',{staticClass:"float-right",attrs:{"to":"/patientsRadio"}},[_c('i',{staticClass:"fa fa-print"})])],1)],1)],1),_c('b-row',{staticStyle:{"width":"100%"}},[(_vm.showPreview)?_c('div',{staticClass:"A4",staticStyle:{"width":"100%"},attrs:{"id":"print"}},[_c('RadioResultPrint',{ref:"print"})],1):_vm._e()]),_vm._l((_vm.getRadioQueue),function(radio,index){return _c('div',{key:radio.id},[(_vm.getRadioQueue[index].isPaidFor)?_c('span',{staticClass:"mx-1 text-muted"},[_vm._v(" Request time "+_vm._s(_vm.getRadioQueue[index].time)+" "),_c('b-row',[_vm._l((radio.tests),function(tests,indexName){return _c('b-col',{key:tests.id,attrs:{"lg":"12"}},[_c('b-row',{staticClass:"mx-2"},[_c('hr'),_c('b-col',{staticClass:"mx-1",attrs:{"sm":"12"}},[_c('label',{staticClass:"float-left",attrs:{"for":""}},[_c('strong',{staticStyle:{"font-style":"italic","font-weight":"bold font-family: serif"}},[_c('b',[_vm._v(_vm._s(tests.name))])])])]),_c('b-col',{staticClass:"mx-3",attrs:{"sm":"12","lg":"12"}},_vm._l((tests.findings),function(finding,indexFinding){return _c('span',{key:finding.id},[_c('b-row',[_c('b-form-textarea',{staticClass:"my-1 mx-0 findingItem",class:{
                        strike:
                          _vm.getRadioQueue[index].tests[indexName].findings[
                            indexFinding
                          ].removed == true,
                      },attrs:{"disabled":_vm.getRadioQueue[index].tests[indexName].findings[
                          indexFinding
                        ].removed == true,"id":"textarea","rows":"3","max-rows":"6"},model:{value:(finding.name),callback:function ($$v) {_vm.$set(finding, "name", $$v)},expression:"finding.name"}}),_c('span',{staticClass:"float-right"},[(
                          _vm.getRadioQueue[index].tests[indexName].findings[
                            indexFinding
                          ].removed == false
                        )?_c('b-icon',{attrs:{"variant":"danger","tooltip":"remove finding","font-scale":"1.5","icon":"backspace-fill"},on:{"click":function($event){return _vm.removeFinding(index, indexFinding, indexName)}}}):_vm._e(),(
                          _vm.getRadioQueue[index].tests[indexName].findings[
                            indexFinding
                          ].removed == true
                        )?_c('b-icon',{attrs:{"variant":"primary","tooltip":"restore this finding","font-scale":"1.5","icon":"bootstrap-reboot"},on:{"click":function($event){return _vm.restoreFinding(index, indexFinding, indexName)}}}):_vm._e()],1)],1)],1)}),0),_c('b-col',{staticClass:"mx-1",attrs:{"lg":"12"}},[_c('label',{attrs:{"for":"textarea-small"}},[_vm._v("Index :")]),_c('b-form-input',{model:{value:(tests.conclusion),callback:function ($$v) {_vm.$set(tests, "conclusion", $$v)},expression:"tests.conclusion"}})],1),_c('b-col',{staticClass:"mx-1",attrs:{"lg":"12"}},[_c('label',{attrs:{"for":"textarea-small"}},[_vm._v("Recommendation :")]),_c('b-form-input',{model:{value:(tests.recommendation),callback:function ($$v) {_vm.$set(tests, "recommendation", $$v)},expression:"tests.recommendation"}})],1)],1)],1)}),_c('b-col',{staticClass:"float-right"},[(!_vm.getRadioQueue[index].isResultSaved)?_c('input',{staticClass:"btn btn-primary my-1 mx-2 float-right",attrs:{"disabled":_vm.isSendRadio,"type":"button","value":"Send"},on:{"click":function($event){return _vm.sendRadioResult(index)}}}):_vm._e(),(_vm.getRadioQueue[index].isResultSaved)?_c('input',{staticClass:"btn btn-info my-1 mx-2 float-right",attrs:{"type":"button","value":"Update"},on:{"click":function($event){return _vm.updateRadioResult(index)}}}):_vm._e()])],2),_c('hr',{staticStyle:{"border":"2px solid lightgray"}})],1):_vm._e()])})],2),_c('b-toast',{attrs:{"id":"toaster","toaster":"b-toaster-top-right"}},[_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline",attrs:{"slot":"toast-title"},slot:"toast-title"},[(_vm.getErrors != null)?_c('div',[_vm._v(" "+_vm._s(_vm.getErrors)+" ")]):_vm._e(),(_vm.getErrors == null)?_c('div',[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning","small":""}}),_vm._v("  Processing... ")],1):_vm._e()])]),_c('hr')],1)}
var staticRenderFns = []

export { render, staticRenderFns }