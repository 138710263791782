import radioQueue from "../view/radiology/radioQueue.vue";
import radioResult from "../view/radiology/radioResult.vue";
import patientData from "../view/radiology/patientData.vue";
import patientList from "../view/radiology/patientRegister.vue";
import radioPrint from "../view/radiology/print/radioResult.vue";

export default [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "radioDashboard",
        name: "radioDashboard",
        meta: {
          title: "Dashboard",
          // scope: "Auth",
        },
        component: () => import("@/view/radiology/dashboard"),
      },
      {
        path: "radioQueue",
        name: "radioQueue",
        meta: {
          title: "Radiology's Queue",
          // scope: "Auth",
        },
        component: radioQueue,
      },
      {
        path: "patientsRadio",
        name: "patientsRadio",
        meta: {
          title: "Patients",
          header: false,
        },
        component: patientList,
      },
      {
        path: "patientsHistoryRadio",
        name: "patientsHistoryRadio",
        meta: {
          title: "Radiology",
          // scope: "Auth",
        },
        component: patientData,
      },
      {
        path: "radioResult",
        name: "radioResult",
        meta: {
          title: "Patients Result",
          // scope: "Auth",
        },
        component: () => radioResult,
      },
      {
        path: "radioPrint",
        name: "radioPrint",
        meta: {
          title: "Patients Radiology Result",
          // scope: "Auth",
        },
        component: () => radioPrint,
      },
    ],
  },
];
