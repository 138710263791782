<template>
  <div>
    <span style="font-family: Agency FB"
        ><span style="color: blue; font-size: 20px">Just</span
        ><span span style="color: green"><b>Care</b></span></span
      > - <span>Powered by <span style="color:blue"  href="www.JDI.org" title="Phone : 0933347171">JDI</span></span>
      
      
  </div>
</template>

<script>
export default {
  data() {
    return {
      credit: "Just Care",
      creditUrl: "home",
    };
  },
};
</script>

<style scoped>
.brandName {
  font-size: 20px;
}
.contact{
display: none;
}

</style>
