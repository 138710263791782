// import router from "../router/indexRouter";
import axios from "axios";
const state = {
  statusMessage: "",
  isNew: false,
  name: "",
  PatientIdNot: "",
  cardSetting: {},
  certificate: {},
  sickLeave: {},
  refer: {},
};

const mutations = {
  setStatusMessage: (state, statusMessage) =>
    (state.statusMessage = statusMessage),
  setIsNew: (state, isNew) => (state.isNew = isNew),
  pushCardSetting: (state, cardSetting) =>
    state.cardSettingcardSetting.unshift(cardSetting),
  setCardSetting: (state, cardSetting) => (state.cardSetting = cardSetting),
  setPat: (state, name) => (state.name = name),
  setPatientIdNot: (state, PatientIdNot) => (state.PatientIdNot = PatientIdNot),
  setCertificate: (state, certificate) => (state.certificate = certificate),
  setSickLeave: (state, sickLeave) => (state.sickLeave = sickLeave),
  setRefer: (state, refer) => (state.refer = refer),
};

const getters = {
  getStatusMessage: (state) => {
    return state.users;
  },
  getCardSetting: (state) => {
    return state.cardSetting;
  },
  getIsNew: (state) => {
    return state.isNew;
  },
  getName: (state) => {
    return state.name;
  },
  getPatientIdNot: (state) => {
    return state.PatientIdNot;
  },
  getCertificate: (state) => {
    return state.certificate;
  },
  getSickLeave: (state) => {
    return state.sickLeave;
  },
  getRefer: (state) => {
    return state.refer;
  },
};
const actions = {
  resetStatusMessage({ commit }) {
    commit("setStatusMessage", "");
  },
  addCertificate({ commit }, data) {
    commit("setCertificate", data);
  },
  addRefer({ commit }, data) {
    commit("setRefer", data);
  },
  addSickLeave({ commit }, data) {
    commit("setSickLeave", data);
  },
  setPatientName({ commit }, name) {
    commit("setPat", name);
  },
  setPatientId({ commit }, patientId) {
    commit("setPatientIdNot", patientId);
  },
  // addCardSetting({ commit }, object) {
  //   commit("pushCardSetting", object);
  // },
  async addCardSetting({ commit }, object) {
    await axios
      .post("/cardSetting", object)
      .then((response) => {
        commit("pushCardSetting", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async fetchCardSetting({ commit }) {
    await axios
      .get("/cardSetting")
      .then((response) => {
        commit("setCardSetting", response.data.cardSetting);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  // notification flug
  isNewAction({ commit }, isNew) {
    commit("setIsNew", isNew);
  },
  removeAccessToken() {
    localStorage.removeItem("firstName"),
      localStorage.removeItem("middleName"),
      localStorage.removeItem("lastName"),
      localStorage.removeItem("Role"),
      localStorage.removeItem("accessToken"),
      localStorage.removeItem("patientId");
    // router.replace("/login");
    location.reload();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
