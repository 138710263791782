<template>
  <div>
    <b-card no-body v-if="displayList">
      <b-card-header class="my-0">
        <b-row class="my-0">
          <b-col lg="2">
            <b-form-select :options="pageOptions" v-model="perPage">

            </b-form-select>
            </b-col>
          <b-col sm="3">
            <b-form-input
              size="sm"
              class="float-right my-0"
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to search..."
            ></b-form-input
          ></b-col>
          <b-col lg="4"> </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          class="customTable table table-head-fixed"
          fixed
          @filtered="onFiltered"
          noCollapse="true"
          :current-page="currentpage"
          :per-page="perPage"
          :filter="filter"
          :small="small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          stacked="md"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getDrQueues"
          show-empty
          :fields="fields"
          :empty-text="
            getUsersList
              ? 'There is no patient queue yet.'
              : 'Unable to load data'
          "
        >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'index' ||
                  field.key === 'sex' ||
                  field.key === 'time' ||
                  field.key === 'cardNo'
                    ? '110px'
                    : '280px',
              }"
            />
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }} {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>

          <template v-slot:cell(status)="data">
            <span class="tag mx-2">
              <span
                class="confirmed"
                v-if="data.item.labResultPercentCompleted == 100"
                >Lab {{ data.item.labResultPercentCompleted }} %</span
              >
              <span
                class="danger"
                v-if="data.item.labResultPercentCompleted == 0"
                >Lab {{ data.item.labResultPercentCompleted }} %</span
              >
              <span
                class="warning"
                v-if="
                  data.item.labResultPercentCompleted > 0 &&
                  data.item.labResultPercentCompleted < 100
                "
                >Lab {{ data.item.labResultPercentCompleted }} %</span
              >
            </span>
            <span class="tag">
              <span
                class="confirmed"
                v-if="data.item.radioResultPercentCompleted == 100"
                >Rad {{ data.item.radioResultPercentCompleted }} %</span
              >
              <span
                class="danger"
                v-if="data.item.radioResultPercentCompleted == 0"
                >Rad {{ data.item.radioResultPercentCompleted }} %</span
              >
              <span
                class="warning"
                v-if="
                  data.item.radioResultPercentCompleted > 0 &&
                  data.item.radioResultPercentCompleted < 100
                "
                >Rad {{ data.item.radioResultPercentCompleted }} %</span
              >
            </span>
            <!-- <span class="tag" v-if="(data.item.status = 'notSeenByDr')">
              Doc
              <b-icon
                v-if="data.item.isSeenByDr == true"
                variant="success"
                icon="check-circle"
                title="laboratory Result is arrived"
              ></b-icon>
              <b-icon
                v-if="data.item.isSeenByDr == false"
                variant="primary"
                icon="arrow-repeat"
                title="waiting for laboratory Results"
              ></b-icon>
            </span> -->
          </template>
        </b-table>
        <li class="float-left ml-3">
        </li>

        <b-pagination
          v-if="checkQueue"
          class="float-right my-0"
          v-model="currentpage"
          :total-rows="total"
          :per-page="perPage"
          align="fill"
          size="sm"
          aria-controls="my-table"
        >
        </b-pagination>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";
// import sound from "../common/noftficaton";

export default {
  data() {
    return {
      total: "",
      small: true,
      getUsersList: false,
      curentEditId: "",
      requestListDate: "",
      filter: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      PatientId: [],
      patientName: "",
      sortBy: "time",
      sortDesc: true,
      curent_page: 1,
      perPage: 50,
      pageOptions: [1,50, 100, 150, 300, "All"],
      fields: fields.fieldsDrQueue,
    };
  },
  created() {
    this.fetchDrQueues().then(() => {
      this.getUsersList = true;
    });
    this.total = this.getDrQueues;
  },
  sockets: {
    newDrsQueue: function () {
      this.fetchDrQueues();
      this.isNewAction(true);

      //this.$swal("worked");
    },
    drsQueUpdated: function () {
      this.fetchDrQueues();
      this.isNewAction(true);
      // sound;
      //this.$swal("worked");
    },
  },

  methods: {
    ...mapActions([
      "fetchDrQueues",
      "fetchPatientsData",
      "fetchPatientsProfileNormalPatient",
      "isNewAction",
    ]),

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.total = filteredItems.length;
      this.currentPage = 1;
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;

      if (item.patientId === this.getPatientIdNot) return "selected-row";

      if (item.isSeenByDr === false) return "table-warning";
    },
    removePatientdata() {
      localStorage.removeItem("reco");
      localStorage.removeItem("recommendation");
      localStorage.removeItem("reason");
      localStorage.removeItem("refferedTo");
      localStorage.removeItem("status");
      localStorage.removeItem("department");
      localStorage.removeItem("treatment");
    },

    onRowSelected(items) {
      this.PatientId = items;
      this.check();

      this.fetchPatientsProfileNormalPatient(this.patientName).then(
        () => this.$router.push("/patientHistory"),
        this.isNewAction(false),
        localStorage.setItem("patientId", this.patientName),
        this.removePatientdata()
      );
    },
    check() {
      for (let i = 0; i < this.PatientId.length; i++) {
        this.patientName = this.PatientId[i].patientId;
      }
    },
  },

  computed: {
    ...mapGetters([
      "getPatients",
      "getDrQueue",
      "getDrQueues",
      "getPatientIdNot",
    ]),
    address() {
      return this.form.address.length > 2 ? true : false;
    },
    checkQueue() {
      return this.getDrQueues.length <= 0 ? false : true;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
li {
  list-style-type: none;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.customTable {
  overflow-y: auto;
  max-height: 490px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
