<template>
  <div style="margin-top:-80px;margin-left:-20px;margin-right:20px">
    <Header subTitle="Laboratory Investgations"></Header>
    <hr />
    <PersonalName style="margin-left:20px" :personal="personal"></PersonalName>
    <hr class="my-1" />

    <b-row v-for="haf in totalModified" :key="haf.id" class="my-3">
      <b-col lg="4" class="ml-3 my-2 col-sm-4" v-for="lab in haf.unique" :key="lab.id">
        <!-- {{ lab }} -->
        <h5>
          <b> {{ lab.category }}</b>
        </h5>
        <b-table :fields="fields" :items="lab.tests">
          <template v-slot:cell(range)="data">
            <span v-if="data.item.minRange && data.item.sex == 'Male'">
              {{ data.item.minRangeMale }} - {{ data.item.maxRangeMale }}
            </span>
            <span v-if="data.item.minRange && data.item.sex == 'Female'">
              {{ data.item.minRangeFemale }} - {{ data.item.maxRangeFemale }}
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(result)="data">
            {{ data.item.result }}
            <span v-if="data.item.power">{{ data.item.power }}</span>
            <span v-if="data.item.unit">{{ data.item.unit }}</span>
          </template>
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'name' || field.key === 'range'
                    ? '300px'
                    : '90px',
              }"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <div class="float-right" style="margin-bottom: 5px">
      <p class="float-right" style="margin-right: 70px">
        Laboratory Technologist :<strong
          > {{ firstName }} {{ middleName }} (Med, Laboratory
          Technologist)</strong
        >
      </p>
    </div>
    <Footer :dataFooter="dataFooter"></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createPdfFromHtml } from "../../print/logic";
import PersonalName from "../../common/print/personalName";
import Header from "../../common/print/header";
import Footer from "../../common/print/footer";
import _ from "lodash";
export default {
  components: {
    PersonalName,
    Header,
    Footer,
  },
  //props: ["editMode"],
  data() {
    return {
      personal: {},
      dataFooter: {},
      modified: [],
      uniqueModified: [],
      labRequests: [],
      totalModified: [],
      labra: [],
      test: [],
      small: true,
      checkSelection: true,
      descrpition: "",
      index1: [],
      physicalExam: [],
      firstName: localStorage.getItem("firstName"),
      middleName: localStorage.getItem("middleName"),
      isSend: false,
      fields: ["name", "result", "range"],
    };
  },
  created() {
    const data = {
      patientId: this.getLabQueue[0].patientId,
      isOutdoor: this.getLabQueue[0].isOutdoor,
    };

    this.fetchPatientsProfile(data)
      .then(() => {
        this.personal = {
          mrn: this.getPatientProfile.patient.cardNo,
          fullName:
            this.getPatientProfile.patient.firstName +
            " " +
            this.getPatientProfile.patient.middleName +
            " " +
            this.getPatientProfile.patient.lastName,
          age:
            this.calcAge(this.getPatientProfile.patient.birthDate) +
            " " +
            "old",
          sex: this.getPatientProfile.patient.sex,
          dateCheck: this.getPatientProfile.patient.medicalHistory[0].date,
          date: window.moment().format("DD/MM/YY hh:mm:ss a"),
        };
        this.dataFooter = {
          date: window.moment().format("DD/MM/YY hh:mm:ss a"),
          printBy:
            localStorage.getItem("firstName") +
            " " +
            localStorage.getItem("middleName"),
          role: localStorage.getItem("Role"),
        };
      })
      .then(() => {
        window.print();
      });
    this.newSturctured();
  },
  methods: {
    ...mapActions(["fetchPatientsProfile"]),
    printRadio() {
      createPdfFromHtml(this.$refs.print.$el);
    },
    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    newSturctured() {
      for (let i = 0; i < this.getLabQueue.length; i++) {
        // create modified, unique
        this.modified = [];
        for (let j = 0; j < this.getLabQueue[i].tests.length; j++) {
          console.log("B: " + i + " : " + this.modified);
          this.modified.push({
            category: this.getLabQueue[i].tests[j].category,
            tests: [],
          });
        }
        this.uniqueModified = _.uniqBy(this.modified, "category");
        // push to tests in uniqueModified
        for (let k = 0; k < this.getLabQueue[i].tests.length; k++) {
          console.log("D: " + k);
          let categoryName = this.getLabQueue[i].tests[k].category;
          for (let l = 0; l < this.uniqueModified.length; l++) {
            console.log("E: " + l);
            if (this.uniqueModified[l].category == categoryName) {
              this.uniqueModified[l].tests.push(this.getLabQueue[i].tests[k]);
            }
          }
        }
        // add to total modified
        this.totalModified.push({ unique: this.uniqueModified });
      }
    },

    sendRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        requestId: this.getRadioQueue[0]._id,
        tests: this.getRadioQueue[index].tests,
      };
      this.addRadioResult(labResult);
      this.isSend = true;
      this.$bvToast.show("toaster");
    },
    updateRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        requestId: this.getRadioQueue[0]._id,
        tests: this.getRadioQueue[index].tests,
      };
      this.updateRadioResults(labResult);
      this.isSend = true;
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile", "getLabQueue"]),
    checkRadioQueue() {
      return this.getRadioQueues != null ? true : false;
    },
    changeBmiColor() {
      return this.physicalExam.bmi > 18 && this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
* {
  font-size: 20px;
}
.leftSpace {
  padding-left: 50px;
}

.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  display: block;
}
.personalInfo {
  margin: 0px 5px;
}
li {
  list-style-type: none;
  display: block;
}
.edited {
  font-weight: bold;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.result {
  font-family: "Times New Roman", Times, serif;
  color: black;
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
