<template>
  <div class="topcard">
    <b-card nobody>
      <b-card-header class="noMargin bgheader">
        <b-row>
          <b-col lg="4" class="mx-1 mt-2">
            Name :
            <b
              >{{ getSinglePayment.firstName }}
              {{ getSinglePayment.middleName }}
              {{ getSinglePayment.lastName }}</b
            >
          </b-col>
          <b-col lg="3" class="mx-1 mt-2">
            Sex :
            <b
              >{{ getSinglePayment.sex }}</b
            >
          </b-col>
          <!-- {{getSinglePayment}} -->
          <b-col lg="2" class="mx-1 mt-2">
            Age :
            <b
              >{{ calcAge(getSinglePayment.birthDate || "12/04/2010") }}</b
            >
          </b-col>
          <b-col class="mt-2" v-if="getSinglePayment.isPaidForAll == true">
            Status :
            <span class="confirmed">
              <b-icon icon="check-circle"></b-icon>
              Paid</span
            >
          </b-col>
          <b-col
            lg="3"
            class="mx-1 my-1"
            v-if="getSinglePayment.requestDiscount"
          >
            Discount :
            <span v-if="getSinglePayment.discount || getSinglePayment.rejected">
              <span class="confirmed" v-if="getSinglePayment.discount == true"
                >Confirmed</span
              >
              <span class="danger" v-if="getSinglePayment.rejected == true"
                >Rejected</span
              >
            </span>

            <span
              class="warning"
              v-if="
                getSinglePayment.requestDiscount == true &&
                getSinglePayment.rejected == false &&
                getSinglePayment.discount == false
              "
              >Pending</span
            >
          </b-col>
          <!-- <b-col lg="3" class="mx-1" v-if="getSinglePayment.requestDiscount">
            Discount :<strong
              >{{
                getSinglePayment.requestDiscount
                  ? "Pending Request"
                  : "Pending Request"
              }}
            </strong>
            <span class="confirmed" v-if="getSinglePayment.requestDiscount==true">Pendiing</span>
          </b-col> -->
          <b-col
            lg="3"
            class="mx-1 my-1"
            v-if="getSinglePayment.discount || getSinglePayment.rejected"
          >
            <li class="float-right" v-if="getSinglePayment.rejected">
              Price:
              <strong>{{ getSinglePayment.total }}</strong>
            </li>
            <li class="float-right" v-if="getSinglePayment.discount">
              Actual Payment:
              <strong>{{ getSinglePayment.actualPayment }}</strong>
            </li>
          </b-col>
          <b-col
            v-if="getSinglePayment.discount || getSinglePayment.rejected"
            class="my-1"
          >
            <b-button
              variant="primary"
              size="sm"
              class="float-right mx-2"
              v-if="!checkDiscount && !getSinglePayment.isPaidForAll"
              @click="discountRequest"
            >
              Report
            </b-button>
            <b-button
              :disabled="isSend"
              size="sm"
              variant="primary"
              class="float-right mx-2"
              v-if="checkDiscount && !getSinglePayment.isPaidForAll"
              @click="paid"
            >
              Pay
            </b-button>
          </b-col>
          <b-col sm="1" class="mt-2 float-right">
            <b-link to="/recipt"> <i class="fa fa-print"></i> </b-link
          ></b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <span v-for="item in getSinglePayment.paymentList" :key="item.id">
          <p class="tag my-0 mx-0" v-if="item.type == 'radiologyRequest'">
            Radiology
          </p>
          <p class="tag my-0 mx-0" v-if="item.type == 'labRequest'">
            Laboratory
          </p>
          <p class="tag my-0 mx-0" v-if="item.type == 'treatmentRequest'">
            Treatment
          </p>
          <b-table hover :small="small" :items="item.payments" :fields="fields">
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
        </span>
        <b-row>
          <b-col
            lg="6"
            v-if="
              !getSinglePayment.requestDiscount && !getSinglePayment.discount
            "
          >
            <b-row
              class="mx-2"
              style="border: 1px solid gray"
              v-if="!getSinglePayment.isPaidForAll"
            >
              <b-col lg="12">
                <label
                  class="text-muted"
                  style="color: red !important; opacity: 0.6"
                  >For Discount Request only</label
                ></b-col
              >

              <b-col lg="6"
                ><input
                  type="number"
                  class="form-control my-1"
                  v-model="discount"
                  placeholder="Actual payment"
              /></b-col>
              <b-col lg="6">
                <input
                  v-if="!checkDiscount"
                  type="text"
                  class="form-control float-right"
                  v-model="reason"
                  placeholder="Reason"
              /></b-col>
            </b-row>
          </b-col>

          <b-col
            style="margin-top: 50px"
            class="col-6"
            lg="4"
            v-if="
              !getSinglePayment.requestDiscount && !getSinglePayment.discount
            "
          >
            <span class="float-right">
              Total: <strong>{{ getSinglePayment.total }}</strong>
            </span>
            <span class="float-right mx-1" v-if="!checkDiscount">
              Discount: <strong>{{ discountLabel }}</strong>
            </span>
          </b-col>
          <b-col
            style="margin-top: 40px"
            class="col-6"
            lg="2"
            v-if="
              !getSinglePayment.requestDiscount && !getSinglePayment.discount
            "
          >
            <b-button
              variant="primary"
              size="sm"
              class="float-right mx-2"
              v-if="!checkDiscount && !getSinglePayment.isPaidForAll"
              @click="discountRequest"
            >
              Report
            </b-button>
            <b-button
              :disabled="isSend"
              size="sm"
              variant="primary"
              class="float-right mx-2"
              v-if="checkDiscount && !getSinglePayment.isPaidForAll"
              @click="paid"
            >
              Pay
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      discount: "",
      selected: [],
      reason: "",
      isSend: false,
      small: true,
      checktext: false,

      fields: [
        { key: "index", label: "#" },
        { key: "name", label: "Service" },
        { key: "price", label: "Unit Price" },
      ],
    };
  },
  
    sockets: {
    newPaymentRequest: function () {
      // this.fetchCashierQueues();
      // this.isNewAction(true);
      this.$router.replace("/cashierQueue");
    },
    cashierNotification: function () {
      // this.fetchCashierQueues();
      this.$router.replace("/cashierQueue");
      // this.isNewAction(true);
    },
  },
  methods: {
    ...mapActions(["paymentForCashier", "paymentForCashierDiscount"]),
    paid() {
      this.paymentForCashier(this.getSinglePayment._id);
      this.$bvToast.toast("Patient Paid Successfully.", {
        title: "Patient Paid Successfully.",
        variant: "sucesss",
        solid: true,
      });
      localStorage.removeItem("patientId");
      this.isSend = true;
    },
    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    discountRequest() {
      const data = {
        actualPayment: this.discount,
        reason: this.reason,
        id: this.getSinglePayment._id,
      };
      this.paymentForCashierDiscount(data);
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getSinglePayment", "getErrors"]),
    total() {
      return this.getSinglePayment.paymentList.reduce(
        (index, item) => index + Number(item.price),
        0
      );
    },
    
    discountLabel() {
      return this.getSinglePayment.total - this.discount;
    },
    checkDiscount() {
      return this.discount.length <= 0 ? true : false;
    },
  },
};
</script>

<style scoped>
.tag {
  font-weight: 600;
  color: #007bff;
}
li {
  list-style-type: none;
}
div.card-body {
  padding: 0px;
}
.noMargin {
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
}
.bgheader {
  background-color: #e0e3eb;
  margin-bottom: 1px;
}
div.card {
  border: none;
}

.noMargin {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
</style>
