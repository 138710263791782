<template>
  <div>
    <b-row>
      <b-col lg="3" class="my-1 mx-2 col-3">
        <b-input-group size="sm">
          <b-form-select
            id="input-3"
            :options="filterOptions"
            size="sm"
            class="my-1"
            v-model="filterOn"
            value-field="_id"
            text-field="name"
            @change="fetchAppointment()"
            disabled-field="notEnabled"
          >
          </b-form-select>
        </b-input-group>
      </b-col>
    </b-row>
    <b-card no-body v-if="displayList">
      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :current-page="currentpage"
          :per-page="per_page"
          :small="small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filterIncludedFields="filterOn"
          :tbody-tr-class="rowClass"
          responsive="sm"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getTreatmentRecord"
          show-empty
          :fields="fields"
          :empty-text="getUsersList ? 'Empty record' : 'Unable to load data'"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }} {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>

          <template v-slot:table-caption>
            <b-pagination
              class="float-right my-0"
              v-model="currentpage"
              :total-rows="getTreatmentRecord.length"
              :per-page="per_page"
              align="fill"
              size="sm"
              aria-controls="my-table"
            >
            </b-pagination> </template
        ></b-table>
        <!-- <div class="form-group">
          <label>Minimal</label>
          <select class="form-control select2" style="width: 100%">
            <option selected="selected">Alabama</option>
            <option>Alaska</option>
            <option>California</option>
            <option>Delaware</option>
            <option>Tennessee</option>
            <option>Texas</option>
            <option>Washington</option>
          </select>
        </div> -->
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";
export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      PatientId: [],
      patientName: "",
      filterOn: "Today's Treatments",
      sortBy: "fullName",
      sortDesc: false,
      filterOptions: ["Today's Treatments"],
      currentpage: 1,
      per_page: 10,
      pageOptions: [5, 10, 15, "All"],

      fields: fields.fieldsTreatmentRecord,
    };
  },
  created() {
    this.fetchTodaysTreatmentsRecord().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "fetchTreatmentsRecord",
      "fetchPatientsProfile",
      "fetchTodaysTreatmentsRecord",
      "fetchPatientsData",
      "fetchTreatmentRecord",
      "fetchTreatmentRequestsofapatient",
    ]),

    fetchAppointment() {
      if (this.filterOn == "Today's Treatments") {
        this.fetchTodaysTreatmentsRecord().then(() => {
          this.getUsersList = true;
        });
      } else {
        this.fetchTreatmentsRecord().then(() => {
          this.getUsersList = true;
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === false) return "table-danger";
    },
    onRowSelected(items) {
      this.PatientId = items;
      console.log(items)
      const data ={
      patientId: items[0].patientId,
      isOutdoor:items[0].isOutdoor,
    }
      this.check();
      this.fetchPatientsProfile(data)

      this.fetchTreatmentRequestsofapatient(this.patientName).then(() =>
      this.$router.push("/treatmentResult")
      );
    },
    check() {
      for (let i = 0; i < this.PatientId.length; i++) {
        this.patientName = this.PatientId[i].patientId;
      }
    },
  },
  computed: {
    ...mapGetters(["getTreatmentRecord", "getDrQueue", "getDrQueues"]),
    address() {
      return this.form.address.length > 2 ? true : false;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
