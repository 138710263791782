<template>
  <div style="margin-top: -80px; margin-left: -40px">
    <Header subTitle="Radiology Results" class="mx-3"></Header>
    <hr class="mx-5" />
    <PersonalName :personal="personal" class="mx-5"></PersonalName>
    <hr class="my-1 mx-5" />
    <div class="lists my-3">
      <ul
        v-for="item in getPatientProfile.patient.medicalHistory[0].radioResults"
        :key="item.id"
      >
        <div v-for="pr in item.results" :key="pr.id">
          <h6 style="display: inline-block; margin: 0px">Findings of :</h6>
          <span class="result"
            ><b> {{ pr.name }}</b></span
          >

          <span v-for="finding in pr.findings" :key="finding.id">
            <li
              style="display: block; font-size: 14px"
              class="result"
              :class="{ edited: finding.edited }"
            >
              <span style="font-size: 26px">.</span> {{ finding.name }}
            </li>
          </span>
          <h5><b> Index: </b> {{ pr.conclusion }}</h5>

          <h5><b> Recommendation: </b> {{ pr.recommendation }}</h5>
          <ul>
            <li></li>
          </ul>
        </div>
      </ul>
    </div>

    <div class="float-right" style="margin-bottom: 35px">
      <!-- <p class="float-right" style="margin-right: 70px">
        <strong
          >By : Dr {{ firstName }} {{ middleName }} (MD, Radiologist)</strong
        >
      </p> -->
    </div>
    <Footer :dataFooter="dataFooter"></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createPdfFromHtml } from "../../print/logic";
import PersonalName from "../../common/print/personalName";
import Header from "../../common/print/header";
import Footer from "../../common/print/footer";
export default {
  components: {
    PersonalName,
    Header,
    Footer,
  },
  //props: ["editMode"],
  data() {
    return {
      personal: {},
      dataFooter: {},
      modified: [],
      uniqueModified: [],
      labRequests: [],
      totalModified: [],
      labra: [],
      test: [],
      small: true,
      checkSelection: true,
      descrpition: "",
      index1: [],
      physicalExam: [],
      firstName: localStorage.getItem("firstName"),
      middleName: localStorage.getItem("middleName"),
      isSend: false,
      fields: ["name", "result", "range"],
    };
  },
  created() {
    // const data = {
    //   patientId: this.getLabQueue[0].patientId,
    //   isOutdoor: this.getLabQueue[0].isOutdoor,
    // };

    this.personal = {
      mrn: this.getPatientProfile.patient.cardNo,
      fullName:
        this.getPatientProfile.patient.firstName +
        " " +
        this.getPatientProfile.patient.middleName +
        " " +
        this.getPatientProfile.patient.lastName,
      age: this.calcAge(this.getPatientProfile.patient.birthDate) + " " + "old",
      sex: this.getPatientProfile.patient.sex,
      dateCheck: this.getPatientProfile.patient.medicalHistory[0].date,
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
    };
    this.dataFooter = {
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
      printBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("middleName"),
      role: localStorage.getItem("Role"),
    };
    setTimeout(() => {
      window.print();
    }, 2000);
  },
  methods: {
    ...mapActions(["fetchPatientsProfile"]),
    printRadio() {
      createPdfFromHtml(this.$refs.print.$el);
    },
    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile", "getLabQueue"]),
  },
};
</script>

<style scoped>
* {
  font-size: 20px;
}
.leftSpace {
  padding-left: 50px;
}

.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  display: block;
}
.personalInfo {
  margin: 0px 5px;
}
li {
  list-style-type: none;
  display: block;
}
.edited {
  font-weight: bold;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.result {
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
