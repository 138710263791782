import axios from "axios";

const state = {
  treatment: [],
  treatments: [],
  treatmentRecord: [],
  treatmentCategory: [],
  treatmentRecords: [],
};

const mutations = {
  setTreatmentRecord: (state, treatmentRecord) =>
    (state.treatmentRecord = treatmentRecord),
  setTreatmentRecords: (state, treatmentRecords) =>
    (state.treatmentRecords = treatmentRecords),
  setTreatments: (state, treatments) => (state.treatments = treatments),
  setTreatmentCategory: (state, treatmentCategory) =>
    (state.treatmentCategory = treatmentCategory),
  setTreatment: (state, treatment) => (state.treatment = treatment),
  pushTreatmentCategory: (state, treatmentCategory) =>
    state.treatmentCategory.unshift(treatmentCategory),
  pushTreatment: (state, treatments) => state.treatments.unshift(treatments),
  deleteTreatment: (state, id) =>
    (state.treatments = state.treatments.filter(
      (treatment) => treatment.id !== id
    )),
  updateTreatment(state, payload) {
    const object = state.treatments.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
};

const getters = {
  getTreatments: (state) => {
    return state.treatments;
  },
  getTreatmentRecord: (state) => {
    return state.treatmentRecord;
  },
  getTreatmentRecords: (state) => {
    return state.treatmentRecords;
  },
  getTreatment: (state) => {
    return state.treatment;
  },
};

const actions = {
  async fetchTreatments({ commit }) {
    await axios
      .get("/treatments/all")
      .then((response) => {
        commit("setTreatments", response.data.treatments);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async fetchActiveTreatments({ commit }) {
    await axios
      .get("/treatments")
      .then((response) => {
        commit("setTreatments", response.data.treatments);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchTreatmentsRecord({ commit }) {
    await axios
      .get("/treatmentRequests/all")
      .then((response) => {
        commit("setTreatmentRecord", response.data.treatmentRequests);

        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchTodaysTreatmentsRecord({ commit }) {
    await axios
      .get("/treatmentRequests/today")
      .then((response) => {
        commit("setTreatmentRecord", response.data.treatmentRequests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchTreatmentRecord({ commit }, id) {
    await axios
      .get("/treatmentRequest/" + `${id}`)
      .then((response) => {
        commit("setTreatmentRecords", response.data.treatmentRequests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchTreatmentRequestsofapatient({ commit }, id) {
    await axios
      .get("/treatmentRequests/patient/" + `${id}`)
      .then((response) => {
        commit("setTreatmentRecord", response.data.treatmentRequests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async changeTreatmentStatus({ commit }, Id) {
    await axios
      .patch("/treatment/toggleStatus" + `/${Id}`)
      .then((response) => {
        commit("setTreatment", response.data.treatment);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.resolve();
      });
  },

  async updateTreatmentOne({ commit }, object) {
    await axios
      .patch("/treatment" + `/${object.id}`, object)
      .then((response) => {
        commit("updateTreatment", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updateTreatmentCategorie({ commit }, object) {
    await axios
      .patch("/treatmentCategory" + `/${object.id}`, object)
      .then((response) => {
        commit("updateTreatment", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async fetchTreatment({ commit }, Id) {
    await axios
      .get("/treatment" + `/${Id}`)
      .then((response) => {
        commit("setTreatment", response.data);

        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async deleteTreatmentOne({ commit }, Id) {
    await axios
      .delete("/treatment" + `/${Id}`)
      .then((response) => {
        commit("deleteTreatment", response.data);
        commit("setErrors", response.data.message);

        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
      });
  },

  async addTreatmentCategory({ commit }, object) {
    await axios
      .post("/treatmentCategory", object)
      .then((response) => {
        commit("pushTreatmentCategory", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.message);
        return Promise.reject();
      });
  },

  async addTreatment({ commit }, object) {
    await axios
      .post("/treatment", object)
      .then((response) => {
        commit("pushTreatment", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  // for lab result send
  async addTreatmentResult({ commit }, object) {
    await axios
      .post("/labResults", object)
      .then((response) => {
        commit("pushLabResult", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
