<template>
  <div>
    <b-card no-body>
      <b-card-header class="Jdi-header" style="padding: 2px; margin: 0px">
        <b-form class="Jdi-row">
          <b-row>
            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Name Of test:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Category:"
                label-for="input-1"
                size="sm"
              >
                <b-form-select
                  id="input-3"
                  class="my-1"
                  size="sm"
                  v-model="form.category"
                  value-field="_id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <option v-for="item in getLabTests" :key="item.id">
                    {{ item.category }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Price:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.price"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col :lg="showRange ? 2 : 3" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Type:"
                label-for="input-1"
                size="sm"
              >
                <b-form-select
                  id="input-3"
                  class="my-1"
                  @change="displayNone"
                  size="sm"
                  v-model="form.typeOfResult"
                  value-field="value"
                  text-field="name"
                  :options="typeList"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="showRange"
              lg="1"
              style="margin-left: 0px; margin-right: 0px; margin-top: 44px"
            >
              <b-form-checkbox-group v-model="radioValue">
                <b-form-radio
                  @change="radioValue = !radioValue"
                  :value="radioValue"
                  >Differ</b-form-radio
                ></b-form-checkbox-group
              >
            </b-col>
          </b-row>
          <b-row>
            <b-row style="margin-left: 0px; margin-right: 0px">
              <b-col lg="3" class="my-1" v-if="showRange">
                <b-form-group
                  class="my-1"
                  id="input-group-1"
                  label="Unit:"
                  label-for="input-1"
                  size="sm"
                >
                  <b-form-input
                    class="my-1"
                    id="input-1"
                    size="sm"
                    v-model="form.unit"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col lg="2" class="my-1" v-if="showRange">
                <b-form-group
                  class="my-1"
                  id="input-group-1"
                  :label="radioValue ? 'Female Min Range' : 'Min Range'"
                  label-for="input-1"
                  size="sm"
                >
                  <b-form-input
                    class="my-1"
                    id="input-1"
                    size="sm"
                    v-model="form.minRangeFemale"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="2" class="my-1" v-if="showRange">
                <b-form-group
                  class="my-1"
                  id="input-group-1"
                  :label="radioValue ? 'Female Max Range' : 'Max Range'"
                  label-for="input-1"
                  size="sm"
                >
                  <b-form-input
                    class="my-1"
                    id="input-1"
                    size="sm"
                    v-model="form.maxRangeFemale"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="2" class="my-1" v-if="showRange && radioValue">
                <b-form-group
                  class="my-1"
                  id="input-group-1"
                  label="Male Min Range:"
                  label-for="input-1"
                  size="sm"
                >
                  <b-form-input
                    class="my-1"
                    id="input-1"
                    size="sm"
                    v-model="form.minRangeMale"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="2" class="my-1" v-if="showRange && radioValue">
                <b-form-group
                  class="my-1"
                  id="input-group-1"
                  label="Male Max Range:"
                  label-for="input-1"
                  size="sm"
                >
                  <b-form-input
                    class="my-1"
                    id="input-1"
                    size="sm"
                    v-model="form.maxRangeMale"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col :lg="radioValue ? 1 : 2" class="my-1" v-if="showRange">
                <b-form-group
                  class="my-1"
                  id="input-group-1"
                  label="Power:"
                  label-for="input-1"
                  size="sm"
                >
                  <b-form-input
                    class="my-1"
                    id="input-1"
                    size="sm"
                    v-model="form.power"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-row>
          <b-row>
            <b-col lg="12">
              <b-button
                :disabled="checkRequired"
                v-if="createMode"
                variant="primary"
                class="float-right"
                @click.prevent="registerTestData"
              >
                Save
              </b-button>
              <b-button
                v-if="editMode"
                variant="primary"
                class="float-right"
                @click.prevent="updateLabTests"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-header>

      <b-card-body style="margin: 0px; padding: 0px">
        <b-row>
          <b-col lg="12" v-for="item in getLabTests" :key="item.id">
            <p style="margin: 0px; padding: 0px" class="capitalize">
              <b>{{ item.category }}</b>
            </p>

            <b-table
              :items="item.tests"
              :fields="fields"
              ref="selectableTable"
              selectable
              noCollapse="true"
              :current-page="currentpage"
              :per-page="per_page"
              :small="small"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
              :select-mode="selectMode"
              @row-selected="onRowSelected"
              show-empty
              :empty-text="getUsersList ? 'Empty data.' : 'Unable to load data'"
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template v-slot:cell(status)="data">
                <toggle-button
                  :value="data.item.status >= 1 ? true : false"
                  color="#007bff"
                  :labels="true"
                  @change="updateLabStatus(data.item._id)"
                />
              </template>
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width:
                      field.key === 'name' ||
                      field.key === 'price' ||
                      field.key === 'Status' ||
                      field.key === 'Actions'
                        ? '100px'
                        : '20px',
                  }"
                />
              </template>

              <template v-slot:cell(Actions)="data">
                <b-link
                  @click="editLabTest(data.item._id, item.index)"
                  class="text-primary"
                >
                  <b-icon icon="pen" variant="primary"></b-icon>
                </b-link>
                <!-- <b-link @click="deleteUsers()" class="text-danger">
                      <b-icon icon="trash" variant="danger"></b-icon>
                    </b-link> -->
              </template>

              <template v-slot:table-caption>
                <b-pagination
                  class="float-right my-0"
                  v-model="currentpage"
                  :total-rows="item.tests.length"
                  :per-page="per_page"
                  align="fill"
                  size="sm"
                  aria-controls="my-table"
                >
                </b-pagination>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";

export default {
  data() {
    return {
      small: true,
      curentData: {},
      getUsersList: false,
      curentEditId: "",
      showRange: false,
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortBy: "name",
      sortDesc: false,
      isBusy: false,
      currentpage: 1,
      radioValue: false,
      per_page: 50,
      pageOptions: [5, 10, 15, 50, "All"],
      genderList: ["Male", "Female"],
      form: {
        name: "",
        category: "",
        price: "",
        typeOfResult: "",
        minRangeFemale: "",
        maxRangeFemale: "",
        power: "",
        minRangeMale: "",
        maxRangeMale: "",
        unit: "",
      },
      typeList: [
        { name: "Number", value: "number" },
        { name: "Text", value: "text" },
        { name: "Positive or Negative", value: "radio" },
        { name: "List", value: "textarea" },
      ],

      fields: fields.fieldsLabTest,
    };
  },

  created() {
    this.fetchLabTests().then(() => {
      this.getUsersList = true;
    });
  },
  sockets: {},
  methods: {
    ...mapActions([
      "addLabTest",
      "fetchLabTests",
      "deleteLabTest",
      "updateLabTestSingle",
      "fetchLabTest",
      "changeLabTestStatus",
    ]),
    // rowClass(item, type) {
    //   if (!item || type !== "row") return;
    //   if (item.status === false) return "table-danger";
    // },
    onRowSelected(items) {
      this.selected = items;
    },
    displayNone() {
      if (this.form.typeOfResult === "number") {
        // this.radioValue = true;
        this.showRange = true;
      }
    },
    updateLabStatus(id) {
      this.changeLabTestStatus(id).then(() => {
        this.fetchLabTests();
      });
    },
    registerTestData() {
      const TestData = {
        name: this.form.name,
        price: this.form.price,
        category: this.form.category,
        unit: this.form.unit,
        typeOfResult: this.form.typeOfResult,
        minRangeMale: this.form.minRangeMale,
        maxRangeMale: this.form.maxRangeMale,
        minRangeFemale: this.form.minRangeFemale,

        maxRange: this.form.maxRange,
        minRange: this.form.minRange,
        maxRangeFemale: this.form.maxRangeFemale,
        power: this.form.power,
      };
      this.addLabTest(TestData).then(() => {
        this.$bvToast.toast(this.getErrors, {
          title: this.getErrors,
          variant: "sucesss",
          solid: true,
        });
        this.clear();
        this.fetchLabTests();
      });
    },
    updateLabTests() {
      const LabTestData = {
        name: this.form.name,
        category: this.form.category,
        price: this.form.price,
        unit: this.form.unit,
        typeOfResult: this.form.typeOfResult,
        minRangeFemale: this.form.minRangeFemale,
        maxRangeFemale: this.form.maxRangeFemale,
        minRangeMale: this.form.minRangeMale,
        maxRangeMale: this.form.maxRangeMale,
        power: this.form.power,
        id: this.curentEditId,
      };
      this.updateLabTestSingle(LabTestData).then(() => {
        (this.editMode = false),
          (this.createMode = true),
          (this.showRange = false),
          (this.radioValue = false),
          this.clear();
        (this.curentData = null), this.fetchLabTests();
      });
      this.$bvToast.show("toaster");
    },
    clear() {
      (this.form.name = ""),
        (this.form.price = ""),
        (this.form.category = ""),
        (this.form.minRangeFemale = ""),
        (this.form.maxRangeFemale = ""),
        (this.form.typeOfResult = ""),
        (this.form.maxRangeMale = ""),
        (this.form.minRangeMale = ""),
        (this.form.power = ""),
        (this.form.unit = "");
    },
    editLabTest(index) {
      this.fetchLabTest(index).then(() => {
        this.curentData = this.getSingleLabTest;
        this.form.name = this.curentData.labTest.name;
        this.curentEditId = this.curentData.labTest._id;
        this.form.price = this.curentData.labTest.price;
        this.form.category = this.curentData.category;
        this.form.typeOfResult = this.curentData.labTest.typeOfResult;
        this.form.minRangeFemale = this.curentData.labTest.minRangeFemale;
        this.form.maxRangeFemale = this.curentData.labTest.maxRangeFemale;
        this.form.minRangeMale = this.curentData.labTest.minRangeFemale;
        this.form.maxRangeMale = this.curentData.labTest.maxRangeMale;
        this.form.power = this.curentData.labTest.power;
        this.form.unit = this.curentData.labTest.unit;
        this.editMode = true;
        (this.radioValue = true), (this.showRange = true);
        this.createMode = false;
      });

      // this.$swal(index);
    },

    deleteLabTests(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((deleteU) => {
          if (deleteU) {
            this.deleteLabTest(id);
            this.$bvToast.toast("Deleted Sucessfully!!!!!", {
              title: "Deleted",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
  },
  computed: {
    ...mapGetters([
      "getLabTests",
      "getLabTest",
      "getSingleLabTest",
      "getErrors",
    ]),
    // displayNone() {
    //   return this.form.typeOfResult === "number" ? true : false;
    // },
    checkRequired() {
      return this.form.name <= 0 ||
        this.form.category <= 0 ||
        this.form.price <= 0 ||
        this.form.typeOfResult <= 0
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
