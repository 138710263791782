<template>
  <div style="margin-top: -80px; margin-left: -40px">
    <Header subTitle="Medical Result/Medical Summary" class="mx-3"></Header>
    <hr class="mx-5" />
    <PersonalName :personal="personal" class="mx-5"></PersonalName>
    <hr class="my-1 mx-5" />
<b-row class="mx-5" v-if="getRefer.drsNote.length > 0">
      <p><b> C/C : </b>{{ getRefer.drsNote[getRefer.drsNote.length - 1].c_c }}</p>
    </b-row>
    <b-row class="mx-5" v-if="getRefer.drsNote.length > 0">
      <p><b>HPI : </b>{{ getRefer.drsNote[getRefer.drsNote.length - 1].hpi }}</p>
    </b-row>

    <div class="row mx-5 mb-3" v-if="getRefer.physicalExam.length > 0">
      <p v-if="getRefer.physicalExam" style="width: 100%"><b > P/E :</b><b style="font-size:15px"> V/S :</b></p>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].bp">
        <li class="mx-3" >
          BP : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].bp }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].pr">
        <li class="mx-3" >
          PR : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].pr }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].rr">
        <li class="mx-3" >
          RR : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].rr }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].t">
        <li class="mx-3" >
          T : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].t }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].pso2">
        <li class="mx-3" >
          PSO2 : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].pso2 }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].wt">
        <li class="mx-3" >
          WT : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].wt }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].ht">
        <li class="mx-3" >
          HT : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].ht }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].bmi">
        <li class="mx-3" >
          BMI : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].bmi }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].ac">
        <li class="mx-3" >
          AC : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].ac }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].hc">
        <li class="mx-3" >
          HC : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].hc }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].muac">
        <li class="mx-3" >
          MUAC : <span class="result">{{ getRefer.physicalExam[getRefer.physicalExam.length - 1].muac }}</span>
        </li>
      </div>
      <p v-if="getRefer.physicalExam[getRefer.physicalExam.length - 1].p_e_note" style="width: 100%">
        <b class="italic"> Physical exam note:</b>
        {{ getRefer.physicalExam[getRefer.physicalExam.length - 1].p_e_note }}
      </p>
      
      
    </div>
    <span v-for="haf in totalModified" :key="haf.id">
      <div
        class="d-flex mt-2"
        style="margin-bottom: -30px"
        v-if="totalModified.length > 0"
      >
        <p class="mx-5"><b>Lab/Ix :</b></p>
        <span class="mx-5">
          By {{ haf.labTechnician }} (Med, Labroratory Technologist)
        </span>
      </div>

      <b-row class="my-3">
        <!-- <p sm="12" class="mx-5"
          ><b>Lab/Ix :</b>
          <span class="mx-5"
            >By {{ haf.labTechnician }}(Med, Labroratory Technologist)</span
          ></p
        > -->

        <b-col sm="5" class="ml-5" v-for="lab in haf.unique" :key="lab.id">
          <!-- {{ lab }} -->
          <p>
            <b class="capitalize">{{ lab.category }}</b>
          </p>
          <b-table class="custom-border" :fields="fields" :items="lab.tests">
            <template v-slot:cell(range)="data">
              <span
                v-if="
                  data.item.minRangeMale &&
                  getPatientProfile.patient.sex == 'Male'
                "
              >
                {{ data.item.minRangeMale }} - {{ data.item.maxRangeMale }}
                {{ data.item.unit }}
              </span>
              <span
                v-else-if="
                  data.item.minRangeFemale &&
                  getPatientProfile.patient.sex == 'Female'
                "
              >
                {{ data.item.minRangeFemale }} -
                {{ data.item.maxRangeFemale }}
                {{ data.item.unit }}
              </span>
              <span v-else>-</span>
            </template>
            <template v-slot:cell(result)="data">
              {{ data.item.result }}
              <span
                v-if="
                  data.item.power && data.item.result != 'result not entered'
                "
                >{{ data.item.power }}</span
              >
              <span
                v-if="
                  data.item.unit && data.item.result != 'result not entered'
                "
                >{{ data.item.unit }}</span
              >
            </template>
            
          </b-table>
        </b-col>
      </b-row>
    </span>
    <span v-if="getRefer.radioResults.length > 0">
      <div class="d-flex">
        <p class="mx-5 float-left"><b>Radiology/Ix :</b></p>
        <p class="mx-5 float-right">
          By Dr : {{ getRefer.radioResults[getRefer.radioResults.length -1].radiologist }} (MD,Radiologist)
        </p>
      </div>
      <b-row class="mx-5">
        <div>
          <span v-for="item in getRefer.radioResults[getRefer.radioResults.length -1].results" :key="item">
            <p style="display: inline-block; margin: 0px">Findings of :</p>

            <span class="result"
              ><b>{{ item.name }}</b></span
            >

            <span v-for="finding in item.findings" :key="finding.id">
              <li
                style="display: block; font-size: 18px"
                class=" my-0"
                :class="{ edited: finding.edited }"
              >
                <span style="font-size: 26px">.</span> {{ finding.name }}
              </li>
            </span>
            <p>
              <b class="mr-5" v-if="item.conclusion"> Index: </b
              >{{ item.conclusion }}
            </p>
            <p>
              <b class="mr-5" v-if="item.recommendation"> Recommendation: </b>
              {{ item.recommendation }}
            </p>
          </span>
        </div>
      </b-row>
    </span>
    <b-row class="mx-5" v-if="getRefer.dx.length > 0">
      <span>
        <li
          v-for="(item, index) in getRefer.dx"
          :key="item"
          class="italic"
        >
          Diagnosis {{ index + 1 }} : <b>{{ item.dx }}</b>
        </li>
      </span>
      <!-- <p><b> Diagnosis :</b> {{ getRefer.diagnosis }}</p> -->
    </b-row>
    <b-row class="mx-5">
      <span v-if="getRefer.tx.length > 0">
        <b> Treatments :</b>

        <ul v-for="item in getRefer.tx" :key="item">
          <span v-for="treatment in item.treatments" :key="treatment._id">
          <li>
            <b>{{ treatment.name }}</b
            >,
          </li></span>
      <p v-if="item.procedure"><span class="italic">Descrpition : </span>{{ item.procedure }}</p>

        </ul>
      </span>

      <p class="w-100" v-if="getRefer.txMgmt.length > 0">
        <span class="italic">Descrpition :</span>
        <ul v-for="tx in getRefer.txMgmt" :key="tx._id">
<li>{{tx.txMgmt}}</li>
        </ul>
      </p>
    </b-row>
    


    <Footer :dataFooter="dataFooter"></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PersonalName from "../../common/print/personalName";
import Header from "../../common/print/header";
import Footer from "../../common/print/footer";
import _ from "lodash";
export default {
  components: {
    PersonalName,
    Header,
    Footer,
  },
  //props: ["editMode"],
  data() {
    return {
      personal: {},
      dataFooter: {},
      modified: [],
      uniqueModified: [],
      labResult: [],
      totalModified: [],
      labra: [],
      test: [],
      small: true,
      checkSelection: true,
      descrpition: "",
      index1: [],
      physicalExam: [],
      firstName: localStorage.getItem("firstName"),
      middleName: localStorage.getItem("middleName"),
      isSend: false,
      fields: ["name", "result", "range"],
    };
  },
  created() {
    this.labResult = [];
    if (this.getRefer.labResults) {
      this.labResult.push(
        this.getRefer.labResults[this.getRefer.labResults.length - 1]
      );
    }

    this.personal = {
      mrn: this.getPatientProfile.patient.cardNo,
      fullName:
        this.getPatientProfile.patient.firstName +
        " " +
        this.getPatientProfile.patient.middleName +
        " " +
        this.getPatientProfile.patient.lastName,
      age: this.calcAge(this.getPatientProfile.patient.birthDate) + " " + "old",
      sex: this.getPatientProfile.patient.sex,
      dateCheck: this.getPatientProfile.patient.medicalHistory[0].date,
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
    };
    this.dataFooter = {
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
      printBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("middleName"),
      role: localStorage.getItem("Role"),
    };
    this.newSturctured();

    setTimeout(() => {
      window.print();
    }, 2000);
  },
  methods: {
    ...mapActions(["fetchPatientsProfile", "addRefer"]),

    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    convertToarray(item) {
      // console.log(item);
      let converted = null;
      if (item != "") {
        converted = item.split(",");
      }

      return converted;
    },
    newSturctured() {
      // for every request
      if (this.labResult != null) {
        for (let i = 0; i < this.labResult.length; i++) {
          // create modified, unique
          this.modified = [];
          // this.totalModified = [];
          for (let j = 0; j < this.labResult[i].tests.length; j++) {
            // console.log("B: " + i + " : " + this.modified);
            this.modified.push({
              category: this.labResult[i].tests[j].category,

              tests: [],
            });
          }
          this.uniqueModified = [];
          this.uniqueModified = _.uniqBy(this.modified, "category");
          // push to tests in uniqueModified
          for (let k = 0; k < this.labResult[i].tests.length; k++) {
            // console.log("D: " + k);
            let categoryName = this.labResult[i].tests[k].category;
            for (let l = 0; l < this.uniqueModified.length; l++) {
              // console.log("E: " + l);
              if (this.uniqueModified[l].category == categoryName) {
                this.uniqueModified[l].tests.push(this.labResult[i].tests[k]);
              }
            }
          }
          // add to total modified
          this.totalModified.push({
            unique: this.uniqueModified,
            time: this.labResult[i].time,
            labTechnician: this.labResult[i].labTechnician,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile", "getLabQueue", "getRefer"]),
    checkRadioQueue() {
      return this.getRadioQueues != null ? true : false;
    },
    changeBmiColor() {
      return this.physicalExam.bmi > 18 && this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
* {
  font-size: 20px;
}
.physical {
  display: block;
  border: 1px solid lightgray;
  padding: 3px;
  border: collapse;
}
.custom-border {
  border: 1px solid lightgray;
  padding: 5px;
}
.leftSpace {
  padding-left: 50px;
}
.btm {
  margin-bottom: 20px;
}
.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  display: block;
}
.personalInfo {
  margin: 0px 5px;
}
.row {
  border-block: collapse;
}
li {
  list-style-type: none;
  display: block;
}
.edited {
  font-weight: bold;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.result {
  padding-left: 8px;
}

.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
