<template>
  <div>
    <b-card no-body v-if="displayList">
      <b-card-body class="text-center twa-body">
        <b-row>
          <b-col sm="2" class="my-1 col-3 ml-1">
            <b-input-group size="sm">
              <b-form-select
                class="perPageSelect"
                v-model="per_page"
                id="sortBySelect"
                :options="pageOptions"
              >
              </b-form-select>
            </b-input-group>
          </b-col>
          <b-col sm="3" class="col-8 my-1">
            <b-form-input
              size="sm"
              class="float-right my-0"
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to search..."
            ></b-form-input
          ></b-col>
        </b-row>
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :current-page="curentpage"
          :per-page="per_page"
          :small="small"
          :filter="filter"
          stacked="sm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getCashierQueues"
          show-empty
          :fields="fields"
          :empty-text="
            getUsersList
              ? 'There is no patient in queue yet.'
              : 'Unable to load data.'
          "
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }} {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>
          <template v-slot:cell(patient)="data">
            <span v-if="data.item.isOutdoor == true">Outdoor</span>
            <span v-else>Normal</span>
          </template>

          <template v-slot:cell(Price)="data">
            <p v-if="data.item.discount">{{ data.item.actualPayment }}</p>
            <p v-if="!data.item.discount">{{ data.item.total }}</p>
          </template>
          <template v-slot:cell(status)="data">
            <span v-if="data.item.requestDiscount == true">
              <p class="confirmed" v-if="data.item.discount == true">
                Confirmed
              </p>
              <p class="danger" v-if="data.item.rejected == true">Rejected</p>
              <p
                class="warning"
                v-if="
                  data.item.discount == false && data.item.rejected == false
                "
              >
                Pending
              </p>
            </span>
            <span v-if="data.item.requestDiscount == false"> No </span>
          </template>

          <template v-slot:cell(Actions)="data">
            <span v-if="data.item.requestDiscount">
              <b-link
                @click="pay(data.item._id)"
                v-if="data.item.discount == true || data.item.rejected == true"
                class="text-primary"
              >
                <b-icon icon="credit-card" variant="primary"></b-icon>Pay
              </b-link>
              <p
                v-if="
                  data.item.requestDiscount == true &&
                  data.item.discount == false &&
                  data.item.rejected == false
                "
              >
                Pending Request
              </p>
            </span>
            <span v-if="!data.item.requestDiscount">
              <b-link
                @click="pay(data.item._id)"
                v-if="!data.item.isPaidForAll"
                class="text-primary"
              >
                <b-icon
                  icon="credit-card"
                  font-scale="1.5"
                  variant="primary"
                ></b-icon>
                Pay
              </b-link>
              <span v-if="data.item.isPaidForAll" class="confirmed">
                <b-icon icon="check-circle"></b-icon>
                Paid</span
              >
            </span>
          </template>

          <template v-slot:table-caption>
            <b-pagination
              class="float-right my-0"
              v-model="curentpage"
              :total-rows="getCashierQueues.length"
              :per-page="per_page"
              align="fill"
              size="sm"
              aria-controls="my-table"
            >
            </b-pagination> </template
        ></b-table>
        <!-- <div class="fa-3x">
          <i class="fa fa-blind" style="color: blue"></i>
        </div> -->
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";

export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortDesc:false,
      sortBy:"isPaidForAll",
      filter: "",
      paymentId: "",
      curentpage: 1,
      per_page: 50,
      pageOptions: [5, 10, 15, 50, "All"],
      fields: fields.fieldsCashierQueue,
    };
  },

  created() {
    this.fetchCashierQueues().then(() => {
      this.getUsersList = true;
    });
  },
  sockets: {
    newPaymentRequest: function () {
      this.fetchCashierQueues();
      this.isNewAction(true);
    },
    cashierNotification: function () {
      this.fetchCashierQueues();
      this.$router.replace("/cashierQueue");
      this.isNewAction(true);
    },
  },
  methods: {
    ...mapActions([
      "fetchCashierQueues",
      "fetchPatientsData",
      "fetchPatientsProfile",
      "paymentForCashier",
      "getPaymentData",
      "isNewAction",
    ]),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isPaidForAll === true) return "table-success";
    },
    pay(id) {
      this.paymentForCashier(id).then(() => {
        this.fetchCashierQueues();
      });
    },
    check() {
      for (let i = 0; i < this.selected.length; i++) {
        this.paymentId = this.selected[i]._id;
      }
    },
    onRowSelected(items) {
      this.selected = items;
      this.check();
      this.getPaymentData(this.paymentId).then(
        () => this.$router.push("/cashierResult"),
        this.isNewAction(false),
        localStorage.setItem("patientId", this.paymentId)
      );
    },
  },
  computed: {
    ...mapGetters(["getPatients", "getDrQueue", "getCashierQueues"]),
    total() {
      return this.getCashierQueues.length;
    },
    checkQueue() {
      return this.getCashierQueues.length <= 0 ? false : true;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}

.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
