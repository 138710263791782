<template>
  <div>
    <div class="mt-3">
      <b-row class="Jdi-row mt-3">
        <b-col lg="9">
          <div v-if="getLabQueue">
            <b-row>
              <b-col lg="4" v-for="(item, index) in getLabQueue" :key="item.id">
                <li>
                  <div class="edit">
                    <p class="noPadding">
                      Date
                      <span class="result">{{ getLabQueue[index].date }}</span>
                    </p>
                    <p class="noPadding">
                      Request Time <span class="result">{{ item.time }}</span>
                    </p>
                    <p class="noPadding">
                      Status
                      <span
                        style="color: green"
                        class="result"
                        v-if="getLabQueue[index].isResultSaved"
                      >
                        Result Arrived</span
                      >
                      <span
                        class="result"
                        v-if="
                          !getLabQueue[index].isResultSaved &&
                          getLabQueue[index].isPaidFor
                        "
                      >
                        Result Pending</span
                      >
                      <span class="result" v-if="!getLabQueue[index].isPaidFor">
                        Payment Pending</span
                      >
                    </p>
                    <b-link
                      v-if="
                        !getLabQueue[index].isResultSaved &&
                        !getLabQueue[index].isPaidFor
                      "
                      class="noPadding border float-right"
                      style="
                        display: inline-block;
                        margin-right: 15px;
                        font-style-type: underline;
                      "
                      @click="sendRequest(index)"
                    >
                      Edit</b-link
                    >
                    <b-link
                      class="noPadding border float-right actions"
                      style="
                        display: inline-block;
                        margin-right: 15px;
                        font-style-type: underline;
                      "
                      @click="showO = !showO"
                    >
                      List</b-link
                    >
                    <b-link
                      v-if="getLabQueue[index].isResultSaved"
                      class="noPadding border float-right"
                      style="display: inline-block"
                      @click="$emit('show-result')"
                    >
                      Show Result</b-link
                    >
                    <b-link
                      v-if="
                        !getLabQueue[index].isResultSaved &&
                        !getLabQueue[index].isPaidFor
                      "
                      class="noPadding border float-right"
                      style="
                        display: inline-block;
                        margin-right: 15px;
                        font-style-type: underline;
                      "
                      @click="cancelRequest(getLabQueue[index]._id)"
                    >
                      Cancel</b-link
                    >
                  </div>
                  <span v-if="showO && !checkPatient">
                    <b-table
                      :fields="fieldsList"
                      :small="small"
                      :items="getLabQueue[index].tests"
                    >
                      <template v-slot:cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                    </b-table>
                  </span>
                </li>
              </b-col>
            </b-row>
          </div>
          <!-- {{ getLabTests }} -->
          <b-row class="mx-1">
            <b-col sm="6" v-for="(item, index) in getLabTests" :key="item.id">
              <!-- <span v-for="item in nt" :key="item.id"> -->
              <hr />
              <!-- <draggable
                v-model="getLabTests"
                group="people"
                @start="drag = true"
                @end="drag = false"
              > -->
              <!-- {{ item }} -->
              <li>
                <b-form-checkbox-group v-model="selected">
                  <b-form-checkbox
                    @change="select(item, item.select, index)"
                    :value="item.tests"
                    class="result"
                    ><span class="text-bold" style="font-style: italic">{{
                      item.category
                    }}</span></b-form-checkbox
                  >
                  <span @click="item.checked = !item.checked">
                    <b-icon v-if="!item.checked" icon="chevron-down"></b-icon>
                    <b-icon
                      v-if="item.checked"
                      icon="chevron-compact-up"
                    ></b-icon>
                  </span>
                </b-form-checkbox-group>
                <hr />
                <span v-if="item.checked">
                  <div v-for="test in item.tests" :key="test.id">
                    <b-form-checkbox-group v-model="selected">
                      <b-form-checkbox
                        v-if="test.status"
                        @change="selectOne(test.name)"
                        size="sm"
                        class="text-muted mx-5"
                        :value="test"
                        >{{ test.name }}</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </div>
                </span>
              </li>
              <!-- </draggable> -->
              <!-- </span> -->
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" v-if="!checkSelection">
          <div class="mt-1 selected">
            <p v-if="checkSelection">You Are Not Selected Yet Nigga</p>
            <b-table
              v-if="!checkSelection"
              :items="selected"
              :small="small"
              :fields="fields"
            >
              <template v-slot:cell(remove)="data">
                <b-icon
                  class="mx-2 icon"
                  variant="danger"
                  tooltip="removing from the request"
                  @click="haf(data.item._id)"
                  font-scale="1.5"
                  icon="backspace-fill"
                ></b-icon>
              </template>
            </b-table>
            <b-row class="mx-3">
              <li class="float-right">Total {{ total }}</li>
            </b-row>
            <b-row class="mx-1">
              <span>
                <b-button
                  variant="danger"
                  size="sm"
                  class="mx-1 my-1"
                  @click="cancel"
                >
                  Cancel
                </b-button>
              </span>

              <b-button
                size="sm"
                v-if="createMode && !checkRole"
                :disabled="isSendLab"
                class="btnPositive"
                @click="sendTests"
              >
                Send to laboratory
              </b-button>

              <b-button
                v-if="checkRole"
                :disabled="isSendLab"
                class="btnPositive"
                size="sm"
                @click="sendTestsCashier"
              >
                Send to Laboratory
              </b-button>

              <span v-if="!createMode && !checkPatient">
                <b-button
                  size="sm"
                  variant="info"
                  class="mx-2 my-1"
                  v-if="!getLabQueue[currentIndex].isPaidFor"
                  @click="updateTests"
                >
                  Update
                </b-button>
              </span>
            </b-row>
          </div>
        </b-col>
        <!-- {{ selected }} -->
        <!-- {{ getLabTests }} -->
      </b-row>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
// import draggable from "vuedraggable";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    // draggable,
  },
  props: ["isOutDoor"],
  data() {
    return {
      status: false,
      myArray: [],
      showO: false,
      showT: false,
      patientName: "",
      requestIndex: 0,
      patientId: "",
      testId: "",
      showModal: false,
      small: true,
      createMode: true,
      isSendLab: false,
      testPrice: "",
      checkedCategory: false,
      selected: [],
      currentIndex: 0,
      requestId: "",
      fields: ["name", "price", "remove"],
      fieldsList: ["index", "name", "category"],
    };
  },
  created() {
    const data = {
      patientId: this.getPatientProfile.patient._id,
      isOutdoor: this.isOutDoor,
    };
    this.fetchLabTestsActive();
    this.fetchLabQueueById(data);
    // this.sortLabTests();
  },

  sockets: {
    labResultIsSaved: function () {
      this.fetchLabQueueById({
        patientId: this.getPatientProfile.patient._id,
        isOutdoor: this.isOutDoor,
      });
    },
    labIsPaid: function () {
      this.fetchLabQueueById({
        patientId: this.getPatientProfile.patient._id,
        isOutdoor: this.isOutDoor,
      });
    },
    newPaymentRequest: function () {
      if (this.checkRole) {
        this.fetchLabQueueById({
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        });
      } else {
        this.fetchLabQueueById({
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        });
      }
    },
  },

  methods: {
    ...mapActions([
      "fetchLabTestsActive",
      "addLabQueue",
      "sendLabTestRequestToCashier",
      "fetchLabQueueById",
      "updateLabRequests",
      "deleteLabRequests",
    ]),

    focusMyElement() {
      this.$refs.focusThis.focus();
    },
    cancelRequest(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want delete this request? It can't be undone.",
          {
            size: "sm",
            title: "Confirmation",
            buttonSize: "sm",
            okTitle: "Confirm",
            cancelTitle: "Exit",
            okVariant: "danger",
            noCloseOnBackdrop: true,
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteLabRequests(id).then(() => {
              this.$bvToast.toast(this.getErrors, {
                title: this.getErrors,
                variant: "danger",
                solid: true,
              });
            });
            const data = {
              patientId: this.getPatientProfile.patient._id,
              isOutdoor: this.isOutDoor,
            };

            this.fetchLabQueueById(data);
          }
        });
    },
    selectOne() {
      this.selected = _.uniqBy(this.selected, "name");
    },
    select(name, status, index) {
      if (!status) {
        this.selected.push(
          this.selected.filter((test) => test.category == name.category)
        );
        this.getLabTests[index].select = true;
        name.status = true;
        // this.status = true;

        this.selected = _.uniqBy(this.selected, "name");
      } else {
        this.selected = this.selected.filter(
          (test) => test.category !== name.category
        );
        this.getLabTests[index].select = false;
        this.selected = _.uniqBy(this.selected, "name");
      }
      this.selected = this.selected.filter((test) => test.name !== undefined);
    },
    remove() {
      this.selected = this.selected.filter((test) => test.category !== name);
    },
    add() {},
    showList(index) {
      this.requestIndex = index;
      this.showO != this.showO;
    },
    disableCategory() {
      this.checkedCategory = true;
    },
    haf(y) {
      // this.$swal(y);
      this.selected = this.selected.filter((test) => test._id !== y);
    },
    // for cancel all request tests
    cancel() {
      this.createMode = true;
      this.selected = [];
      this.isSendLab = false;
    },

    sendRequest(index) {
      // this.selected.push({ checked: true });
      this.requestIndex = index;
      this.fetchLabQueueById({
        patientId: this.getPatientProfile.patient._id,
        isOutdoor: this.isOutDoor,
      }).then(() => {
        this.requestId = this.getLabQueue[index]._id;
        this.createMode = false;
        this.currentIndex = index;
        this.selected = this.getLabQueue[index].tests;
      });
    },

    sendTests() {
      const data = {
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        birthDate:this.getPatientProfile.patient.birthDate,
        sex: this.getPatientProfile.patient.sex,
        cardNo: this.getPatientProfile.patient.cardNo,
        total: this.total,
        tests: this.selected,
        isOutdoor: this.isOutDoor,
      };
      this.addLabQueue(data).then(() => {
        const data = {
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        };
        // this.fetchLabTestsActive();
        this.fetchLabQueueById(data);
        this.$bvToast.toast("Note Added", {
          title: "Labratory Tests Sent To Labratory.",
          variant: "sucesss",
          solid: true,
        });
        this.isSendLab = true;
        this.$emit("dataInserted");
      });
    },
    sendTestsCashier() {
      const data = {
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        birthDate:this.getPatientProfile.patient.birthDate,
        sex:this.getPatientProfile.patient.sex,
        total: this.total,
        tests: this.selected,
        isOutdoor: this.isOutDoor,
      };
      this.addLabQueue(data).then(() => {
        const data = {
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        };
        // this.fetchLabTestsActive();
        this.fetchLabQueueById(data);
        this.$bvToast.toast("Note Added", {
          title: "Labratory Tests Sent To Labratory.",
          variant: "sucesss",
          solid: true,
        });
        this.isSendLab = true;
        this.$emit("dataInserted");
      });
    },
    updateTests() {
      const data = {
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        sex: this.getPatientProfile.patient.sex,
        cardNo: this.getPatientProfile.patient.cardNo,
        requestId: this.requestId,
        total: this.total,
        tests: this.selected,
        isOutdoor: this.isOutDoor,
      };
      this.updateLabRequests(data).then(() => {
        const data = {
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        };
        // this.fetchLabTestsActive();
        this.fetchLabQueueById(data);
        this.$bvToast.toast("Note Added", {
          title: "Laboratory request updated sucessfully.",
          variant: "success",
          solid: true,
        });
        this.isSendLab = true;
        this.$emit("dataInserted");
      });
    },
  },

  computed: {
    ...mapGetters([
      "getLabTests",
      "getPatientProfile",
      "getLabQueue",
      "getErrors",
      "getOutDoorPatients",
    ]),

    checkSelection() {
      return this.selected.length <= 0 ? true : false;
    },

    total() {
      return this.selected.reduce(
        (index, item) => index + Number(item.price),
        0
      );
    },
    // checking for labratory existeness
    checkPatient() {
      return this.getLabQueue.length <= 0 ? true : false;
    },
    checkRole() {
      return localStorage.getItem("Role") === "Cashier" ? true : false;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

.noPadding {
  padding-left: 4px;
  margin: 0px;
}

.result {
  font-weight: bold;
}
</style>
