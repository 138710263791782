<template>
  <div>
    <h5 class="mb-2 text-center">Daily Revenues Report</h5>
    <b-row class="my-2">
      <JDI-Dashboard
        :fun="showPatients"
        icon="fa fa-users"
        title="All Patients"
        bg="bg-info"
        :number="getAllPatients"
      ></JDI-Dashboard>
      <JDI-Dashboard
        :fun="changeOutDoor"
        bg="bg-success"
        icon="fa fa-bookmark"
        title="OP Today's Revenue"
        :number="getOutdoorRevenueToday.totalIncome + ' ' + 'Birr'"
      ></JDI-Dashboard>
      <JDI-Dashboard
        bg="bg-primary"
        :fun="changeNormal"
        icon="fa fa-home"
        title="IP Today's Revenue"
        :number="
          getTodayRevenue.totalIncome -
          getOutdoorRevenueToday.totalIncome +
          ' ' +
          'Birr'
        "
      ></JDI-Dashboard>
      <JDI-Dashboard
        bg="bg-secondary"
        :fun="change"
        icon="fa fa-calendar"
        title="Total Revenue"
        :number="
          getTodayRevenue.totalIncome +
          getTodayCreditPaid.totalPaid +
          ' ' +
          'Birr'
        "
      ></JDI-Dashboard>
      <JDI-Dashboard
        bg="bg-light"
        icon="fa fa-money-check"
        title="Total Today Credit "
        :number="getTodayRevenue.totalDiscount + ' ' + 'Birr'"
      ></JDI-Dashboard>
      <JDI-Dashboard
        bg="bg-secondary"
        icon="fa fa-file-invoice-dollar"
        title="Total Unpaid Credit"
        :number="getDiscountList.totalUnpaid ? getDiscountList.totalUnpaid + ' ' + 'Birr' : 0 +' Birr'"
      ></JDI-Dashboard>
      <JDI-Dashboard
        bg="bg-info"
        icon="fa fa-wallet"
        title="Today credit paid"
        :number="getTodayCreditPaid.totalPaid + ' ' + 'Birr'"
      ></JDI-Dashboard>
    </b-row>
    <hr class="my-1" />
    <b-row>
      <JDI-Daily :data="i" v-for="i in getTestsReport" :key="i"></JDI-Daily>
    </b-row>
    <hr class="my-2" />
    <b-row>
      <b-col sm="5" class="col-sm-12 col-lg-5 col-md-5">
        <JDI-Todolist />
      </b-col>
      <b-col sm="7" class="col-sm-12 col-lg-7 col-md-7">
        <h5 class="mb-2 text-center">Credit Lists</h5>

        <discount />
      </b-col>
    </b-row>
    <h5 class="mb-2 text-center">Today credit paid lists</h5>

    <todayCredit />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import discount from "../admin/components/discountLists";
import todayCredit from "../admin/components/creditTodayPaid";
import _ from "lodash";
export default {
  components: {
    discount,
    todayCredit,
  },
  data() {
    return {
      today: null,
      searchEngine: [],
      datesa: "",
    };
  },
  created() {
    const data = {
      month: window.moment().format("MM"),
      day: window.moment().format("DD"),
    };
    this.fetchAllPatients();
    this.fetchTodaysPayments();
    this.fetchTestsReport(data);
    this.fetchTodaysoutdoor().then(() => {
      console.log("outdoor fetched");
    });
    this.dateMomonet();
    this.normalPatient();
    // this.testSearch();
  },
  methods: {
    ...mapActions([
      "fetchAllPatients",
      "fetchTestsReport",
      "fetchAllRadiology",
      "fetchAllLabratory",
      "fetchTodaysPayments",
      "fetchTodaysoutdoor",
      "fetchOutdoorPatientPayments",
      "fetchNormalPatientPayments",
    ]),
    changeOutDoor() {
      this.fetchOutdoorPatientPayments().then(() => {
        this.$router.push("/paymentDetail");
      });
    },
    dateMomonet() {
      this.datesa = window.moment().format("MM/YYYY");
    },
    changeNormal() {
      this.fetchNormalPatientPayments().then(() => {
        this.$router.push("/paymentDetail");
      });
    },
    testSearch() {
      for (let i = 0; i < this.getTodayRevenue.todaysPayments.length; i++) {
        console.log("joined inside i");
        console.log("j" + this.getTodayRevenue.todaysPayments);

        for (
          let j = 0;
          j < this.getTodayRevenue.todaysPayments[i].paymentList.length;
          j++
        ) {
          // console.log("j" + this.getTodayRevenue.todaysPayments[i].paymentList);
          for (
            let k = 0;
            k <
            this.getTodayRevenue.todaysPayments[i].paymentList[j].payments
              .length;
            k++
          ) {
            console.log(
              "k" +
                this.getTodayRevenue.todaysPayments[i].paymentList[j].payments[
                  k
                ].name
            );
            if (
              this.getTodayRevenue.todaysPayments[i].paymentList[j].payments[k]
                .name == "cardPayment"
            ) {
              this.searchEngine.push({ name: "Name" });
            }
          }
        }
      }
    },
    change() {
      this.$router.push("/paymentDetail");
    },
    showPatients() {
      this.$router.push("/allPatientsAdmin");
    },
    normalPatient() {
      let normal = this.getTodayRevenue.todaysPayments;
      this.today = _.uniqBy(normal, "patientId");
    },
  },
  computed: {
    ...mapGetters([
      "getAllPatients",
      "getTodayRevenue",
      "getOutdoorRevenueToday",
      "getTestsReport",
      "getDiscountList",
      "getTodayCreditPaid",
    ]),
    searchTests() {
      // let name=""
      let count = 0;
      for (let i = 0; i < this.getTodayRevenue.length; i++) {
        for (let j = 0; j < this.getTodayRevenue[i].paymentList.length; j++) {
          for (
            let k = 0;
            k < this.getTodayRevenue[i].paymentList[j].payments.length;
            k++
          ) {
            if (
              this.getTodayRevenue[i].paymentList[j].payments[k].name ==
              "cardPayment"
            ) {
              count += 1;
              console.log(
                "l :" + this.getTodayRevenue[i].paymentList[j].payments[k].name
              );
            }
          }
        }
      }
      return count;
    },
  },
};
</script>

<style scoped></style>
