import axios from "axios";
// import router from "../router/indexRouter";
const state = {
  users: [],
  user: [],
};

const mutations = {
  removetoken() {
    localStorage.removeItem("firstName"),
      localStorage.removeItem("middleName"),
      localStorage.removeItem("lastName"),
      localStorage.removeItem("Role"),
      localStorage.removeItem("accessToken"),
      localStorage.removeItem("patientId");
  },
  setUsers: (state, users) => (state.users = users),
  setUser: (state, user) => (state.user = user),
  pushUser: (state, userInstance) => state.users.unshift(userInstance),
  deleteUser: (state, id) =>
    (state.users = state.users.filter((user) => user.id !== id)),
  updateUser(state, payload) {
    const object = state.users.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
};

const getters = {
  getUsers: (state) => {
    return state.users;
  },
  getUser: (state) => {
    return state.user;
  },
};

const actions = {
  async fetchUsers({ commit }) {
    await axios
      .get("/users")
      .then((response) => {
        commit("setUsers", response.data.users);
        // if (response.status == 200) {
        //   console.log("inside loop");
        //   this.removeAccessToken();
        //   router.replace("/login");
        //   location.reload();
        // }
        return Promise.resolve();
      })
      .catch((error) => {
        if (!error.response) {
          commit("setErrors", "Network error,check your server connection");
        } else if (error.response.status == 401) {
          this.removeAccessToken();
        }
      });
  },

  async updateUserStatus({ commit }, Id) {
    await axios
      .patch("/user/toggleStatus" + `/${Id}`)
      .then((response) => {
        commit("updateUser", response.data);

        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
      });
  },

  async singleUser({ commit }, id) {
    await axios.get("/user" + `/${id}`).then((response) => {
      commit("setUser", response.data);
      return Promise.resolve();
    });
  },

  async addUser({ commit }, object) {
    await axios
      .post("/user", object)
      .then((response) => {
        commit("pushUser", response.data);
        commit("setErrors", response.data.message);

        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        if (error.response.status == 200) {
          this.$router.push("/login");
        }
        return Promise.reject();
      });
  },

  async updateUserData({ commit }, object) {
    await axios
      .patch("/user" + `/${object.id}`, object)
      .then((response) => {
        commit("updateUser", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async deleteUser({ commit }, id) {
    await axios
      .delete("/user" + `/${id}`)
      .then((response) => {
        commit("deleteUser", id);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
