<template>
    <div class="col-md-4 col-lg-3 col-sm-6 col-12 my-2 cursor" @click="fun()">
      <div class="info-box">
        <span class="info-box-icon" :class="bg"><i :class="icon"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">{{ title }}</span>
          <span class="info-box-number">{{ number }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
</template>

<script>
export default {
  props: ["icon", "title", "number","fun","bg"],
};
</script>

<style scoped>
.footer {
  border-top: 1px solid rgb(222, 226, 226);
  display: inline-block;
}
.cursor{
  cursor: pointer;
}

</style>
