import axios from "axios";

const state = {
  allPatients: "",
  allRadio: "",
  allLabratory: "",
  allUsers: "",
  allOutDoorPatients: "",
  allAppointments: "",
  TodayRevenue: "",
  TodayLog: "",
  Todayoutdoor: "",
  TodaysPatient: "",
  discountList: [],
  discountAdmin: [],
  testsReport: [],
  todayCreditPaid: [],
};

const mutations = {
  setAllPatient: (state, allPatients) => (state.allPatients = allPatients),
  setTestsReport: (state, testsReport) => (state.testsReport = testsReport),
  setTodayCreditPayment: (state, todayCreditPaid) => (
    state.todayCreditPaid= todayCreditPaid
  ),
  setDiscountList: (state, discountList) => (state.discountList = discountList),
  setDiscountAdmin: (state, discountAdmin) =>
    (state.discountAdmin = discountAdmin),
  setAllRadio: (state, allRadio) => (state.allRadio = allRadio),
  setAllLabratory: (state, allLabratory) => (state.allLabratory = allLabratory),
  setAllUsers: (state, allUsers) => (state.allUsers = allUsers),

  setAllOutDoorPatient: (state, allOutDoorPatients) =>
    (state.allOutDoorPatients = allOutDoorPatients),
  setAllLAppointment: (state, allAppointments) =>
    (state.allAppointments = allAppointments),
  setTodayIncome: (state, TodayRevenue) => (state.TodayRevenue = TodayRevenue),
  setTodayOutdoor: (state, Todayoutdoor) => (state.Todayoutdoor = Todayoutdoor),
  setTodayLog: (state, TodayLog) => (state.TodayLog = TodayLog),
  setTodaysPatient: (state, TodaysPatient) =>
    (state.TodaysPatient = TodaysPatient),
};

const getters = {
  getAllPatients: (state) => {
    return state.allPatients;
  },
  getTestsReport: (state) => {
    return state.testsReport;
  },
  getOutdoorRevenueToday: (state) => {
    return state.Todayoutdoor;
  },
  getTodayCreditPaid: (state) => {
    return state.todayCreditPaid;
  },
  getAllRadios: (state) => {
    return state.allRadio;
  },
  getAllLabratory: (state) => {
    return state.allLabratory;
  },
  getDiscountList: (state) => {
    return state.discountList;
  },
  getDiscountAdmin: (state) => {
    return state.discountAdmin;
  },
  getAllUsers: (state) => {
    return state.allUsers;
  },
  getAllOutdoorPatients: (state) => {
    return state.allOutDoorPatients;
  },
  getTodayRevenue: (state) => {
    return state.TodayRevenue;
  },
  getTodayPatient: (state) => {
    return state.TodaysPatient;
  },
  getTodayLog: (state) => {
    return state.getTodayLog;
  },
};

const actions = {
  async fetchAllPatients({ commit }) {
    await axios
      .get("/noOfPatients")
      .then((response) => {
        commit("setAllPatient", response.data.noOfPatients);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchTestsReport({ commit }, data) {
    await axios
      .get("/daily/" + `${data.month}` + `/${data.day}`)
      .then((response) => {
        commit("setTestsReport", response.data.list);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchTodaysPayments({ commit }) {
    await axios
      .get("/todaysPayments")
      .then((response) => {
        commit("setTodayIncome", response.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchNormalPatientPayments({ commit }) {
    await axios
      .get("/normalPayments")
      .then((response) => {
        commit("setTodayIncome", response.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchOutdoorPatientPayments({ commit }) {
    await axios
      .get("/outdoorPayments")
      .then((response) => {
        commit("setTodayIncome", response.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchTodaysoutdoor({ commit }) {
    await axios
      .get("/todaysPayments/outdoor")
      .then((response) => {
        commit("setTodayOutdoor", response.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async requestListByDate({ commit }, date) {
    await axios
      .get("/paymentsListByDate/" + `${date}`)
      .then((response) => {
        commit("setTodayIncome", response.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchAllLabratory({ commit }) {
    await axios
      .get("/labTests")
      .then((response) => {
        commit("setAllLabratory", response.data.users);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async fetchAllRadiology({ commit }, id) {
    await axios
      .get("/radiologyTest" + `/${id}`)
      .then((res) => {
        commit("setAllRadio", res.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async confirmDiscount({ commit }, id) {
    await axios
      .patch("/confirmDiscount" + `/${id}`)
      .then((res) => {
        commit("setErrors", res.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async rejectDiscount({ commit }, id) {
    await axios
      .patch("/rejectDiscount" + `/${id}`)
      .then((res) => {
        commit("setErrors", res.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async fetchTodayRevenue({ commit }, id) {
    await axios.get("/radiologyTest" + `/${id}`).then((res) => {
      commit("setTodayRevenue", res.data);
      return Promise.resolve();
    });
  },
  async fetchTodayPatients({ commit }, id) {
    await axios.get("/radiologyTest" + `/${id}`).then((res) => {
      commit("setTodaysPatient", res.data);
      return Promise.resolve();
    });
  },
  async discountPay({ commit }, data) {
    await axios.patch("/payDiscount" + `/${data.paymentId}`, data).then(() => {
      commit("setStatusMessage");
      return Promise.resolve();
    });
  },
  async discountRequestList({ commit }) {
    await axios.get("/discountList").then((res) => {
      commit("setDiscountList", res.data);
      return Promise.resolve();
    });
  },
  async discountAdminAc({ commit }) {
    await axios.get("/discountListAdmin").then((res) => {
      commit("setDiscountAdmin", res.data.patientPaymentList);
      return Promise.resolve();
    });
  },

  async todayCreditPaidList({ commit }) {
    await axios.get("/creditTodayPayment").then((res) => {
      commit("setTodayCreditPayment", res.data);
      return Promise.resolve();
    });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
