import Dashboard from "../view/admin/dashboard.vue";
import cardSetting from "../view/admin/cardSetting.vue";
import LabCategory from "../view/admin/category.vue";
import paymentDetail from "../view/admin/payment.vue";
import createTreatment from "../view/admin/createTreatment.vue";
import CreateLab from "../view/admin/createLabTest.vue";
import discountReport from "../view/admin/discountReport.vue";
import radioCategory from "../view/admin/radiologyCategorie.vue";
import createRadiology from "../view/admin/radiology.vue";
import account from "../view/admin/staffAccount.vue";
import treatmentCategory from "../view/admin/treatmentCategory.vue";
import medicationCategory from "../view/admin/medicationCategory.vue";
import createMedication from "../view/admin/createMedication.vue";
import allPatients from "../view/admin/patientRegister.vue"

export default [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/Dashboard",
        name: "dashboardAdmin",
        meta: {
          title: "Admin Dashboard",
          // scope: "Auth",
        },
        component: Dashboard,
      },
      {
        path: "LabCategory",
        name: "labratoryCategory",
        meta: {
          title: "Create labratory category",
          // scope: "Auth",
        },
        component: LabCategory,
      },
      {
        path: "createLab",
        name: "createLabTest",
        meta: {
          title: "Add Laboratory Test",
          // scope: "Auth",
        },
        component: CreateLab,
      },
      {
        path: "allPatientsAdmin",
        name: "allPatientsAdmin",
        meta: {
          title: "All Patients List",
          // scope: "Auth",
        },
        component: allPatients,
      },
      {
        path: "cardSetting",
        name: "cardSetting",
        meta: {
          title: "System config",
          // scope: "Auth",
        },
        component: cardSetting,
      },
      {
        path: "paymentDetail",
        name: "paymentDetail",
        meta: {
          title: "Payment details",
          // scope: "Auth",
        },
        component: paymentDetail,
      },
      {
        path: "createMedication",
        name: "createMedication",
        meta: {
          title: "Add Medications",
          // scope: "Auth",
        },
        component: createMedication,
      },
      {
        path: "createTreatment",
        name: "createTreatment",
        meta: {
          title: "Add Treatments",
          // scope: "Auth",
        },
        component: createTreatment,
      },

      {
        path: "treatmentCategory",
        name: "treatmentCategory",
        meta: {
          title: "Add Treatment Category",
          // scope: "Auth",
        },
        component: treatmentCategory,
      },
      {
        path: "medicationCategory",
        name: "medicationCategory",
        meta: {
          title: "Add Medication Category",
          // scope: "Auth",
        },
        component: medicationCategory,
      },
      {
        path: "reportMessage",
        name: "reportMessage",
        meta: {
          title: "Report Message",
          // scope: "Auth",
        },
        component: discountReport,
      },
      {
        path: "radioCategory",
        name: "radioCategory",
        meta: {
          title: "Add Radiology Category",
          // scope: "Auth",
        },
        component: radioCategory,
      },
      {
        path: "radioCreate",
        name: "radioCreate",
        meta: {
          title: "Add Radiology Tests",
          // scope: "Auth",
        },
        component: createRadiology,
      },
      {
        path: "account",
        name: "account",
        meta: {
          title: "User Account",
          // scope: "Auth",
        },
        component: account,
      },
    ],
  },
];
