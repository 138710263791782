<template>
  <div class="login-page">
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
    <div class="login-box">
      <div class="login-logo">
        <!-- <a>Just<b>CARE</b></a> -->
        <img
          src="../../assets/images/no1.gif"
          style="width: 150px"
          alt="Client Logo"
          srcset=""
        />
        <span style="font-family: Agency FB"
          ><span style="color: blue; font-size: 40px">Just</span
          ><span style="color: green"><b>Care</b></span></span
        >
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body">
          <h4 class="text-center">Welcome back.</h4>
          <p class="login-box-msg">Sign in to start your session</p>

          <form>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                v-model="record.userName"
                placeholder="Username"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                type="password"
                class="form-control"
                v-model="record.password"
                placeholder="Password"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary">
                  <input
                    type="checkbox"
                    v-model="record.rememberMe"
                    id="remember"
                  />
                  <label for="remember" class="mx-1"> Remember Me </label>
                </div>
              </div>
              <!-- /.col -->
              <div class="col-4">
                <b-button
                  type="submit"
                  size="sm"
                  variant="primary"
                  @click.prevent="loginBtn"
                  class="btn btn-primary btn-block"
                >
                <i class="fa fa-sign-in-alt"></i>
                  Sign In
                </b-button>
              </div>
              <!-- /.col -->
            </div>
          </form>

          <div class="social-auth-links text-center mb-3">
            <p>- OR -</p>
            <a href="#" class="btn btn-block btn-primary">
              <i class="fa">JS</i> Sign in using JustCare Account
            </a>
          </div>
          <!-- /.social-auth-links -->

          <p class="mb-1">
            <b-link to="/forgotPassword">I forgot my password</b-link>
          </p>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "../../common/footer";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Footer },
  data: () => ({
    checkPassword: "initial",
    checkEmail: "initial",
    record: {
      userName: null,
      password: null,
      remember_me: false,
      accessToken: null,
    },
  }),
  created() {
    // window.print();
    this.fetchUsers();
    this.token = localStorage.getItem("accessToken");
    this.department = localStorage.getItem("Dep");
    if (localStorage.getItem("accessToken")) {
      // this.$router.push("/");
    }
  },
  methods: {
    ...mapActions(["login", "fetchUsers"]),
    loginBtn() {
      const data = {
        username: this.record.userName,
        password: this.record.password,
      };

      this.login(data);
      this.$bvToast.show("toaster");
      // this.$swal({
      //   toast: true,
      //   // position: "top-end",
      //   text:this.getErrors,
      //   title:'Oops...' ,
      //   icon: "error",
      //   timer: 3000,
      //   // footer:'this is footer',
      //   showConfirmButton: false,
      //   // timerProgressBar: true,
      //   background: "gray",
      //   // color: "white",
      // });
    },
  },
  computed: {
    ...mapGetters(["getErrors", "getUsers"]),
    validEmail() {
      return this.getUsers.find((users) => {
        users.username == this.userName ? false : true;
      });
    },
    // validPassword() {},
  },
};
</script>

<style scoped>
.container {
  margin-top: 10px;
  width: 500px;
}
.textField {
  border: 1px solid darkgray;
  width: 80%;
}
.control {
  width: 80%;
}
.containerTitle {
  margin: auto;
  width: 50%;
  padding: auto;
}

.amen {
  color: white;
  font-size: 30px;
  text-align: center;
}
.navbar-expand {
  width: 100%;
  margin: auto;
}
.navbar {
  text-align: center;
}
</style>
