<template>
  <div class="text-center main-footer">
    <!-- <hr style="height: 1px; border: none; background-color: #f1f1f1" /> -->
    <span>
      <span style="font-family: Agency FB"
        ><span style="color: blue; font-size: 20px">Just</span
        ><span span style="color: green"><b>Care</b></span></span
      >
      &copy; {{ new Date().getFullYear() }} Powered by
      <b style="color: blue">JDI.</b></span
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.main-footer {
  position: fixed;
  bottom: 0;
  margin-bottom: 10px !important;
  width: 100%;
  
}
</style>
