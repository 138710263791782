<template>
  <div>
    <b-card no-body style="mrgin-top: 10px">
      <p
        style="padding-left: 30px; padding-top: 3px"
        v-if="!validatePatientData"
      >
        There is no laboratory test for this patient yet.
      </p>
      <b-card-header
        class="Jdi-header"
        :small="small"
        v-if="validatePatientData"
      >
        <b-row class="Jdi-row">
          <b-col lg="4" class="my-0">
            <p class="my-0">
              Name :
              <strong class="result"
                >{{ getLabQueue[0].firstName }}
                {{ getLabQueue[0].middleName }}
                {{ getLabQueue[0].lastName }}
              </strong>
            </p>
            <!-- {{ validatePatientData }} -->
          </b-col>

          <b-col lg="2" v-if="!getLabQueue[0].isOutdoor">
            <p class="my-0">
              Sex : <strong class="result">{{ getLabQueue[0].sex }}</strong>
            </p>
          </b-col>
          <b-col lg="2" v-if="!getLabQueue[0].isOutdoor">
            <p class="my-0">
              Age :
              <strong class="result"
                >{{ calcAge(getLabQueue[0].birthDate) }} old
              </strong>
            </p>
          </b-col>
          <b-col lg="3" v-if="!getLabQueue[0].isOutdoor">
            <p class="my-0">
              MRN : <strong class="result">{{ getLabQueue[0].cardNo }}</strong>
            </p>
            <!-- {{ getLabQueue }} -->
          </b-col>
        </b-row>
      </b-card-header>
      <b-container fluid v-if="validatePatientData">
        <b-row>
          <b-col lg="7" class="float-right">
            <b-link
              to="/patientsLab"
              style="margin-right: 5px"
              class="float-right"
              >Print
            </b-link>
          </b-col>
        </b-row>

        <span v-for="(haf, index) in totalModified" :key="haf.id">
          <div v-if="getLabQueue[index].isPaidFor">
            Request Time
            {{ getLabQueue[index].time }}
            <b-card-group deck>
              <b-row style="width: 100%">
                <b-col lg="6" v-for="lab in haf.unique" :key="lab.id">
                  <b-card
                    bg-variant="default"
                    text-variant="black"
                    :header="lab.category"
                    class="card my-1"
                  >
                    <b-row v-for="tests in lab.tests" :key="tests.id">
                      <b-col sm="4">
                        <label for="" class="float-left my-2">{{
                          tests.name
                        }}</label></b-col
                      >
                      <b-col sm="8">
                        <input
                          v-if="tests.typeOfResult == 'text'"
                          type="text"
                          v-model="tests.result"
                          class="form-control my-1"
                          :id="tests.id"
                          size="sm"
                          value="tests.name"
                          aria-describedby="helpId"
                          :placeholder="tests.result"
                        />
                        <textarea
                          v-model="tests.result"
                          v-if="tests.typeOfResult == 'textarea'"
                        ></textarea>
                        <!-- <ol
                          style="white-space: pre-line"
                          v-if="tests.typeOfResult == 'textarea'"
                        >
                          {{
                            tests.result
                          }}
                        </ol> -->

                        <input
                          type="number"
                          v-if="tests.typeOfResult == 'number'"
                          v-model="tests.result"
                          class="form-control my-1"
                          :id="tests.id"
                          size="sm"
                          value="tests.name"
                          aria-describedby="helpId"
                          :placeholder="tests.result"
                        />
                        <ul
                          v-if="
                            tests.typeOfResult == 'number' &&
                            tests.minRangeFemale
                          "
                        >
                          <span v-if="getLabQueue[0].sex == 'Male'">
                            Range {{ tests.minRangeMale }} -
                            {{ tests.maxRangeMale }}
                            <span v-if="!tests.power == 0"
                              >x 10<sup>{{ tests.power }}</sup></span
                            >
                            {{ tests.unit }}
                          </span>
                          <span v-if="getLabQueue[0].sex == 'Female'">
                            Range
                            <strong
                              >{{ tests.minRangeFemale }} -
                              {{ tests.maxRangeFemale }}
                              <span v-if="!tests.power == 0"
                                >x 10<sup>{{ tests.power }}</sup></span
                              ></strong
                            >
                            {{ tests.unit }}
                          </span>
                        </ul>

                        <b-form-group v-if="tests.typeOfResult == 'radio'">
                          <b-row>
                            <b-col lg="6" style="margin-top: 7px">
                              <b-form-radio
                                v-model="tests.result"
                                value="Positive"
                                class="mx-2"
                                >Positive</b-form-radio
                              >
                            </b-col>
                            <b-col lg="6" style="margin-top: 7px">
                              <b-form-radio
                                class="mx-2"
                                v-model="tests.result"
                                value="Negative"
                                >Negative</b-form-radio
                              >
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col lg="12">
                  <input
                    :disabled="isSendLab"
                    v-if="!getLabQueue[index].isResultSaved"
                    class="btn btn-primary my-1 mx-2 float-right"
                    @click="sendLabResult(index)"
                    type="button"
                    value="Send"
                  />
                  <input
                    v-if="getLabQueue[index].isResultSaved"
                    class="btn btn-primary my-1 float-right"
                    @click="updateLabResult(index)"
                    type="button"
                    value="Update"
                  />
                </b-col>
              </b-row>
            </b-card-group>
            <hr
              style="border: 2px solid lightgray; background-color: lightgray"
            />
          </div>
        </span>
        <hr />
      </b-container>
    </b-card>

    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { createPdfFromHtml } from "../../print/logic";
// import labresult from "../labratory/print/printResult";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
export default {
  // components: { labresult },
  data() {
    return {
      showPreview: false,
      labra: [],
      small: true,
      Preview: true,
      previewBtn: false,
      test: [],
      editMode: false,
      createMode: true,
      selectedRadio: "",
      isSendLab: false,
      modified: [],
      uniqueModified: [],
      labRequests: [],
      totalModified: [],
    };
  },

  created() {
    this.newSturctured();
    for (let i = 0; i < this.getLabQueue.length; i++) {
      this.labra.push(this.getLabQueue[i].tests);
    }
  },
  sockets: {
    // labResultIsSaved: function () {
    //   this.fetchLabQueueById(this.getLabQueue[0].patientId);
    // },
  },
  methods: {
    ...mapActions([
      "fetchLabQueueById",
      "fetchLabTestSingle",
      "addLabResult",
      "updateLabResults",
    ]),
    showPreviewS() {
      this.showPreview = !this.showPreview;
      this.Preview = !this.Preview;
      this.previewBtn = false;
    },
    printMH() {
      if (this.showPreview) {
        createPdfFromHtml(this.$refs.print.$el);
      } else {
        this.$bvToast.toast("Note Added", {
          title: "Please First Preview The Medical History.",
          variant: "sucesss",
          solid: true,
        });
      }
    },

    newSturctured() {
      // for every request

      for (let i = 0; i < this.getLabQueue.length; i++) {
        // create modified, unique

        this.modified = [];
        for (let j = 0; j < this.getLabQueue[i].tests.length; j++) {
          // console.log("B: " + i + " : " + this.getLabQueue[i].category);

          this.modified.push({
            category: this.getLabQueue[i].tests[j].category,
            tests: [],
          });
        }
        this.uniqueModified = _.uniqBy(this.modified, "category");
        // push to tests in uniqueModified
        for (let k = 0; k < this.getLabQueue[i].tests.length; k++) {
          // console.log("D: " + k);
          let categoryName = this.getLabQueue[i].tests[k].category;
          for (let l = 0; l < this.uniqueModified.length; l++) {
            // console.log("E: " + l);
            if (this.uniqueModified[l].category == categoryName) {
              this.uniqueModified[l].tests.push(this.getLabQueue[i].tests[k]);
            }
          }
        }

        // add to total modified
        this.totalModified.push({ unique: this.uniqueModified });
      }
    },

    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },

    sendLabResult(index) {
      const labResult = {
        patientId: this.getLabQueue[0].patientId,
        tests: this.labra[index],
        labTechnician:
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("middleName"),
        requestId: this.getLabQueue[index]._id,
        isOutdoor: this.getLabQueue[0].isOutdoor,
      };
      this.addLabResult(labResult);
      this.isSendLab = true;
      localStorage.removeItem("patientId");
      this.$bvToast.show("toaster");
    },
    updateLabResult(index) {
      const labResult = {
        patientId: this.getLabQueue[0].patientId,
        tests: this.labra[index],
        isOutdoor: this.getLabQueue[0].isOutdoor,
        labTechnician:
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("middleName"),
        id: this.getLabQueue[index]._id,
      };
      this.updateLabResults(labResult);
      localStorage.removeItem("patientId");
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getLabQueue", "getLabTests", "getErrors","getAge"]),
    validatePatientData() {
      return this.getLabQueue.length > 0 ? true : false;
    },
  },
};
</script>

<style scoped>
input {
  height: 30px;
}
li {
  list-style-type: none;
}
.result {
  font-family: monospace;
  color: black;
  font-size: 15px;
  padding-left: 8px;
}
</style>
