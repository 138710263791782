<template>
  <div>
    <b-card no-body v-if="displayCreate">
      <b-card-header class="Jdi-Card-header" style="margin: 0px">
        <b-row class="Jdi-row">
          <b-col lg="6" class="my-1 float-left Jdi-col" style="margin: 0px">
            <b-button variant="info" @click="ShowList" style="margin: 0px">
              Show List
            </b-button>
          </b-col>
          <b-col lg="6" class="my-1 float-right"> </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="Jdi-Card-body">
        <b-form novalidate>
          <b-row>
            <b-col lg="4" class="my-1">
              <label>First Name :</label
              ><span
                v-if="firstNameValidation"
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <span v-if="!firstNameValidation"
                ><b-icon
                  class="mx-2 icon"
                  icon="check-circle"
                  font-scale="1.7"
                  variant="success"
                ></b-icon
              ></span>

              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.firstName"
                type="text"
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1">
              <label>Middle Name :</label
              ><span
                v-if="middleNameValidation"
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <span v-if="!middleNameValidation"
                ><b-icon
                  class="mx-2 icon"
                  icon="check-circle"
                  font-scale="1.7"
                  variant="success"
                ></b-icon
              ></span>

              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.middleName"
                type="text"
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1">
              <label>Last Name :</label
              ><span
                v-if="lastNameValidation"
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <span v-if="!lastNameValidation"
                ><b-icon
                  class="mx-2 icon"
                  icon="check-circle"
                  font-scale="1.7"
                  variant="success"
                ></b-icon
              ></span>

              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.lastName"
                type="text"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" class="my-1">
              <label>Sex :</label
              ><span
                v-if="genderValidation"
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <span v-if="!genderValidation"
                ><b-icon
                  class="mx-2 icon"
                  icon="check-circle"
                  font-scale="1.7"
                  variant="success"
                ></b-icon
              ></span>
              <b-form-select
                id="input-3"
                class="my-1"
                :options="genderList"
                size="sm"
                v-model="form.gender"
                value-field="name"
                text-field="name"
                disabled-field="notEnabled"
              >
              </b-form-select>
            </b-col>
            <b-col lg="4" class="my-1">
              <b-row>
                <b-col lg="12">
                  <label>Birth Date :</label
                  ><span
                    v-if="birthDateValidation"
                    class="text-muted mx-1"
                    style="
                      font-size: 12px;
                      font-style: italic;
                      color: red !important;
                    "
                    >Required</span
                  >
                  <span v-if="!birthDateValidation"
                    ><b-icon
                      class="mx-2 icon"
                      icon="check-circle"
                      font-scale="1.7"
                      variant="success"
                    ></b-icon
                  ></span>
                  <birth-datepicker
                    class="col-12"
                    @blur="birthDateToAge"
                    yearFirst
                    delimiter="-"
                    v-model="form.birthDate"
                    placeholder="date birth"
                    name="date of birth"
                  />
                </b-col>
                <!-- <b-col lg="4" class="my-1">
                  <label>Age :</label
                  ><span
                    class="text-muted mx-1"
                    style="font-size: 10px; font-style: italic"
                    >Optional</span
                  >

                  <b-form-input
                    class="my-1"
                    @blur="ageToBirthDate"
                    id="input-1"
                    size="sm"
                    v-model="form.age"
                    type="number"
                  ></b-form-input>
                </b-col> -->
              </b-row>
            </b-col>

            <b-col lg="4" class="my-1">
              <label>Phone Number :</label
              ><span
                v-if="phoneValidation"
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <span v-if="!phoneValidation"
                ><b-icon
                  class="mx-2 icon"
                  icon="check-circle"
                  font-scale="1.7"
                  variant="success"
                ></b-icon
              ></span>
              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.phoneNumber"
                type="number"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" class="my-1">
              <label>Emergency Name :</label
              ><span
                class="text-muted mx-1"
                style="font-size: 12px; font-style: italic"
                >Optional</span
              >

              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.emergencyName"
                type="text"
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1">
              <label>Emergency Contact :</label
              ><span
                class="text-muted mx-1"
                style="font-size: 12px; font-style: italic"
                >Optional</span
              >

              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.emergencyPhone"
                type="text"
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1" style="padding-top: 25px">
              <b-button
                :disabled="checkValidation"
                v-if="createMode"
                class="my-1 mx-2 float-right btnPositive"
                type="submit"
                @click.prevent="Register"
              >
                Save
              </b-button>
              <b-button
                v-if="editMode"
                class="my-1 mx-2 float-right"
                type="submit"
                @click.prevent="updatePatients"
                variant="info"
              >
                Update
              </b-button>

              <b-button
                v-if="editMode"
                class="my-1 mx-2 float-right"
                type="submit"
                @click.prevent="cancel"
                variant="secondary"
              >
                Cancel
              </b-button>

              <!-- <b-button
                  class="my-1 float-right clearBtn"
                  type="reset"
                  @click="clearFields"
                  :disabled="checkValidationClear"
                  variant="default"
                >
                  Clear
                </b-button> -->
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>

    <b-card no-body v-if="displayList">
      <b-card-header variant="secondary">
        <b-row>
          <b-col lg="2" class="paddingLeft col-3">
            <b-input-group size="sm">
              <b-form-select
                v-model="per_page"
                id="sortBySelect"
                :options="pageOptions"
                class="w-20"
              >
              </b-form-select>
            </b-input-group>
          </b-col>

          <b-col lg="5" class="col-5">
            <b-form-group>
              <b-input-group size="sm" class="w-20">
                <b-form-input
                  class="float-right"
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to search..."
                ></b-form-input
                ><b-button
                  size="sm"
                  class="mx-1"
                  variant="info"
                  @click="filterPatients"
                >
                  <i class="fa fa-search"></i> Search
                </b-button>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="5" class="col-4">
            <b-button
              variant="primary"
              class="float-right"
              @click.prevent="ShowCreate"
              style="width: auto"
              size="sm"
            >
              Create New
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          class="customTable table table-head-fixed"
          fixed
          @filtered="onFiltered"
          noCollapse="true"
          :current-page="currentPage"
          :per-page="per_page"
          :small="small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          stacked="md"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getPatients.patients"
          show-empty
          :fields="fields"
          :empty-text="getUsersList ? 'Empty Record' : 'Unable To Load Data'"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 + getPatients.currentPage * 100 - 100 }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }}
            {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>
          <template v-slot:cell(Age)="data">
            {{ ageCa(data.item.birthDate) }}
          </template>

          <template v-slot:cell(Action)="data">
            <b-link @click="editPatient(data.item._id)" class="text-primary">
              <b-icon icon="pen" variant="primary"></b-icon>
            </b-link>
            .
            <b-link @click="sendPatient(data.item)" class="text-primary mx-3">
              <b-icon
                icon="cursor-fill"
                variant="primary"
                font-scale="1.5"
              ></b-icon>
            </b-link>
          </template>
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'index' ||
                  field.key === 'sex' ||
                  field.key === 'Age' ||
                  field.key === 'cardNo' ||
                  field.key === 'phoneNumber' ||
                  field.key === 'Action' ||
                  field.key === 'registeredDate'
                    ? '110px'
                    : '280px',
              }"
            />
          </template>
        </b-table>
        <li class="float-left ml-3">
          Patients display
          <b>
            {{
              getPatients.currentPage * 100 -
              99 +
              " - " +
              getPatients.currentTotal
            }}</b
          ><b v-if="!filter">{{ " of " + getPatients.total }}</b>
        </li>

        <ul class="pagination float-right" v-if="filter">
          <li
            @click="next(getPatients.previous)"
            v-if="getPatients.hasPrevious"
            size="sm"
            class="lists"
            to="?page=3"
          >
            <i class="fa fa-arrow-left"></i>
          </li>
          <li class="lists" v-if="getPatients.hasPrevious">
            {{ getPatients.previous }}
          </li>
          <li class="lists active">{{ getPatients.currentPage }}</li>
          <li class="lists" v-if="getPatients.hasNext">
            {{ getPatients.next }}
          </li>

          <li
            class="lists"
            @click="next(getPatients.next)"
            v-if="getPatients.hasNext"
          >
            <i class="fa fa-arrow-right"></i>
          </li>
          <li class="lists" @click="next(getPatients.last)">
            {{ getPatients.last }}
          </li>
        </ul>
      </b-card-body>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    maxDate.setDate(now.getDate());
    return {
      small: true,
      content: "<b>This is Haftom Gidey Araya</b>",
      getUsersList: false,
      filter: "",
      errors: {},
      value_date: true,
      curentEditId: "",
      max: maxDate,
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      difference: "",
      todayDate: "",
      patientAddQueue: [],
      sortBy: "registeredDate",
      sortDesc: true,
      isBusy: true,
      showInvalid: true,
      patientBirthDate: "",
      hideCompleted: true,
      currentpage: 1,
      per_page: "100",
      pageOptions: [5, 10, 15, 30, 50, 100, "All"],
      genderList: ["Female", "Male"],
      fields: [
        { key: "index", label: "#" },
        {
          key: "fullName",
          label: "Full Name",
        },
        { key: "sex", label: "Sex" },
        "Age",
        { key: "phoneNumber", label: "Mobile" },
        { key: "cardNo", label: "MRN" },
        // { key: "registeredDate", label: "R.Date" },
        "Action",
      ],

      curentData: [],
      age: 0,
      normalAge: null,
      lastVisitedDate: "",
      patientData: {
        patientId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        cardNO: "",
        birthDate:'',
        sex:''
      },
      form: {
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        birthDate: 0,
        phoneNumber: "",
        emergencyName: "",
        emergencyPhone: "",
      },
    };
  },
  sockets: {
    patientListChanged: function () {
      this.fetchPatients();
    },
  },
  created() {
    //this.ageCa();
    this.fetchCardSetting();
    this.fetchPatients().then(() => {
      this.getUsersList = true;
      this.isBusy = false;
      // this.getPatients.patients.reverse()
    });
  },
  methods: {
    ...mapActions([
      "addPatient",
      "fetchCardSetting",
      "addDrQueue",
      "fetchPatients",
      "updatePatient",
      "confirmCardPayment",
    ]),
    ageToBirthDate() {
      this.form.birthdate = this.ageCalculate();
    },
    filterPatients() {
      const data = {
        page: this.getPatients.currentPage,
        name: this.filter,
      };
      this.fetchPatients(data);
    },
    next(page) {
      const data = {
        page: page,
        name: this.filter,
      };
      this.fetchPatients(data);
    },
    convertDate(date) {
      return window.moment(date).format("DD-MM-YYYY");
    },

    sendPatient(data) {
      this.patientAddQueue = this.getPatients.patients.filter(
        (t) => t._id == data._id
      );

      for (let i = 0; i < this.patientAddQueue.length; i++) {
        this.patientData.patientId = this.patientAddQueue[i]._id;
        this.patientData.firstName = this.patientAddQueue[i].firstName;

        this.age = this.ageCa(this.patientAddQueue[i].birthDate).split(" ");
        this.normalAge = this.age[0];
        this.lastVisitedDate = this.patientAddQueue[i].lastVisitDate;
        this.patientData.sex = this.patientAddQueue[i].sex;
        this.patientData.birthDate = this.patientAddQueue[i].birthDate;
        this.patientData.middleName = this.patientAddQueue[i].middleName;
        this.patientData.lastName = this.patientAddQueue[i].lastName;
        this.patientData.cardNo = this.patientAddQueue[i].cardNo;
      }
      this.addDrQueue(this.patientData)
        .then(() => {
          this.$bvToast.show("toaster");
        })
        .catch(() => {
          if (this.getErrors == "Sorry patient card payment is expired") {
            this.$bvModal
              .msgBoxConfirm(
                this.normalAge > 10
                  ? this.getCardSetting.cardPrice +
                      " birr has to be paid for card"
                  : this.getCardSetting.cardPriceUnderAge +
                      " birr has to be paid for card",
                {
                  size: "sm",
                  title: "Card Payment",
                  buttonSize: "sm",
                  okTitle: "Confirm",
                  okVariant: "primary",
                  noCloseOnBackdrop: true,
                  centered: true,
                }
              )
              .then((confirm) => {
                if (confirm) {
                  this.confirmCardPayment(this.patientData).then(() => {
                    this.addDrQueue(this.patientData).then(() => {
                      this.$bvToast.toast(this.getErrors, {
                        title: this.getErrors,
                        variant: "primary",
                        solid: true,
                      });
                    });
                  });
                }
              });
          }
          this.$bvToast.show("toaster");
        });
    },
    clearFields() {
      this.form.firstName = "";
      this.curentEditId = "";
      this.form.middleName = "";
      this.form.lastName = "";
      this.form.gender = "";
      this.form.birthdate = "";
      this.form.phoneNumber = "";
      this.form.emergencyPhone = "";
      this.form.emergencyName = "";
      this.editMode = false;
      this.createMode = true;
    },
    updateStatus() {
      // this.changeStatus(id).then(() => {
      //   this.$emit("dataInserted");
      // });
      this.$bvToast.show("toaster");
    },
    updatePatients() {
      const PatientUpdate = {
        firstName: this.form.firstName,
        middleName: this.form.middleName,
        lastName: this.form.lastName,
        birthDate: this.form.birthDate,
        sex: this.form.gender,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        emergencyName: this.form.emergencyName,
        emergencyPhone: this.form.emergencyPhone,
        id: this.curentEditId,
      };
      this.updatePatient(PatientUpdate);

      this.$bvToast.toast("Updated Sucessfully!!!!!", {
        title: "User updated sucessfully.",
        variant: "sucesss",
        solid: true,
      });
      this.clearFields();
    },
    editPatient(index) {
      this.curentData = this.getPatients.patients.filter((t) => t._id == index);
      this.form.firstName = this.curentData[0].firstName;
      this.curentEditId = this.curentData[0]._id;
      this.form.middleName = this.curentData[0].middleName;
      this.form.lastName = this.curentData[0].lastName;
      this.form.gender = this.curentData[0].sex;
      this.form.birthDate = Number(
        window.moment(this.curentData[0].birthDate, "DD/MM/YYYY").format("x")
      );
      this.form.phoneNumber = this.curentData[0].phoneNumber;
      this.form.emergencyPhone = this.curentData[0].emergencyPhone;
      this.form.emergencyName = this.curentData[0].emergencyName;

      this.editMode = true;
      this.createMode = false;
      this.displayList = false;
      this.displayCreate = true;
    },
    deletePatients(user) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((deleteU) => {
          if (deleteU) {
            this.deletePatient(user);
            this.$bvToast.toast("Deleted Sucessfully!!!!!", {
              title: "Deleted",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    cancel() {
      this.createMode = true;
      this.editMode = false;
      this.clearFields();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === false) return "table-danger";
      if (item.firstName === this.getName) return "table-success";
    },
    onRowSelected(items) {
      this.selected = items;
    },
    ShowList() {
      this.displayList = true;
      this.displayCreate = false;
    },
    ShowCreate() {
      this.displayList = false;
      this.displayCreate = true;
    },
    ageCa(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    ageCalculate() {
      let haf, age;
      if (this.form.age) {
        return;
      } else {
        haf = window.moment().subtract(this.form.age, "year");
      }
      age = window.moment(haf).format("DD/MM/YYYY");
      return Number(window.moment(age, "DD/MM/YYYY").format("x"));
    },
    Register() {
      const patientData = {
        firstName: this.form.firstName,
        middleName: this.form.middleName,
        lastName: this.form.lastName,
        birthDate: this.form.birthDate,
        sex: this.form.gender,
        emergencyName: this.form.emergencyName,
        phoneNumber: this.form.phoneNumber,
        emergencyPhone: this.form.emergencyPhone,
      };

      // const drQueue = {
      //   firstName: this.form.firstName,
      //   middleName: this.form.middleName,
      //   lastName: this.form.lastName,
      //   patientId: this.getPatientId._id,
      // };

      this.addPatient(patientData);

      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters([
      "getPatientId",
      "getPatients",
      "getErrors",
      "getStatusMessage",
      "getName",
      "getCardSetting",
    ]),

    checkValidation() {
      return this.form.firstName.length <= 0 ||
        this.form.middleName.length <= 0 ||
        this.form.lastName.length <= 0 ||
        this.form.gender.length <= 0 ||
        this.form.birthDate.length <= 0 ||
        this.form.phoneNumber.length <= 0
        ? true
        : false;
    },
    firstNameValidation() {
      return this.form.firstName.length <= 0 || this.form.firstName.length <= 3
        ? true
        : false;
    },
    middleNameValidation() {
      return this.form.middleName.length <= 0 ||
        this.form.middleName.length <= 3
        ? true
        : false;
    },
    lastNameValidation() {
      return this.form.lastName.length <= 0 || this.form.lastName.length <= 3
        ? true
        : false;
    },
    genderValidation() {
      return this.form.gender.length <= 0 ? true : false;
    },
    birthDateValidation() {
      return this.form.birthDate.length <= 0 ? true : false;
    },
    phoneValidation() {
      return this.form.phoneNumber.length <= 0 ? true : false;
    },
    checkPatient() {
      return this.getPatients.length <= 0 ? true : false;
    },
    checkValidationClear() {
      return this.form.firstName != null ||
        this.form.middleName != null ||
        this.form.lastName != null ||
        this.form.gender != null ||
        this.form.birthDate != null ||
        this.form.emergencyName != null ||
        this.form.emergencyPhone != null ||
        this.form.phoneNumber != null
        ? false
        : true;
    },
    filteredTasks() {
      return this.hideCompleted
        ? this.getPatients.filter((t) => !t.done)
        : this.tasks;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.customTable {
  overflow-y: auto;
  max-height: 460px;
  margin-top: -25px !important;
}
.textfield {
  height: 30px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.paddingLeft {
  padding-left: 0px;
}
.icon {
  padding-top: 10px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
  border: none;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
.clearBtn {
  border: 1px solid #007bff;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
.card-header {
  background-color: #fff;
}
li {
  list-style-type: none;
}
.active {
  background-color: #007bff;
  color: white;
}
.lists {
  border: 1px solid #007bff;
  margin-right: 3px;
  padding: 5px;
  cursor: pointer;
}
</style>
