<template>
  <div>
    <div class="slip-box">
      <div
        class="row my-2 slip-list-item"
        v-for="(list, index) in item"
        :key="list._id"
      >
        <div class="col-sm-1 col-1">
          <div class="border-slip pl-2">
            {{ index + 1 }}
          </div>
        </div>
        <div class="col-sm-8 col-8">
          <div class="border-slip pl-2">
            {{ list.name }}
          </div>
        </div>
        <div class="col-sm-3 col-3">
          <div class="border-slip pl-2">
            {{ list.price }} Birr
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.slip-list-item {
  border-left: 3px solid #0072b5;
}

</style>