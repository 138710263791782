import axios from "axios";

const state = {
  outDoorpatients: [],
  outDoorpatient: [],
};

const mutations = {
  setOutDoorPatients: (state, outDoorpatients) =>
    (state.outDoorpatients = outDoorpatients),
  setOutDoorPatient: (state, outDoorpatient) =>
    (state.outDoorpatient = outDoorpatient),
  pushOutDoorPatient: (state, outDoorpatients) =>
    state.outDoorpatients.unshift(outDoorpatients),
};

const getters = {
  getOutDoorPatients: (state) => {
    return state.outDoorpatients;
  },
  getOutDoorPatient: (state) => {
    return state.outDoorpatient;
  },
};

const actions = {
  async fetchOutDoorPatients({ commit }) {
    await axios
      .get("/odPatients")
      .then((response) => {
        commit("setOutDoorPatients", response.data.odPatients);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async fetchOutDoorPatient({ commit }, Id) {
    await axios
      .get("/odPatient/" + `${Id}`)
      .then((response) => {
        commit("setPatient", response.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  // async fetchOutDoorPatientNormal({ commit }, object) {
  //   await axios
  //     .get("/odPatient/" + `${object.patientId}` + `/${object.isOutdoor}`)
  //     .then((response) => {
  //       commit("setPatient", response.data);
  //       return Promise.resolve();
  //     })
  //     .catch(() => {
  //       return Promise.reject();
  //     });
  // },

  async addOutDoorPatient({ commit }, object) {
    await axios
      .post("/odPatient", object)
      .then((response) => {
        commit("pushOutDoorPatient", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
