<template>
  <div class="twa-header">
    <b-card nobody>
      <b-card-header class="twa-header" style="margin: -20px">
        <b-row>
          <b-col lg="3">
            <b-form-group
              id="input-group-2"
              label="First Name:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="firstName"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group
              id="input-group-2"
              label="Middle Name:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="middleName"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group
              id="input-group-2"
              label="Last Name:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="lastName"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-button
              variant="primary"
              class="float-right my-2"
              :disabled="validateForm"
              @click="addPatient"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body>
        <div align="center">
          <b-table
            ref="selectableTable"
            selectable
            noCollapse="true"
            :current-page="curentpage"
            :per-page="per_page"
            :small="small"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :tbody-tr-class="rowClass"
            responsive="sm"
            stacked="md"
            :filterIncludedFields="filterOn"
            @filtered="onFiltered"
            :select-mode="selectMode"
            @row-selected="onRowSelected"
            :items="getOutDoorPatients"
            show-empty
            :fields="fields"
            :empty-text="getUsersList ? 'Empty Record' : 'Unable to load data'"
          >
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(fullName)="data">
              {{ data.item.firstName }}
              {{ data.item.middleName }}
              {{ data.item.lastName }}
            </template>

            <template v-slot:table-caption>
              <b-pagination
                v-if="checkQueue"
                class="float-right my-0"
                v-model="curentpage"
                :total-rows="getOutDoorPatients.length"
                :per-page="per_page"
                align="fill"
                size="sm"
                aria-controls="my-table"
              >
              </b-pagination> </template
          ></b-table>
        </div>
      </b-card-body>
      <!-- <textarea v-model="message"></textarea>
      <p style="white-space: pre-line">{{ message }}</p> -->
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      filter: null,
      message: "",
      value_date: true,
      curentEditId: "",
      editMode: false,
      showModal: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      patientAddQueue: [],
      sortBy: "fullName",
      sortDesc: false,
      patientId: [],
      hideCompleted: true,
      curent_page: 1,
      filterBy: "",
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      fields: [
        { key: "index", label: "#" },
        { key: "fullName", label: "Full Name", sortable: true },
        { key: "time", label: "Time" },
      ],
    };
  },
  created() {
    this.fetchOutDoorPatients().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "addOutDoorPatient",
      "fetchOutDoorPatients",
      "fetchOutDoorPatient",
    ]),
    onRowSelected(items) {
      this.PatientId = items;
      this.check();
      this.fetchOutDoorPatient(this.patientName).then(() =>
        this.$router.push("/outDoorResult")
      );
    },
    check() {
      for (let i = 0; i < this.PatientId.length; i++) {
        this.patientName = this.PatientId[i]._id;
      }
    },
    clear() {
      (this.firstName = ""), (this.middleName = ""), (this.lastName = "");
    },
    addPatient() {
      const data = {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
      };
      this.addOutDoorPatient(data).then(() => {
        this.fetchOutDoorPatients();
        this.clear();
      });

      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getOutDoorPatients", "getErrors", "getPatientProfile"]),
    validateForm() {
      return this.firstName == "" ||
        this.middleName == "" ||
        this.lastName == ""
        ? true
        : false;
    },
    checkQueue() {
      return this.getOutDoorPatients.length <= 0 ? false : true;
    },
  },
};
</script>

<style scoped>
input {
  height: 30px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}

.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
</style>
