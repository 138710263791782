<template>
  <div>
    <!-- {{ getDiscountList }} -->
    <b-card-header style="margin-top: 3px">
      <b-row>
        <!-- <b-col lg="4" class="col-12"
          ><b-form-select
            id="input-3"
            class="my-1"
            :options="genderList"
            size="sm"
            v-model="date"
            value-field="_id"
            text-field="name"
            disabled-field="notEnabled"
          >
            <option selected>select date</option>
          </b-form-select>
        </b-col> -->
        <b-col lg="4" class="col-12"
          ><b-form-input
            class="my-1"
            id="input-1"
            size="sm"
            v-model="filter"
            placeholder="Type to search..."
            type="text"
          ></b-form-input>
        </b-col>
        <b-col lg="4"> </b-col>
      </b-row>
    </b-card-header>
    <b-table
      :fields="fields"
      :filter="filter"
      :items="getDiscountAdmin"
      :current-page="curentpage"
      :per-page="per_page"
      :small="small"
      stacked="md"
      show-empty
      :empty-text="
        getUsersList
          ? 'There is no new  discount requests.'
          : 'Unable to load data.'
      "
    >
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template v-slot:cell(fullName)="data">
        {{ data.item.firstName }} {{ data.item.middleName }}
        {{ data.item.lastName }}
      </template>

      <template v-slot:cell(status)="data">
        <span class="tag mx-2">
          <span class="confirmed" v-if="data.item.discount == true"
            >Confirmed</span
          >
          <span class="danger" v-if="data.item.rejected == true">Rejected</span>
          <span
            class="warning"
            v-if="
              data.item.rejected == false &&
              data.item.discount == false &&
              data.item.requestDiscount == true
            "
            >Pending</span
          >
        </span>
      </template>

      <template v-slot:cell(Actions)="data">
        <b-link
          @click="accept(data.item._id)"
          class="text-primary mx-3"
          v-if="
            data.item.rejected == false &&
            data.item.discount == false &&
            data.item.requestDiscount == true
          "
        >
          <b-icon
            icon="check-circle"
            font-scale="1.7"
            variant="primary"
          ></b-icon>
        </b-link>
        <b-link
          @click="reject(data.item._id)"
          class="text-primary"
          v-if="
            data.item.rejected == false &&
            data.item.discount == false &&
            data.item.requestDiscount == true
          "
        >
          <b-icon icon="x-square" font-scale="1.7" variant="danger"></b-icon>
        </b-link>
      </template>
      <template v-slot:table-caption>
        <b-pagination
          v-if="checkDiscount"
          class="float-right my-0"
          v-model="currentpage"
          :total-rows="getDiscountAdmin.length"
          :per-page="per_page"
          align="fill"
          size="sm"
          aria-controls="my-table"
        >
        </b-pagination>
      </template>
    </b-table>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";
export default {
  data() {
    return {
      small: true,
      curentpage: 1,
      date: "select date",
      filter: "",
      per_page: 20,
      getUsersList: false,
      fields: fields.fieldsDiscountReport,
    };
  },
  sockets: {
    newDiscountRequest: function () {
      this.discountAdminAc().then(() => {
        this.getUsersList = true;
      });
    },
  },
  created() {
    this.discountAdminAc().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "discountRequestList",
      "discountAdminAc",
      "confirmDiscount",
      "rejectDiscount",
      "getErrors",
    ]),
    accept(id) {
      this.confirmDiscount(id)
        .then(() => {
          // this.$swal("Confirmed");
          this.$bvToast.show("toaster");
        })
        .catch(() => {
          // this.$swal("failed to confirm");
          this.$bvToast.show("toaster");
        });
      this.discountRequestList();
    },
    reject(id) {
      this.rejectDiscount(id);
      this.discountRequestList();
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getDiscountAdmin"]),
  },
  checkDiscount() {
    return this.getDiscountList.length > 1 ? true : false;
  },
};
</script>

<style acoped></style>
