<template>
  <div>
    <b-card style="margin: -20px">
      <b-card-body style="margin: -20px">
        <b-link @click="goToPrint" class="float-right" to="/certificatePrint"
          ><i class="fa fa-print" style="color: blue"></i
        ></b-link>
        <b-container>
          <keep-alive>
            <b-row>
              <b-col>
                <label>Diagnosis</label>
                <b-textarea
                  id="textarea"
                  v-model="certificateObject.diagnosis"
                  placeholder="Diagnosis"
                  rows="3"
                  max-rows="6"
                ></b-textarea>
              </b-col>

              <b-col lg="12">
                <label>Recommendation</label>
                <keep-alive>
                  <b-textarea
                    id="textarea"
                    v-model="certificateObject.recommendation"
                    rows="3"
                    max-rows="6"
                  ></b-textarea>
                </keep-alive>
              </b-col>
              <b-col lg="4">
                <label>Examination Date</label>
                <b-form-input
                  type="date"
                  v-model="certificateObject.examDate"
                  placeholder="Enter your name"
                ></b-form-input>
              </b-col>
            </b-row>
          </keep-alive>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      certificateObject: {
        examDate: "",
        recommendation: "",
        diagnosis: [],
      },
      showPreview: false,
    };
  },
  created() {
    if (this.certificateObject.diagnosis.length <= 0) {
      this.certificateObject.diagnosis =
        "Sorry there is no DIAGNOSIS for this patient";
    }
    this.certificateObject.recommendation =
      localStorage.getItem("reccommendtion") || "";
    for (
      let i = 0;
      i < this.getPatientProfile.patient.medicalHistory.length;
      i++
    ) {
      for (
        let j = 0;
        j < this.getPatientProfile.patient.medicalHistory[i].dx.length;
        j++
      ) {
        this.certificateObject.diagnosis = this.getPatientProfile.patient.medicalHistory[
          i
        ].dx[j].dx;
      }
    }
  },
  methods: {
    ...mapActions(["addCertificate"]),

    goToPrint() {
      this.addCertificate(this.certificateObject).then(() => {
        this.$router.push("/certificatePrint");
        localStorage.setItem(
          "reccommendtion",
          this.certificateObject.recommendation
        );
      });
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile"]),
  },
};
</script>

<style scoped></style>
