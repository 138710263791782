<template>
  <div>
    <b-card no-body style="margin: 0px">
      <b-card-header :small="small" class="noMargin bgheader">
        <b-row class="Jdi-row">
          <b-col lg="4" class="my-0 mx-1">
            <span>
              Name :
              <strong
                ><span class="result"
                  >{{ getPatientProfile.patient.firstName }}
                  {{ getPatientProfile.patient.middleName }}
                  {{ getPatientProfile.patient.lastName }}
                </span></strong
              >
            </span>

            <!-- {{ getPatientProfile }} -->
          </b-col>
          <b-col
            lg="2"
            class="my-0 mx-1"
            v-if="!getPatientProfile.patient.isOutdoor"
          >
            <p class="my-0">
              Sex :
              <strong
                ><span class="result">{{
                  getPatientProfile.patient.sex
                }}</span></strong
              >
            </p>
          </b-col>
          <b-col
            lg="3"
            class="my-0 mx-1"
            v-if="!getPatientProfile.patient.isOutdoor"
          >
            <p class="my-0">
              Age :<strong>
                <span class="result"
                  >{{ getPatientProfile.age }} old</span
                ></strong
              >
            </p>
          </b-col>
          <b-col lg="2" class="my-0 mx-1">
            <p class="my-0" v-if="!getPatientProfile.patient.isOutdoor">
              MRN :<strong>
                <span class="result">{{
                  getPatientProfile.patient.cardNo
                }}</span></strong
              >
            </p>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="noMargin">
        <div>
          <b-card no-body class="noMargin">
            <b-tabs
              v-model="tabIndex"
              card
              pills
              align="right"
              :small="small"
              active-nav-item-class="text-uppercase "
              content-class="mt-0"
              class="JDI-tabs"
            >
              <b-tab no-body title="Radiology Request" class="JDI-tab">
                <RadioResult></RadioResult>
              </b-tab>
              <b-tab
                
                no-body
                title="Patient History"
                class="JDI-tab"
                v-if="!getPatientProfile.patient.medicalHistory.length == 0 &&!getPatientProfile.patient.isOutdoor "
              >
                <p v-if="checkSelection">
                  there is no history for this patient
                </p>
                <div class="d-flex mt-4 justify-content-between">
                  <b-button
                    @click="increment"
                    size="sm"
                    variant="primary"
                    :disabled="
                      index ==
                      getPatientProfile.patient.medicalHistory.length - 1
                    "
                    class="float-left mx-1"
                  >
                    Previous
                  </b-button>

                  <b-button
                    @click="decrement"
                    variant="primary"
                    size="sm"
                    :disabled="index == 0"
                    class="float-right mx-1"
                  >
                    New
                  </b-button>
                </div>
                <b-row>
                  <b-col lg="12">
                    <p class="date">
                      {{ index1.date
                      }}
                    </p>
                    <b-card
                      no-body
                      header="Note"
                      v-if="!index1.drsNote.length == 0"
                    >
                      <span
                        v-for="drsNote in index1.drsNote"
                        :key="drsNote.id"
                        class="mx-1"
                      >
                        <li class="physical mx-3">
                          C/C :
                          <span class="result">{{ drsNote.c_c }}</span>
                        </li>
                        <b-card-text>
                          <li class="physical mx-3">
                            HPI :
                            <span class="result" v-html="drsNote.hpi"></span>
                          </li>
                        </b-card-text>
                      </span>
                    </b-card>
                    <b-card
                      header="Physical Exam"
                      no-body
                      v-if="index1.physicalExam.length != 0"
                    >
                      <span
                        v-for="pe in index1.physicalExam"
                        :key="pe.id"
                        class="mx-1"
                      >
                        <p class="mx-1">
                          Reported time
                          <span class="result">{{ pe.time }}</span>
                        </p>
                        <b-row>
                          <b-col lg="6" class="my-4 col-6">
                            <li class="physical mx-3">
                              BP : <span class="result">{{ pe.bp }}</span>
                            </li>
                            <li class="physical mx-3">
                              PR : <span class="result">{{ pe.pr }}</span>
                            </li>
                            <li class="physical mx-3">
                              RR : <span class="result">{{ pe.rr }}</span>
                            </li>
                            <li class="physical mx-3">
                              T : <span class="result">{{ pe.t }}</span>
                            </li>
                            <li class="physical mx-3">
                              Pso2 : <span class="result">{{ pe.pso2 }}</span>
                            </li>
                          </b-col>
                          <b-col lg="6" class="my-4 col-6">
                            <li class="physical mx-3">
                              HT : <span class="result">{{ pe.ht }}</span>
                            </li>
                            <li class="physical mx-3">
                              WT : <span class="result">{{ pe.wt }}</span>
                            </li>
                            <li class="physical mx-3">
                              BMI :
                              <span
                                class="result"
                                :class="{ red: changeBmiColor }"
                                >{{ pe.bmi }}</span
                              >
                            </li>
                            <li class="physical mx-3">
                              AC : <span class="result">{{ pe.ac }}</span>
                            </li>
                            <li class="physical mx-3">
                              HC : <span class="result">{{ pe.hc }}</span>
                            </li>
                            <li class="physical mx-3">
                              MUAC : <span class="result">{{ pe.muac }}</span>
                            </li>
                          </b-col>
                          <b-col lg="12">
                            <hr />
                            <li class="physical mx-3">
                              P/E Note :<span class="result">
                                {{ pe.p_e_note }}</span
                              >
                            </li>
                          </b-col>
                        </b-row>
                      </span>
                    </b-card>

                    <b-card
                      header="Clinical Dx"
                      no-body
                      v-if="index1.dx.length != 0"
                    >
                      <ul v-for="dx in index1.dx" :key="dx.id">
                        <b-card-text>
                          <li class="physical mx-3 my-3" v-for="(item,index) in convertToarray(dx.dx)" :key="item">
                            <span class="result">Diagnosis {{index + 1}} :{{item}}</span>
                          </li>
                        </b-card-text>
                      </ul>
                    </b-card>

                    <!-- this for lab Tests -->
                    <b-card-group deck v-if="index1.labResults.length !== 0">
                      <b-card header="Laboratory Results">
                        <div class="">
                          <span v-for="haf in totalModified" :key="haf.id">
                            <hr class="mx-3 mt-3" />
                            <b-row class="mx-2">
                              <b-col sm="6" class="col-12">
                                <span
                                  >Time : <b>{{ haf.time }}</b></span
                                >
                              </b-col>
                              <b-col sm="5" class="col-12 mx-3">
                                <span
                                  >Lab Technician :
                                  <b>{{ haf.labTechnician }}</b></span
                                >
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col
                                lg="6"
                                class="col-12"
                                v-for="lab in haf.unique"
                                :key="lab.id"
                              >
                                <b-card
                                  bg-variant="default"
                                  text-variant="black"
                                  :header="lab.category"
                                  class="cardLab my-1"
                                >
                                  <b-row
                                    v-for="tests in lab.tests"
                                    :key="tests.id"
                                  >
                                    <b-col lg="3">
                                      <li>{{ tests.name }}</li>
                                    </b-col>
                                    <b-col
                                      v-if="
                                        getPatientProfile.patient.sex ==
                                        'Female'
                                      "
                                      lg="9"
                                      class="col-6 float-left"
                                    >
                                      <li class="result">
                                        <span
                                          v-if="
                                            tests.result != 'result not entered'
                                          "
                                        >
                                        </span>

                                        <span
                                          v-if="tests.typeOfResult === 'number'"
                                        >
                                          <strong
                                            :class="{
                                              checkResult:
                                                tests.minRangeFemale >
                                                  tests.result ||
                                                tests.maxRangeFemale <
                                                  tests.result,
                                            }"
                                          >
                                            {{ tests.result }}
                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>

                                        <span
                                          v-if="
                                            tests.typeOfResult === 'textarea'
                                          "
                                        >
                                          <strong
                                            :class="{
                                              checkResult:
                                                tests.minRangeFemale >
                                                  tests.result ||
                                                tests.maxRangeFemale <
                                                  tests.result,
                                            }"
                                          >
                                            <p style="white-space: pre-line">
                                              {{ tests.result }}
                                            </p>

                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>

                                        <span
                                          v-if="tests.typeOfResult === 'radio'"
                                        >
                                          <strong
                                            :class="{
                                              checkResult:
                                                tests.result == 'Positive',
                                            }"
                                          >
                                            {{ tests.result }}
                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>
                                        <span
                                          v-if="tests.typeOfResult === 'text'"
                                        >
                                          <strong>
                                            {{ tests.result }}
                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>
                                        {{ tests.unit }}
                                        <span
                                          v-if="
                                            tests.typeOfResult === 'number' &&
                                            tests.result != 'result not entered'
                                          "
                                        >
                                          Range [{{ tests.minRangeFemale }} -
                                          {{ tests.maxRangeFemale }}]
                                          <span v-if="tests.power > 0">
                                            x 10<sup>{{
                                              tests.power
                                            }}</sup></span
                                          >
                                        </span>
                                      </li>
                                    </b-col>
                                    <b-col
                                      v-if="
                                        getPatientProfile.patient.sex == 'Male'
                                      "
                                      lg="9"
                                      class="col-6 float-left"
                                    >
                                      <span class="result">
                                        <span
                                          v-if="
                                            tests.result == 'result not entered'
                                          "
                                        >
                                          Result</span
                                        >
                                        <span
                                          v-if="
                                            tests.typeOfResult === 'textarea'
                                          "
                                        >
                                          <strong
                                            :class="{
                                              checkResult:
                                                tests.minRangeFemale >
                                                  tests.result &&
                                                tests.maxRangeFemale >
                                                  tests.result,
                                            }"
                                          >
                                            <p style="white-space: pre-line">
                                              {{ tests.result }}
                                            </p>

                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>
                                        <span
                                          v-if="tests.typeOfResult === 'text'"
                                        >
                                          <strong>
                                            {{ tests.result }}
                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>
                                        <span
                                          v-if="tests.typeOfResult === 'number'"
                                        >
                                          <strong
                                            :class="{
                                              checkResult:
                                                tests.minRangeMale >
                                                  tests.result ||
                                                tests.maxRangeMale <
                                                  tests.result,
                                            }"
                                            >{{ tests.result }}
                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>

                                        <span
                                          v-if="tests.typeOfResult === 'radio'"
                                        >
                                          <strong
                                            :class="{
                                              checkResult:
                                                tests.result == 'Positive',
                                            }"
                                            >{{ tests.result }}
                                            <span
                                              v-if="
                                                tests.power > 0 &&
                                                tests.result !=
                                                  'result not entered'
                                              "
                                              >x 10<sup>{{ tests.power }}</sup>
                                            </span></strong
                                          >
                                        </span>

                                        <span
                                          v-if="
                                            tests.typeOfResult === 'number' &&
                                            tests.result != 'result not entered'
                                          "
                                        >
                                          Range [{{ tests.minRangeMale }} -
                                          {{ tests.maxRangeMale }}]
                                          <span v-if="tests.power > 0">
                                            x 10<sup>{{
                                              tests.power
                                            }}</sup></span
                                          >
                                        </span>
                                      </span>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </b-col>
                            </b-row>
                          </span>
                        </div>
                        <div id="print sheet" class="A4" v-if="showPreview">
                          <medicalHistoryPrint
                            :printType="printType"
                            ref="print"
                          ></medicalHistoryPrint>
                        </div>
                      </b-card>
                    </b-card-group>

                    <!-- this for Radio test -->
                    <b-card
                      style="margin: -2px"
                      header="Radiology Results"
                      v-if="index1.radioResults.length != 0"
                    >
                      <b-card style="margin: -20px">
                        <b-list-group
                          v-for="radioTest in index1.radioResults"
                          :key="radioTest.id"
                        >
                          <b-row>
                            <b-col lg="12">
                              <small class="text-muted"
                                >Radiologist:
                                {{ index1.radioResults[0].radiologist }}</small
                              >
                              <small class="text-muted float-right">
                                Reported Time
                                <span class="result">{{ radioTest.time }}</span>
                              </small>
                            </b-col>
                          </b-row>
                          <b-row
                            v-for="finding in radioTest.results"
                            :key="finding.id"
                          >
                            <b-col lg="12" style="margin-top: 15px">
                              <h6>{{ finding.name }}</h6>
                            </b-col>
                            <b-row>
                              <b-col
                                lg="12"
                                v-for="radioTe in finding.findings"
                                :key="radioTe.id"
                              >
                                <li
                                  :class="{ edited: radioTe.edited }"
                                  v-if="!radioTe.removed"
                                >
                                  <span style="font-size: 16px; margin-top: 0px"
                                    >-</span
                                  >
                                  {{ radioTe.name }}
                                </li>
                                <!-- <li
                                  :class="{ edited: radioTe.edited }"
                                  v-html="radioTe.name"
                                ></li> -->
                              </b-col>
                              <li
                                class="physical mx-3"
                                v-if="finding.conclusion"
                              >
                                Index :
                                <span class="result"
                                  ><strong>{{
                                    finding.conclusion
                                  }}</strong></span
                                >
                              </li>
                              <li
                                class="physical mx-3"
                                v-if="finding.recommendation"
                              >
                                Recommendation :
                                <span class="result"
                                  ><strong>{{
                                    finding.recommendation
                                  }}</strong></span
                                >
                              </li>
                            </b-row>
                          </b-row>
                        </b-list-group>
                      </b-card>
                    </b-card>

                    <b-card-group deck>
                      <b-card
                        header="Treatment"
                        no-body
                        v-if="
                          index1.tx.length != 0 || index1.txMgmt.length != 0
                        "
                      >
                        <span
                          v-for="treatment in index1.tx"
                          :key="treatment.id"
                        >
                          <div v-if="treatment.length !== 0">
                            <b-row>
                              <b-col lg="6"
                                ><p
                                  class="text-muted mx-1 float-right"
                                  style="font-style: italic"
                                >
                                  {{ treatment.requestType }}
                                </p></b-col
                              >
                              <b-col lg="6">
                                <p class="text-muted mx-2">
                                  <span class="text-muted"
                                    >Time {{ treatment.time }}</span
                                  >
                                </p>
                              </b-col>
                            </b-row>
                            <li
                              class="physical mx-3"
                              v-for="treItem in treatment.treatments"
                              :key="treItem.id"
                            >
                              <span>{{ treItem.name }} </span>
                              <span class="text-muted" v-if="treItem.dose">
                                : {{ treItem.doseOrder }} x {{ treItem.dose }}
                                {{ treItem.unit }},
                              </span>
                              <span class="text-muted">
                                for {{ treItem.days }} days
                                {{ treItem.perDay }} per day</span
                              >
                            </li>

                            <hr />
                          </div>
                          <li v-if="treatment.procedure" class="mx-3">
                            <b> Descrpition</b>
                            <span class="result">{{
                              treatment.procedure
                            }}</span>
                          </li>

                          <hr />
                        </span>
                        <li v-if="index1.txMgmt" class="mx-2 my-1">
                          <span v-for="dx in index1.txMgmt" :key="dx.id">
                            <li class="result">{{ dx.txMgmt }}</li>
                          </span>
                        </li>
                      </b-card>
                    </b-card-group>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-card-body>
      <!-- {{ labResults }}
      <hr />
      {{ getPatientProfile }} -->
    </b-card>
  </div>
</template>

<script>
import RadioResult from "./radioResult";
import _ from "lodash";
import { download } from "../../print/download";
import { createPdfFromHtml } from "../../print/logic";
import { mapGetters, mapActions } from "vuex";
const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const maxDate = new Date(today);
maxDate.setMonth(maxDate.getMonth());
maxDate.setDate(now.getDate());
const minDate = new Date(today);
minDate.setMonth(minDate.getMonth());
minDate.setDate(now.getDate() + 1);
export default {
  components: {
    RadioResult,
  },
  data() {
    return {
      previewBtn: true,
      appointment: "",
      Preview: false,
      addNote: false,
      small: true,
      tabIndex: 0,
      min: minDate,
      createMode: true,
      editMode: false,
      showModal: false,
      index1: [],
      index: 0,
      isOutDoor: false,
      printTypes: [],
      printType: "0",
      labResults: [],
      print: "0",
      modified: [],
      uniqueModified: [],
      labRequests: [],
      totalModified: [],
      prints: [
        { name: "Print Now", key: "0" },
        { name: "Download Now", key: "1" },
      ],
      showPreview: false,
      value: false,
      form: {
        note: "",
        appointment: "",
      },
      leng: this.getPatientProfile,
    };
  },

  created() {
    //this.singlePatientAppointment(this.getPatientProfile.patient._id);
    this.labResults =
      this.getPatientProfile.patient.medicalHistory[this.index].labResults;

    this.index1 = this.getPatientProfile.patient.medicalHistory[this.index];
    for (
      let i = 0;
      i < this.getPatientProfile.patient.medicalHistory.length;
      i++
    ) {
      this.printTypes.push({
        Date: this.getPatientProfile.patient.medicalHistory[i].date,
        index: i,
      });
    }

    this.newSturctured();
  },
  // sockets: {
  //   MHUpdated: function () {
  //     const data = {
  //       patientId: this.getPatientProfile.patient._id,
  //       isOutdoor: this.isOutDoor,
  //     };
  //     this.fetchPatientsProfile(data).then(() => {
  //       this.index1 = this.getPatientProfile.patient.medicalHistory[this.index];
  //       this.labResults =
  //         this.getPatientProfile.patient.medicalHistory[this.index].labResults;
  //       this.newSturctured();
  //     });
  //   },
  // },
  methods: {
    ...mapActions([
      "addDrsNote",
      "fetchPatientsData",
      "fetchPatientsProfile",
      "fetchPatientsProfileNormalPatient",
      "deletePatientData",
      "updatePatientData",
      "isNewAction",
      "addPatientAppointments",
      "singlePatientAppointment",
    ]),

    addAppointment() {
      const data = {
        patientId: this.getPatientProfile.patient._id,
        appointmentDate: this.appointment,
      };
      this.addPatientAppointments(data).then(() => {
        this.singlePatientAppointment(this.getPatientProfile.patient._id);
      });
    },
    convertToarray(item){
      let converted=null;
      if(item!=''){
         converted = item.split(",")
      }

return converted
    },
    isNew() {
      this.isNewAction(false);
    },

    showPreviewS() {
      this.showPreview = !this.showPreview;
      this.Preview = !this.Preview;
      this.previewBtn = false;
    },
    printMH() {
      if (this.showPreview) {
        createPdfFromHtml(this.$refs.print.$el);
      } else {
        this.$bvToast.toast("Note Added", {
          title: "Please First Preview The Medical History.",
          variant: "sucesss",
          solid: true,
        });
      }
    },
    newSturctured() {
      // for every request
      for (let i = 0; i < this.labResults.length; i++) {
        // create modified, unique
        this.modified = [];
        // this.totalModified = [];
        for (let j = 0; j < this.labResults[i].tests.length; j++) {
          console.log("B: " + i + " : " + this.modified);
          this.modified.push({
            category: this.labResults[i].tests[j].category,

            tests: [],
          });
        }
        this.uniqueModified = [];
        this.uniqueModified = _.uniqBy(this.modified, "category");
        // push to tests in uniqueModified
        for (let k = 0; k < this.labResults[i].tests.length; k++) {
          console.log("D: " + k);
          let categoryName = this.labResults[i].tests[k].category;
          for (let l = 0; l < this.uniqueModified.length; l++) {
            console.log("E: " + l);
            if (this.uniqueModified[l].category == categoryName) {
              this.uniqueModified[l].tests.push(this.labResults[i].tests[k]);
            }
          }
        }
        // add to total modified
        this.totalModified.push({
          unique: this.uniqueModified,
          time: this.labResults[i].time,
          labTechnician: this.labResults[i].labTechnician,
        });
      }
    },
    downloadMH() {
      if (this.showPreview) {
        download(this.$refs.print.$el);
      } else {
        this.$bvToast.toast("Note Added", {
          title: "Please First Preview The Medical History.",
          variant: "sucesss",
          solid: true,
        });
      }
    },

    registerPatientData() {
      const patientData = {
        comment: this.form.note,
        patientId: this.getPatientProfile._id,
      };
      this.addDrsNote(patientData).then(() => {
        this.$emit("dataInserted");
        this.$bvToast.toast("Note Added", {
          title: "Note Added Successfully",
          variant: "sucesss",
          solid: true,
        });
      });
    },
    increment() {
      this.labResults = [];
      this.index1 =
        this.getPatientProfile.patient.medicalHistory[this.index + 1];
      this.labResults =
        this.getPatientProfile.patient.medicalHistory[
          this.index + 1
        ].labResults;
      this.index = this.index + 1;
      this.newSturctured();
    },
    decrement() {
      this.labResults = [];
      this.index1 =
        this.getPatientProfile.patient.medicalHistory[this.index - 1];
      this.labResults =
        this.getPatientProfile.patient.medicalHistory[
          this.index - 1
        ].labResults;
      this.index = this.index - 1;
      this.newSturctured();
    },
    showResult() {
      this.tabIndex = 0;
      this.index1 = 0;
      this.index1 = this.getPatientProfile.patient.medicalHistory[0];
      this.labResults =
        this.getPatientProfile.patient.medicalHistory[0].labResults;
    },
  },
  computed: {
    ...mapGetters([
      "getPatientsData",
      "getPatientData",
      "getPatientProfile",
      "getErrors",
      "getIsNew",
      "getPatientAppointments",
    ]),
    checkSelection() {
      return this.getPatientProfile.patient.medicalHistory.length <= 0
        ? true
        : false;
    },
    checkIndexEnd() {
      return this.index < this.getPatientProfile.patient.medicalHistory.length
        ? false
        : true;
    },
    changeBmiColor() {
      return this.index1.physicalExam.bmi < 18 ||
        this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
  display: inline-block;
}
.result {
  list-style-type: inline-block;
  display: inline-block;
}
.range {
  list-style-type: inline-block;
  display: inline-block;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.noMarginLab {
  margin: -13px;
  padding: -10px;
}
.result {
  font-family: monospace;
  color: black;
  font-size: 15px;
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}

.select {
  height: 30px;
  padding: 2px;
}
.checkResult {
  background-color: red;
  color: white;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
.JDI-tabs {
  padding: 0px;
  margin: 0px;
}
.noMargin {
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
}
.bgheader {
  background-color: #e0e3eb;
  padding-bottom: 1px;
}
.nopadding {
  padding: 0px;
}
</style>
