var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-table',{ref:"selectableTable",staticClass:"customTable table table-head-fixed",attrs:{"selectable":"","fixed":"","noCollapse":"true","current-page":_vm.currentPage,"per-page":_vm.per_page,"small":_vm.small,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"filter":_vm.filter,"tbody-tr-class":_vm.rowClass,"responsive":"sm","stacked":"md","select-mode":_vm.selectMode,"items":_vm.dataList,"show-empty":"","fields":_vm.fields,"empty-text":_vm.getUsersList ? 'Empty Record' : 'Unable To Load Data'},on:{"filtered":_vm.onFiltered,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
          width:
            field.key === 'index' ||
            field.key === 'sex' ||
            field.key === 'Age' ||
            field.key === 'cardNo' ||
            field.key === 'phoneNumber' ||
            field.key === 'registeredDate'
              ? '110px'
              : '280px',
        })})})}},{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(fullName)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.middleName)+" "+_vm._s(data.item.lastName)+" ")]}},{key:"cell(Age)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.ageCa(data.item.birthDate))+" ")]}}])}),_c('li',{staticClass:"float-left ml-3"},[_vm._v(" Total Patients "),_c('b',[_vm._v(" "+_vm._s(_vm.dataList.length))])]),(_vm.checkQueue)?_c('b-pagination',{staticClass:"float-right my-0",attrs:{"total-rows":_vm.dataList.length,"per-page":_vm.per_page,"align":"fill","size":"sm","aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }