<template>
  <div>
    <b-input type="text" v-model="filter"> </b-input>
    <b-table
      ref="selectableTable"
      selectable
      class="customTable table table-head-fixed"
      fixed
      @filtered="onFiltered"
      noCollapse="true"
      :current-page="currentPage"
      :per-page="per_page"
      :small="small"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :tbody-tr-class="rowClass"
      responsive="sm"
      stacked="md"
      :select-mode="selectMode"
      @row-selected="onRowSelected"
      :items="dataList"
      show-empty
      :fields="fields"
      :empty-text="getUsersList ? 'Empty Record' : 'Unable To Load Data'"
    >
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width:
              field.key === 'index' ||
              field.key === 'sex' ||
              field.key === 'Age' ||
              field.key === 'cardNo' ||
              field.key === 'phoneNumber' ||
              field.key === 'registeredDate'
                ? '110px'
                : '280px',
          }"
        />
      </template>
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template v-slot:cell(fullName)="data">
        {{ data.item.firstName }}
        {{ data.item.middleName }}
        {{ data.item.lastName }}
      </template>
      <template v-slot:cell(Age)="data">
        {{ ageCa(data.item.birthDate) }}
      </template>
    </b-table>
    <li class="float-left ml-3">
      Total Patients <b> {{ dataList.length }}</b>
    </li>

    <b-pagination
      v-if="checkQueue"
      class="float-right my-0"
      v-model="currentPage"
      :total-rows="dataList.length"
      :per-page="per_page"
      align="fill"
      size="sm"
      aria-controls="my-table"
    >
    </b-pagination>
  </div>
</template>
<script>
export default {
  props: ["dataList", "fun"],
  data() {
    return {
      filter: "",
      currentPage: "",
      perPage: "",
    };
  },
  methods: {
    onRowSelected() {
      this.fun();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.total = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
