var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topcard"},[_c('b-card',{attrs:{"nobody":""}},[_c('b-card-header',{staticClass:"noMargin bgheader"},[_c('b-row',[_c('b-col',{staticClass:"mx-1 mt-2",attrs:{"lg":"4"}},[_vm._v(" Name : "),_c('b',[_vm._v(_vm._s(_vm.getSinglePayment.firstName)+" "+_vm._s(_vm.getSinglePayment.middleName)+" "+_vm._s(_vm.getSinglePayment.lastName))])]),_c('b-col',{staticClass:"mx-1 mt-2",attrs:{"lg":"3"}},[_vm._v(" Sex : "),_c('b',[_vm._v(_vm._s(_vm.getSinglePayment.sex))])]),_c('b-col',{staticClass:"mx-1 mt-2",attrs:{"lg":"2"}},[_vm._v(" Age : "),_c('b',[_vm._v(_vm._s(_vm.calcAge(_vm.getSinglePayment.birthDate || "12/04/2010")))])]),(_vm.getSinglePayment.isPaidForAll == true)?_c('b-col',{staticClass:"mt-2"},[_vm._v(" Status : "),_c('span',{staticClass:"confirmed"},[_c('b-icon',{attrs:{"icon":"check-circle"}}),_vm._v(" Paid")],1)]):_vm._e(),(_vm.getSinglePayment.requestDiscount)?_c('b-col',{staticClass:"mx-1 my-1",attrs:{"lg":"3"}},[_vm._v(" Discount : "),(_vm.getSinglePayment.discount || _vm.getSinglePayment.rejected)?_c('span',[(_vm.getSinglePayment.discount == true)?_c('span',{staticClass:"confirmed"},[_vm._v("Confirmed")]):_vm._e(),(_vm.getSinglePayment.rejected == true)?_c('span',{staticClass:"danger"},[_vm._v("Rejected")]):_vm._e()]):_vm._e(),(
              _vm.getSinglePayment.requestDiscount == true &&
              _vm.getSinglePayment.rejected == false &&
              _vm.getSinglePayment.discount == false
            )?_c('span',{staticClass:"warning"},[_vm._v("Pending")]):_vm._e()]):_vm._e(),(_vm.getSinglePayment.discount || _vm.getSinglePayment.rejected)?_c('b-col',{staticClass:"mx-1 my-1",attrs:{"lg":"3"}},[(_vm.getSinglePayment.rejected)?_c('li',{staticClass:"float-right"},[_vm._v(" Price: "),_c('strong',[_vm._v(_vm._s(_vm.getSinglePayment.total))])]):_vm._e(),(_vm.getSinglePayment.discount)?_c('li',{staticClass:"float-right"},[_vm._v(" Actual Payment: "),_c('strong',[_vm._v(_vm._s(_vm.getSinglePayment.actualPayment))])]):_vm._e()]):_vm._e(),(_vm.getSinglePayment.discount || _vm.getSinglePayment.rejected)?_c('b-col',{staticClass:"my-1"},[(!_vm.checkDiscount && !_vm.getSinglePayment.isPaidForAll)?_c('b-button',{staticClass:"float-right mx-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.discountRequest}},[_vm._v(" Report ")]):_vm._e(),(_vm.checkDiscount && !_vm.getSinglePayment.isPaidForAll)?_c('b-button',{staticClass:"float-right mx-2",attrs:{"disabled":_vm.isSend,"size":"sm","variant":"primary"},on:{"click":_vm.paid}},[_vm._v(" Pay ")]):_vm._e()],1):_vm._e(),_c('b-col',{staticClass:"mt-2 float-right",attrs:{"sm":"1"}},[_c('b-link',{attrs:{"to":"/recipt"}},[_c('i',{staticClass:"fa fa-print"})])],1)],1)],1),_c('b-card-body',[_vm._l((_vm.getSinglePayment.paymentList),function(item){return _c('span',{key:item.id},[(item.type == 'radiologyRequest')?_c('p',{staticClass:"tag my-0 mx-0"},[_vm._v(" Radiology ")]):_vm._e(),(item.type == 'labRequest')?_c('p',{staticClass:"tag my-0 mx-0"},[_vm._v(" Laboratory ")]):_vm._e(),(item.type == 'treatmentRequest')?_c('p',{staticClass:"tag my-0 mx-0"},[_vm._v(" Treatment ")]):_vm._e(),_c('b-table',{attrs:{"hover":"","small":_vm.small,"items":item.payments,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}}],null,true)})],1)}),_c('b-row',[(
            !_vm.getSinglePayment.requestDiscount && !_vm.getSinglePayment.discount
          )?_c('b-col',{attrs:{"lg":"6"}},[(!_vm.getSinglePayment.isPaidForAll)?_c('b-row',{staticClass:"mx-2",staticStyle:{"border":"1px solid gray"}},[_c('b-col',{attrs:{"lg":"12"}},[_c('label',{staticClass:"text-muted",staticStyle:{"color":"red !important","opacity":"0.6"}},[_vm._v("For Discount Request only")])]),_c('b-col',{attrs:{"lg":"6"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.discount),expression:"discount"}],staticClass:"form-control my-1",attrs:{"type":"number","placeholder":"Actual payment"},domProps:{"value":(_vm.discount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.discount=$event.target.value}}})]),_c('b-col',{attrs:{"lg":"6"}},[(!_vm.checkDiscount)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reason),expression:"reason"}],staticClass:"form-control float-right",attrs:{"type":"text","placeholder":"Reason"},domProps:{"value":(_vm.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reason=$event.target.value}}}):_vm._e()])],1):_vm._e()],1):_vm._e(),(
            !_vm.getSinglePayment.requestDiscount && !_vm.getSinglePayment.discount
          )?_c('b-col',{staticClass:"col-6",staticStyle:{"margin-top":"50px"},attrs:{"lg":"4"}},[_c('span',{staticClass:"float-right"},[_vm._v(" Total: "),_c('strong',[_vm._v(_vm._s(_vm.getSinglePayment.total))])]),(!_vm.checkDiscount)?_c('span',{staticClass:"float-right mx-1"},[_vm._v(" Discount: "),_c('strong',[_vm._v(_vm._s(_vm.discountLabel))])]):_vm._e()]):_vm._e(),(
            !_vm.getSinglePayment.requestDiscount && !_vm.getSinglePayment.discount
          )?_c('b-col',{staticClass:"col-6",staticStyle:{"margin-top":"40px"},attrs:{"lg":"2"}},[(!_vm.checkDiscount && !_vm.getSinglePayment.isPaidForAll)?_c('b-button',{staticClass:"float-right mx-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.discountRequest}},[_vm._v(" Report ")]):_vm._e(),(_vm.checkDiscount && !_vm.getSinglePayment.isPaidForAll)?_c('b-button',{staticClass:"float-right mx-2",attrs:{"disabled":_vm.isSend,"size":"sm","variant":"primary"},on:{"click":_vm.paid}},[_vm._v(" Pay ")]):_vm._e()],1):_vm._e()],1)],2)],1),_c('b-toast',{attrs:{"id":"toaster","toaster":"b-toaster-top-right"}},[_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline",attrs:{"slot":"toast-title"},slot:"toast-title"},[(_vm.getErrors != null)?_c('div',[_vm._v(" "+_vm._s(_vm.getErrors)+" ")]):_vm._e(),(_vm.getErrors == null)?_c('div',[_c('b-spinner',{attrs:{"variant":"danger","label":"Spinning","small":""}}),_vm._v("  Processing... ")],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }