import axios from "axios";
const state = {
  medications: [],
  medication: [],
};

const mutations = {
  setmedication: (state, medication) => (state.medication = medication),
  setmedications: (state, medications) => (state.medications = medications),
  pushmedication: (state, medicationInstance) =>
    state.medications.unshift(medicationInstance),
  deletemedication: (state, id) =>
    (state.medications = state.medications.filter(
      (medication) => medication.id !== id
    )),
  updatemedication(state, payload) {
    const object = state.medications.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
};

const getters = {
  getmedications: (state) => {
    return state.medications;
  },
  getmedication: (state) => {
    return state.medication;
  },
};

const actions = {
  async fetchMedications({ commit }) {
    await axios
      .get("/medications/all")
      .then((response) => {
        commit("setmedications", response.data.medications);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async fetchActiveMedications({ commit }) {
    await axios
      .get("/medications")
      .then((response) => {
        commit("setmedications", response.data.medications);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async updatemedicationstatus({ commit }, Id) {
    await axios
      .patch("/medication/toggleStatus" + `/${Id}`)
      .then((response) => {
        commit("updatemedication", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .then((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async singlemedication({ commit }, id) {
    await axios
      .get("/medication" + `/${id}`)
      .then((response) => {
        commit("setmedication", response.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async addMedication({ commit }, object) {
    await axios
      .post("/medication", object)
      .then((response) => {
        commit("pushmedication", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updatemedication({ commit }, object) {
    await axios
      .patch("/medication" + `/${object.id}`, object)
      .then((response) => {
        commit("updatemedication", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async deletemedication({ commit }, id) {
    await axios
      .delete("/medication" + `/${id}`)
      .then((response) => {
        commit("deletemedication", id);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
