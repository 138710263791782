const fieldsLabTest = [
  { key: "index", label: "#", sortable: false },

  {
    key: "name",
    label: "Name",
    sortable: true,
  },

  {
    key: "price",
    label: "Price",
    sortable: true,
  },
  "Status",
  "Actions",
];

const fieldsMedication = [
  { key: "index", label: "#", sortable: false },

  {
    key: "name",
    label: "Name",
    sortable: true,
  },

  {
    key: "unitPrice",
    label: "Price",
    sortable: true,
  },
  "Status",
  "Actions",
];

const fieldsCategories = [
  { key: "index", label: "#" },
  { key: "category", label: "Category" },
  "Actions",
];
const fieldsLabCategories = [
  { key: "index", label: "#" },
  { key: "category", label: "Category" },
  { key: "actions", label: "Action" },
];
const fieldsRadioCategories = [
  { key: "index", label: "#" },
  { key: "category", label: "Category" },
  { key: "actions", label: "Action" },
];
const fieldsStaffAccount = [
  { key: "index", label: "#", sortable: false },
  {
    key: "fullName",
    label: "Full Name",
    sortable: true,
  },
  {
    key: "gender",
    label: "Gender",
  },

  "Actions",
  "Selected",
];

const fieldsPaymentQueue = [
  { key: "index", label: "#", sortable: false },
  "fullName",
  { key: "cardNo", label: "MRN" },
  { key: "actualPaid", label: "Actual Paid" },
  { key: "Ptype", label: "Patient type" },

  { key: "total", label: "Total" },
  { key: "discount", label: "Discount" },
  "payment",
  // { key: "paymentList", label: "Payment List" },
];

const fieldsDrQueue = [
  { key: "index", label: "#", sortable: false },
  "fullName",
  { key: "cardNo", label: "MRN" },
  { key: "time", label: "Queue time" },
  { key: "status", label: "Status" },
];

const fieldsCashierQueue = [
  { key: "index", label: "#" },
  "fullName",
  // { key: "date", label: "Regester Time" },
  { key: "status", label: "Discount Request" },
  { key: "patient", label: "P.Type" },
  "Price",
  "Actions",
];
const fieldsTreatmentRecord = [
  { key: "index", label: "#", sortable: false },
  "fullName",
  { key: "date", label: "Regester Time" },
];

const fieldsDiscountReport = [
  { key: "index", label: "#", sortable: false },
  "fullName",
  { key: "actualPayment", label: "Actual Payment" },
  { key: "total", label: "Total Price" },
  { key: "discountReason", label: "Discount Reason" },
  "status",
  "Actions",
];

const fieldsLabQueue = [
  { key: "index", label: "#", sortable: false },
  { key: "fullName", label: "Patient Name", sortable: true },
  { key: "type", label: "P.Type" },
  { key: "time", label: "Regester Time", sortable: true },
  { key: "status", label: "Status" },
];
const fieldsRadioQueue = [
  { key: "index", label: "#" },
  { key: "fullName", label: "Patient Name", sortable: true },
  { key: "type", label: "P.Type" },
  { key: "time", label: "Regester Time", sortable: true },
  { key: "status", label: "Status" },
];

const fildsTreatmentList = [
  { key: "index", label: "#", sortable: false },

  {
    key: "name",
    label: "Name",
  },

  {
    key: "unitPrice",
    label: "Price",
  },
  "Status",
  "Actions",
];

export default {
  fieldsLabTest,
  fieldsCategories,
  fieldsTreatmentRecord,
  fieldsStaffAccount,
  fieldsPaymentQueue,
  fildsTreatmentList,
  fieldsDrQueue,
  fieldsLabQueue,
  fieldsCashierQueue,
  fieldsDiscountReport,
  fieldsRadioQueue,
  fieldsMedication,
  fieldsRadioCategories,
  fieldsLabCategories,
};
