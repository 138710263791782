var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.displayList)?_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"my-0"},[_c('b-row',{staticClass:"my-0"},[_c('b-col',{attrs:{"lg":"2"}},[_c('b-form-select',{attrs:{"options":_vm.pageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-input',{staticClass:"float-right my-0",attrs:{"size":"sm","type":"search","id":"filterInput","placeholder":"Type to search..."},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('b-col',{attrs:{"lg":"4"}})],1)],1),_c('b-card-body',{staticClass:"text-center twa-body"},[_c('b-table',{ref:"selectableTable",staticClass:"customTable table table-head-fixed",attrs:{"selectable":"","fixed":"","noCollapse":"true","current-page":_vm.currentpage,"per-page":_vm.perPage,"filter":_vm.filter,"small":_vm.small,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"tbody-tr-class":_vm.rowClass,"responsive":"sm","stacked":"md","select-mode":_vm.selectMode,"items":_vm.getDrQueues,"show-empty":"","fields":_vm.fields,"empty-text":_vm.getUsersList
            ? 'There is no patient queue yet.'
            : 'Unable to load data'},on:{"filtered":_vm.onFiltered,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
              width:
                field.key === 'index' ||
                field.key === 'sex' ||
                field.key === 'time' ||
                field.key === 'cardNo'
                  ? '110px'
                  : '280px',
            })})})}},{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(fullName)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.middleName)+" "+_vm._s(data.item.lastName)+" ")]}},{key:"cell(status)",fn:function(data){return [_c('span',{staticClass:"tag mx-2"},[(data.item.labResultPercentCompleted == 100)?_c('span',{staticClass:"confirmed"},[_vm._v("Lab "+_vm._s(data.item.labResultPercentCompleted)+" %")]):_vm._e(),(data.item.labResultPercentCompleted == 0)?_c('span',{staticClass:"danger"},[_vm._v("Lab "+_vm._s(data.item.labResultPercentCompleted)+" %")]):_vm._e(),(
                data.item.labResultPercentCompleted > 0 &&
                data.item.labResultPercentCompleted < 100
              )?_c('span',{staticClass:"warning"},[_vm._v("Lab "+_vm._s(data.item.labResultPercentCompleted)+" %")]):_vm._e()]),_c('span',{staticClass:"tag"},[(data.item.radioResultPercentCompleted == 100)?_c('span',{staticClass:"confirmed"},[_vm._v("Rad "+_vm._s(data.item.radioResultPercentCompleted)+" %")]):_vm._e(),(data.item.radioResultPercentCompleted == 0)?_c('span',{staticClass:"danger"},[_vm._v("Rad "+_vm._s(data.item.radioResultPercentCompleted)+" %")]):_vm._e(),(
                data.item.radioResultPercentCompleted > 0 &&
                data.item.radioResultPercentCompleted < 100
              )?_c('span',{staticClass:"warning"},[_vm._v("Rad "+_vm._s(data.item.radioResultPercentCompleted)+" %")]):_vm._e()])]}}],null,false,2067621578)}),_c('li',{staticClass:"float-left ml-3"}),(_vm.checkQueue)?_c('b-pagination',{staticClass:"float-right my-0",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"align":"fill","size":"sm","aria-controls":"my-table"},model:{value:(_vm.currentpage),callback:function ($$v) {_vm.currentpage=$$v},expression:"currentpage"}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }