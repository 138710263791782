<template>
  <div>
    <b-card no-body style="mrgin: 0px" id="print" class="A4">
      <b-card-header class="Jdi-header" :small="small">
        <b-row class="Jdi-row">
        <b-col lg="11"></b-col>
        <b-col lg="1"><b-link to="/patientsRadio" class="float-right"><i class="fa fa-print"></i></b-link></b-col>
         </b-row>
      </b-card-header>
      <b-row style="width: 100%"
        ><div id="print" class="A4" v-if="showPreview" style="width: 100%">
          <RadioResultPrint ref="print"></RadioResultPrint></div
      ></b-row>
      <div v-for="(radio, index) in getRadioQueue" :key="radio.id">
        <span class="mx-1 text-muted" v-if="getRadioQueue[index].isPaidFor">
          Request time
          {{ getRadioQueue[index].time }}

          <b-row>
            <b-col
              lg="12"
              v-for="(tests, indexName) in radio.tests"
              :key="tests.id"
            >
              <b-row class="mx-2">
                <hr />
                <b-col sm="12" class="mx-1">
                  <label for="" class="float-left"
                    ><strong
                      style="font-style: italic;font-weight:bold font-family: serif"
                      ><b>{{ tests.name }}</b></strong
                    ></label
                  >
                </b-col>

                <b-col sm="12" lg="12" class="mx-3">
                  <span
                    v-for="(finding, indexFinding) in tests.findings"
                    :key="finding.id"
                  >
                    <b-row>
                      <b-form-textarea
                        :disabled="
                          getRadioQueue[index].tests[indexName].findings[
                            indexFinding
                          ].removed == true
                        "
                        class="my-1 mx-0 findingItem"
                        :class="{
                          strike:
                            getRadioQueue[index].tests[indexName].findings[
                              indexFinding
                            ].removed == true,
                        }"
                        id="textarea"
                        v-model="finding.name"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <span class="float-right">
                        <b-icon
                          v-if="
                            getRadioQueue[index].tests[indexName].findings[
                              indexFinding
                            ].removed == false
                          "
                          @click="removeFinding(index, indexFinding, indexName)"
                          variant="danger"
                          tooltip="remove finding"
                          font-scale="1.5"
                          icon="backspace-fill"
                        ></b-icon>
                        <b-icon
                          v-if="
                            getRadioQueue[index].tests[indexName].findings[
                              indexFinding
                            ].removed == true
                          "
                          @click="
                            restoreFinding(index, indexFinding, indexName)
                          "
                          variant="primary"
                          tooltip="restore this finding"
                          font-scale="1.5"
                          icon="bootstrap-reboot"
                        ></b-icon>
                      </span>
                    </b-row>
                  </span>
                </b-col>
                <b-col lg="12" class="mx-1">
                  <label for="textarea-small">Index :</label>
                  <b-form-input v-model="tests.conclusion"></b-form-input>
                </b-col>
                <b-col lg="12" class="mx-1">
                  <label for="textarea-small">Recommendation :</label>
                  <b-form-input v-model="tests.recommendation"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="float-right">
              <input
                :disabled="isSendRadio"
                v-if="!getRadioQueue[index].isResultSaved"
                class="btn btn-primary my-1 mx-2 float-right"
                @click="sendRadioResult(index)"
                type="button"
                value="Send"
              />
              <input
                v-if="getRadioQueue[index].isResultSaved"
                @click="updateRadioResult(index)"
                class="btn btn-info my-1 mx-2 float-right"
                type="button"
                value="Update"
              />
            </b-col>
          </b-row>

          <hr style="border: 2px solid lightgray" />
        </span>
      </div>
    </b-card>

    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="primary" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
    <hr />
  </div>
</template>

<script>
import RadioResultPrint from "./radioResultPrint";
import { mapGetters, mapActions } from "vuex";
import { createPdfFromHtml } from "../../print/logic";
export default {
  components: { RadioResultPrint },
  data() {
    return {
      labra: [],
      test: [],
      small: true,
      showPreview: false,
      checkSelection: true,
      isSendRadio: false,
      descrpition: "",
      physicalExam: [],
      isSend: false,
      fields: ["name", "id"],
    };
  },
  created() {
    //const data = {
    //   patientId: this.getRadioQueue[0].patientId,
    //   isOutdoor: this.getRadioQueue[0].isOutdoor,
    // };
    // this.fetchPatientsProfile(data);
  },
  methods: {
    ...mapActions([
      "addRadioResult",
      "fetchPatientsProfile",
      "updateRadioResults",
      "fetchRadioQueueById",
    ]),
    printRadio() {
      createPdfFromHtml(this.$refs.print.$el);
    },
    showPreviewS() {
      this.showPreview = !this.showPreview;
    },
    removeFinding(index, finding, name) {
      this.getRadioQueue[index].tests[name].findings[finding].removed = true;
    },
    restoreFinding(index, finding, name) {
      this.getRadioQueue[index].tests[name].findings[finding].removed = false;
    },
    sendRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        requestId: this.getRadioQueue[index]._id,
        isOutdoor: this.getRadioQueue[index].isOutdoor,
        radiologist:
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("middleName"),
        tests: this.getRadioQueue[index].tests,
      };
      this.addRadioResult(labResult).then(() => {
        this.fetchRadioQueueById(this.data);
      });
      this.isSendRadio = true;
      this.$bvToast.show("toaster");
    },
    updateRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        isOutdoor: this.getRadioQueue[0].isOutdoor,
        requestId: this.getRadioQueue[index]._id,
        radiologist:
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("middleName"),
        tests: this.getRadioQueue[index].tests,
      };
      this.updateRadioResults(labResult).then(() => {
        this.fetchRadioQueueById(this.data);
      });
      this.isSend = true;
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters([
      "getRadioQueue",
      "getRadioQueues",
      "getLabTests",
      "getPatientProfile",
      "getPysicalExam",
      "getErrors",
    ]),
    checkRadioQueue() {
      return this.getRadioQueues != null ? true : false;
    },
    changeBmiColor() {
      return this.physicalExam.bmi > 18 && this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.leftSpace {
  padding-left: 50px;
}
.findingItem {
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  width: 90%;
}
li {
  list-style-type: none;
  display: block;
}
.strike {
  opacity: 0.5;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.result {
  font-family: monospace;
  color: black;
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
