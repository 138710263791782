<template>
  <div class="sheet padding-10mm">
    <b-card no-body style="margin: 5px" id="print" class="A4">
      <b-card-header class="Jdi-header" :small="small">
        <b-row class="Jdi-row">
          <b-col lg="3"> <img src="@/assets/images/amenlogo.png" /></b-col>
          <b-col lg="6">
            <h3 style="text-align: center; color: blue">ኣሜን ስፔሻሊቲ ክሊኒክ</h3>
            <h3
              style="
                text-align: center;
                color: red;
                font-family: Lucida Handwriting;
              "
            >
              Amen Speciality Clinic
            </h3>
          </b-col>
          <b-col lg="3"> </b-col>
        </b-row>
      </b-card-header>
      <hr style="border: 2px solid darkgray; margin-top: 0px" />
      <div class="personalInfo">
        <b-row style="margin=0px;padding:0px">
          <b-col lg="5"
            >Name:
            <strong class="result"
              >{{ getPatientProfile.patient.firstName }}
              {{ getPatientProfile.patient.middleName }}
              {{ getPatientProfile.patient.lastName }}</strong
            ></b-col
          ><b-col lg="4" v-if="!getPatientProfile.patient.isOutdoor"
            >Age:
            <strong class="result"
              >{{ getPatientProfile.age }} old
            </strong></b-col
          ><b-col lg="3" v-if="!getPatientProfile.patient.isOutdoor"
            >Sex:
            <strong class="result">{{
              getPatientProfile.patient.sex
            }}</strong></b-col
          >
          <!-- <b-col lg="2">Date: {{ getPatientProfile.patient.time }}</b-col> -->
          <b-col lg="12"
            >Request:
            <ul
              style="display: inline-block"
              v-for="item in getPatientProfile.patient.medicalHistory[0]
                .radioResults"
              :key="item.id"
            >
              <span v-for="pr in item.results" :key="pr.id">
                <strong class="result"
                  >{{ pr.name }}
                  <span v-if="item.results.length > 1">,</span></strong
                >
              </span>
            </ul></b-col
          >
        </b-row>
      </div>
      <hr style="border: 2px solid darkgray; margin-top: 3px" />

      <ul
        v-for="item in getPatientProfile.patient.medicalHistory[0].radioResults"
        :key="item.id"
      >
        <div v-for="pr in item.results" :key="pr.id">
          <h6 style="display: inline-block; margin: 0px">Findings of :</h6>
          {{ pr.name }}

          <ul v-for="finding in pr.findings" :key="finding.id">
            <li
              style="display: block; font-size: 14px"
              class="result"
              :class="{ edited: finding.edited }"
            >
              <span style="font-size: 26px">.</span> {{ finding.name }}
            </li>
          </ul>
          <h5>Index:</h5>
          <ul>
            <li>{{ pr.conclusion }}</li>
          </ul>
        </div>
      </ul>

      <div class="float-right" style="margin-bottom: 5px">
        <p class="float-right" style="margin-right: 70px">
          Radiologist Name :
          <strong> Dr {{ firstName }} {{ middleName }}</strong>
        </p>
      </div>

      <b-card-footer>
        <b-row
          style="
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 0px;
            height: 17px;
          "
        >
          <b-col lg="2">
            <p style="font-size: 10px">Telephone: 0344412323</p></b-col
          >
          <b-col lg="8"
            ><h6
              style="
                text-align: center;
                color: blue;
                display: block;
                font-size: 10px;
              "
            >
              ኣድራሻ ዓዲ ሽምዱሕን ጎኒ ትራፊክ መብራህቲ ጎኒ ኣንበሳ ባንክ ን ኣንፈት ምዕራብ
            </h6>
            <h6
              style="
                text-align: center;
                color: blue;
                display: block;
                font-size: 10px;
              "
            >
              Address Adishmduhn trafic light near to Anbessa Bank
            </h6>
          </b-col>
          <b-col lg="2">
            <p style="font-size: 10px">Phone: +251968944000</p></b-col
          >
        </b-row>
        <hr />
        <b-row style="height: 10px; bottom: 0">
          <b-col><p style="font-size: 10px">JustCARE</p></b-col>
          <b-col><p style="font-size: 10px">Contact 0934337171</p></b-col>
          <b-col
            ><p style="font-size: 10px">
              Powered By JDI Technologies Plc
            </p></b-col
          >
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
//import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { createPdfFromHtml } from "../../print/logic";
export default {
  //props: ["editMode"],
  data() {
    return {
      labra: [],
      test: [],
      small: true,
      checkSelection: true,
      descrpition: "",
      physicalExam: [],
      firstName: localStorage.getItem("firstName"),
      middleName: localStorage.getItem("middleName"),
      isSend: false,
      fields: ["name", "id"],
    };
  },
  created() {
    this.fetchPatientsProfile(this.getRadioQueue[0].patientId);
    for (
      let i = 0;
      i < this.getPatientProfile.patient.medicalHistory.length;
      i++
    ) {
      this.physicalExam = this.getPatientProfile.patient.medicalHistory[
        i
      ].physicalExam;
    }
  },
  methods: {
    ...mapActions(["fetchPatientsProfile"]),
    printRadio() {
      createPdfFromHtml(this.$refs.print.$el);
    },
    sendRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        requestId: this.getRadioQueue[0]._id,
        tests: this.getRadioQueue[index].tests,
      };
      this.addRadioResult(labResult);
      this.isSend = true;
      this.$bvToast.show("toaster");
    },
    updateRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        requestId: this.getRadioQueue[0]._id,
        tests: this.getRadioQueue[index].tests,
      };
      this.updateRadioResults(labResult);
      this.isSend = true;
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile"]),
    checkRadioQueue() {
      return this.getRadioQueues != null ? true : false;
    },
    changeBmiColor() {
      return this.physicalExam.bmi > 18 && this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.leftSpace {
  padding-left: 50px;
}

.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  display: block;
}
.personalInfo {
  margin: 0px 5px;
}
li {
  list-style-type: none;
  display: block;
}
.edited {
  font-weight: bold;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.result {
  font-family: "Times New Roman", Times, serif;
  color: black;
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
