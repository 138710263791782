<template>
  <b-nav>
    <b-navbar-brand style="font-size: 20px">
      <img src="@/assets/images/no1.gif" height="80px; width:100%" />
    </b-navbar-brand>
  </b-nav>
</template>

<script>
export default {
  data() {
    return {
      brand: "Amen Speciality Clinic",
    };
  },
};
</script>
