import VueRouter from "vue-router";
import Vue from "vue";
import admin from "./admin";
import doctor from "./doctor";
import cashier from "./cashier";
import laboratory from "./laboratory";
import radiology from "./radilogy";
import login from "../view/common/homePage.vue";
import forgotPassword from "../view/common/forgotPassword.vue";
import notfound from "../view/common/notFound.vue";
import changePassword from "../view/common/changePassword.vue";

Vue.use(VueRouter);
const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...admin,
    ...doctor,
    ...cashier,
    ...radiology,
    ...laboratory,

    {
      path: "/login",
      name: "login",
      component: login,
      meta: {
        title: "Login",
        // scope: "NonAuth",
      },
    },

    {
      path: "/forgotPassword",
      name: "forgotPassword",
      component: forgotPassword,
      meta: {
        title: "Forgot Password",
        // scope: "NonAuth",
      },
    },

    {
      path: "/notfound",
      name: "notfound",
      component: notfound,
      meta: {
        title: "notFound",
        // scope: "NonAuth",
      },
    },
    {
      path: "/changePassword",
      name: "changePassword",
      component: changePassword,
      meta: {
        title: "Password Change",
        // scope: "NonAuth",
      },
    },
    {
      path: "*",
      redirect: "/notfound",
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (localStorage.getItem("accessToken") != null) {
    next();
  } else {
    next("/login");
  }
});

export default router;
