import axios from "axios";

const state = {
  labTests: [],
  labTest: [],
  labResult: [],
  singleLabTest: [],
};

const mutations = {
  setLabTests: (state, labTests) => (state.labTests = labTests),
  setLabTest: (state, labTest) => (state.labTest = labTest),
  singleLabTest: (state, singleLabTest) =>
    (state.singleLabTest = singleLabTest),
  pushLabTest: (state, labTests) => state.labTests.unshift(labTests),
  pushLabResult: (state, labResult) => state.labResult.unshift(labResult),
  deleteLabTest: (state, id) =>
    (state.labTests = state.labTests.filter((user) => user.id !== id)),
  updateLabTest(state, payload) {
    const object = state.labTests.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
  updateLabResult(state, payload) {
    const object = state.labResult.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
};

const getters = {
  getLabTests: (state) => {
    return state.labTests;
  },
  getLabTest: (state) => {
    return state.labTest;
  },
  getSingleLabTest: (state) => {
    return state.singleLabTest;
  },
};

const actions = {
  async fetchLabTests({ commit }) {
    await axios
      .get("/labTests/all")
      .then((response) => {
        commit("setLabTests", response.data.labTests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async fetchLabTestsActive({ commit }) {
    await axios
      .get("/labTests")
      .then((response) => {
        commit("setLabTests", response.data.labTests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async changeLabTestStatus({ commit }, Id) {
    await axios
      .patch("/labTest/toggleStatus" + `/${Id}`)
      .then((response) => {
        commit("setLabTests", response.data.labTests);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.message);
        return Promise.reject();
      });
  },

  async addLabTest({ commit }, object) {
    await axios
      .post("/labTest", object)
      .then((response) => {
        commit("pushLabTest", response.data);
        commit("setErrors", "Labratory Result Sent successfully.");
        return Promise.resolve();
      })
      .catch(() => {
        commit("setErrors", "Failed To Send?");
      });
  },
  // for post lab test request
  async sendLabTestRequest({ commit }, object) {
    await axios
      .post("/labRequest", object)
      .then((response) => {
        commit("pushLabTest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.message);
        return Promise.reject();
      });
  },

  // for lab result send
  async addLabResult({ commit }, object) {
    await axios
      .post("/labResults", object)
      .then((response) => {
        commit("pushLabResult", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updateLabTestSingle({ commit }, object) {
    await axios
      .put("/labTest" + `/${object.id}`, object)
      .then((response) => {
        commit("updateLabTest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updateLabResults({ commit }, object) {
    await axios
      .patch("/labResults" + `/${object.id}`, object)
      .then((response) => {
        commit("updateLabResult", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async fetchLabTest({ commit }, id) {
    await axios
      .get("/labTest" + `/${id}`)
      .then((res) => {
        commit("singleLabTest", res.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
