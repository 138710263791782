import labQueue from "../view/labratory/labQueue.vue";
import patientList from "../view/labratory/patientRegister.vue";
import labResultForm from "../view/labratory/labResultForm.vue";

export default [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "labDashboard",
        name: "labDashboard",
        meta: {
          title: "dashboard",
          // scope: "Auth",
        },
        component: () => import("@/view/labratory/dashboard"),
      },
      {
        path: "labQueue",
        name: "labQueue",
        meta: {
          title: "Laboratory's Queue",
          // scope: "Auth",
        },
        component: labQueue,
      },
      {
        path: "patientsLab",
        name: "patientsLab",
        meta: {
          title: "Patients",
          header: false,
        },
        component: patientList,
      },
      {
        path: "labResultForm",
        name: "labResultForm",
        meta: {
          title: "Laboratory Result",
          // scope: "Auth",
        },
        component: labResultForm,
      },
      {
        path: "another",
        name: "another",

        meta: {
          title: "Patient Labratory Result",
          header: false,
          // scope: "Auth",
        },
        components: import("@/view/labratory/labTests"),
      },
    ],
  },
];
