const role = [
  "Admin",
  "Labratory",
  "Cashier",
  "Radiology",
  "Doctor",
  "Pharmacy",
];

const data = [
  { name: "haftom", date: "034", status: true },
  { name: "gidey", date: "021", status: false },
  { name: "tsgabu", date: "089", status: true },
  { name: "abrha", date: "073", status: false },
];

export default { role, data };
