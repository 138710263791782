import sidebar from "../common/sidebar.vue";
import cashierQueue from "../view/cashier/cashierQueue.vue";
import appointment from "../view/cashier/listAppointment.vue";
import outDoorService from "../view/cashier/outDoorService.vue";
import cashierResult from "../view/cashier/cashierResult.vue";
import patientRegister from "../view/cashier/patientRegister.vue";
import treatmentTable from "../view/cashier/treatmentTable.vue";
import treatmentDetail from "../view/cashier/treatmentDetail.vue";
import recipt from "../view/cashier/print/recipt.vue";
import outDoorResult from "../view/cashier/outDoorresult.vue";
import labSelection from "../view/cashier/labSelection.vue";
import discountLists from "../view/admin/components/discountLists.vue";
import raiologyLabSelection from "../view/cashier/radiologyLabSelection copy.vue";

export default [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "cashierDashboard",
        name: "cashierDashboard",
        meta: {
          title: "Dashboard",
          // scope: "Auth",
        },
        component: () => import("@/view/cashier/dashboard"),
      },
      {
        path: "cashierQueue",
        name: "cashierQueue",
        meta: {
          title: "Queues",
          // scope: "Auth",
        },
        component: cashierQueue,
      },
      {
        path: "listAppointment",
        name: "listAppointment",
        meta: {
          title: "Appointments",
          // scope: "Auth",
        },
        component: appointment,
      },
      {
        path: "outDoorService",
        name: "outDoorService",
        meta: {
          title: "Outdoor Service",
          // scope: "Auth",
        },
        component: outDoorService,
      },
      {
        path: "discountLists",
        name: "discountLists",
        meta: {
          title: "Credit Lists",
          // scope: "Auth",
        },
        component: discountLists,
      },
      {
        path: "cashierResult",
        name: "cashierResult",
        meta: {
          title: "Payment Details",
          // scope: "Auth",
        },
        component: cashierResult,
      },
      {
        path: "patientRegister",
        name: "patientRegister",
        meta: {
          title: "Patient Registration",
          // scope: "Auth",
        },
        component: patientRegister,
      },
      {
        path: "tratment",
        name: "treatmentTable",
        meta: {
          title: "List Of treatments",
          // scope: "Auth",
        },
        component: treatmentTable,
      },
      {
        path: "treatmentResult",
        name: "treatmentDetail",
        meta: {
          title: "Patient Treatments",
          // scope: "Auth",
        },
        component: treatmentDetail,
      },
      {
        path: "recipt",
        name: "recipt",

        meta: {
          title: "Patient recipt",
          header: false,
          // scope: "Auth",
        },
        components: {
          default: recipt,
          "no-sidebar": sidebar,
        },
      },
      {
        path: "outDoorResult",
        name: "outDoorResult",
        meta: {
          title: "Patient Result",
          // scope: "Auth",
        },
        component: outDoorResult,
      },
      {
        path: "cashierLabSelection",
        name: "cashierLabSelection",
        meta: {
          title: "outDoor Labratory Test",
          // scope: "Auth",
        },
        component: labSelection,
      },
      {
        path: "cashierRadioSelection",
        name: "cashierRadioSelection",
        meta: {
          title: "outDoor Radiology Test",
          // scope: "Auth",
        },
        component: raiologyLabSelection,
      },
    ],
  },
];
