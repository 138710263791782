import Vue from "vue";

import login from "./homePage";
Vue.component("login", login);

import home from "./login";
Vue.component("home", home);

import common from "./common";
Vue.component("common", common);

export default [home, login,common];
