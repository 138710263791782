<template>
    <div class="col-md-4 col-sm-6 col-12">
      <div class="info-box bg-info">
        <span class="info-box-icon"><i class="fa fa-dollar-sign"></i></span>

        <div class="info-box-content">
          <h5
            class="info-box-text"
            v-if="data.category == 'cardPayment'"
            >Card Daily Revenue</h5
          >
          <h5
            class="info-box-text"
            v-if="data.category == 'labRequest'"
            >Laboratory Daily Revenue</h5
          >
          <h5
            class="info-box-text "
            v-if="data.category == 'radiologyRequest'"
            >Radiology Daily Revenue</h5
          >
          <h5
            class="info-box-text"
            v-if="data.category == 'treatmentRequest'"
            >Treatment Daily Revenue</h5
          >
          <span class="info-box-number">{{ data.total }} Birr</span>

          <span class="progress-description ">
            Date {{ day }}
          </span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      day:window.moment().format("DD/MM/YYYY")
    }
  },
};
</script>