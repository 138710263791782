<template>
  <div>
    <b-card nobody style="margin: -20px">
      <b-card-body nobody style="margin: -20px">
        <b-container>
          <!-- {{ getPatientProfile.patient }} -->
          <b-link class="float-right" @click="addToRefer"
            ><i class="fa fa-print" style="color: blue"></i
          ></b-link>
          <b-row>
            <b-col lg="4">
              <label> Referred to :</label>
              <b-form-input
                class="my-0"
                size="sm"
                v-model="refer.referedTo"
                type="text"
              ></b-form-input>
            </b-col>
            <b-col lg="4">
              <label> Department :</label>
              <b-form-input
                class="my-0"
                size="sm"
                v-model="refer.department"
                type="text"
              ></b-form-input>
            </b-col>
            <b-col lg="4">
              <label> Status :</label>
              <b-form-input
                class="my-0"
                size="sm"
                v-model="refer.status"
                type="text"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <label class="mx-3">C/C</label>
              <b-textarea
                id="textarea"
                class="mx-3"
                v-model="refer.cc"
                rows="3"
                max-rows="6"
              ></b-textarea>
            </b-col>
            <b-col lg="12">
              <label class="mx-3">HPI</label>
              <b-textarea
                id="textarea"
                class="mx-3"
                v-model="refer.hpi"
                rows="3"
                max-rows="6"
              ></b-textarea>
            </b-col>

            <b-col class="col-12">
              <b-col lg="5" class="mx-1">
                <h5 class="subTitle">Physical Examination</h5>
                <b-row>
                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">BP</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.bp"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">Ht</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.ht"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">PR</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.pr"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">Wt</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.wt"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">RR</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.rr"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">BMI</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          class="red"
                          :class="{ green: bmiChangeColor }"
                          :disabled="true"
                          v-model="bmicalculate"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2" class="mx-1">
                        <label size="sm" for="input-small"
                          >PSO<sub>2</sub></label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.pso2"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">AC</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.ac"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2">
                        <label for="input-small">T<sup>o</sup></label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.to"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="2" class="mx-1">
                        <label size="sm" for="input-small">HC</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="pe.hc"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6" class="col-6"> </b-col>

                  <b-col lg="6" class="col-6">
                    <b-row class="my-1">
                      <b-col sm="3" class="mx-1">
                        <label size="sm" for="input-small">MUAC</label>
                      </b-col>
                      <b-col sm="7">
                        <b-form-input
                          v-model="pe.muac"
                          id="input-small"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>

              <b-row class="textAreaRow ml-3">
                <b-form-textarea
                  id="textarea"
                  v-model="pe.descrpition"
                  placeholder="further note for P/E"
                  rows="2"
                  max-rows="4"
                ></b-form-textarea>
              </b-row>
              <b-col class="col-12">
              <label>Diagnosis</label>
              <b-textarea
                id="textarea"
                v-model="refer.diagnosis"
                placeholder="Diagnosis"
                rows="3"
                max-rows="6"
              ></b-textarea>
            </b-col>
            </b-col>
              <label class="mx-4">Tratment</label>

            <b-col class="col-12">
              <span v-if="refer.txGiven.length > 0">
              <ul v-for="item in refer.txGiven" :key="item._id">
                <li>
                   {{ item.name }}
                  <span
                    > {{ item.dose }}{{ item.unit }} for
                    {{ item.days }}/{{ item.perDay }}</span
                  >
                </li>
              </ul>
              </span>
              <span v-if="refer.txMgmt.length > 0 ">
              <ul>
                <li>{{refer.txMgmt[0].txMgmt}}</li>
              </ul>
              </span>
            </b-col>
            <b-col class="col-12">
              <b-textarea
                id="textarea"
                class="mx-3"
                v-model="refer.treatment"
                placeholder="Treatment"
                rows="3"
                max-rows="6"
              ></b-textarea>
            </b-col>
            
            <b-col class="col-12">
              <label class="mx-3">Reason for refereal :</label>
              <b-textarea
                v-model="refer.reason"
                rows="1"
                class="mx-3"
                max-rows="1"
              ></b-textarea>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>

    <!-- {{ refer.txGiven }} -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      laboratory: [],
      refer: {
        physicalExam: [],
        referedTo: "",
        department: "",
        treatment: "",
        hpi: [],
        txMgmt: [],
        txGiven: [],
        status: "",
        reason: "",
        diagnosis: [],
        cc: "",
        labResult: [],
        radioResult: [],
        pe: {},
      },
      pe: {
        bp: "",
        pr: "",
        rr: "",
        to: "",
        wt: 1,
        bmi: "",
        pso2: "",
        ac: "",
        hc: "",
        muac: "",
        ht: 1,
        descrpition: "",
      },
      showPreview: false,
      isAdd: true,
    };
  },
  created() {
    
    if (this.refer.diagnosis.length >= 0) {
      this.refer.diagnosis = "Sorry there is no DIAGNOSIS for this patient";
    }
    if (this.refer.hpi) {
      this.refer.hpi = "Sorry there is no HPI for this patient";
    }
    if (this.refer.cc.length >= 0) {
      this.refer.cc = "Sorry there is no C?C for this patient";
    }

    this.refer.referedTo = localStorage.getItem("refferedTo") || "";
    this.refer.department = localStorage.getItem("department") || "";
    this.refer.status = localStorage.getItem("status") || "";
    this.refer.reason = localStorage.getItem("reason") || "";
    this.refer.treatment = localStorage.getItem("treatment") || "";

    this.refer.pe = this.pe;

    this.refer.physicalExam = this.getPatientProfile.patient.medicalHistory[0].physicalExam[
      this.getPatientProfile.patient.medicalHistory[0].physicalExam.length - 1
    ];
    this.refer.labResult = this.getPatientProfile.patient.medicalHistory[0].labResults[
      this.getPatientProfile.patient.medicalHistory[0].labResults.length - 1
    ];
    this.refer.radioResult = this.getPatientProfile.patient.medicalHistory[0].radioResults[0];
    this.refer.cc = this.getPatientProfile.patient.medicalHistory[0].drsNote[0].c_c;

    this.refer.hpi = this.getPatientProfile.patient.medicalHistory[0].drsNote[0].hpi;

    this.refer.diagnosis = this.getPatientProfile.patient.medicalHistory[0].dx[0].dx;
    this.refer.txGiven = this.getPatientProfile.patient.medicalHistory[0].tx[0].treatments;
    this.refer.txMgmt = this.getPatientProfile.patient.medicalHistory[0].txMgmt;
    if (
      this.getPatientProfile.patient.medicalHistory[0].physicalExam.length > 0
    ) {
      this.pe.bp = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].bp;
      this.pe.pr = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].pr;
      this.pe.rr = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].rr;
      this.pe.to = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].t;
      this.pe.pso2 = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].pso2;
      this.pe.wt = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].wt;
      this.pe.ht = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].ht;
      this.pe.bmi = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].bmi;
      this.pe.hc = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].hc;
      this.pe.muac = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].muac;
      this.pe.ac = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].ac;
      this.pe.descrpition = this.getPatientProfile.patient.medicalHistory[0].physicalExam[0].p_e_note;
    }
  },
  methods: {
    ...mapActions(["addRefer"]),
    addToRefer() {
      this.addRefer(this.refer).then(() => {
        this.$router.push("/referPrint");
        localStorage.setItem("refferedTo", this.refer.referedTo);
        localStorage.setItem("department", this.refer.department);
        localStorage.setItem("status", this.refer.status);
        localStorage.setItem("reason", this.refer.reason);
        localStorage.setItem("treatment", this.refer.treatment);
      });
    },

    
    showPreviewS() {
      this.showPreview = !this.showPreview;
    },
    startDates() {
      this.isAdd = false;
    },
    endDates() {
      this.sickLeaveObject.restDate = window
        .moment(this.sickLeaveObject.startDate, "YYYY-MM-DD")
        .toNow(true);
    },
   
  },
  computed: {
    ...mapGetters(["getPatientProfile"]),
    bmicalculate() {
      let weight = this.pe.wt;
      let height = this.pe.ht;
      return Number(weight / (height * height)).toFixed(2);
    },
  },
};
</script>

<style scoped>
.pageout {
  position: fixed;
  top: 200vh;
}
</style>
