<template>
  <div class="sheet padding-10mm">
    <b-card no-body style="margin: 0px" id="print" class="A4">
      <b-card-header class="Jdi-header" :small="small">
        <b-row class="Jdi-row">
          <b-col lg="3"> <img src="@/assets/images/amenlogo.png" /></b-col>
          <b-col lg="6">
            <h3 style="text-align: center; color: blue">ኣሜን ስፔሻሊቲ ክሊኒክ</h3>
            <h3
              style="
                text-align: center;
                color: red;
                font-family: Lucida Handwriting;
              "
            >
              Amen Speciality Clinic
            </h3>
          </b-col>
          <b-col lg="3"> </b-col>
        </b-row>
      </b-card-header>
      <hr style="border: 2px solid darkgray; margin-top: 0px" />
      <div class="personalInfo">
        <b-row style="margin=0px;padding:0px">
          <b-col lg="5"
            >Name:
            <strong class="result"
              >{{ getPatientProfile.patient.firstName }}
              {{ getPatientProfile.patient.middleName }}
              {{ getPatientProfile.patient.lastName }}</strong
            ></b-col
          ><b-col lg="4"
            >Age:
            <strong class="result"
              >{{ getPatientProfile.age }} old
            </strong></b-col
          ><b-col lg="3"
            >Sex:
            <strong class="result">{{
              getPatientProfile.patient.sex
            }}</strong></b-col
          >
        </b-row>
      </div>
      <b-row>
        <b-col lg="12">
          <p class="date">Checked Date {{ index1.date }}</p>
          <b-card-group>
            <b-card no-body header="Note" v-if="index1.drsNote">
              <ul
                v-for="drsNote in index1.drsNote"
                :key="drsNote.id"
                class="my-4"
              >
                <li class="physical mx-3">
                  C/C :
                  <span class="result">{{ drsNote.c_c }}</span>
                </li>
                <b-card-text>
                  <li class="physical mx-3">
                    HPI :
                    <span class="result">{{ drsNote.hpi }}</span>
                  </li>
                </b-card-text>
              </ul>
            </b-card>
          </b-card-group>
          <b-card
            header="Physical Exam"
            no-body
            v-if="index1.physicalExam.length != 0"
          >
            <ul v-for="pe in index1.physicalExam" :key="pe.id">
              <p>
                Reported Time
                <span class="result">{{ pe.time }}</span>
              </p>
              <b-row>
                <b-col lg="6" class="my-4">
                  <li class="physical mx-3">
                    BP : <span class="result">{{ pe.bp }}</span>
                  </li>
                  <li class="physical mx-3">
                    PR : <span class="result">{{ pe.pr }}</span>
                  </li>
                  <li class="physical mx-3">
                    RR= <span class="result">{{ pe.rr }}</span>
                  </li>
                  <li class="physical mx-3">
                    T : <span class="result">{{ pe.t }}</span>
                  </li>
                  <li class="physical mx-3">
                    Pso2 : <span class="result">{{ pe.pso2 }}</span>
                  </li>
                </b-col>
                <b-col lg="6" class="my-4">
                  <li class="physical mx-3">
                    HT : <span class="result">{{ pe.ht }}</span>
                  </li>
                  <li class="physical mx-3">
                    WT : <span class="result">{{ pe.wt }}</span>
                  </li>
                  <li class="physical mx-3">
                    BMI :
                    <span
                      class="result red"
                      :class="{ green: changeBmiColor }"
                      >{{ pe.bmi }}</span
                    >
                  </li>
                  <li class="physical mx-3">
                    AC : <span class="result">{{ pe.ac }}</span>
                  </li>
                  <li class="physical mx-3">
                    HC : <span class="result">{{ pe.hc }}</span>
                  </li>
                  <li class="physical mx-3">
                    MUAC : <span class="result">{{ pe.muac }}</span>
                  </li>
                </b-col>
                <b-col lg="12">
                  <hr />
                  <li class="physical mx-3">
                    P/E Note :<span class="result"> {{ pe.p_e_note }}</span>
                  </li>
                </b-col>
              </b-row>
            </ul>
          </b-card>

          
          <!-- this for lab Tests -->
          <b-card-group deck v-if="index1.labResults.length != 0">
            <b-card header="Laboratory Results">
              <b-list-group
                v-for="(labTest, index) in index1.labResults"
                :key="labTest.id"
              >
                <p>
                  Reported Time
                  <span class="result">{{
                    index1.labResults[index].time
                  }}</span>
                </p>
                <ul
                  v-for="item in index1.labResults[index].tests"
                  :key="item.id"
                >
                  <b-row>
                    <b-col lg="3" class="col-6">
                      <li>{{ item.name }}</li>
                    </b-col>
                    <b-col
                      v-if="getPatientProfile.patient.sex == 'Female'"
                      lg="9"
                      class="col-6 float-left"
                    >
                      <li class="result">
                        <span v-if="item.result != 'result not entered'">
                          Result</span
                        >
                        <strong>
                          {{ item.result }}
                          <span
                            v-if="
                              item.power > 0 &&
                              item.result != 'result not entered'
                            "
                            >x 10<sup>{{ item.power }}</sup>
                          </span></strong
                        >
                        {{ item.unit }}
                        <span
                          v-if="
                            item.typeOfResult === 'number' &&
                            item.result != 'result not entered'
                          "
                        >
                          Range [{{ item.minRangeFemale }} -
                          {{ item.maxRangeFemale }}]
                          <span v-if="item.power > 0">
                            x 10<sup>{{ item.power }}</sup></span
                          >
                        </span>
                      </li>
                    </b-col>
                    <b-col
                      v-if="getPatientProfile.patient.sex == 'Male'"
                      lg="9"
                      class="col-6 float-left"
                    >
                      <li class="result">
                        <span v-if="item.result == 'result not entered'">
                          Result</span
                        >
                        <strong
                          >{{ item.result }}
                          <span
                            v-if="
                              item.power > 0 &&
                              item.result != 'result not entered'
                            "
                            >x 10<sup>{{ item.power }}</sup>
                          </span></strong
                        >
                        <span
                          v-if="
                            item.typeOfResult === 'number' &&
                            item.result != 'result not entered'
                          "
                        >
                          Range [{{ item.minRangeMale }} -
                          {{ item.maxRangeMale }}]
                          <span v-if="item.power > 0">
                            x 10<sup>{{ item.power }}</sup></span
                          >
                        </span>
                      </li>
                    </b-col>
                  </b-row>
                </ul>
              </b-list-group>
              <small class="text-muted">Technician Labratory: Haftom</small>
            </b-card>
          </b-card-group>

          <!-- this for Radio test -->
          <b-card
            header="Radiology Results"
            v-if="index1.radioResults.length != 0"
          >
            <b-list-group
              v-for="radioTest in index1.radioResults"
              :key="radioTest.id"
            >
              <p>
                Reported Time
                <span class="result">{{ radioTest.time }}</span>
              </p>
              <b-row v-for="finding in radioTest.results" :key="finding.id">
                <b-col lg="6" class="col-6 my-2">
                  <h6>{{ finding.name }}</h6>
                </b-col>
                <b-row>
                  <b-col
                    lg="12"
                    class="col-6"
                    v-for="radioTe in finding.findings"
                    :key="radioTe.id"
                  >
                    <li :class="{ edited: radioTe.edited }">
                      # {{ radioTe.name }}
                    </li>
                  </b-col>
                  <li class="physical mx-3">
                    Conclusion :
                    <span class="result">{{ finding.conclusion }}</span>
                  </li>
                </b-row>
              </b-row>
            </b-list-group>
            <small class="text-muted">Radiologist: Ts04</small>
          </b-card>

          <b-card-group deck>
            <b-card header="Treatment" no-body v-if="index1.tx.length != 0">
              <ul v-for="treatment in index1.tx" :key="treatment.id">
                <div v-if="treatment.length != 0">
                  <p>
                    Reported Time
                    <span class="result">{{ treatment.time }}</span>
                  </p>
                  <li
                    class="physical mx-3"
                    v-for="treItem in treatment.treatments"
                    :key="treItem.id"
                  >
                    <span class="result">{{ treItem.name }}</span>
                  </li>
                </div>
              </ul>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>

      <b-card-footer>
        <b-row
          style="
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 0px;
            height: 17px;
          "
        >
          <b-col lg="2">
            <p style="font-size: 10px">Telephone: 0344412323</p></b-col
          >
          <b-col lg="8"
            ><h6
              style="
                text-align: center;
                color: blue;
                display: block;
                font-size: 10px;
              "
            >
              ኣድራሻ ዓዲ ሽምዱሕን ጎኒ ትራፊክ መብራህቲ ጎኒ ኣንበሳ ባንክ ን ኣንፈት ምዕራብ
            </h6>
            <h6
              style="
                text-align: center;
                color: blue;
                display: block;
                font-size: 10px;
              "
            >
              Address Adishmduhn trafic light near to Anbessa Bank
            </h6>
          </b-col>
          <b-col lg="2">
            <p style="font-size: 10px">Phone: +251968944000</p></b-col
          >
        </b-row>
        <hr />
        <b-row style="height: 10px; bottom: 0">
          <b-col><p style="font-size: 10px">JustCARE</p></b-col>
          <b-col><p style="font-size: 10px">Contact 0934337171</p></b-col>
          <b-col
            ><p style="font-size: 10px">
              Powered By JDI Technologies Plc
            </p></b-col
          >
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
//import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { createPdfFromHtml } from "../../print/logic";
export default {
  props: ["printType"],
  data() {
    return {
      labra: [],
      test: [],
      small: true,
      checkSelection: true,
      descrpition: "",
      physicalExam: [],
      index1: [],
      index: 2,
      isSend: false,
      fields: ["name", "id"],
    };
  },
  created() {
    this.index1 = this.getPatientProfile.patient.medicalHistory[this.printType];
  },
  methods: {
    ...mapActions(["fetchPatientsProfile"]),
    printRadio() {
      createPdfFromHtml(this.$refs.print.$el);
    },
    convertToarray(item) {
      console.log(item);
      let converted = null;
      if (item != "") {
        converted = item.split(",");
      }

      return converted;
    },
    sendRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        requestId: this.getRadioQueue[0]._id,
        tests: this.getRadioQueue[index].tests,
      };
      this.addRadioResult(labResult);
      this.isSend = true;
      this.$bvToast.show("toaster");
    },
    updateRadioResult(index) {
      const labResult = {
        patientId: this.getRadioQueue[0].patientId,
        requestId: this.getRadioQueue[0]._id,
        tests: this.getRadioQueue[index].tests,
      };
      this.updateRadioResults(labResult);
      this.isSend = true;
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile"]),
    checkRadioQueue() {
      return this.getRadioQueues != null ? true : false;
    },
    changeBmiColor() {
      return this.physicalExam.bmi > 18 && this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.leftSpace {
  padding-left: 50px;
}

.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  display: block;
}
.personalInfo {
  margin: 0px 5px;
}
li {
  list-style-type: none;
  display: block;
}
.edited {
  font-weight: bold;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.result {
  font-family: monospace;
  color: black;
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
