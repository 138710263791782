<template>
  <div >
    <span class="float-right" v-if="role == 'Doctor'">Physician <b> Dr {{ name }} (MD,Internist)</b> </span>
    <span class="text-center" v-if="role == 'Radiology'"
      >Physician Dr {{ name }} (MD,Radiologist)
    </span>
    <span class="text-center" v-if="role == 'Laboratory'"
      >Physician {{ name }} (MED,Laboratory Technologist)
    </span>
    <span class="text-center border-slip" v-if="role == 'Cashier'">Reciever Name: {{ name }} </span>
    <span class="ml-3 border-slip">Sign ___________________</span>
  </div>
</template>


<script>
export default {
  props: ["name"],
  data() {
    return {
      role: "",
    };
  },
  created() {
    this.role = localStorage.getItem("Role");
  },
};
</script>

<style scoped>
</style>