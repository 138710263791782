<template>
  <div style="margin-top: -80px; margin-left: -30px">
    <Header subTitle="Referral Paper" class="mx-3"></Header>
    <hr class="mx-5" />
    <PersonalName :personal="personal" class="mx-5"></PersonalName>
    <hr class="my-1 mx-5" />
    <b-row style="margin-left: 32px">
      <b-col sm="5"
        ><span class="italic">Referred to :</span>
        <b>{{ getRefer.referedTo }}</b></b-col
      >
      <b-col sm="4"
        ><span class="italic">Department :</span>
        <b>{{ getRefer.department }}</b>
      </b-col>
      <b-col sm="3" style="margin-left: -60px">
        <span class="italic">Status :</span>
        <b> <b></b> {{ getRefer.status }}</b>
      </b-col>
    </b-row>
    <hr class="my-1 mx-5" />
    <b-row class="mx-5" v-if="getRefer.cc != ''">
      <p>
        <b class="italic"> C/C : </b><b>{{ getRefer.cc }}</b>
      </p>
    </b-row>
    <b-row class="mx-5" v-if="getRefer.hpi != ''">
      <p>
        <b class="italic">HPI : </b> <b>{{ getRefer.hpi }}</b>
      </p>
    </b-row>

    <div class="row mx-5 mb-3">
      
      <p v-if="getRefer.pe" style="width: 100%"><b><span style="font-size:25px">P/E</span>  V/S :</b></p>
      
      <div class="col-sm-2 physical" v-if="getRefer.pe.bp != ''">
        <li class="mx-3">
          BP : <span class="result">{{ getRefer.pe.bp }}</span>
        </li>
      </div>
      <div class="col-sm-2  physical" v-if="getRefer.pe.pr != ''">
        <li class="mx-3">
          PR : <span class="result">{{ getRefer.pe.pr }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.rr != ''">
        <li class="mx-3">
          RR : <span class="result">{{ getRefer.pe.rr }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.to != ''">
        <li class="mx-3">
          T : <span class="result">{{ getRefer.pe.to }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.pso2 != ''">
        <li class="mx-3">
          Pso2 :
          <span class="result">{{ getRefer.pe.pso2 }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.ht != ''">
        <li class="mx-3">
          HT : <span class="result">{{ getRefer.pe.ht }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.wt != ''">
        <li class="mx-3">
          WT : <span class="result">{{ getRefer.pe.wt }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.bmi != ''">
        <li class="ml-3">
          BMI :
          <span class="result" :class="{ red: changeBmiColor }">{{
            getRefer.pe.bmi
          }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.ac != ''">
        <li class="mx-3">
          AC : <span class="result">{{ getRefer.pe.ac }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.hc != ''">
        <li class="mx-3">
          HC : <span class="result">{{ getRefer.pe.hc }}</span>
        </li>
      </div>
      <div class="col-sm-2 physical" v-if="getRefer.pe.muac != ''">
        <li class="mx-3">
          MUAC :
          <span class="result">{{ getRefer.pe.muac }}</span>
        </li>
      </div>
      <p v-if="getRefer.pe.descrpition != ''" style="width: 100%">
        <b class="italic"> Physical exam note:</b>
        {{ getRefer.pe.descrpition }}
      </p>
    </div>

    

    
    <!-- {{ totalModified }} -->
    <span v-if="totalModified.length > 0">
    <span v-for="haf in totalModified" :key="haf.id">
      <div class="d-flex" style="margin-bottom: -20px">
        <p class="mx-5"><b>Lab/Ix :</b></p>
        <span class="mx-5">
          By {{ haf.labTechnician }} (Med, Labroratory Technologist)
        </span>
      </div>

      <b-row class="">
        <!-- <p sm="12" class="mx-5"
          ><b>Lab/Ix :</b>
          <span class="mx-5"
            >By {{ haf.labTechnician }}(Med, Labroratory Technologist)</span
          ></p
        > -->

        <b-col
          sm="5"
          class="ml-5 col-sm-5"
          v-for="lab in haf.unique"
          :key="lab.id"
        >
          <!-- {{ lab }} -->
          <p>
            <b class="capitalize">{{ lab.category }}</b>
          </p>
          <!-- {{lab.tests}} -->
          
          <b-table
            :fields="fields"
            class="custom-border table my-1"
            :items="lab.tests"
          >
            <template v-slot:cell(range)="data">
              <span v-if="getPatientProfile.patient.sex == 'Male'">
                {{ data.item.minRangeMale }} - {{ data.item.maxRangeMale }}
                <span v-if="data.item.minRangeMale">{{ data.item.unit }}</span>
              </span>
              <span v-if="getPatientProfile.patient.sex == 'Female'">
                {{ data.item.minRangeFemale }} -
                {{ data.item.maxRangeFemale }}
                <span v-if="data.item.minRangeFemale">{{
                  data.item.unit
                }}</span>
              </span>
              <span v-else>-</span>
            </template>
            <template v-slot:cell(result)="data">
              {{ data.item.result }}
              <span
                v-if="
                  data.item.power && data.item.result != 'result not entered'
                "
                >{{ data.item.power }}</span
              >
              <span
                v-if="
                  data.item.unit && data.item.result != 'result not entered'
                "
                >{{ data.item.unit }}</span
              >
            </template>
            <!-- <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'name' || field.key === 'range'
                      ? '300px'
                      : '120px',
                }"
              />
            </template> -->
          </b-table>
        </b-col>
      </b-row>
    </span>
    </span>
    <b-row class="mx-5 mb-3">
      <span>
        <li
          v-for="(item, index) in convertToarray(getRefer.diagnosis)"
          :key="item"
          class="italic"
        >
          Diagnosis {{ index + 1 }} : <b>{{ item }}</b>
        </li>
      </span>
    </b-row>
    <b-row class="mx-5 my-3">
<b-card-text>
 <b class="italic" v-if="getRefer.treatment"> Treatments :</b>

  <p style="white-space: pre-line">{{getRefer.treatment}}</p>
</b-card-text>

      <!-- <span v-if="getRefer.txGiven">

        <ul v-for="item in getRefer.txGiven.treatments" :key="item">
          <li style="margin-bottom: -20px">
            <b>{{ item.name }}</b
            >,
          </li>
        </ul>
      </span>
      <p v-if="getRefer.txGiven">
        <b>{{ getRefer.txGiven.procedure }}</b>
      </p>

      <p
        class="w-100"
        style="margin-left: 40px"
        v-if="getRefer.txMgmt.length > 0"
      >
        <b class="italic">Treatment Descrpition :</b>
        <b>{{ getRefer.txMgmt[0].txMgmt }}</b>
      </p> -->
    </b-row>

    <b-row class="mx-5" v-if="getRefer.reason">
      <p>
        <b class="italic">Reason for referal :</b> <b>{{ getRefer.reason }}</b>
      </p>
    </b-row>
    <!-- <b-row class="mx-5 btm">
      <b-col sm="6">
        <p>
          Refered by :
          <b> Dr {{ firstName }} {{ middleName }} (MD, Internist)</b>
        </p>
      </b-col>
      <b-col sm="3">
        <p>Sign _________________________</p>
      </b-col>
      <b-col sm="3">
        <p>Date :{{ personal.date }}</p>
      </b-col>
    </b-row> -->

    <Footer :dataFooter="dataFooter" style="pageBreakAfter:always"></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PersonalName from "../../common/print/personalName";
import Header from "../../common/print/header";
import Footer from "../../common/print/footer";
import _ from "lodash";
export default {
  components: {
    PersonalName,
    Header,
    Footer,
  },
  //props: ["editMode"],
  data() {
    return {
      personal: {},
      dataFooter: {},
      modified: [],
      uniqueModified: [],
      labResult: [],
      totalModified: [],
      labra: [],
      test: [],
      small: true,
      checkSelection: true,
      descrpition: "",
      index1: [],
      physicalExam: [],
      firstName: localStorage.getItem("firstName"),
      middleName: localStorage.getItem("middleName"),
      isSend: false,
      fields: ["name", "result", "range"],
    };
  },
  created() {
    this.labResult = [];
      this.labResult.push(this.getRefer.labResult);
   
    

    this.personal = {
      mrn: this.getPatientProfile.patient.cardNo,
      fullName:
        this.getPatientProfile.patient.firstName +
        " " +
        this.getPatientProfile.patient.middleName +
        " " +
        this.getPatientProfile.patient.lastName,
      age: this.calcAge(this.getPatientProfile.patient.birthDate) + " " + "old",
      sex: this.getPatientProfile.patient.sex,
      dateCheck: this.getPatientProfile.patient.medicalHistory[0].date,
      date: window.moment().format("DD/MM/YY hh:mm:ss A"),
    };
    this.dataFooter = {
      date: window.moment().format("DD/MM/YY hh:mm:ss A"),
      printBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("middleName"),
      role: localStorage.getItem("Role"),
    };
    this.newSturctured();

    setTimeout(() => {
      window.print();
    }, 2000);
  },
  methods: {
    ...mapActions(["fetchPatientsProfile"]),

    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    convertToarray(item) {
      // console.log(item);
      let converted = null;
      if (item != "") {
        converted = item.split(",");
      }

      return converted;
    },
    newSturctured() {
      // this.$swal(this.getRefer.labResult.length)
      
      // for every request
      if (this.labResult.length > 0) {
        for (let i = 0; i < this.labResult.length; i++) {
          // create modified, unique
          this.modified = [];
          // this.totalModified = [];
          for (let j = 0; j < this.labResult[i].tests.length; j++) {
            console.log("B: " + i + " : " + this.modified);
            this.modified.push({
              category: this.labResult[i].tests[j].category,
              tests: [],
            });
          }
          this.uniqueModified = [];
          this.uniqueModified = _.uniqBy(this.modified, "category");
          // push to tests in uniqueModified
          for (let k = 0; k < this.labResult[i].tests.length; k++) {
            console.log("D: " + k);
            let categoryName = this.labResult[i].tests[k].category;
            for (let l = 0; l < this.uniqueModified.length; l++) {
              console.log("E: " + l);
              if (this.uniqueModified[l].category == categoryName) {
                this.uniqueModified[l].tests.push(this.labResult[i].tests[k]);
              }
            }
          }
          // add to total modified
          this.totalModified.push({
            unique: this.uniqueModified,
            time: this.labResult[i].time,
            labTechnician: this.labResult[i].labTechnician,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile", "getLabQueue", "getRefer"]),
    checkRadioQueue() {
      return this.getRadioQueues != null ? true : false;
    },
    changeBmiColor() {
      return this.physicalExam.bmi > 18 && this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
* {
  font-size: 20px;
}
.physical {
  display: block;
  border: 1px solid lightgray;
  padding: 3px;
  border: collapse;
}
.custom-border {
  border: 1px solid lightgray;
}
.leftSpace {
  padding-left: 50px;
}
.btm {
  margin-bottom: 20px;
}
.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  display: block;
}
.personalInfo {
  margin: 0px 5px;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 20px;
}
li {
  list-style-type: none;
  display: block;
}
.edited {
  font-weight: bold;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}
.result {
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
