import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router/indexRouter";
import VueRouter from "vue-router";
import VueSocketIO from "vue-socket.io";
import component from "@/view/common/main";
import store from "./store/indexStore";
import Vuelidate from "vuelidate";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import custom from "./assets/JDI.css";
import print from "./print/print.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import wysiwyg from "vue-wysiwyg";
import dashboard from "./common/dashboard.vue";
import todolist from "./view/admin/components/todolist.vue";
import dailyReport from "./view/admin/components/dailyTests.vue";
//import "~vue-wysiwyg/dist/vueWysiwyg.css";
import "vue-wysiwyg/dist/vueWysiwyg.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.component("JDI-Dashboard", dashboard);
Vue.component("JDI-Todolist", todolist);
Vue.component("JDI-Daily", dailyReport);

Vue.config.productionTip = true;

Vue.use(axios);
Vue.use(wysiwyg, {});
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(custom);
Vue.use(print);
window.html2canvas = require("html2canvas");
window.TableExport = require("tableexport");
window.saveAs = require("file-saver");
window.fs = require("fs");

Vue.use(
  new VueSocketIO({
    debug: true,
    // connection: "https://systems.jditechnology.com/",
     connection: "https://systems.jditechnology.com/",
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

window.moment = require("moment");
window.swal = require("sweetalert2/src/sweetalert2.js");
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import birthDatepicker from "vue-birth-datepicker";
import "vue-birth-datepicker/dist/vueBirthDatepicker.css";
Vue.component("birth-datepicker", birthDatepicker);

// axios.defaults.baseURL = "https://systems.jditechnology.com/";
 axios.defaults.baseURL = "https://systems.jditechnology.com/";

Vue.prototype.$http = axios;
let token = null;
if (localStorage.getItem("accessToken") != null)
  token = localStorage.getItem("accessToken");

if (token) {
  Vue.prototype.$http.defaults.headers["accessToken"] = token;
}

import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);

new Vue({
  store,
  router,
  component,
  render: (h) => h(App),
}).$mount("#app");
