<template>
  <div>
    <b-card no-body>
      <b-card-header :small="small">
        <b-row class="Jdi-row">
          <b-col lg="4">
            <p class="my-0">
              Name :<strong
                >{{ getPatientProfile.patient.firstName }}
                {{ getPatientProfile.patient.middleName }}
                {{ getPatientProfile.patient.lastName }}
              </strong>
            </p>
          </b-col>
          <b-col lg="4">
            <p class="my-0">
              Time : <strong>{{ getPatientProfile.patient.time }}</strong>
            </p>
          </b-col>
        </b-row>
      </b-card-header>

      <b-tabs
        card
        pills
        justified
        align="right"
        :small="small"
        active-nav-item-class="text-uppercase "
        class="JDI-tabs"
      >
        <b-tab no-body title="Labratory Tests">
          <LabSelection :isOutDoor="isOutDoor"></LabSelection>
        </b-tab>

        <b-tab no-body title="Radiology Tests">
          <RadiologyLabSelection :isOutDoor="isOutDoor" />
        </b-tab>

        <b-tab title="Treatment">
          <Treatment :isOutDoor="isOutDoor" />
        </b-tab>
      </b-tabs>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import Treatment from "./../common/treatmentSelection";
import RadiologyLabSelection from "./../common/radiologyLabSelection";

import LabSelection from "./../common/labSelection";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Treatment,
    RadiologyLabSelection,
    LabSelection,
  },
  data() {
    return {
      addNote: false,
      small: true,
      isOutDoor: true,
      createMode: true,
      editMode: false,
      form: {
        note: "",
        appointment: "",
      },
    };
  },

  created() {
    // this.$on("dataInserted", () => {
    //   this.fetchOutDoorPatient(this.getPatientProfile._id);
    // });
  },
  methods: {
    ...mapActions([
      "addDrsNote",
      "fetchPatientsData",
      "fetchPatientsProfile",
      "deletePatientData",
      "updatePatientData",
    ]),
  },
  computed: {
    ...mapGetters([
      "getPatientsData",
      "getPatientData",
      "getPatientProfile",
      "getOutDoorPatients",
      "getErrors",
    ]),
    checkSelection() {
      return this.getOutDoorPatients.length <= 0 ? true : false;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
  display: inline-block;
}
.JDI-tabs {
  padding: 0px;
  margin: 0px;
}
</style>
