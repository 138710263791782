<template>
  <div>
    <b-card no-body v-if="displayList">
      <b-card-header class="twa-header">
        <b-row class="twa-row">
          <b-col lg="2" class="my-1 col-3">
            <b-input-group size="sm">
              <b-form-select
                class="perPageSelect"
                v-model="per_page"
                id="sortBySelect"
                :options="pageOptions"
              >
              </b-form-select>
            </b-input-group>
          </b-col>

          <b-col sm="3" class="my-1 col-9">
            <b-form-group>
              <b-input-group size="sm" class="w-20">
                <b-form-input
                  class="float-right"
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to search..."
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          class="customTable table table-head-fixed"
          fixed
          @filtered="onFiltered"
          noCollapse="true"
          :current-page="currentPage"
          :per-page="per_page"
          :small="small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          responsive="sm"
          stacked="sm"
          :select-mode="selectMode"
          :items="getDiscountList.patientPaymentList"
          show-empty
          :fields="fields"
          :empty-text="getUsersList ? 'Empty Record' : 'Unable To Load Data'"
        >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'index' ||
                  field.key === 'actualPayment' ||
                  field.key === 'Unpaid' ||
                  field.key === 'total' ||
                  field.key === 'discountReason' ||
                  field.key === 'Actions'
                    ? '100px'
                    : '180px',
              }"
            />
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(Unpaid)="data">
            {{ data.item.total - data.item.actualPayment }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }}
            {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>
          <template v-slot:cell(Age)="data">
            {{ ageCa(data.item.birthDate) }}
          </template>
          <template v-slot:cell(Actions)="data">
            <span
              ><i
                style="font-size: 20px; color: #17a2b8"
                class="fa fa-wallet"
                @click="pay(data.item)"
              ></i>
            </span>
          </template>
        </b-table>
        <li class="float-left ml-3" v-if="role!='Cashier'">
          Total unpaid credit yet <b> {{ getDiscountList.totalUnpaid }} </b>Birr
        </li>

        <b-pagination
          class="float-right my-0"
          v-model="currentPage"
          :total-rows="total"
          :per-page="per_page"
          align="fill"
          size="sm"
          aria-controls="my-table"
        >
        </b-pagination>
      </b-card-body>
    </b-card>
    <b-modal
      class="mx-4"
      v-model="modalShow"
      :title="
        currentPatient.firstName +
        ' ' +
        currentPatient.middleName +
        ' ' +
        currentPatient.lastName
      "
      hide-footer
      centered
      size="sm"
    >
      <div class="body">
        <label>Paid</label>
        <b-input type="text" v-model="discount" @blur="calcPrice"></b-input>
        <label>Left</label>
        <b-input type="text" v-model="left"></b-input>
      </div>
      <div class="footer">
        <b-button
          @click="paymentDiscount"
          size="sm"
          class="float-right"
          variant="info"
        >
          <i class="fa fa-stamp"></i>
          Pay</b-button
        >
      </div>
    </b-modal>

    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="info" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    maxDate.setDate(now.getDate());
    return {
      total: "",
      small: true,
      modalShow: false,
      getUsersList: false,
      filter: null,
      errors: {},
      value_date: true,
      curentEditId: "",
      currentPatient: {},
      max: maxDate,
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      PatientId: [],
      selectMode: "single",
      selected: [],
      patientAddQueue: [],
      sortBy: "registeredDate",
      sortDesc: false,
      showInvalid: true,
      hideCompleted: true,
      currentPage: 1,
      per_page: 50,
      left: "",
      discount: "",
      pageOptions: [5, 10, 15, 50, "All"],
      genderList: ["Female", "Male"],
      role:'',
      fields: [
        { key: "index", label: "#" },
        {
          key: "fullName",
          label: "Name",
        },
        { key: "discountReason", label: "Reason" },
        { key: "total", label: "Price" },
        { key: "actualPayment", label: "Paid" },

        "Unpaid",
        "Actions",
      ],
    };
  },
  created() {
    this.count();
    this.role=localStorage.getItem("Role")
    this.discountRequestList().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "fetchPatients",
      "discountRequestList",
      "discountPay",
      "todayCreditPaidList",
      "fetchPatientsProfile",
      "fetchPatientsProfileNormalPatient",
    ]),
    async count() {
      this.total = await this.getDiscountList.length;
    },
    pay(patient) {
      this.currentPatient = patient;
      this.left = patient.total - patient.actualPayment;
      this.modalShow = true;
    },
    paymentDiscount() {
      const data = {
        left: this.left,
        discount: this.discount,
        paymentId: this.currentPatient._id,
        patientId: this.currentPatient.patientId,
        user:localStorage.getItem("userId"),
      };

      if (
        this.currentPatient.total - this.currentPatient.actualPayment <
        this.discount
      ) {
        this.$bvToast.toast("Paid Sucessfully!!!!!", {
          title: "Please check your price,",
          variant: "danger",
          solid: true,
        });
      } else {
        this.discountPay(data).then(() => {
          this.$bvToast.toast("Paid Sucessfully!!!!!", {
            title: "Paid Successfully",
            variant: "success",
            solid: true,
          });
          this.discount = "";
          this.modalShow = false;
          this.left = "";
          this.discountRequestList();
          this.todayCreditPaidList();
        });
      }
    },
    ageCa(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.total = filteredItems.length;
      this.currentPage = 1;
    },
    calcPrice() {
      if (this.discount != "") {
        this.left = this.left - this.discount;
      } else {
        this.left =
          this.currentPatient.total - this.currentPatient.actualPayment;
      }
    },
    // onRowSelected(items) {
    //   this.PatientId = items;
    //   this.check();
    //   this.fetchPatientsProfileNormalPatient(this.patientName).then(
    //     () => this.$router.push("/patientHistory"),
    //     localStorage.setItem("patientId", this.patientName)
    //   );
    // },
    check() {
      for (let i = 0; i < this.PatientId.length; i++) {
        this.patientName = this.PatientId[i]._id;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getPatientId",
      "getDiscountList",
      "getErrors",
      "getStatusMessage",
    ]),
    checkPrice() {
      return this.left < this.discount ? true : false;
    },

    filteredTasks() {
      return this.hideCompleted
        ? this.getDiscountList.filter((t) => !t.done)
        : this.tasks;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
li {
  list-style-type: none;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
