<template>
  <div>
    <Physician :name="dataFooter.printBy" class="float-right my-footer" />

    <div class="text-center main-footer">
      <div class="detals">
        <small class="mx-4">Printed : {{ dataFooter.date }}</small>
      </div>

      <span>
        <span style="font-family: Agency FB"
          ><span style="color: blue; font-size: 15px">Just</span
          ><span style="color: green; font-size: 15px"><b>Care</b></span></span
        >
        <small>
          &copy; {{ new Date().getFullYear() }} Powered by
          <b style="color: blue">JDI.</b></small
        ></span
      >
    </div>
  </div>
</template>

<script>
import Physician from "./physician";
export default {
  components: { Physician },
  props: ["dataFooter"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.main-footer {
  position: fixed;
  display: none;
  bottom: 0;
  margin-top: 30px;
  page-break-before: auto;
  width: 100%;
  background-color: white;
}
.my-footer {
  bottom: 0;
  right: 0;
  margin-right: 20px;
}
</style>
