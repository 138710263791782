<template>
  <div>
    <b-card no-body style="mrgin: 0px">
      <b-card-header class="Jdi-header" :small="small">
        <b-row class="Jdi-row">
          <b-col lg="4" class="my-0">
            <p class="my-0 mx-0">
              Name :
              <strong class="result"
                >{{ getTreatmentRecord[0].firstName }}
                {{ getTreatmentRecord[0].middleName }}
                {{ getTreatmentRecord[0].lastName }}
              </strong>
            </p>
          </b-col>

          <b-col lg="2" class="my-0" v-if="!getTreatmentRecord[0].isOutdoor">
            <p class="my-0 mx-0">
              Sex :
              <strong class="result my-0">{{
                getTreatmentRecord[0].sex
              }}</strong>
            </p>
          </b-col>
          <b-col lg="3" class="my-0" v-if="!getTreatmentRecord[0].isOutdoor">
            <p class="my-0 mx-0">
              Card No :
              <strong class="result">{{ getTreatmentRecord[0].cardNo }}</strong>
            </p>
          </b-col>
          <b-col lg="3">
            <p class="my-0 mx-0">
              Time :
              <strong class="result">{{ getTreatmentRecord[0].time }}</strong>
            </p>
          </b-col>
        </b-row>
      </b-card-header>
      <b-container fluid class="container">
        <div class="mt-3">
          <b-card-group deck>
            <b-row style="width: 100%">
              <!-- {{getTreatmentRecord}}
              <hr> -->
              <b-col lg="6" v-for="item in getTreatmentRecord" :key="item.id">
                <b-card
                  v-if="item.isPaidFor == true"
                  bg-variant="default"
                  text-variant="black"
                  :header="
                    item.datesToBeGiven[0] +
                    '-' +
                    item.datesToBeGiven[item.datesToBeGiven.length - 1]
                  "
                  class="card my-2"
                >
                  <small class="text-muted noMargin float-right"
                    ><p style="color: blue; font-weight: bold">
                      {{ item.requestType }}
                    </p></small
                  >
                  <!-- {{item}} -->
                  <span v-for="tests in item.tests" :key="tests.id">
                    <b-card-text v-if="tests"
                      ><p class="name" v-if="tests.name">
                        Name :
                        <span class="result"
                          >{{ tests.name }} {{ tests.doseOrder
                          }}{{ tests.unit }} </span
                        ><span v-if="tests.days && tests.perDay"
                          >{{ tests.days }} Days {{ tests.perDay }} Per
                          Day</span
                        >
                      </p>
                    </b-card-text>
                    <b-card-text
                      v-if="getPatientProfile.patient.medicalHistory.length > 0"
                    >
                      <span
                        v-if="
                          getPatientProfile.patient.medicalHistory[0].txMgmt
                            .length > 0
                        "
                      >
                        <li class="d-flex">
                          <strong>For out pharmacy</strong>
                        </li>
                        <li>
                          {{
                            getPatientProfile.patient.medicalHistory[0]
                              .txMgmt[0].txMgmt
                          }}
                        </li>
                      </span>
                    </b-card-text>
                  </span>
                  <b-card-text v-if="item.procedure"
                    >Procedure: <span class="result">{{ item.procedure }}</span>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-card-group>
        </div>
      </b-container>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      labra: [],
      test: [],
      small: true,
      treatmentGet: [],
      fields: ["index", "days", "perDay"],
    };
  },

  created() {},
  methods: {
    ...mapActions([
      "fetchLabQueueById",
      "fetchLabTestSingle",
      "fetchPatientsProfile",
      "getTreatmetRecord",
    ]),
  },
  computed: {
    ...mapGetters(["getTreatmentRecord", "getErrors", "getPatientProfile"]),
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
  display: inline-block;
}
.noMargin {
  margin-top: -15px;
  margin-right: -15px;
  display: block;
}
.title {
  font-size: 20px;
  font-weight: bold;
}
.card {
  padding: 0px;
}
input {
  height: 30px;
  border: 1px solid darkgray;
}
.result {
  font-family: monospace;
  color: black;
  padding-left: 8px;
}
.totalPrice {
  width: 200px;
}
.btnTotal {
  margin: 2px;
  padding: 2px;
}
</style>
