<template>
  <div>
   
    <div class="mt-3">
      <b-row>
        <b-col lg="9">
          <b-row class="Jdi-row" style="width: 100%" v-if="getRadioQueue">
            <b-col lg="4" v-for="(item, index) in getRadioQueue" :key="item.id">
              <div class="edit">
                <p class="noPadding">
                  Date
                  <span class="result">{{ getRadioQueue[index].date }}</span>
                </p>
                <p class="noPadding">
                  Request Time <span class="result">{{ item.time }}</span>
                </p>
                <p class="noPadding">
                  Status
                  <span
                    style="color: green"
                    class="result"
                    v-if="getRadioQueue[index].isResultSaved"
                  >
                    Result Arrived</span
                  >
                  <span
                    class="result"
                    v-if="
                      !getRadioQueue[index].isResultSaved &&
                      getRadioQueue[index].isPaidFor
                    "
                  >
                    Result Pending</span
                  >
                  <span class="result" v-if="!getRadioQueue[index].isPaidFor">
                    Payment Pending</span
                  >
                </p>

                <b-link
                  v-if="
                    !getRadioQueue[index].isResultSaved &&
                    !getRadioQueue[index].isPaidFor
                  "
                  class="noPadding float-right border"
                  style="
                    display: inline-block;
                    margin-right: 15px;
                    font-style-type: underline;
                  "
                  @click="sendRequest(index)"
                >
                  Edit</b-link
                >
                <b-link
                  class="noPadding float-right border"
                  style="
                    display: inline-block;
                    margin-right: 15px;
                    font-style-type: underline;
                  "
                  @click="showO = !showO"
                >
                  List</b-link
                >
                <b-link
                  v-if="getRadioQueue[index].isResultSaved"
                  class="noPadding float-right border"
                  style="display: inline-block"
                  @click="$emit('show-result')"
                >
                  Show Result</b-link
                >
                <b-link
                  v-if="
                    !getRadioQueue[index].isResultSaved &&
                    !getRadioQueue[index].isPaidFor
                  "
                  class="noPadding float-right border"
                  style="
                    display: inline-block;
                    margin-right: 15px;
                    font-style-type: underline;
                  "
                  @click="cancelRequest(getRadioQueue[index]._id)"
                >
                  Cancel</b-link
                >
              </div>
              <span v-if="showO && !checkPatient">
                <b-table
                  :fields="fieldsList"
                  :small="small"
                  :items="getRadioQueue[index].tests"
                >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                </b-table>
              </span>
            </b-col>
          </b-row>

          <b-row>
            <li style="width: 100%;margin:0px,padding:0px">
              <b-col
                lg="6"
                v-for="item in getRadioTests"
                :key="item.id"
                class="mx-2"
              >
                <hr />
                <p style="font-style: italic">
                  {{ item.category }}
                  <span class="mx-2" @click="item.checked = !item.checked">
                    <b-icon v-if="!item.checked" icon="chevron-down"></b-icon>
                    <b-icon
                      v-if="item.checked"
                      icon="chevron-compact-up"
                    ></b-icon>
                  </span>
                </p>
                <hr />
                <span v-if="item.checked">
                  <div v-for="test in item.tests" :key="test.id">
                    <b-form-checkbox-group v-model="selected">
                      <b-form-checkbox
                        v-if="test.status"
                        size="sm"
                        :testId="test._id"
                        :value="test"
                        :testPrice="test.price"
                        >{{ test.name }}</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </div>
                </span>
              </b-col>
            </li>
          </b-row>
        </b-col>
        <b-col lg="3" v-if="!checkSelection">
          <div class="selected">
            <b-table
              v-if="!checkSelection"
              :items="selected"
              :small="small"
              :fields="fields"
            >
              <template v-slot:cell(remove)="data">
                <b-icon
                  class="mx-2 icon"
                  variant="danger"
                  tooltip="removing from the request"
                  @click="haf(data.item._id)"
                  font-scale="1.5"
                  icon="backspace-fill"
                ></b-icon>
              </template>
            </b-table>
            <b-row class="mx-3">
              <li class="float-right">Total {{ total }}</li>
            </b-row>
            <span class="mx-2">
              <span v-if="!checkPatient && !createMode"> </span>
              <b-button variant="danger" size="sm" @click="cancel">
                Cancel
              </b-button>
            </span>
            <span v-if="!checkPatient">
              <b-button
                variant="info"
                class="mx-2"
                size="sm"
                v-if="!getRadioQueue[currentIndex].isPaidFor && !createMode"
                @click="updateTests"
              >
                Update
              </b-button>
            </span>

            <b-button
              v-if="createMode && !checkRole"
              class="btnPositive"
              size="sm"
              :disabled="click"
              @click="sendTests"
            >
              Send to Radiology
            </b-button>
            <b-button
              v-if="createMode && checkRole"
              class="btnPositive"
              size="sm"
              :disabled="click"
              @click="sendTestsCashier"
            >
              Send to Radiology
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["isOutDoor", "tabIndex"],
  data() {
    return {
      patientName: "",
      patientId: "",
      showO: false,
      radio: null,
      requestIndex: 0,
      showModal: false,
      testId: "",
      testPrice: "",
      createMode: true,
      role: localStorage.getItem("Role"),
      selected: [],
      currentIndex: 0,
      requestId: "",
      click: false,
      small: true,
      value: true,
      fields: ["name", "price", "remove"],
      fieldsList: ["index", "name"],
    };
  },
  created() {
    this.fetchRadioTests();
    const data = {
      patientId: this.getPatientProfile.patient._id,
      isOutdoor: this.isOutDoor,
    };
    this.fetchRadioQueueByIdResult(data);
    this.radio = this.getRadioQueue[0].tests;

    // this.fetchRadioQueueByIdResult(data);
    this.fetchRadioTests();
  },
  sockets: {
    radiologyResultIsSaved: function () {
      this.fetchRadioQueueByIdResult({
        patientId: this.getPatientProfile.patient._id,
        isOutdoor: this.isOutDoor,
      });
    },
    radioIsPaid: function () {
      this.fetchRadioQueueByIdResult({
        patientId: this.getPatientProfile.patient._id,
        isOutdoor: this.isOutDoor,
      });
    },
    newPaymentRequest: function () {
      this.fetchRadioQueueByIdResult({
        patientId: this.getPatientProfile.patient._id,
        isOutdoor: this.isOutDoor,
      });
    },
  },
  methods: {
    ...mapActions([
      "fetchRadioTests",
      "sendRadioTestRequest",
      "addRadioRequest",
      "sendRadioTestRequestToCashier",
      "fetchRadioQueueByIdResult",
      "updateRadioRequests",
      "deleteRadioRequests",
    ]),
    loadPrevioushistory() {
      this.selected = this.radio;
    },
    cancelRequest(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want delete this request? It can't be undone.",
          {
            size: "sm",
            title: "Confirmation",
            buttonSize: "sm",
            cancelTitle: "Exit",
            okTitle: "Confirm",
            okVariant: "danger",
            noCloseOnBackdrop: true,
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteRadioRequests(id).then(() => {
              this.fetchRadioQueueByIdResult(data);
              this.selected = [];
              this.createMode = true;
              this.click = false;
              this.$bvToast.toast(this.getErrors, {
                title: this.getErrors,
                variant: "danger",
                solid: true,
              });
            });
            const data = {
              patientId: this.getPatientProfile.patient._id,
              isOutdoor: this.isOutDoor,
            };
            this.fetchRadioQueueByIdResult(data);
          }
        });
    },
    cancel() {
      this.createMode = true;
      this.selected = [];
      this.click = false;
      // this.editMode = false;
    },
    showResult() {
      this.tabIndex = 0;
    },
    sendRequest(index) {
      this.fetchRadioQueueByIdResult({
        patientId: this.getPatientProfile.patient._id,
        isOutdoor: this.isOutDoor,
      }).then(() => {
        this.requestId = this.getRadioQueue[index]._id;
        this.currentIndex = index;
        this.createMode = false;
        this.selected = this.getRadioQueue[index].tests;
      });
    },
    sendTests() {
      const data = {
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        cardNo: this.getPatientProfile.patient.cardNo,
        sex: this.getPatientProfile.patient.sex,
        birthDate: this.getPatientProfile.patient.birthDate,
        requestId: this.requestId,
        total: this.total,
        tests: this.selected,
        isOutdoor: this.isOutDoor,
      };
      this.addRadioRequest(data).then(() => {
        this.createMode = false;
        const data = {
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        };

        this.fetchRadioQueueByIdResult(data);
        this.click = true;
        this.$emit("dataInserted");
      });
      this.$bvToast.show("toaster");
    },

    sendTestsCashier() {
      const data = {
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        sex: this.getPatientProfile.patient.sex,
        birthDate: this.getPatientProfile.patient.birthDate,
        total: this.total,
        tests: this.selected,
        isOutdoor: this.isOutDoor,
      };
      this.addRadioRequest(data).then(() => {
        this.createMode = false;
        const data = {
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        };

        this.fetchRadioQueueByIdResult(data);
        this.click = true;
        this.$emit("dataInserted");
      });
      this.$bvToast.show("toaster");
    },
    updateTests() {
      const data = {
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        cardNo: this.getPatientProfile.patient.cardNo,
        requestId: this.requestId,
        total: this.total,
        tests: this.selected,
        isOutdoor: this.isOutDoor,
      };
      this.updateRadioRequests(data).then(() => {
        const data = {
          patientId: this.getPatientProfile.patient._id,
          isOutdoor: this.isOutDoor,
        };
        this.$bvToast.toast(this.getErrors, {
          title: this.getErrors,
          variant: "success",
          solid: true,
        });
        this.fetchRadioQueueByIdResult(data);
        this.click = true;
        this.$emit("dataInserted");
      });
    },
    haf(y) {
      // this.$swal(y);
      this.selected = this.selected.filter((test) => test._id !== y);
    },
  },

  computed: {
    ...mapGetters([
      "getRadioTests",
      "getPatientProfile",
      "getRadioQueue",
      "getErrors",
      "getOutDoorPatients",
    ]),

    checkSelection() {
      return this.selected.length <= 0 ? true : false;
    },
    checkPatient() {
      return this.getRadioQueue.length <= 0 ? true : false;
    },
    checkRole() {
      return localStorage.getItem("Role") === "Cashier" ? true : false;
    },
    total() {
      return this.selected.reduce(
        (index, item) => index + Number(item.price),
        0
      );
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}
.labSelected {
  border: 1px solid darkgray;
  background-color: lightgray;
}

.noPadding {
  padding-left: 4px;
  margin: 0px;
}
.result {
  font-weight: bold;
}
.btn-load {
  margin: 20px;
}
</style>
