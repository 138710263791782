<template>
  <div>
    <b-card no-body v-if="displayCreate">
      <b-card-header style="margin: 0px">
        <b-row class="Jdi-row">
          <b-col lg="6" class="float-left" style="margin: 0px">
            <b-button variant="info" @click="ShowList" style="margin: 0px">
              Back to list
            </b-button>
          </b-col>
          <b-col lg="6" class="my-1 float-right"> </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="Jdi-Card-body">
        <b-form>
          <b-row class="mx-1" style="color: red" v-if="!checkValidation"
            >Please fill in all required fields{{ checkValidation }}</b-row
          >
          <b-row>
            <b-col lg="4" class="my-1">
              <label>First Name :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.firstName"
                type="text"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1">
              <label>Middle Name :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.middleName"
                type="text"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1">
              <label>Last Name :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.lastName"
                type="text"
                required
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" class="my-1">
              <label>Sex :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <b-form-select
                id="input-3"
                class="my-1"
                :options="genderList"
                size="sm"
                v-model="form.gender"
                value-field="_id"
                text-field="name"
                disabled-field="notEnabled"
              >
              </b-form-select>
            </b-col>
            <b-col lg="4" class="my-1">
              <label>Role :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <b-form-select
                id="input-3"
                class="my-1"
                :options="role"
                size="sm"
                v-model="form.role"
                value-field="_id"
                text-field="name"
                disabled-field="notEnabled"
              >
              </b-form-select>
            </b-col>
            <b-col lg="4" class="my-1">
              <label>Phone Number :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <b-form-input
                size="sm"
                class="my-1"
                id="input-1"
                v-model="form.phoneNumber"
                type="number"
                required
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" class="my-1">
              <label>Email :</label
              ><span
                class="text-muted mx-1"
                style="font-size: 12px; font-style: italic"
                >Optional</span
              >
              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.email"
                type="text"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1" v-if="createMode">
              <label>Username :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required</span
              >
              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.userName"
                type="text"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="4" class="my-1" v-if="createMode">
              <label>Password :</label
              ><span
                class="text-muted mx-1"
                style="
                  font-size: 12px;
                  font-style: italic;
                  color: red !important;
                "
                >Required,</span
              ><span
                class="text-muted"
                style="
                  color: gray !important;
                  font-size: 10px !important;
                  font-style: italic;
                "
                >Please use at least 6 digit.</span
              >
              <b-form-input
                class="my-1"
                id="input-1"
                size="sm"
                v-model="form.password"
                type="text"
                required
              ></b-form-input>
            </b-col>
            <b-col lg="12" class="my-1" style="padding-top: 25px">
              <b-button
                v-if="createMode"
                :disabled="checkValidation"
                class="my-1 mx-2 float-right btnPositive"
                type="submit"
                @click.prevent="Register"
                variant="primary"
              >
                <!-- {{ !checkValidation ? save : please }} -->
                Save
              </b-button>
              <b-button
                v-if="editMode"
                class="my-1 mx-2 float-right"
                type="submit"
                @click.prevent="updateUsers"
                variant="info"
              >
                Update
              </b-button>

              <b-button
                v-if="editMode"
                class="my-1 mx-2 float-right"
                type="submit"
                @click.prevent="cancel"
                variant="secondary"
              >
                Cancel
              </b-button>

              <b-button
                v-if="clear"
                :disabled="checkValidationClear"
                class="my-1 float-right"
                type="reset"
                @click="clearFields"
                variant="danger"
              >
                Clear
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>

    <b-card no-body v-if="displayList">
      <b-card-header class="twa-header">
        <b-row class="twa-row">
          <b-col lg="3" class="my-1 col-3">
            <b-input-group size="sm">
              <b-form-select
                v-model="per_page"
                id="sortBySelect"
                :options="pageOptions"
                class="w-20"
              >
              </b-form-select>
            </b-input-group>
          </b-col>

          <b-col lg="5" class="my-1 col-5">
            <b-form-group>
              <b-input-group size="sm" class="w-20">
                <b-form-input
                  class="float-right"
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to search..."
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="4" class="my-1 col-4">
            <b-button
              variant="primary"
              class="float-right"
              @click.prevent="ShowCreate"
              style="width: auto"
              size="sm"
            >
              Create New
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :current-page="currentpage"
          :per-page="per_page"
          :small="small"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive="sm"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getUsers"
          stacked="md"
          show-empty
          :fields="fields"
          :empty-text="getUsersList ? 'Empty Record' : 'Unable to load data'"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }}
            {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>

          <template v-slot:cell(status)="data">
            <toggle-button
              :value="data.item.status >= 1 ? true : false"
              color="#007bff"
              :labels="true"
              @change="updateStatus(data.item._id)"
            />
          </template>

          <template v-slot:cell(Action)="data">
            <b-link @click="editUser(data.index)" class="text-primary">
              <b-icon
                class="lgFont mx-2"
                icon="pen"
                variant="primary"
                font-scale="1.5"
              ></b-icon>
            </b-link>
            <b-link @click="resetPassword(data.item._id)" class="text-danger">
              <b-icon
                class="lgFont"
                icon="unlock-fill"
                variant="danger"
                font-scale="1.5"
              ></b-icon>
            </b-link>
          </template>

          <template v-slot:table-caption>
            <b-pagination
              class="float-right my-1"
              v-model="currentpage"
              :total-rows="getUsers.length"
              :per-page="per_page"
              align="fill"
              size="sm"
              aria-controls="my-table"
            >
            </b-pagination> </template
        ></b-table>
      </b-card-body>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="primary" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import role from "../../constants/about";
export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      clear: true,
      save: "Save",
      please: "Fill all required fields",
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortBy: "fullName",
      sortDesc: false,
      filter: "",
      currentpage: 1,
      testnow: "",
      per_page: 10,
      role: role.role,
      pageOptions: [5, 10, 15, "All"],
      genderList: ["Female", "Male"],
      fields: [
        { key: "index", label: "#", sortable: false },
        {
          key: "fullName",
          label: "Full Name",
          sortable: true,
        },
        { key: "sex", label: "Sex" },
        { key: "role", label: "Role" },
        { key: "email", label: "email" },
        { key: "username", label: "Username" },
        "Status",
        "Action",
      ],

      form: {
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        role: "",
        phoneNumber: "",
        userName: "",
        password: "",
      },
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions([
      "addUser",
      "fetchUsers",
      "updateUserData",
      "updateUserStatus",
      "resetUserPassword",
    ]),
    cancel() {
      (this.editMode = false), (this.createMode = true), this.clearFields();
    },
    clearFields() {
      this.form.firstName = "";
      this.curentEditId = "";
      this.form.middleName = "";
      this.form.lastName = "";
      this.form.gender = "";
      this.form.role = "";
      (this.form.email = ""), (this.form.phoneNumber = "");
      this.form.userName = "";
      this.form.password = "";
      this.editMode = false;
      this.createMode = true;
    },
    updateStatus(id) {
      this.updateUserStatus(id).then(() => {
        this.$emit("dataInserted");

        this.$bvToast.show("toaster");
        this.fetchUsers();
      });
    },
    resetPassword(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to reset?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((reset) => {
          if (reset) {
            this.resetUserPassword(id);
            this.$bvToast.show("toaster");
          }
        });
    },
    updateUsers() {
      const userUpdate = {
        firstName: this.form.firstName,
        middleName: this.form.middleName,
        lastName: this.form.lastName,
        role: this.form.role,
        sex: this.form.gender,
        username: this.form.userName,
        password: this.form.password,
        email: this.form.email,
        phone: this.form.phoneNumber,
        id: this.curentEditId,
      };
      this.updateUserData(userUpdate).then(() => {
        this.fetchUsers();
        this.clearFields();
      });

      this.$bvToast.show("toaster");
    },
    editUser(index) {
      this.form.firstName = this.getUsers[index].firstName;
      this.curentEditId = this.getUsers[index]._id;
      this.form.middleName = this.getUsers[index].middleName;
      this.form.lastName = this.getUsers[index].lastName;
      this.form.gender = this.getUsers[index].sex;
      this.form.role = this.getUsers[index].role;
      this.form.phoneNumber = this.getUsers[index].phone;
      this.form.userName = this.getUsers[index].username;
      this.form.email = this.getUsers[index].email;
      this.editMode = true;
      this.createMode = false;
      this.displayList = false;
      (this.clear = false), (this.displayCreate = true);
    },

    deleteUsers(user) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((deleteU) => {
          if (deleteU) {
            this.deleteUser(user);
            this.$bvToast.toast("Deleted Sucessfully!!!!!", {
              title: "Deleted",
              variant: "danger",
              solid: true,
            });
          }
        });
    },

    onRowSelected(items) {
      this.selected = items;
    },
    ShowList() {
      this.displayList = true;
      this.displayCreate = false;
    },
    ShowCreate() {
      this.displayList = false;
      this.displayCreate = true;
      this.editMode = false;
      this.createMode = true;
      this.clearFields();
    },
    Register() {
      const userData = {
        firstName: this.form.firstName,
        middleName: this.form.middleName,
        lastName: this.form.lastName,
        role: this.form.role,
        sex: this.form.gender,
        email: this.form.email,
        phone: this.form.phoneNumber,
        username: this.form.userName,
        password: this.form.password,
      };

      this.addUser(userData).then(() => {
        this.clearFields();
        this.fetchUsers();
      });
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getUsers", "getErrors"]),
    checkValidation() {
      return this.form.firstName.length <= 0 ||
        this.form.middleName.length <= 0 ||
        this.form.lastName.length <= 0 ||
        this.form.gender.length <= 0 ||
        this.form.role.length <= 0 ||
        this.form.userName.length <= 0 ||
        this.form.password.length <= 0 ||
        this.form.phoneNumber.length <= 0
        ? true
        : false;
    },
    checkValidationClear() {
      return this.form.firstName != null ||
        this.form.middleName != null ||
        this.form.lastName != null ||
        this.form.gender != null ||
        this.form.role != null ||
        this.form.userName != null ||
        this.form.password != null ||
        this.form.phoneNumber != null
        ? false
        : true;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip:hover .tooltipText {
  visibility: visible;
}
.tooltip .tooltipText {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 6px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
.clearBtn {
  border: 1px solid #007bff;
}
</style>
