import axios from "axios";

const state = {
  radioTests: [],
  radioTest: [],
  radioResult: [],
  singleRadioTest: [],
};

const mutations = {
  setRadioTests: (state, radioTests) => (state.radioTests = radioTests),
  singleRadioTestList: (state, singleRadioTest) =>
    (state.singleRadioTest = singleRadioTest),
  setRadioTest: (state, radioTest) => (state.radioTest = radioTest),
  pushRadioTest: (state, radioTest) => state.radioTest.unshift(radioTest),
  pushRadioResult: (state, radioResult) =>
    state.radioResult.unshift(radioResult),
  deleteRadioTest: (state, id) =>
    (state.radioTests = state.radioTests.filter((user) => user.id !== id)),
  updateRadioTest(state, payload) {
    const object = state.radioTests.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
  updateRadioResult(state, payload) {
    const object = state.radioResult.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
};

const getters = {
  getRadioTests: (state) => {
    return state.radioTests;
  },
  getRadioTest: (state) => {
    return state.radioTest;
  },
  getSingleRadioTest: (state) => {
    return state.singleRadioTest;
  },
};

const actions = {
  async fetchRadioTests({ commit }) {
    await axios
      .get("/radiologyTests")
      .then((response) => {
        commit("setRadioTests", response.data.radiologyTests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async fetchRadioTestsAll({ commit }) {
    await axios
      .get("/radiologyTests/all")
      .then((response) => {
        commit("setRadioTests", response.data.radiologyTests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchRadioTest({ commit }, id) {
    await axios
      .get("/radiologyTest" + `/${id}`)
      .then((res) => {
        commit("singleRadioTestList", res.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async changeRadioTestStatus({ commit }, Id) {
    await axios
      .patch("/radiologyTest/toggleStatus" + `/${Id}`)
      .then((response) => {
        commit("updateRadioTest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.resolve();
      });
  },

  async addRadioTest({ commit }, object) {
    await axios
      .post("/radiologyTest", object)
      .then((response) => {
        commit("pushRadioTest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async addRadioRequest({ commit }, object) {
    await axios
      .post("/radiologyRequest", object)
      .then((response) => {
        commit("pushRadioTest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  // for post lab test request
  async sendLabTestRequest({ commit }, object) {
    await axios
      .post("/labRequest", object)
      .then((response) => {
        commit("pushLabTest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  // for lab result send
  async addRadioResult({ commit }, object) {
    await axios
      .post("/radiologyResult", object)
      .then((response) => {
        commit("pushRadioResult", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async updateRadioResults({ commit }, object) {
    await axios
      .patch("/radiologyResult/" + `${object.requestId}`, object)
      .then((response) => {
        commit("setErrors", response.data.message);
        // commit("updateRadioResult", response.data);

        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updateRadiologyTest({ commit }, object) {
    await axios
      .put("/radiologyTest" + `/${object.id}`, object)
      .then((response) => {
        commit("updateRadioTest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async deleteLabTest({ commit }, id) {
    await axios.delete("/user" + `/${id}`).then(() => {
      commit("deleteLabTest", id);
      return Promise.resolve();
    });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
