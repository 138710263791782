<template>
  <div>
    <b-card nobody style="margin: -20px">
      <b-card-body nobody style="margin: -20px">
        <b-link @click="addToSick" class="float-right"
          ><i style="color: blue" class="fa fa-print"></i
        ></b-link>
        <b-container>
          <b-row class="mx-2">
            <b-col class="col-12">
              <label >Diagnosis</label>
              <b-textarea
                id="textarea"
                v-model="sickLeaveObject.diagnosis"
                placeholder="Diagnosis"
                rows="3"
                max-rows="6"
              ></b-textarea>
            </b-col>
            <b-col>
              <b-col lg="12" class="col-12">
                <label>Recommendation</label>
                <b-textarea
                  id="textarea"
                  v-model="sickLeaveObject.recommendation"
                  rows="3"
                  max-rows="6"
                ></b-textarea>
              </b-col>
              <b-row>
                <b-col>
                  <label>Start Date</label>
                  <b-form-input
                    type="date"
                    v-model="sickLeaveObject.startDate"
                    @change="startDates"
                    placeholder="Enter date"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <label>End Date</label>
                  <b-form-input
                    type="date"
                    :disabled="isAdd"
                    v-model="sickLeaveObject.endDate"
                    @change="endDates"
                    placeholder="Enter your name"
                  ></b-form-input
                ></b-col>
                <b-col lg="3">
                  <label>Rest Date</label>
                  <b-form-input
                    type="text"
                    disabled
                    v-model="sickLeaveObject.restDate"
                  ></b-form-input>
                </b-col>
                <b-col lg="3">
                  <label>Follow up</label>
                  <b-form-input
                    type="date"
                    v-model="sickLeaveObject.followup"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      title: "Labratory Investgation",
      sickLeaveObject: {
        startDate: "",
        endDate: "",
        followUp: "",
        restDate: "",
        recommendation: "",
        diagnosis: [],
      },
      showPreview: false,
      isAdd: true,
    };
  },
  created() {
    if (this.sickLeaveObject.diagnosis.length <= 0) {
      this.sickLeaveObject.diagnosis =
        "Sorry There is no DIAGNOSIS for this patient";
    }
    this.sickLeaveObject.recommendation = localStorage.getItem("reco") || "";
    for (
      let i = 0;
      i < this.getPatientProfile.patient.medicalHistory.length;
      i++
    ) {
      for (
        let j = 0;
        j < this.getPatientProfile.patient.medicalHistory[i].dx.length;
        j++
      ) {
        this.sickLeaveObject.diagnosis = this.getPatientProfile.patient.medicalHistory[
          i
        ].dx[j].dx;
      }
    }
  },
  methods: {
    ...mapActions(["addSickLeave"]),
    addToSick() {
      this.addSickLeave(this.sickLeaveObject).then(() => {
        this.$router.push("/sickLeavePrint");
        localStorage.setItem("reco", this.sickLeaveObject.recommendation);
      });
    },

    showPreviewS() {
      this.showPreview = !this.showPreview;
    },
    startDates() {
      this.isAdd = false;
    },
    endDates() {
      var b = window.moment(this.sickLeaveObject.startDate);
      var a = window.moment(this.sickLeaveObject.endDate);
      // 1
      this.sickLeaveObject.restDate = a.diff(b, "days") + " " + "Days";
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile"]),
  },
};
</script>

<style scoped>
.pageout {
  position: fixed;
  top: 200vh;
}
</style>
