var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.displayList)?_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"text-center twa-body"},[_c('b-row',[_c('b-col',{staticClass:"my-1 col-3 ml-1",attrs:{"sm":"2"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-select',{staticClass:"perPageSelect",attrs:{"id":"sortBySelect","options":_vm.pageOptions},model:{value:(_vm.per_page),callback:function ($$v) {_vm.per_page=$$v},expression:"per_page"}})],1)],1),_c('b-col',{staticClass:"col-8 my-1",attrs:{"sm":"3"}},[_c('b-form-input',{staticClass:"float-right my-0",attrs:{"size":"sm","type":"search","id":"filterInput","placeholder":"Type to search..."},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('b-table',{ref:"selectableTable",attrs:{"selectable":"","noCollapse":"true","current-page":_vm.curentpage,"per-page":_vm.per_page,"small":_vm.small,"filter":_vm.filter,"stacked":"sm","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"tbody-tr-class":_vm.rowClass,"responsive":"sm","select-mode":_vm.selectMode,"items":_vm.getCashierQueues,"show-empty":"","fields":_vm.fields,"empty-text":_vm.getUsersList
            ? 'There is no patient in queue yet.'
            : 'Unable to load data.'},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(fullName)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.middleName)+" "+_vm._s(data.item.lastName)+" ")]}},{key:"cell(patient)",fn:function(data){return [(data.item.isOutdoor == true)?_c('span',[_vm._v("Outdoor")]):_c('span',[_vm._v("Normal")])]}},{key:"cell(Price)",fn:function(data){return [(data.item.discount)?_c('p',[_vm._v(_vm._s(data.item.actualPayment))]):_vm._e(),(!data.item.discount)?_c('p',[_vm._v(_vm._s(data.item.total))]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [(data.item.requestDiscount == true)?_c('span',[(data.item.discount == true)?_c('p',{staticClass:"confirmed"},[_vm._v(" Confirmed ")]):_vm._e(),(data.item.rejected == true)?_c('p',{staticClass:"danger"},[_vm._v("Rejected")]):_vm._e(),(
                data.item.discount == false && data.item.rejected == false
              )?_c('p',{staticClass:"warning"},[_vm._v(" Pending ")]):_vm._e()]):_vm._e(),(data.item.requestDiscount == false)?_c('span',[_vm._v(" No ")]):_vm._e()]}},{key:"cell(Actions)",fn:function(data){return [(data.item.requestDiscount)?_c('span',[(data.item.discount == true || data.item.rejected == true)?_c('b-link',{staticClass:"text-primary",on:{"click":function($event){return _vm.pay(data.item._id)}}},[_c('b-icon',{attrs:{"icon":"credit-card","variant":"primary"}}),_vm._v("Pay ")],1):_vm._e(),(
                data.item.requestDiscount == true &&
                data.item.discount == false &&
                data.item.rejected == false
              )?_c('p',[_vm._v(" Pending Request ")]):_vm._e()],1):_vm._e(),(!data.item.requestDiscount)?_c('span',[(!data.item.isPaidForAll)?_c('b-link',{staticClass:"text-primary",on:{"click":function($event){return _vm.pay(data.item._id)}}},[_c('b-icon',{attrs:{"icon":"credit-card","font-scale":"1.5","variant":"primary"}}),_vm._v(" Pay ")],1):_vm._e(),(data.item.isPaidForAll)?_c('span',{staticClass:"confirmed"},[_c('b-icon',{attrs:{"icon":"check-circle"}}),_vm._v(" Paid")],1):_vm._e()],1):_vm._e()]}},{key:"table-caption",fn:function(){return [_c('b-pagination',{staticClass:"float-right my-0",attrs:{"total-rows":_vm.getCashierQueues.length,"per-page":_vm.per_page,"align":"fill","size":"sm","aria-controls":"my-table"},model:{value:(_vm.curentpage),callback:function ($$v) {_vm.curentpage=$$v},expression:"curentpage"}})]},proxy:true}],null,false,2639048546)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }