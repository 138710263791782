<template>
  <div>
    <div class="mt-3">
      <b-row class="Jdi-row mt-3">
        <!-- <b-col lg="4" class="float-right" v-for="item in haftom" :key="item.id">
          <b-card-group deck>
            <b-card :header="item.catagory">
              <b-form-checkbox-group v-model="selected">
                <b-row>
                  <b-col lg="">
                    <b-form-checkbox :id="co._id" size="sm" :value="co.name">{{
                      co.name
                    }}</b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form-checkbox-group>
            </b-card>
          </b-card-group>
        </b-col>
        {{ getTestsData }} -->
        <b-col lg="9">
          <b-row>
            <b-col v-for="item in getRadioTests" :key="item.id" class="mx-4">
              <p>{{ item.category }}</p>
              <div v-for="test in item.tests" :key="test.id">
                <b-form-checkbox-group v-model="selected">
                  <b-form-checkbox
                    size="sm"
                    @change="clicked(test._id)"
                    :testId="test._id"
                    :value="test"
                    :testPrice="test.price"
                    >{{ test.name }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" v-if="!checkSelection">
          <div class="mt-3 labSelected">
            <ul>
              <p v-if="checkSelection">You Are Not Selected Yet Nigga</p>
              <b-table
                v-if="!checkSelection"
                :items="selected"
                :small="small"
                :fields="fields"
              ></b-table>
              <li v-if="checkRole">{{ total }}</li>
              <b-button variant="info" :disabled="click" @click="sendTests">
                Send to Radiology
              </b-button>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- {{ getRadioQueue }}
    {{ getRadioTests }}-->
    <!-- <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="primary" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast> -->
  </div>
</template>

<script>
//import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      patientName: "",
      patientId: "",
      testId: "",
      testPrice: "",
      selected: [],
      click: false,
      small: true,
      value: true,
      fields: ["name", "price"],
    };
  },
  created() {
    this.fetchRadioTests();
  },
  methods: {
    ...mapActions([
      "fetchRadioTests",
      "sendRadioTestRequest",
      "addRadioRequest",
      "sendRadioTestRequestToCashier",
    ]),
    sendTests() {
      const data = {
        firstName: this.getOutDoorPatients.firstName,
        middleName: this.getOutDoorPatients.middleName,
        lastName: this.getOutDoorPatients.lastName,
        patientId: this.getOutDoorPatients._id,
        isOutdoor: true,
        total: this.total,
        tests: this.selected,
      };
      this.addRadioRequest(data).then(() => {
        this.$bvToast.toast("Note Added", {
          title: "Radiology request sent successfully.",
          variant: "sucesss",
          solid: true,
        });
        this.click = true;
        this.$emit("dataInserted");
      });
    },
  },

  computed: {
    ...mapGetters([
      "getRadioTests",
      "getOutDoorPatients",
      "getRadioQueue",
      "getErrors",
    ]),

    checkSelection() {
      return this.selected.length <= 0 ? true : false;
    },
    checkRole() {
      return localStorage.getItem("Role") === "Cashier" ? true : false;
    },
    total() {
      return this.selected.reduce(
        (index, item) => index + Number(item.price),
        0
      );
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}
.labSelected {
  border: 1px solid darkgray;
  background-color: lightgray;
}
</style>
