import axios from "axios";
import router from "../router/indexRouter";
const state = {
  patients: [],
  patient: [],
  drQueue: [],
  drQueues: [],
  labQueue: [],
  radioQueue: [],
  radioQueues: [],
  labQueues: [],
  cashierQueues: [],
  cashierQueue: [],
  patientId: {},
  age: "",
  payment: [],
};

const mutations = {
  setPayment: (state, payment) => (state.payment = payment),
  setAge: (state, age) => (state.age = age),
  setPatients: (state, patients) => (state.patients = patients),
  setLabQueue: (state, labQueue) => (state.labQueue = labQueue),
  setLabQueues: (state, labQueues) => (state.labQueues = labQueues),
  setPatientId: (state, patientId) => (state.patientId = patientId),

  setCashierQueue: (state, cashierQueue) => (state.cashierQueue = cashierQueue),
  setCashierQueues: (state, cashierQueues) =>
    (state.cashierQueues = cashierQueues),

  setPatient: (state, patient) => (state.patient = patient),
  setRadioQueues: (state, radioQueues) => (state.radioQueues = radioQueues),
  setRadioQueue: (state, radioQueue) => (state.radioQueue = radioQueue),
  setDrQueue: (state, drQueue) => (state.drQueue = drQueue),

  setDrQueues: (state, drQueues) => (state.drQueues = drQueues),

  pushDrQueue: (state, patientInstance) =>
    state.drQueues.unshift(patientInstance),

  pushLabQueue: (state, labInstance) => state.labQueue.unshift(labInstance),
  pushRadioQueue: (state, radioInstance) =>
    state.radioQueue.unshift(radioInstance),
  pushPatient: (state, patientInstance) =>
    state.patients.unshift(patientInstance),
  deletePatient: (state, id) =>
    (state.patients = state.patients.filter((patient) => patient.id !== id)),
  updatePatient(state, payload) {
    const object = state.patients.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
  updateRadioRequest(state, payload) {
    const object = state.radioQueue.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
  updateLabRequest(state, payload) {
    const object = state.labQueue.find((item) => item.id === payload.id);
    Object.assign(object, payload);
  },
};

const getters = {
  getPatients: (state) => {
    return state.patients;
  },
  getPatientProfile: (state) => {
    return state.patient;
  },
  getDrQueues: (state) => {
    return state.drQueues;
  },
  getAge: (state) => {
    return state.age;
  },
  getDrQueue: (state) => {
    return state.drQueue;
  },
  getLabQueue: (state) => {
    return state.labQueue;
  },
  getLabQueues: (state) => {
    return state.labQueues;
  },
  getRadioQueues: (state) => {
    return state.radioQueues;
  },
  getRadioQueue: (state) => {
    return state.radioQueue;
  },

  getCashierQueue: (state) => {
    return state.getCashierQueue;
  },
  getCashierQueues: (state) => {
    return state.cashierQueues;
  },
  getPatientId: (state) => {
    return state.patientId;
  },
  getSinglePayment: (state) => {
    return state.payment;
  },
};

const actions = {
  async fetchPatients({ commit },page) {
    let currentPage;
    console.log(page)
    if(page){
      currentPage=page.page
    }else{
      currentPage= 1
    }
    await axios
      .get(page ? "/patients"+`?page=${currentPage}&name=${page.name}`:"/patients"+`?page=${currentPage}`)
      .then((response) => {
        commit("setPatients", response.data);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        if (error.response.status == 401) {
          localStorage.removeItem("accessToken");
          router.replace("/login");
        }
        return Promise.reject();
      });
  },

  async fetchLabQueue({ commit }) {
    await axios
      .get("/labQueue")
      .then((response) => {
        commit("setLabQueues", response.data.queue);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async requestLabByDate({ commit }, date) {
    await axios
      .get("/labQueue/" + `${date}`)
      .then((response) => {
        commit("setLabQueues", response.data.queue);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchRadioQueue({ commit }) {
    await axios
      .get("/radioQueue")
      .then((response) => {
        commit("setRadioQueues", response.data.queue);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async addRadioQueue({ commit }, object) {
    await axios
      .post("/RadioRequest", object)
      .then((response) => {
        commit("pushRadioQueue", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async fetchRadioQueueById({ commit }, object) {
    await axios
      .get(
        "/radiologyRequest/" + `${object.patientId}` + `/${object.isOutdoor}`
      )
      .then((response) => {
        commit("setRadioQueue", response.data.radiologyRequests);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async fetchCashierQueues({ commit }) {
    await axios
      .get("/patientPaymentList")
      .then((response) => {
        commit("setCashierQueues", response.data.todaysPayments);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async singleCashierQueue({ commit }) {
    await axios
      .get("/labQueue/61e91a51891ed40374408b4a")
      .then((response) => {
        commit("setCashierQueue", response.data.labOrder);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchLabQueueById({ commit }, object) {
    await axios
      .get(
        "/labRequest/" +
          `${object.patientId}/` +
          `${object.isOutdoor}/` +
          `${object.date}`
      )
      .then((response) => {
        commit("setLabQueue", response.data.labRequest);
        commit("setAge", response.data.age);
        console.log(response.data.age)
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },
  async fetchRadioQueueByIdResult({ commit }, object) {
    await axios
      .get(
        "/radiologyRequest/" + `${object.patientId}` + `/${object.isOutdoor}`
      )
      .then((response) => {
        commit("setRadioQueue", response.data.radiologyRequests);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },
  // async fetchPatientsProfile({ commit }, id) {
  //   await axios.get("/patient" + `/${id}`).then((response) => {
  //     commit("setPatient", response.data.patient);
  //     return Promise.resolve();
  //   });
  // },
  async fetchPatientsProfile({ commit }, object) {
    console.log(object)
    await axios
      .get("/medicalHistory" + `/${object.patientId}` + `/${object.isOutdoor}`)
      .then((response) => {
        commit("setPatient", response.data);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },
  async fetchPatientsProfileNormalPatient({ commit }, id) {
    await axios
      .get("/medicalHistory" + `/${id}`)
      .then((response) => {
        commit("setPatient", response.data);
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          commit("setErrors", error.response.data.error);
        } else {
          commit("setErrors", "Network error,check your server connection");
        }
        return Promise.reject();
      });
  },

  async getPaymentData({ commit }, id) {
    await axios
      .get("/patientPayment" + `/${id}`)
      .then((response) => {
        commit("setPayment", response.data.patientPayment);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  storePatientPayment({ commit }, object) {
    commit("setPayment", object);
  },
  async fetchDrQueues({ commit }) {
    await axios
      .get("/doctorsQueue")
      .then((response) => {
        commit("setDrQueues", response.data.queue);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchDrQueue({ commit }) {
    await axios
      .get("/doctorsQueue")
      .then((response) => {
        commit("setDrQueue", response.data.queue);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async singleUser({ commit }, id) {
    await axios
      .get("/patient" + `/${id}`)
      .then((response) => {
        commit("setPatient", response.data.patient);

        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async addDrsNote({ commit }, object) {
    await axios
      .post("/doctorsNote", object)
      .then((response) => {
        commit("pushPatient", response.data);
      })
      .catch(() => {
        console.log("in error passed")
        // commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  //
  async addPatient({ commit }, object) {
    await axios
      .post("/patient", object)
      .then((response) => {
        commit("pushPatient", response.data);
        commit("setPatientId", response.data.patient);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async addLabQueue({ commit }, object) {
    await axios
      .post("/labRequest", object)
      .then((response) => {
        commit("pushLabQueue", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updatePatient({ commit }, object) {
    await axios
      .patch("/patient" + `/${object.id}`, object)
      .then((response) => {
        commit("updatePatient", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch(() => {
        commit("setErrors", "Failed");
        return Promise.reject();
      });
  },

  async paymentForCashier({ commit }, id) {
    await axios
      .patch("/paidFor" + `/${id}`)
      .then((response) => {
        commit("setCashierQueues", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async paymentForCashierDiscount({ commit }, object) {
    await axios
      .patch("/requestDiscount" + `/${object.id}`, object)
      .then((response) => {
        commit("setCashierQueues", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async addDrQueue({ commit }, object) {
    await axios
      .post("/doctorsQueue", object)
      .then((response) => {
        commit("pushDrQueue", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async confirmCardPayment({ commit }, object) {
    await axios
      .post("/confirmCardPayment", object)
      .then((response) => {
        commit("pushDrQueue", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updateLabRequests({ commit }, object) {
    await axios
      .patch("/labRequest/" + `${object.requestId}`, object)
      .then((response) => {
        commit("updateLabRequest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async deleteLabRequests({ commit }, id) {
    await axios
      .delete("/labRequest/" + `${id}`)
      .then((response) => {
        commit("updateLabRequest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async updateRadioRequests({ commit }, object) {
    await axios
      .patch("/radiologyRequest/" + `${object.requestId}`, object)
      .then((response) => {
        commit("updateRadioRequest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async deleteRadioRequests({ commit }, id) {
    await axios
      .delete("/radiologyRequest/" + `${id}`)
      .then((response) => {
        commit("updateRadioRequest", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },

  async deletePatient({ commit }, id) {
    await axios.delete("/patient" + `/${id}`).then(() => {
      commit("deletePatient", id);
      return Promise.resolve();
    });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
