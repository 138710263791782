<template>
  <div>
    <div class="sub-menu d-none d-md-block" v-if="show">
      <Sidebar :showLogo="true" />
      <!-- <router-view name="no-sidebar" class="JDI-layout-other" /> -->
    </div>

    <div class="JDI-layout-body" :class="{ fullWidth: !show }">
      <div class="row JDI-title-bar" v-if="show">
        <div class="JDI-header" style="height: auto">
          <h5>{{ this.$route.meta.title }}</h5>
        </div>

        <div class="JDI-user">
          <Avater />
        </div>
      </div>

      <div class="content">
      
        <router-view class="JDI-layout-content" />
        
        <!-- <router-view></router-view> -->
      </div>
      <!--<Footer class="JDI-layout-footer" />-->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Avater from "../../common/avater";
import Sidebar from "../../common/sidebar";

export default {
  data() {
    return {
      show: true,
      role: localStorage.getItem("Role"),
    };
  },
  components: {
    Avater,
    Sidebar,
  },
  created() {
    if (this.role == "Admin") {
      this.$router.push("/Dashboard");
    } else if (this.role == "Cashier") {
      if (localStorage.getItem("patientId") != null) {
        this.getPaymentData(localStorage.getItem("patientId"));
        this.$router.push("/cashierResult");
      } else {
        this.$router.push("/cashierQueue");
      }
    } else if (this.role == "Labratory") {
      if (localStorage.getItem("patientId") != null) {
        this.fetchLabQueueById(localStorage.getItem("patientId"));
        this.$router.push("/labResultForm");
      } else {
        this.$router.push("/labQueue");
      }
    } else if (this.role == "Radiology") {
      if (localStorage.getItem("patientId") != null) {
        //this.fetchRadioQueueById(localStorage.getItem("patientId"));
        this.$router.push("/radioResultForm");
      } else {
        this.$router.push("/radioQueue");
      }
    } else if (this.role == "Doctor") {
      if (localStorage.getItem("patientId") != null) {
        this.fetchPatientsProfile(localStorage.getItem("patientId"));
        this.$router.push("/patientHistory");
      } else {
        this.$router.push("/drQueue");
      }
    }
  },
  watch: {
    $route(to) {
      if (to.meta.header == false) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchPatientsProfile",
      "fetchLabQueueById",
      "fetchRadioQueueById",
      "getPaymentData",
    ]),
  },
  computed: {},
};
</script>

<style scoped>
.fullWidth {
  margin-left: auto !important;
}
.content {
  margin-top: 15%;
}
@media (min-width: 767px) {
  .JDI-title-bar {
    padding-right: 230px !important;
  }
  .content {
    margin-top: 5%;
  }
}
@media (min-width: 700px) {
  
  .content {
    margin-top: 10%;
  }
}
@media (min-width: 1100px) {
  
  .content {
    margin-top: 5%;
  }
}
</style>
