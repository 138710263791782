<template>
  <div>
    <b-card no-body v-if="displayList" class="my-1">
    <b-row>
    <b-col sm="4" class="ml-3 mt-1 mr-3">
    <b-form-input
    v-model="filter"
    placeholder="Type to search..."
    >
    
    </b-form-input>
    </b-col>
    </b-row>
      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :current-page="currentpage"
          :per-page="per_page"
          :small="small"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          stacked="sm"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getRadioQueues"
          show-empty
          :fields="fields"
          :empty-text="
            getUsersList
              ? 'There is no patient queue yet.'
              : 'Unable to load data.'
          "
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }} {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>
          <template v-slot:cell(status)="data">
            <span class="tag mx-2">
              <span class="confirmed" v-if="data.item.percentCompleted == 100"
                >Rad {{ data.item.percentCompleted }} %</span
              >
              <span class="danger" v-if="data.item.percentCompleted == 0"
                >Rad {{ data.item.percentCompleted }} %</span
              >
              <span
                class="warning"
                v-if="
                  data.item.percentCompleted > 0 &&
                  data.item.percentCompleted < 100
                "
                >Rad {{ data.item.percentCompleted }} %</span
              >
            </span>
          </template>

          <template v-slot:cell(type)="data">
            <span v-if="data.item.isOutdoor == true">Outdoor</span>
            <span v-if="data.item.isOutdoor == false">Normal</span>
          </template>

          <template v-slot:table-caption>
            <b-pagination
              v-if="!checkQueue"
              class="float-right my-0"
              v-model="currentpage"
              :total-rows="getRadioQueues.length"
              :per-page="per_page"
              align="fill"
              size="sm"
              aria-controls="my-table"
            >
            </b-pagination> </template
        ></b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";
export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      displayDetail: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      filter:'',
      isOutDoor: false,
      patientName: "",
      patientId: "",
      sortBy: "percentCompleted",
      currentpage: 1,
      sortDesc: false,
      curent_page: 1,
      per_page: 30,
      pageOptions: [5, 10, 15, "All"],
      fields: fields.fieldsRadioQueue,
    };
  },
  created() {
    this.fetchRadioQueue().then(() => {
      this.getUsersList = true;
    });
  },
  sockets: {
    radioIsPaid: function () {
      this.isNewAction(true);
      this.fetchRadioQueue();
    },
  },
  methods: {
    ...mapActions([
      "fetchRadioQueue",
      "fetchPatientsData",
      "fetchRadioQueueById",
      "fetchPatientsProfile",
      "fetchOutDoorPatient",
      "isNewAction",
    ]),

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isResultSaved == false) return "table-warning";
      if (item.percentCompleted > 0 && item.percentCompleted < 100)
        return "table-wh";
      if (item.percentCompleted == 100) return "table-success";
    },

    onRowSelected(items) {
      this.selected = items;
      this.check();
      let data = {
        patientId: this.patientName,
        isOutdoor: this.isOutDoor,
      };
      if (this.isOutDoor) {
        this.fetchPatientsProfile(data).then(() => {
          this.fetchRadioQueueById(data).then(
            () => this.isNewAction(false),
            (data = {}),
            this.$router.push("/patientsHistoryRadio")
          );
        });
      } else {
        this.fetchPatientsProfile(data).then(() => {
          this.fetchRadioQueueById(data).then(
            () => this.isNewAction(false),
            (data = {}),
            this.$router.push("/patientsHistoryRadio")
          );
        });
      }
    },
    check() {
      for (let i = 0; i < this.selected.length; i++) {
        this.patientName = this.selected[i].patientId;
        this.isOutDoor = this.selected[i].isOutdoor;
      }
    },
  },
  computed: {
    ...mapGetters(["getPatients", "getRadioQueue", "getRadioQueues"]),
  },
  checkQueue() {
    return this.getRadioQueues.length <= 0 ? true : false;
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
