import axios from "axios";

const state = {
  patientAppointment: [],
  patientAppointments: [],
};

const mutations = {
  setApointment: (state, patientAppointment) =>
    (state.patientAppointment = patientAppointment),
  setApointments: (state, patientAppointments) =>
    (state.patientAppointments = patientAppointments),
  pushPatientAppointment: (state, patientAppointments) =>
    state.patientAppointments.unshift(patientAppointments),
};

const getters = {
  getPatientAppointments: (state) => {
    return state.patientAppointments;
  },
  getPatientAppointment: (state) => {
    return state.patientAppointment;
  },
};

const actions = {
  async fetchPatientAppointment({ commit }) {
    await axios
      .get("/appointments/all")
      .then((response) => {
        commit("setApointments", response.data.appointments);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async singlePatientAppointment({ commit }, id) {
    await axios
      .get("/appointments/patient/" + `${id}`)
      .then((response) => {
        commit("setApointments", response.data.appointments);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async fetchPatientAppointmentToday({ commit }) {
    await axios
      .get("/appointments")
      .then((response) => {
        commit("setApointments", response.data.appointments);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async addPatientAppointments({ commit }, object) {
    await axios
      .post("/appointment", object)
      .then((response) => {
        commit("pushPatientAppointment", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async updatePatientAppointments({ commit }, object) {
    await axios
      .put("/appointment/" + `${object.patientId}`, object)
      .then((response) => {
        commit("pushPatientAppointment", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.message);
        return Promise.reject();
      });
  },
  // for post lab test request
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
