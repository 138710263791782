<template>
  <div>
    <b-card no-body v-if="displayList">
      <b-card-header class="twa-header">
        <b-row class="twa-row mt-2">
          <b-col lg="2" class="pb-0 col-3">
            <b-input-group size="sm">
              <b-form-select
                class="perPageSelect"
                v-model="per_page"
                id="sortBySelect"
                :options="pageOptions"
              >
              </b-form-select>
            </b-input-group>
          </b-col>

          <b-col sm="5" class="pb-0 col-9">
            <b-form-group>
              <b-input-group size="sm" class="w-20">
                <b-form-input
                  class="float-right"
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to search..."
                ></b-form-input
                ><b-button
                  size="sm"
                  class="mx-1"
                  variant="info"
                  @click="filterPatients"
                >
                  <i class="fa fa-search"></i> Search
                </b-button>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="text-center twa-body">
        <b-table
          ref="selectableTable"
          selectable
          class="customTable table table-head-fixed"
          fixed
          @filtered="onFiltered"
          noCollapse="true"
          :current-page="currentPage"
          :per-page="per_page"
          :small="small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          stacked="md"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getPatients.patients"
          show-empty
          :fields="fields"
          :empty-text="getUsersList ? 'Empty Record' : 'Unable To Load Data'"
        >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'index' ||
                  field.key === 'sex' ||
                  field.key === 'Age' ||
                  field.key === 'cardNo' ||
                  field.key === 'phoneNumber' ||
                  field.key === 'registeredDate'
                    ? '110px'
                    : '280px',
              }"
            />
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 + getPatients.currentPage * 100 - 100 }}
          </template>
          <template v-slot:cell(registeredDate)="data">
            {{ convertDate(data.item.registeredDate) }}
          </template>
          <template v-slot:cell(fullName)="data">
            {{ data.item.firstName }}
            {{ data.item.middleName }}
            {{ data.item.lastName }}
          </template>
          <template v-slot:cell(Age)="data">
            {{ ageCa(data.item.birthDate) }}
          </template>
        </b-table>
        <li class="float-left ml-3">
          Patients display
          <b>
            {{
              getPatients.currentPage * 100 -
              99 +
              " - " +
              getPatients.currentTotal  
              
            }}</b
          ><b v-if="!filter">{{" of " +
              getPatients.total}}</b>
        </li>

        
        <ul class="pagination float-right" v-if="filter">
          <li
            @click="next(getPatients.previous)"
            v-if="getPatients.hasPrevious"
            size="sm"
            class="lists"
            to="?page=3"
          >
            <i class="fa fa-arrow-left"></i>
          </li>
          <li class="lists" v-if="getPatients.hasPrevious">
            {{ getPatients.previous }}
          </li>
          <li class="lists active">{{ getPatients.currentPage }}</li>
          <li class="lists" v-if="getPatients.hasNext">
            {{ getPatients.next }}
          </li>

          <li
            class="lists"
            @click="next(getPatients.next)"
            v-if="getPatients.hasNext"
          >
            <i class="fa fa-arrow-right"></i>
          </li>
          <li class="lists" @click="next(getPatients.last)">
            {{ getPatients.last }}
          </li>
        </ul>
      </b-card-body>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="info" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    maxDate.setDate(now.getDate());
    return {
      total: "",
      small: true,
      getUsersList: false,
      filter: null,
      errors: {},
      value_date: true,
      curentEditId: "",
      max: maxDate,
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      PatientId: [],
      selectMode: "single",
      selected: [],
      patientAddQueue: [],
      sortBy: "registeredDate",
      sortDesc: true,
      showInvalid: true,
      hideCompleted: true,
      currentPage: 1,
      per_page: 100,
      pageOptions: [5, 10, 15, 50, 100],
      genderList: ["Female", "Male"],
      fields: [
        { key: "index", label: "#" },
        {
          key: "fullName",
          label: "Full Name",
        },
        { key: "sex", label: "Sex" },
        "Age",
        { key: "phoneNumber", label: "Mobile" },
        { key: "cardNo", label: "MRN" },
        // { key: "registeredDate", label: "R.Date" },
      ],
    };
  },
  created() {
    // this.ageCa();
    this.count();
    this.fetchPatients().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "fetchPatients",
      "fetchPatientsProfile",
      "fetchPatientsProfileNormalPatient",
    ]),
    async count() {
      this.total = await this.getPatients.length;
    },
    filterPatients() {
      const data = {
        page: this.getPatients.currentPage,
        name: this.filter,
      };
      this.fetchPatients(data);
    },
   
    next(page) {
      const data = {
        page: page,
        name: this.filter,
      };
      this.fetchPatients(data);
    },
    convertDate(date) {
      return window.moment(date).format("DD-MM-YYYY");
    },
    ageCa(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.total = filteredItems.length;
      this.currentPage = 1;
    },
    removePatientdata() {
      localStorage.removeItem("reco");
      localStorage.removeItem("recommendation");
      localStorage.removeItem("reason");
      localStorage.removeItem("refferedTo");
      localStorage.removeItem("status");
      localStorage.removeItem("treatment");
      localStorage.removeItem("department");
    },
    onRowSelected(items) {
      this.PatientId = items;
      this.check();
      this.fetchPatientsProfileNormalPatient(this.patientName).then(
        () => this.$router.push("/patientHistory"),
        localStorage.setItem("patientId", this.patientName),
        this.removePatientdata()
      );
    },
    check() {
      for (let i = 0; i < this.PatientId.length; i++) {
        this.patientName = this.PatientId[i]._id;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getPatientId",
      "getPatients",
      "getErrors",
      "getStatusMessage",
    ]),

    filteredTasks() {
      return this.hideCompleted
        ? this.getPatients.filter((t) => !t.done)
        : this.tasks;
    },
    checkQueue() {
      return this.getPatients.length <= 0 ? false : true;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}

.twa-header {
  margin: 0px;
  padding: 0px;
}

.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
li {
  list-style-type: none;
}
.active {
  background-color: #007bff;
  color: white;
}
.lists {
  border: 1px solid #007bff;
  margin-right: 3px;
  padding: 5px;
  cursor: pointer;
}
</style>
