<template>
  <div>
    <div>
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        :small="small"
        v-model="showModal"
        title="Postpone your Appointment."
        @show="resetModal"
        @hidden="resetModal"
        @ok="updateAppointment"
      >
        <form ref="form">
          <b-form-group
            :state="nameState"
            label="New Appointment"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-datepicker
              v-model="appointment"
              value-as-date
              value="date"
              :min="min"
              locale="en"
            ></b-form-datepicker>
          </b-form-group>
        </form>
      </b-modal>
    </div>
    <b-card-header class="JDI-Card-header">
      <b-row class="twa-row">
        <b-col lg="3" class="my-1 col-4">
          <b-input-group size="sm">
            <b-form-select
              v-model="perpage"
              id="sortBySelect"
              :options="pageOptions"
              class="w-20"
            >
            </b-form-select>
          </b-input-group>
        </b-col>

        <b-col lg="3" class="my-1 col-4">
          <b-input-group size="sm">
            <b-form-select
              id="input-3"
              :options="filterOptions"
              size="sm"
              v-model="filterOn"
              value-field="_id"
              text-field="name"
              @change="fetchAppointment()"
              disabled-field="notEnabled"
            >
            </b-form-select>
          </b-input-group>
        </b-col>

        <b-col lg="5" class="my-1 col-4">
          <b-form-group>
            <b-input-group size="sm" class="w-20">
              <b-form-input
                class="float-right"
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to search..."
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-header>

    <div align="center">
      <b-table
        ref="selectableTable"
        selectable
        noCollapse="true"
        :current-page="curentpage"
        :per-page="per_page"
        :small="small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :tbody-tr-class="rowClass"
        responsive="sm"
        stacked="md"
        :filterIncludedFields="filterOn"
        @filtered="onFiltered"
        :select-mode="selectMode"
        @row-selected="onRowSelected"
        :items="getPatientAppointments"
        show-empty
        :fields="fields"
        :empty-text="getUsersList ? 'Empty Record' : 'Unable to load data'"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(fullName)="data">
          {{ data.item.patient.firstName }}
          {{ data.item.patient.middleName }}
          {{ data.item.patient.lastName }}
        </template>

        <template v-slot:cell(Action)="data">
          <b-link
            v-b-modal.modal-footer-sm
            @click="updateapp(data.item._id)"
            class="text-primary"
          >
            <b-icon icon="bootstrap-reboot" variant="primary"></b-icon> </b-link
          >.
          <b-link @click="sendPatient(data.item._id)" class="text-primary">
            <b-icon icon="cursor-fill" variant="primary"></b-icon>
          </b-link>
        </template>

        <template v-slot:table-caption>
          <b-pagination
            class="float-right my-0"
            v-if="checkQueue"
            v-model="curentpage"
            :total-rows="getPatientAppointments.length"
            :per-page="per_page"
            align="fill"
            size="sm"
            aria-controls="my-table"
          >
          </b-pagination> </template
      ></b-table>
    </div>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const maxDate = new Date(today);
maxDate.setMonth(maxDate.getMonth());
maxDate.setDate(now.getDate());
const minDate = new Date(today);
minDate.setMonth(minDate.getMonth());
minDate.setDate(now.getDate() + 1);
export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      filter: null,
      value_date: true,
      curentEditId: "",
      editMode: false,
      min: minDate,
      showModal: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      filterOn: "Today Appointments",
      patientAddQueue: [],
      sortBy: "fullName",
      sortDesc: false,
      hideCompleted: true,
      curent_page: 1,
      filterBy: "",
      filterOptions: ["Today Appointments", "All"],
      perpage: 50,
      pageOptions: [5, 10, 15, 50, "All"],
      genderList: ["Female", "Male"],
      fields: [
        { key: "index", label: "#", sortable: false },
        {
          key: "fullName",
          label: "Full Name",
          sortable: true,
        },
        { key: "patient.phoneNumber", label: "Mobile" },

        { key: "appointmentDate", label: "Appointment Date" },
        "Action",
      ],
      patientData: {
        patientId: "",
        firstName: "",
        middleName: "",
        lastName: "",
      },
    };
  },
  created() {
    this.fetchPatientAppointmentToday().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "fetchPatientsProfile",
      "fetchPatientAppointment",
      "fetchPatientAppointmentToday",
      "addDrQueue",
      "updatePatientAppointments",
    ]),
    fetchAppointment() {
      if (this.filterOn == "Today Appointments") {
        this.fetchPatientAppointmentToday();
      } else {
        this.fetchPatientAppointment();
      }
    },
    updateapp(id) {
      this.appointmentId = id;
      this.showModal = true;
    },
    updateAppointment() {
      const data = {
        patientId: this.appointmentId,
        appointmentDate: this.appointment,
      };
      this.updatePatientAppointments(data).then(() => {
        if (this.filterOn == "Today Appointments") {
          this.fetchPatientAppointmentToday();
        } else {
          this.fetchPatientAppointment();
        }
      });
    },
    sendPatient(id) {
      this.patientAddQueue = this.getPatientAppointments.filter(
        (t) => t._id == id
      );

      for (let i = 0; i < this.getPatientAppointments.length; i++) {
        this.patientData.patientId = this.getPatientAppointments[i].patient._id;
        this.patientData.firstName = this.getPatientAppointments[
          i
        ].patient.firstName;
        this.patientData.middleName = this.getPatientAppointments[
          i
        ].patient.middleName;
        this.patientData.lastName = this.getPatientAppointments[
          i
        ].patient.lastName;
      }

      this.addDrQueue(this.patientData);
      this.$bvToast.show("toaster");
    },
  },
  computed: {
    ...mapGetters(["getPatientAppointments", "getPatientAppointment"]),
  },
  checkQueue() {
    return this.getPatientAppointments.length <= 0 ? false : true;
  },
};
</script>

<style scoped>
.JDI-Card-header {
  margin-bottom: 0px;
}
</style>
