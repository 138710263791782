<template>
  <div>
    <b-row class="ml-2">
      <b-col class="float-right">
        <p class="float-right ml-4 my-0">
          Date :
          <span class="">
            <b style="text-decoration:underline">{{ personal.dateCheck }}</b></span
          >
        </p>
      </b-col>
    </b-row>
    <b-row class="ml-2">
      <b-col class="float-right">
        <p class="float-right ml-4 my-0 mb-3">
          MRN :
          <span class="">
            <b style="text-decoration:underline">{{ personal.mrn }}</b></span
          >
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" class="col-sm-4 col-4">
        <span>Name : </span
        ><span class="">
          <b>{{ personal.fullName }}</b></span
        >
      </b-col>
      <b-col lg="4" class="col-sm-4 col-4">
        <span>Age : </span
        ><span class="">
          <b>{{ personal.age }}</b></span
        >
      </b-col>
      <b-col lg="4" class="col-sm-4 col-4">
        <span>Sex : </span
        ><span class="">
          <b>{{ personal.sex }}</b></span
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["personal"],
};
</script>

<style scoped>
.result {
  font-weight: bold;
}
.custom-row {
  align-items: right;
  float: right;
  text-decoration: underline;
}
</style>
