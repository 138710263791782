<template>
  <div>
    <b-card no-body>
      <b-card-header class="Jdi-header" style="padding: 2px; margin: 0px">
        <b-form class="Jdi-row">
          <b-row>
            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Name Of medication:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- 
            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Category:"
                label-for="input-1"
                size="sm"
              >
                <b-form-select
                  :disabled="editMode"
                  id="input-3"
                  class="my-1"
                  size="sm"
                  v-model="form.category"
                  value-field="_id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <option v-for="item in getLabTests" :key="item.id">
                    {{ item.category }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col> -->

            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Price:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.price"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
              <b-button
                :disabled="checkRequired"
                v-if="createMode"
                variant="primary"
                style="margin-top: 40px"
                class="float-right"
                @click.prevent="registerTestData"
              >
                Save
              </b-button>
              <b-button
                v-if="editMode"
                variant="primary"
                style="margin-top: 40px"
                class="float-right"
                @click.prevent="updateLabTests"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-header>

      <b-card-body style="margin: 0px; padding: 0px">
        <b-row>
          <b-col lg="12">
            <b-table
              :items="getmedications"
              :fields="fields"
              ref="selectableTable"
              selectable
              noCollapse="true"
              :current-page="currentpage"
              :per-page="per_page"
              :small="small"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :tbody-tr-class="rowClass"
              responsive="sm"
              :select-mode="selectMode"
              @row-selected="onRowSelected"
              show-empty
              :empty-text="
                getUsersList ? 'Empty Record' : 'Unable to load data'
              "
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template v-slot:cell(status)="data">
                <toggle-button
                  :value="data.item.status >= 1 ? true : false"
                  color="#007bff"
                  :labels="true"
                  @change="updateLabStatus(data.item._id)"
                />
              </template>

              <template v-slot:cell(Actions)="data">
                <b-link
                  @click="editMedication(data.item._id)"
                  class="text-primary"
                >
                  <b-icon icon="pen" variant="primary"></b-icon>
                </b-link>
                <!-- <b-link
                  @click="deleteMedications(data.item._id)"
                  class="text-danger"
                > 
                  <b-icon icon="trash" variant="danger"></b-icon>
                </b-link>-->
              </template>

              <template v-slot:table-caption>
                <b-pagination
                  class="float-right my-0"
                  v-model="currentpage"
                  :total-rows="getmedications.length"
                  :per-page="per_page"
                  align="fill"
                  size="sm"
                  aria-controls="my-table"
                >
                </b-pagination>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";

export default {
  data() {
    return {
      small: true,
      curentData: {},
      getUsersList: false,
      curentEditId: "",

      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortBy: "name",
      sortDesc: false,
      isBusy: false,
      curentpage: 1,
      per_page: 20,
      pageOptions: [5, 40, 50, "All"],
      genderList: ["Male", "Female"],
      form: {
        name: "",
        price: "",
      },
      categories: [
        "HEMATOLOGY",
        "CLINICAL CHEMISTRY",
        "URINALYSIS",
        "SEROLOGY",
      ],
      fields: fields.fieldsMedication,
    };
  },

  created() {
    this.fetchMedications().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "addMedication",
      "fetchMedications",
      "deleteMedication",
      "updatemedication",
      "fetchLabTest",
      "updatemedicationstatus",
    ]),
    // rowClass(item, type) {
    //   if (!item || type !== "row") return;
    //   if (item.status === false) return "table-danger";
    // },
    onRowSelected(items) {
      this.selected = items;
    },
    deleteMedications(id) {
      this.deleteMedication(id).then(() => {
        this.fetchMedications();
      });

      this.$bvToast.show("toaster");
    },
    updateLabStatus(id) {
      this.updatemedicationstatus(id).then(() => {
        this.fetchMedications();
      });
      this.$bvToast.show("toaster");
    },
    registerTestData() {
      const TestData = {
        name: this.form.name,
        unitPrice: this.form.price,
      };
      this.addMedication(TestData).then(() => {
        this.clear();
        this.fetchMedications();
      });
      this.$bvToast.show("toaster");
    },
    updateLabTests() {
      const LabTestData = {
        name: this.form.name,
        unitPrice: this.form.price,
        id: this.curentEditId,
      };
      this.updatemedication(LabTestData);
      this.$bvToast.show("toaster");
    },
    clear() {
      (this.form.name = ""), (this.form.price = "");
    },
    editMedication(index) {
      this.curentData = this.getmedications.find(
        (medication) => medication._id == index
      );
      this.form.name = this.curentData.name;
      this.curentEditId = this.curentData._id;
      this.form.price = this.curentData.unitPrice;
      this.editMode = true;
      this.createMode = false;

      // this.$swal(index);
    },
    deleteLabTests(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((deleteU) => {
          if (deleteU) {
            this.deleteLabTest(id);
            this.$bvToast.toast("Deleted Sucessfully!!!!!", {
              title: "Deleted",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
  },
  computed: {
    ...mapGetters([
      "getmedications",
      "getLabTest",
      "getSingleLabTest",
      "getErrors",
    ]),
    checkRequired() {
      return this.form.name <= 0 || this.form.price <= 0 ? true : false;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
