import doctorQueue from "../view/doctor/drQueue.vue";
import patientData from "../view/doctor/patientData.vue";
import patientRegister from "../view/doctor/patientRegister.vue";
import certificate from "../view/doctor/certificate.vue";
import certificatePrint from "../view/doctor/certificatePrint.vue";
import refer from "../view/doctor/refer.vue";
import referPrint from "../view/doctor/referPrint.vue";
import sickLeavePrint from "../view/doctor/sickLeavePrint.vue";
import sickLeave from "../view/doctor/sickLeave.vue";
import patientHistory from "../view/doctor/PHistoryPrint.vue";
export default [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "drDashboard",
        name: "drDashboard",
        meta: {
          title: "Dashboard",
          // scope: "Auth",
        },
        component: () => import("@/view/doctor/dashboard"),
      },
      {
        path: "drQueue",
        name: "drQueue",
        meta: {
          title: "Doctor's Queue",
          // scope: "Auth",
        },
        component: doctorQueue,
      },
      {
        path: "patientHistory",
        name: "patientHistory",
        meta: {
          title: "Patient History",
          // scope: "Auth",
        },
        component: patientData,
      },
      {
        path: "allPatients",
        name: "allPatients",
        meta: {
          title: "All Patients",
          // scope: "Auth",
        },
        component: patientRegister,
      },
      {
        path: "certificate",
        name: "certificate",
        meta: {
          title: "Certificate For Patient",
          // scope: "Auth",
        },
        component: certificate,
      },
      {
        path: "certificatePrint",
        name: "certificatePrint",
        meta: {
          title: "Certificate For Patient",
          header: false,
        },
        component: certificatePrint,
      },
      {
        path: "refer",
        name: "refer",
        meta: {
          title: "Refer Patient",
          // scope: "Auth",
        },
        component: refer,
      },
      {
        path: "referPrint",
        name: "referPrint",
        meta: {
          title: "Refer Patient",
          header: false,
        },
        component: referPrint,
      },
      {
        path: "patientHistoryPrint",
        name: "patientHistoryPrint",
        meta: {
          title: "Patient History",
          header: false,
        },
        component: patientHistory,
      },
      {
        path: "sickLeave",
        name: "sickLeave",
        meta: {
          title: "Sick Leave Paper",
          // scope: "Auth",
        },
        component: sickLeave,
      },
      {
        path: "sickLeavePrint",
        name: "sickLeavePrint",
        meta: {
          title: "Sick Leave Paper",
          header: false,
        },
        component: sickLeavePrint,
      },
    ],
  },
];
