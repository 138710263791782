<template>
  <div>
    <div>
      <b-row class="Jdi-row">
        <b-col lg="8">
          <b-row class="Jdi-row">
            <!-- {{ getTreatmentRecord }} -->
            <b-col
              lg="6"
              v-for="(item, index) in getTreatmentRecord"
              :key="item.id"
            >
              <div
                class="edit"
                v-if="getTreatmentRecord[index].date == todaysDate"
              >
                <p class="noPadding">
                  Date
                  <span class="result">{{
                    getTreatmentRecord[index].date
                  }}</span>
                </p>
                <p class="noPadding">
                  Request Time <span class="result">{{ item.time }}</span>
                </p>
                <p class="noPadding">
                  Status
                  <span
                    class="result"
                    v-if="getTreatmentRecord[index].isResultSaved"
                  >
                    Result Arrived</span
                  >
                  <span
                    class="result"
                    v-if="getTreatmentRecord[index].isPaidFor"
                  >
                    Treatment Pending</span
                  >
                  <span
                    class="result"
                    v-if="!getTreatmentRecord[index].isPaidFor"
                  >
                    Payment Pending</span
                  >
                </p>
                <b-link
                  v-if="
                    !getTreatmentRecord[index].isResultSaved &&
                    !getTreatmentRecord[index].isPaidFor
                  "
                  class="noPadding float-right border"
                  style="
                    display: inline-block;
                    margin-right: 15px;
                    font-style-type: underline;
                  "
                  @click="sendRequest(index)"
                >
                  Edit</b-link
                >
                <b-link
                  class="noPadding float-right border"
                  style="
                    display: inline-block;
                    margin-right: 15px;
                    font-style-type: underline;
                  "
                  @click="showO = !showO"
                >
                  List</b-link
                >
                <b-link
                  v-if="getTreatmentRecord[index].isResultSaved"
                  class="noPadding float-right border"
                  style="display: inline-block"
                  @click="showResult(index)"
                >
                  Show Result</b-link
                >
                <b-link
                  v-if="
                    !getTreatmentRecord[index].isResultSaved &&
                    !getTreatmentRecord[index].isPaidFor
                  "
                  class="noPadding float-right border"
                  style="
                    display: inline-block;
                    margin-right: 15px;
                    font-style-type: underline;
                  "
                  @click="cancelRequest(getTreatmentRecord[index]._id)"
                >
                  Cancel</b-link
                >
              </div>
              <span
                v-if="
                  showO &&
                  !checkPatient &&
                  getTreatmentRecord[index].date == todaysDate
                "
              >
                <b-table
                  :fields="fieldsList"
                  :small="small"
                  :items="getTreatmentRecord[index].tests"
                >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                </b-table>
              </span>
            </b-col>
          </b-row>
          <b-row class="mx-1" style="padding-bottom: 10px">
            <!--in txt.file  -->
            <li style="width: 100%;margin:0px,padding:0px">
              <b-col lg="12" v-for="item in getTreatments" :key="item.id">
                <hr lg="12" />
                <p
                  class="text-bold"
                  style="font-style: italic; font-weight: bold"
                >
                  {{ item.category }}
                  <span @click="item.checked = !item.checked">
                    <b-icon v-if="!item.checked" icon="chevron-down"></b-icon>
                    <b-icon
                      v-if="item.checked"
                      icon="chevron-compact-up"
                    ></b-icon>
                  </span>
                </p>
                <span v-if="item.checked">
                  <div v-for="test in item.treatments" :key="test.id">
                    <b-row class="my-1">
                      <b-col lg="4">
                        <b-form-checkbox-group v-model="selected">
                          <b-form-checkbox
                            class="my-1 text-muted"
                            size="sm"
                            :testId="test._id"
                            :value="test"
                            :testPrice="test.price"
                            >{{ test.name }}
                            <span class="text-muted" v-if="test.dose"
                              >- {{ test.dose }} {{ test.unit }}</span
                            ></b-form-checkbox
                          >
                        </b-form-checkbox-group>
                      </b-col>
                      <b-col lg="2" class="my-1">
                        <b-form-input
                          placeholder="Days"
                          type="number"
                          v-model="test.days"
                          size="sm"
                        ></b-form-input>
                      </b-col>

                      <b-col lg="3" class="my-1">
                        <b-form-input
                          type="number"
                          placeholder="Per day"
                          v-model="test.perDay"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                      <b-col lg="3" class="my-1">
                        <b-form-input
                          type="number"
                          placeholder="Dose"
                          v-model="test.doseOrder"
                          size="sm"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </span>
              </b-col>
            </li>
          </b-row>
        </b-col>
        <b-col lg="4" v-if="!checkSelection">
          <div class="mx-3 selected">
            <b-table
              v-if="!checkSelection"
              :items="selected"
              :small="small"
              :fields="fields"
            >
              <template v-slot:cell(remove)="data">
                <b-icon
                  class="mx-2 icon"
                  variant="danger"
                  tooltip="removing from the request"
                  @click="haf(data.item._id)"
                  font-scale="1.5"
                  icon="backspace-fill"
                ></b-icon>
              </template>
            </b-table>
            <b-row class="mx-3">
              <!-- <li class="float-right">Total {{ total }}</li> -->
            </b-row>
            <b-row>
              <b-col lg="12" class="float-right my-2" v-if="!checkRole">
                <b-select
                  style="padding: 2px"
                  class="smallHeight"
                  size="sm"
                  :options="requests"
                  v-model="requestType"
                  text-field="name"
                  value-field="key"
                >
                </b-select>
              </b-col>
            </b-row>
            <textarea
              style="width: 100%"
              v-if="!checkRole"
              v-model="procedure"
              placeholder="descrpition"
              cols="2"
              rows="2"
            ></textarea>
            <b-row>
              <b-button
                variant="danger"
                size="sm"
                class="mx-4 my-1"
                @click="cancel"
              >
                Cancel
              </b-button>
              <b-button
                size="sm"
                class="mx-2 my-1 btnPositive"
                v-if="checkRole && createMode"
                @click="sendTestsCashier"
              >
                Send
              </b-button>
              <b-button
                size="sm"
                class="mx-2 my-1 btnPositive"
                v-if="!checkRole && createMode"
                @click="sendTests"
              >
                Send
              </b-button>
              <b-button
                size="sm"
                class="mx-2 my-1"
                v-if="!checkRole && !createMode && !checkPatient"
                variant="info"
                @click="updateTests"
              >
                Update
              </b-button>
              <!-- {{ selected }} -->
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast> -->
  </div>
</template>

<script>
//import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["isOutDoor"],
  data() {
    return {
      patientName: "",
      showO: false,
      patientId: "",
      procedure: "",
      requestType: "New",
      currentIndex: 0,
      requestIndex: 0,
      fieldsList: ["index", "name"],
      showModal: false,
      editMode: false,
      createMode: true,
      requestId: "",
      selected: [],
      small: true,
      treatment: [],
      fields: ["name", "unitPrice", "remove"],
      requests: [
        { name: "New", key: "New" },
        { name: "Added", key: "Added" },
        { name: "Revised", key: "Revised" },
      ],
    };
  },
  created() {
    this.fetchActiveTreatments();
    this.fetchTreatmentRequestsofapatient(this.getPatientProfile.patient._id);
  },
  methods: {
    ...mapActions([
      "fetchActiveTreatments",
      "addTreatmentDr",
      "UpdateTreatmentRequest",
      "deleteTreatmentRequest",
      "fetchTreatmentRecord",
      "fetchTreatmentRequestsofapatient",
      "sendLabTestRequestToCashier",
    ]),
    cancelRequest(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want delete this request? It can't be undone.",
          {
            size: "sm",
            title: "Confirmation",
            buttonSize: "sm",
            okTitle: "Confirm",
            cancelTitle: "Exit",
            okVariant: "danger",
            noCloseOnBackdrop: true,
            centered: true,
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteTreatmentRequest(id).then(() => {
              this.createMode = true;
              this.selected = [];

              this.$bvToast.toast(this.getErrors, {
                title: this.getErrors,
                variant: "danger",
                solid: true,
              });
            });
          }

          this.fetchTreatmentRequestsofapatient(
            this.getPatientProfile.patient._id
          );
        });
    },

    haf(y) {
      // this.$swal(y);
      this.selected = this.selected.filter((test) => test._id !== y);
    },

    sendRequest(index) {
      this.fetchTreatmentRecord(this.getTreatmentRecord[index]._id).then(() => {
        this.requestId = this.getTreatmentRecord[index]._id;
        this.currentIndex = index;
        this.procedure = this.getTreatmentRecord[index].procedure;
        this.requestType = this.getTreatmentRecord[index].requestType;
        this.selected = this.getTreatmentRecord[index].tests;
        this.createMode = false;
        this.editMode = true;
      });
    },
    sendTests() {
      const data = {
        treatments: this.selected,
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        cardNo: this.getPatientProfile.patient.cardNo,
        sex: this.getPatientProfile.patient.sex,
        procedure: this.procedure,
        requestType: this.requestType,
        isOutdoor: this.isOutDoor,
      };
      this.addTreatmentDr(data).then(() => {
        this.fetchTreatmentRequestsofapatient(
          this.getPatientProfile.patient._id
        );
        this.isSend = true;
        this.clear();
      });
      this.$bvToast.show("toaster");
    },
    sendTestsCashier() {
      const data = {
        treatments: this.selected,
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        procedure: this.procedure,
        requestType: this.requestType,
        isOutdoor: this.isOutDoor,
      };
      this.addTreatmentDr(data).then(() => {
        this.fetchTreatmentRequestsofapatient(
          this.getPatientProfile.patient._id
        );
        this.isSend = true;

        this.clear();
      });
      this.$bvToast.show("toaster");
    },
    updateTests() {
      const data = {
        tests: this.selected,
        firstName: this.getPatientProfile.patient.firstName,
        middleName: this.getPatientProfile.patient.middleName,
        lastName: this.getPatientProfile.patient.lastName,
        patientId: this.getPatientProfile.patient._id,
        procedure: this.procedure,
        requestId: this.requestId,
        total: this.total,
        requestType: this.requestType,
        isOutdoor: this.isOutDoor,
      };
      this.UpdateTreatmentRequest(data).then(() => {
        this.$bvToast.toast(this.getErrors, {
          title: this.getErrors,
          variant: "success",
          solid: true,
        });
        //this.isSend = true;
        this.fetchTreatmentRequestsofapatient(
          this.getPatientProfile.patient._id
        );
      });
      // this.$bvToast.show("toaster");
    },
    clear() {
      this.selected = [];
      this.procedure = "";
    },
    cancel() {
      this.createMode = true;
      this.selected = [];
    },
  },

  computed: {
    ...mapGetters([
      "getTreatments",
      "getPatientProfile",
      "getErrors",
      "getTreatmentRecord",
      "getOutDoorPatients",
    ]),

    todaysDate() {
      return window.moment().format("DD/MM/YYYY");
    },

    checkSelection() {
      return this.selected.length <= 0 ? true : false;
    },
    checkPatient() {
      return this.getTreatmentRecord.length <= 0 ? true : false;
    },
    checkRole() {
      return localStorage.getItem("Role") === "Cashier" ? true : false;
    },

    total() {
      return this.selected.reduce(
        (index, item) => index + Number(item.unitPrice),
        0
      );
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

.smallHeight {
  height: 30px;
}

.noPadding {
  padding-left: 4px;
  margin: 0px;
}
.result {
  font-weight: bold;
}
</style>
