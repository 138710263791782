<template>
  <div>
    <b-card no-body>
      <b-card-header style="padding: 0px; margin: 0px">
        <b-form>
          <b-row class="Jdi-row">
            <b-col lg="4" class="my-1 col-7">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Category:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.categorie"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="8" class="col-5">
              <b-button
                style="width: auto; margin-top: 35px"
                v-if="createMode"
                size="sm"
                :disabled="categoryValidate"
                @click="registerCategorie"
                variant="primary"
                class="float-right"
              >
                Save
              </b-button>

              <b-button
                size="sm"
                v-if="editMode"
                :disabled="categoryValidate"
                @click="updateCategoriesData"
                style="width: auto; margin-top: 35px"
                variant="info"
                class="float-right"
              >
                Update
              </b-button>

              <!-- <b-button
                style="width: auto"
                variant="danger"
                :disabled="categoryValidate"
                class="float-right mt-3 mr-4"
              >
                Clear
              </b-button> -->
            </b-col>
            <!-- <ul v-for="h in haftom" :key="h.id">
            <li>{{h.name}}</li>
            <ul v-for="a in h.address" :key="a.id">
            <li>{{a.name}}</li>
            
            </ul>
            </ul> -->
          </b-row>
        </b-form>
      </b-card-header>

      <b-card-body class="Jdi-body" style="margin: 0px; padding: 0px">
        <b-table
          ref="selectableTable"
          selectable
          noCollapse="true"
          :busy.sync="isBusy"
          :current-page="currentpage"
          :per-page="per_page"
          :small="small"
          :sort-compare-options="{ numeric: true, sensitivity: 'base' }"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          responsive="sm"
          :select-mode="selectMode"
          @row-selected="onRowSelected"
          :items="getCategorys"
          show-empty
          :fields="fields"
          :empty-text="
            getTestsList
              ? 'There is no laboratory category yet'
              : 'Unable to load data'
          "
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(status)="data">
            <toggle-button
              :value="data.item.status >= 1 ? true : false"
              color="#007bff"
              :labels="true"
              @change="updateStatus(data.item._id)"
            />
          </template>

          <template v-slot:cell(actions)="data">
            <b-link @click="editCategorie(data.item._id)" class="text-primary">
              <b-icon icon="pen" variant="primary"></b-icon> </b-link
            >
            <!-- <b-link
              @click="deleteCategories(data.item._id)"
              class="text-danger"
            >
              <b-icon icon="trash" variant="danger"></b-icon>
            </b-link> -->
          </template>
          <template v-slot:table-caption>
            <b-pagination
              class="float-right my-0"
              v-model="currentpage"
              :total-rows="getCategorys.length"
              :per-page="per_page"
              align="fill"
              size="sm"
              aria-controls="my-table"
            >
            </b-pagination>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";
//import router from "../../routes";
export default {
  data() {
    return {
      small: true,
      isdrop: true,
      value: false,
      getTestsList: false,
      myToggle: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortBy: "age",
      currentData: [],
      sortDesc: false,
      isBusy: false,
      currentpage: 1,
      per_page: 20,
      pageOptions: [5, 30, 50, "All"],

      form: {
        categorie: "",
      },
      fields: fields.fieldsLabCategories,

      haftom: [
        {
          name: "haf",
          address: [{ name: "haftpppppp" }],
        },
        {
          name: "azmeru",
          address: [{ name: "azmeruppp" }],
        },
      ],
    };
  },
  created() {
    this.fetchCategorysAll().then(() => {
      this.getTestsList = true;
    });
    this.$on("dataInserted", () => {
      this.fetchCategorysAll().then(() => {
        this.getTestsList = true;
      });
    });
  },
  methods: {
    ...mapActions([
      "addCategory",
      "fetchCategorysAll",
      "changeCategorieStatus",
      "updateCategorys",
      "deleteCategorie",
      "updateLabCategorysName"
    ]),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === false) return "table-danger";
    },
    onRowSelected(items) {
      this.selected = items;
    },
    registerCategorie() {
      //router.push("/create");
      const CategorieData = {
        category: this.form.categorie,
      };
      this.addCategory(CategorieData).then(() => {
        this.$emit("dataInserted");
        this.form.categorie = "";
      });
      this.$bvToast.show("toaster");
    },
    updateStatus(id) {
      this.changeCategorieStatus(id).then(() => {
        this.$emit("dataInserted");
      });
      this.$bvToast.show("toaster");
    },
    deleteCategories(id) {
      this.deleteCategory(id).then(() => {
        this.$emit("dataInserted");
      });
      this.$bvToast.show("toaster");
    },
    editCategorie(index) {
      this.currentData = this.getCategorys.find((item) => item._id === index);
      this.form.categorie = this.currentData.category;
      this.curentEditId = this.currentData._id;
      this.createMode = false;
      this.editMode = true;
    },
    updateCategoriesData() {
      const CategoryData = {
        category: this.form.categorie,
        id: this.curentEditId,
      };
      this.updateLabCategorysName(CategoryData).then(() => {
        this.$emit("dataInserted");
      this.$bvToast.show("toaster");

      });
    },
  },
  computed: {
    ...mapGetters(["getCategorys", "getErrors"]),
    categoryValidate() {
      return this.form.categorie == "" ? true : false;
    },
  },
};
</script>

<style scoped>
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
</style>
