<template>
  <div style="margin-left: -40px">
    <Header subTitle="Sick Leave Paper" class="mx-3"></Header>
    <hr class="mx-5" />
    <PersonalName :personal="personal" class="mx-5"></PersonalName>
    <hr class="my-1 mx-5" />

    <b-container class="details mt-5 ml-5">
      <p
        class="sick-ma"
        v-for="(item, index) in convertToarray(getSickLeave.diagnosis)"
        :key="item"
      >
        <b class="italic">Diagnosis {{ index + 1 }}: </b> <b>{{ item }}</b>
      </p>
      <p class="sick-ma">
        <b class="italic"> Recommendation :</b>
      </p>
      
      <b style="white-space: pre-line" 
        >
        <span >
         {{ getSickLeave.recommendation }}</span></b
      >
      <p class="sick-ma">
        <b class="italic">Patient seen From</b>
        <b> {{ dateFormat(getSickLeave.startDate) }}</b> To
        <b> {{ dateFormat(getSickLeave.endDate) }}</b>
      </p>

      <p class="sick-ma">
        <b class="italic">Rest required :</b>
        <b>{{ getSickLeave.restDate }}</b>
      </p>
      <p class="sick-ma">
        <b class="italic">Follow up :</b>
        <b>{{ dateFormat(getSickLeave.followup) }}</b>
      </p>
    </b-container>
    <!-- <div class="float-right" style="margin-bottom: 5px">
      <p class="float-right" style="margin-right: 70px">
        <strong>Dr : {{ firstName }} {{ middleName }} (MD, Internist)</strong>
      </p>
    </div> -->
    <Footer :dataFooter="dataFooter"></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PersonalName from "../../common/print/personalName";
import Header from "../../common/print/header";
import Footer from "../../common/print/footer";
export default {
  components: {
    PersonalName,
    Header,
    Footer,
  },
  //props: ["editMode"],
  data() {
    return {
      personal: {},
      dataFooter: {},
      modified: [],
      uniqueModified: [],
      labRequests: [],
      totalModified: [],
      labra: [],
      test: [],
      small: true,
      checkSelection: true,
      descrpition: "",
      index1: [],
      physicalExam: [],
      firstName: localStorage.getItem("firstName"),
      middleName: localStorage.getItem("middleName"),
      isSend: false,
      fields: ["name", "result", "range"],
    };
  },
  created() {
    this.personal = {
      mrn: this.getPatientProfile.patient.cardNo,
      fullName:
        this.getPatientProfile.patient.firstName +
        " " +
        this.getPatientProfile.patient.middleName +
        " " +
        this.getPatientProfile.patient.lastName,
      age: this.calcAge(this.getPatientProfile.patient.birthDate) + " " + "old",
      sex: this.getPatientProfile.patient.sex,
      dateCheck: this.getPatientProfile.patient.medicalHistory[0].date,
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
    };
    this.dataFooter = {
      date: window.moment().format("DD/MM/YY hh:mm:ss a"),
      printBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("middleName"),
      role: localStorage.getItem("Role"),
    };
    setTimeout(() => {
      window.print();
    }, 2000);
  },
  methods: {
    ...mapActions(["fetchPatientsProfile"]),

    calcAge(birthDate) {
      return window.moment(birthDate, "DD-MM-YYYY").toNow(true);
    },
    dateFormat(date) {
      return window.moment(date).format("DD/MM/YYYY");
    },
    convertToarray(item) {
      let converted = null;
      if (item != "") {
        converted = item.split(",");
      }

      return converted;
    },
  },
  computed: {
    ...mapGetters(["getPatientProfile", "getLabQueue", "getSickLeave"]),
    checkRadioQueue() {
      return this.getRadioQueues != null ? true : false;
    },
    changeBmiColor() {
      return this.physicalExam.bmi > 18 && this.index1.physicalExam.bmi < 25
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.sick-ma {
  margin-top: 20px;
}
* {
  font-size: 20px;
}
.leftSpace {
  padding-left: 50px;
}

.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  display: block;
}
.personalInfo {
  margin: 0px 5px;
}
li {
  list-style-type: none;
  display: block;
}
.edited {
  font-weight: bold;
}
.date {
  color: blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 1rem;
  padding-left: 10px;
  font-family: monospace;
  color: black;
}

.result {
  padding-left: 8px;
}
.physical {
  display: block;
}
.peNote {
  font-size: 18px;
}
.edited {
  font-weight: bold;
}
.red {
  background-color: red !important;
  color: white !important;
}
.green {
  background-color: green !important;
  color: white !important;
}
</style>
