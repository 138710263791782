<template>
  <div>
    <b-card no-body>
      <b-card-header class="Jdi-header" style="padding: 2px; margin: 0px">
        <b-form class="Jdi-row">
          <b-row>
            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                label="Name of treatment:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  size="sm"
                  v-model="form.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3" class="my-1">
              <b-form-group
                class="my-1"
                label="Category:"
                label-for="input-1"
                size="sm"
              >
                <b-form-select
                  size="sm"
                  v-model="form.category"
                  value-field="_id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <option v-for="item in getTreatments" :key="item.id">
                    {{ item.category }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="1" class="my-1">
              <b-form-group
                class="my-1"
                label="Price:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.price"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="1" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Dose:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.dose"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="1" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Unit:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.unit"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3" class="my-1">
              <b-button
                :disabled="checkRequired"
                v-if="createMode"
                variant="primary"
                style="margin-top: 40px"
                size="sm"
                class="float-right"
                @click.prevent="registerTestData"
              >
                Save
              </b-button>
              <b-button
                v-if="editMode"
                variant="primary"
                size="sm"
                style="margin-top: 40px"
                class="float-right"
                @click.prevent="updateTreatments"
              >
                Update
              </b-button>
              <b-button
                v-if="editMode"
                variant="default"
                size="sm"
                style="margin-top: 40px"
                class="float-right"
                @click.prevent="cancel"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-header>

      <b-card-body style="margin: 0px; padding: 0px">
        <b-row>
          <b-col lg="12" v-for="item in getTreatments" :key="item.id">
            <b style="padding: 0px; margin: 0px" class="capitlize">{{ item.category }}</b>

            <b-table
              :items="item.treatments"
              :fields="fields"
              ref="selectableTable"
              selectable
              noCollapse="true"
              :current-page="curentpage"
              :per-page="per_page"
              :small="small"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :tbody-tr-class="rowClass"
              responsive="sm"
              :select-mode="selectMode"
              @row-selected="onRowSelected"
              show-empty
              :empty-text="
                getUsersList ? 'Empty Record' : 'Unable to load data'
              "
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template v-slot:cell(status)="data">
                <toggle-button
                  :value="data.item.status >= 1 ? true : false"
                  color="#007bff"
                  :labels="true"
                  @change="updateTreatmentStatus(data.item._id)"
                />
              </template>

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width:
                      field.key === 'name' ||
                      field.key === 'price' ||
                      field.key === 'Status' ||
                      field.key === 'Actions'
                        ? '100px'
                        : '20px',
                  }"
                />
              </template>

              <template v-slot:cell(Actions)="data">
                <b-link
                  @click="editTreatment(data.item._id)"
                  class="text-primary"
                >
                  <b-icon icon="pen" variant="primary"></b-icon>
                </b-link>
                <!-- <b-link
                      @click="deleteTreatments(data.item._id)"
                      class="text-danger"
                    >
                      <b-icon icon="trash" variant="danger"></b-icon>
                    </b-link> -->
              </template>

              <template v-slot:table-caption>
                <b-pagination
                  class="float-right my-0"
                  v-model="currentpage"
                  :total-rows="item.treatments.length"
                  :per-page="per_page"
                  align="fill"
                  size="sm"
                  aria-controls="my-table"
                >
                </b-pagination>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";

export default {
  data() {
    return {
      small: true,
      curentData: {},
      getUsersList: false,
      curentEditId: "",
      selectedTest: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortBy: "name",
      sortDesc: false,
      isBusy: false,
      curentpage: 1,
      per_page: 40,
      pageOptions: [5, 30, 50, "All"],
      genderList: ["Male", "Female"],
      form: {
        name: "",
        category: "",
        price: "",
        unit: "",
        dose: "",
      },
      categories: [
        "HEMATOLOGY",
        "CLINICAL CHEMISTRY",
        "URINALYSIS",
        "SEROLOGY",
      ],
      fields: fields.fildsTreatmentList,
    };
  },

  created() {
    this.fetchTreatments().then(() => {
      this.getUsersList = true;
    });
  },
  methods: {
    ...mapActions([
      "addTreatment",
      "fetchTreatments",
      "fetchTreatment",
      "deleteTreatmentOne",
      "updateTreatmentOne",
      "changeTreatmentStatus",
    ]),
    // rowClass(item, type) {
    //   if (!item || type !== "row") return;
    //   if (item.status === false) return "table-danger";
    // },
    cancel() {
      this.createMode = true;
      this.editMode = false;
      this.clear();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    updateTreatmentStatus(id) {
      this.changeTreatmentStatus(id);
      //this.fetchTreatments();
    },

    registerTestData() {
      const TestData = {
        name: this.form.name,
        unitPrice: this.form.price,
        category: this.form.category,
        dose: this.form.dose,
        unit: this.form.unit,
      };
      this.addTreatment(TestData).then(() => {
        this.clear();
        this.fetchTreatments();
      });
      this.$bvToast.show("toaster");
    },
    updateTreatments() {
      const TreatmentData = {
        name: this.form.name,
        category: this.form.category,
        unitPrice: this.form.price,
        dose: this.form.dose,
        unit: this.form.unit,
        id: this.curentEditId,
      };
      this.updateTreatmentOne(TreatmentData).then(() => {
        (this.editMode = false), (this.createMode = true), this.clear();
        this.fetchTreatments();
      });
      this.$bvToast.show("toaster");
    },
    clear() {
      (this.form.name = ""),
        (this.form.dose = ""),
        (this.form.unit = ""),
        (this.form.price = ""),
        (this.form.category = "");
    },
    editTreatment(index) {
      this.fetchTreatment(index).then(() => {
        this.curentData = this.getTreatment;
        this.form.name = this.curentData.treatment.name;
        this.curentEditId = this.curentData.treatment._id;
        this.form.price = this.curentData.treatment.unitPrice;
        this.form.category = this.curentData.category;
        this.form.unit = this.curentData.treatment.unit;
        this.form.dose = this.curentData.treatment.dose;

        this.editMode = true;
        this.createMode = false;
      });

      // this.$swal(index);
    },
    deleteTreatments(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((deleteU) => {
          if (deleteU) {
            this.deleteTreatmentOne(id);
            this.$bvToast.show("toaster");
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getTreatments", "getTreatment", "getErrors"]),
    checkRequired() {
      return this.form.name <= 0 ||
        this.form.category <= 0 ||
        this.form.price <= 0
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.twa-row {
  margin: 0px;
  padding: 0px;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
