import axios from "axios";

const state = {
  category: [],
  categorys: [],
  radiologyCategory: [],
};

const mutations = {
  setCategory: (state, category) => (state.category = category),
  setCategorys: (state, categorys) => (state.categorys = categorys),
  pushCategoryCategory: (state, radiologyCategory) =>
    state.radiologyCategory.unshift(radiologyCategory),
  pushCategory: (state, categorys) => state.categorys.unshift(categorys),
  deleteCategory: (state, id) =>
    (state.categorys = state.categorys.filter((user) => user.id !== id)),
  updateCategory(state, payload) {
    console.log(payload)
    const object = state.radiologyCategory.find((item) => item._id === payload._id);
    Object.assign(object, payload);
  },
};

const getters = {
  getCategorys: (state) => {
    return state.categorys;
  },
  getCategory: (state) => {
    return state.category;
  },
};

const actions = {
  async fetchCategorys({ commit }) {
    await axios
      .get("/labTests")
      .then((response) => {
        commit("setCategorys", response.data.labTests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  async fetchCategorysAll({ commit }) {
    await axios
      .get("/labTests/all")
      .then((response) => {
        commit("setCategorys", response.data.labTests);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  async addCategory({ commit }, object) {
    await axios
      .post("/labTestCategory", object)
      .then((response) => {
        commit("pushCategory", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async updateCategorys({ commit }, object) {
    await axios
      .post("/labTestCategory/" + `${object.id}`, object)
      .then((response) => {
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
  async updateCategorysName({ commit }, object) {
    await axios
      .patch("/radiologyCategory/" + `${object.id}`, object)
      .then((res) => {
        console.log(res.data)
        commit("setErrors", "Updated Successfuly");

      })
      .catch((error) => {
        console.log(error)
        commit("setErrors", error.response.data.error);
      });
  },
  async updateLabCategorysName({ commit }, object) {
    await axios
      .patch("/labCategory/" + `${object.id}`, object)
      .then((res) => {
        console.log(res.data)
        commit("setErrors", "Updated Successfuly");

      })
      .catch((error) => {
        console.log(error)
        commit("setErrors", error.response.data.error);
      });
  },

  async addCategoryRadiology({ commit }, object) {
    await axios
      .post("/radiologyCategory", object)
      .then((response) => {
        commit("pushCategoryCategory", response.data);
        commit("setErrors", response.data.message);
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setErrors", error.response.data.error);
        return Promise.reject();
      });
  },
};
//---------------------------------

export default {
  state,
  getters,
  actions,
  mutations,
};
