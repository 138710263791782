<template>
  <div>
    <b-card no-body>
      <b-card-header class="Jdi-header" style="padding: 2px; margin: 0px">
        <b-form class="Jdi-row">
          <b-row>
            <b-col lg="4" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Name Of test:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Category:"
                label-for="input-1"
                size="sm"
              >
                <b-form-select
                  id="input-3"
                  class="my-1"
                  size="sm"
                  v-model="form.category"
                  value-field="_id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <option v-for="item in getRadioTests" :key="item.id">
                    {{ item.category }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="4" class="my-1">
              <b-form-group
                class="my-1"
                id="input-group-1"
                label="Price:"
                label-for="input-1"
                size="sm"
              >
                <b-form-input
                  class="my-1"
                  id="input-1"
                  size="sm"
                  v-model="form.price"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12" v-if="!editMode">
              <b-form-group label-colssm="3" class="mb-0">
                <b-input-group
                  class="my-1"
                  id="input-group-1"
                  label="Findings:"
                  label-for="input-1"
                  size="sm"
                >
                  <b-form-input
                    class="my-1"
                    id="input-1"
                    size="sm"
                    v-model="form.Finding"
                    type="text"
                    placeholder="enter findings here"
                    required
                  ></b-form-input>
                  <b-input-group-append class="btnAdd my-1">
                    <b-button
                      @click="addFinding"
                      variant="info"
                      :disabled="form.Finding == ''"
                      >Add</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <p v-if="validateFindingLists">
                  please add atleast one finding first
                </p>
              </b-form-group>
            </b-col>

            <ul v-for="item in findingList" :key="item.id">
              <li class="findingItem"># {{ item.name }}</li>
            </ul>

            <b-col lg="12" class="my-1">
              <b-button
                v-if="createMode"
                variant="primary"
                size="sm"
                class="float-right"
                @click.prevent="registerTestData"
                :disabled="validateRadioTests"
              >
                Save
              </b-button>
              <b-button
                v-if="editMode"
                variant="primary"
                style="margin-top: 40px"
                class="float-right"
                size="sm"
                @click.prevent="updateRadioTests"
              >
                Update
              </b-button>
              <b-button
                v-if="editMode"
                variant="secondary"
                size="sm"
                style="margin-top: 40px"
                class="float-right mx-1"
                @click.prevent="cancel"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-header>

      <b-card-body style="margin: 0px; padding: 0px">
        <b-row>
          <b-col lg="12" v-for="item in getRadioTests" :key="item.id">
            <b class="capitalize">{{ item.category }}</b>

            <b-table
              :items="item.tests"
              :fields="fields"
              ref="selectableTable"
              selectable
              noCollapse="true"
              :current-page="curentpage"
              :per-page="per_page"
              :small="small"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :tbody-tr-class="rowClass"
              responsive="sm"
              :select-mode="selectMode"
              @row-selected="onRowSelected"
              show-empty
              :empty-text="
                getUsersList ? 'Empty Record' : 'Unable to load data'
              "
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width:
                      field.key === 'name' ||
                      field.key === 'price' ||
                      field.key === 'Status' ||
                      field.key === 'Actions'
                        ? '100px'
                        : '20px',
                  }"
                />
              </template>

              <template v-slot:cell(status)="data">
                <toggle-button
                  :value="data.item.status >= 1 ? true : false"
                  color="#007bff"
                  :labels="true"
                  @change="updateRadioStatus(data.item._id)"
                />
              </template>

              <template v-slot:cell(Actions)="data">
                <b-link @click="editRadio(data.item._id)" class="text-primary">
                  <b-icon icon="pen" variant="primary"></b-icon>
                </b-link>
                <!-- <b-link @click="deleteUsers()" class="text-danger">
                      <b-icon icon="trash" variant="danger"></b-icon>
                    </b-link> -->
              </template>

              <template v-slot:table-caption>
                <b-pagination
                  class="float-right my-0"
                  v-model="currentpage"
                  :total-rows="item.tests.length"
                  :per-page="per_page"
                  align="fill"
                  size="sm"
                  aria-controls="my-table"
                >
                </b-pagination>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- {{ curentData }} -->
    <!-- {{ findingList }} -->
    <b-toast id="toaster" toaster="b-toaster-top-right">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <div v-if="getErrors != null">
          {{ getErrors }}
        </div>
        <div v-if="getErrors == null">
          <b-spinner variant="danger" label="Spinning" small></b-spinner>&nbsp;
          Processing...
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "../../constants/fieldsTable";

export default {
  data() {
    return {
      small: true,
      getUsersList: false,
      curentEditId: "",
      editMode: false,
      createMode: true,
      displayList: true,
      displayCreate: false,
      selectMode: "single",
      selected: [],
      sortBy: "name",
      findingList: [],
      sortDesc: false,
      isBusy: false,
      curentpage: 1,
      per_page: 40,
      curentData: [],
      pageOptions: [5, 30, 50, "All"],
      genderList: ["Male", "Female"],
      form: {
        name: "",
        category: "",
        price: "",
        Finding: "",
      },
      
      fields: fields.fieldsLabTest,
    };
  },

  created() {
    this.fetchRadioTestsAll().then(() => {
      this.getTestsList = true;
    });
  },
  methods: {
    ...mapActions([
      "addRadioTest",
      "fetchRadioTestsAll",
      "updateRadiologyTest",
      "fetchRadioTest",
      "changeRadioTestStatus",
    ]),
    addFinding() {
      this.findingList.unshift({ name: this.form.Finding });
      this.form.Finding = null;
    },
    updateRadioStatus(id) {
      this.changeRadioTestStatus(id).then(() => {
        this.fetchRadioTestsAll();
      });
      this.$bvToast.show("toaster");
    },
    // rowClass(item, type) {
    //   if (!item || type !== "row") return;
    //   if (item.status === false) return "table-danger";
    // },
    onRowSelected(items) {
      this.selected = items;
    },
    registerTestData() {
      const TestData = {
        name: this.form.name,
        price: this.form.price,
        category: this.form.category,
        findings: this.findingList,
      };
      this.addRadioTest(TestData).then(() => {
        this.fetchRadioTestsAll();
        this.clear();
      });
      this.$bvToast.show("toaster");
    },
    cancel() {
      this.editMode = false;
      this.createMode = true;
      this.Form.Finding = [];
      this.clear();
    },
    updateRadioTests() {
      const LabTestData = {
        name: this.form.name,
        category: this.form.category,
        price: this.form.price,
        id: this.curentEditId,
      };
      this.updateRadiologyTest(LabTestData).then(() => {
        this.fetchRadioTestsAll();
        this.clear();
      });
      this.$bvToast.show("toaster");
    },
    clear() {
      (this.form.name = ""),
        (this.form.price = ""),
        (this.form.category = ""),
        (this.form.Finding = []);
    },
    editRadio(index) {
      this.fetchRadioTest(index).then(() => {
        this.curentData = this.getSingleRadioTest;
        this.form.name = this.curentData.radiologyTest.name;
        this.curentEditId = this.curentData.radiologyTest._id;
        this.form.price = this.curentData.radiologyTest.price;
        this.form.category = this.curentData.category;
        this.editMode = true;
        this.createMode = false;
      });
    },
    deleteLabTests(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "danger",
          noCloseOnBackdrop: true,
          centered: true,
        })
        .then((deleteU) => {
          if (deleteU) {
            this.deleteLabTest(id);
            this.$bvToast.toast("Deleted Sucessfully!!!!!", {
              title: "Deleted",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getRadioTests", "getSingleRadioTest", "getErrors"]),
    validateFinding() {
      return this.form.Finding.length <= 0 ? true : false;
    },
    validateRadioTests() {
      return this.form.name == "" ||
        this.form.category == "" ||
        this.form.price == ""
        ? true
        : false;
    },
    validateFindingLists() {
      return this.findingList.length == null ? true : false;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 15px;
  border: 1px solid darkgray;
  font-size: 14px;
}
li {
  list-style-type: none;
  display: block;
}
.btnAdd {
  height: 30px;
}
.twa-header {
  margin: 0px;
  padding: 0px;
}
.twa-body {
  margin: 0px;
  padding: 0px;
}
.JDI-row {
  margin: 0px;
  padding: 0px;
}
.findingItem {
  margin-right: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 12px;
  display: block;
  width: 100%;
}
select {
  margin-bottom: 15px;
  font-size: 13px;
  border: 1px solid darkgray;
}
</style>
