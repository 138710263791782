<template>
  <div style="margin-top: -100px">
    <b-row class="main-header">
      <b-col class="col-sm-3 col-3">
        <img src="@/assets/images/2.jpg"
      /></b-col>
      <b-col class="col-sm-6 col-6">
        <h3 style="text-align: center; color: blue; font-size: 40px">
          ቅዱስ ስፔሻሊቲ ክሊኒክ
        </h3>
        <h3
          style="
            text-align: center;
            color: red;
            font-size: 35px;
            font-family: Lucida Handwriting;
          "
        >
          Kdus Speciality Clinic
        </h3>
      </b-col>
      <b-col class="text-muted col-sm-3 col-3">
        <!-- <span style="font-family: Agency FB"
          >
          <span style="text-align: center;" class="ml-3">
          <span
            style="color: blue; font-size: 40px; margin-bottom: 0px"
            class="opacity mb-0"
            >Just</span
          ><span class="opacity mb-0" style="color: green; font-size: 40px; margin-bottom: 0px"
            >Care</span
          ></span>
          <p class="my-0" style="margin-top: -17px !important;">
            Excellence is our brand.
            <small style="margin-top: -10px !important;text-align: center;" class="d-flex">Powered by JDI.</small>
          </p>
        </span> -->
      </b-col>
      <b-row>
        <b-col sm="6" class="col-sm-5 ml-5 col-6">
          <!-- <p class="italic">
          Address :<b>Mekelle, Adishmduhn near traffic light.</b>
        </p> -->
          <p class="italic" style="margin-top: -20px; font-size: 16px">
            ኣድራሻ :<b style="font-size: 16px">መቐለ, ዓዲ ሓቂ ድሕሪ ደደቢት ማይክሮ ፋይናንስ</b>
          </p>
        </b-col>
        <b-col sm="2" class="col-sm-4 col-3" style="margin-top: -20px">
          <span class="italic"
            ><i class="fa fa-mobile"></i>
            <b style="font-size: 16px">0968-944000</b> </span
          ><span class="italic"
            ><i class="fa fa-phone-alt"></i>
            <b style="font-size: 16px">034-4412323</b>
          </span>
        </b-col>
        <b-col sm="3" class="col-sm-3 col-3" style="margin-top: -20px"> </b-col>
      </b-row>
    </b-row>
    <h3 class="text-center my-1" style="font-size: 40px">
      {{ subTitle }}
    </h3>
  </div>
</template>

<script>
export default {
  props: ["subTitle"],
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  font-size: 20px;
}
.main-footer {
  top: 0;
  width: 100%;
}
.opacity {
  opacity: 0.3;
}
.italic {
  font-style: italic;
  font-size: 14px;
  padding-right: 5px;
  border-radius: 6px;
}
.main-header {
  border: 1px solid lightgray;
  margin-left: 25px;
  border-radius: 6px;
  /* height: 200px; */
}
.row {
  width: 100%;
  padding: 3px;
}
</style>
